import type { RichTextToolbarOptions } from '@shared/elements/rich-text/rich-text.types';
import icons from './rich-text.svg.json';

export enum RichTextElementTypes {
  SELECT = 'SELECT',
  BUTTON = 'BUTTON',
  INPUT = 'INPUT',
}

export const NONE = 'none';

export const RICH_TEXT_TOOLBAR: Array<RichTextToolbarOptions> = [
  {
    undo: {
      action: 'undo',
      element: RichTextElementTypes.BUTTON,
      title: 'Undo',
      icon: icons['undo'],
      shortcut: 'z',
    },
    redo: {
      action: 'redo',
      element: RichTextElementTypes.BUTTON,
      title: 'Redo',
      icon: icons['redo'],
      shortcut: 'y',
    },
  },
  {
    // 'font-name': {
    //   action: 'fontname',
    //   element: RichTextElementTypes.SELECT,
    //   title: 'Font',
    //   options: [
    //     { value: 'serif', text: 'Serif', selected: true },
    //     { value: 'sans-serif', text: 'Sans Serif' },
    //     { value: 'monospace', text: 'Monospace' },
    //     { value: 'cursive', text: 'Cursive' },
    //     { value: 'fantasy', text: 'Fantasy' },
    //   ],
    // },
    bold: {
      action: 'bold',
      element: RichTextElementTypes.BUTTON,
      title: 'Bold',
      icon: icons['bold'],
      shortcut: 'b',
    },
    italic: {
      action: 'italic',
      element: RichTextElementTypes.BUTTON,
      title: 'Italic',
      icon: icons['italic'],
      shortcut: 'i',
    },
    underline: {
      action: 'underline',
      element: RichTextElementTypes.BUTTON,
      title: 'Underline',
      icon: icons['underline'],
      shortcut: 'u',
    },
    'font-size': {
      action: 'fontsize',
      element: RichTextElementTypes.SELECT,
      title: 'Font size',
      options: [
        { text: '48px', value: '48px' },
        { text: '32px', value: '32px' },
        { text: '24px', value: '24px' },
        { text: '18px', value: '18px' },
        { text: '16px', value: '16px' },
        { text: '14px', value: '14px' },
        { text: '12px', value: '12px' },
        { text: '10px', value: '10px' },
        { text: '8px', value: '8px' },
      ],
    },
    // 'font-color': {
    //   action: 'fontcolor',
    //   element: RichTextElementTypes.INPUT,
    //   title: 'Font Color',
    //   type: 'color',
    // },
  },
  {
    'format-block': {
      action: 'formatblock',
      element: RichTextElementTypes.SELECT,
      title: 'Styles',
      options: [
        { value: 'p', text: 'Paragraph' },
        { value: 'h1', text: 'Title 1' },
        { value: 'h2', text: 'Title 2' },
        { value: 'h3', text: 'Title 3' },
        { value: 'h4', text: 'Title 4' },
        { value: 'h5', text: 'Title 5' },
        { value: 'h6', text: 'Title 6' },
        { value: 'pre', text: 'Preformatted' },
      ],
    },
  },
  {
    'justify-left': {
      action: 'justifyleft',
      element: RichTextElementTypes.BUTTON,
      title: 'Justify Left',
      icon: { ...icons['justify'], flip: false },
    },
    'justify-center': {
      action: 'justifycenter',
      element: RichTextElementTypes.BUTTON,
      title: 'Justify Center',
      icon: icons['justify-center'],
    },
    'justify-right': {
      action: 'justifyright',
      element: RichTextElementTypes.BUTTON,
      title: 'Justify Right',
      icon: { ...icons['justify'], flip: true },
    },
  },
  {
    'insert-ordered-list': {
      action: 'insertorderedlist',
      element: RichTextElementTypes.BUTTON,
      title: 'Numbered List',
      icon: icons['numbered-list'],
      shortcut: 'o',
    },
    'insert-unordered-list': {
      action: 'insertunorderedlist',
      element: RichTextElementTypes.BUTTON,
      title: 'Bullet List',
      icon: icons['bulleted-list'],
      shortcut: 'l',
    },
    indent: {
      action: 'indent',
      element: RichTextElementTypes.BUTTON,
      title: 'Increase indent',
      icon: { ...icons['indent'], flip: true, direction: 'ltr' },
      shortcut: ']',
    },
    outdent: {
      action: 'outdent',
      element: RichTextElementTypes.BUTTON,
      title: 'Decrease indent',
      icon: { ...icons['indent'], flip: false, direction: 'ltr' },
      shortcut: '[',
    },
  },
  {
    'remove-format': {
      action: 'removeformat',
      element: RichTextElementTypes.BUTTON,
      title: 'Remove Format',
      icon: icons['clean'],
    },
  },
  {
    'doc-direction': {
      action: 'doc-direction',
      element: RichTextElementTypes.BUTTON,
      title: 'Direction',
      icon: icons['direction'],
    },
  },
  {
    'full-screen': {
      action: 'full-screen',
      element: RichTextElementTypes.BUTTON,
      title: 'Full Screen',
      icon: icons['full-screen'],
    },
  },
  // {
  //   'insert-dynamic-placeholder': {
  //     element: RichTextElementTypes.SELECT,
  //     title: 'Insert Dynamic Placeholder',
  //     action: 'insert-dynamic-placeholder',
  //     type: 'dynamic-placeholder-select',
  //     options: [
  //       { text: 'Select Placeholder', value: '', selected: true },
  //       { text: '[NAME]', value: '[NAME]' }, // Add other placeholders as needed
  //     ],
  //   },
  // },
];
