import store, { type RootState } from '@/store';
import type { ActionTree } from 'vuex';
import type { SettingsState } from '@/views/settings/store/settings.types';
import type {
  GetLetterHeaderFooterRes,
  GetUserConfigurationRes,
  UpdateLetterHeaderFooterReq,
  UpdateUserConfigurationReq,
  UpdatePhoneNumberReq,
} from '@/views/settings/types';
import { container } from 'tsyringe';
import SettingsService from '@/views/settings/service/settings.service';
import {
  AGREE_TO_TERMS,
  GET_LETTER_HEADER_FOOTER,
  GET_USER_SETTINGS,
  UPDATE_LETTER_HEADER_FOOTER,
  UPDATE_USER_SETTINGS,
  UPDATE_PHONE_NUMBER,
} from '@/views/settings/store/settings.constants';
import FileService from '@shared/services/file.service';
import { StoreNamespace } from '@/store/store-namespace';
import { GET_AUTH_SESSION } from '@/store/auth/auth.constants';

const settingsService = container.resolve(SettingsService);

const settingsActions: ActionTree<SettingsState, RootState> = {
  [AGREE_TO_TERMS]: async (_): Promise<void> => {
    await settingsService[AGREE_TO_TERMS]();
    await store.dispatch(`${StoreNamespace.AUTH_MODULE}/${GET_AUTH_SESSION}`);
  },

  [GET_USER_SETTINGS]: async (_): Promise<GetUserConfigurationRes> => {
    return await settingsService[GET_USER_SETTINGS]();
  },

  [UPDATE_USER_SETTINGS]: async (_, payload: UpdateUserConfigurationReq): Promise<void> => {
    await settingsService[UPDATE_USER_SETTINGS](payload);
  },

  [UPDATE_PHONE_NUMBER]: async (_, payload: UpdatePhoneNumberReq): Promise<void> => {
    return await settingsService[UPDATE_PHONE_NUMBER](payload);
  },

  [GET_LETTER_HEADER_FOOTER]: async (_): Promise<GetLetterHeaderFooterRes> => {
    return await settingsService[GET_LETTER_HEADER_FOOTER]();
  },

  [UPDATE_LETTER_HEADER_FOOTER]: async (_, letter: UpdateLetterHeaderFooterReq): Promise<void> => {
    const { header, footer } = letter;

    const payload = new FormData();
    payload.append('header', header ? FileService.base64ToFile(header, { name: 'header', type: 'image/png' }) : 'null');
    payload.append('footer', footer ? FileService.base64ToFile(footer, { name: 'footer', type: 'image/png' }) : 'null');

    await settingsService[UPDATE_LETTER_HEADER_FOOTER](payload);
  },
};

export default settingsActions;
