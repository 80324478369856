import type { Ref } from 'vue';

export const useFormValidator = (formRef: Ref<Element | undefined>, isSilent?: boolean): (() => boolean) => {
  let isFormValid = true;

  const execute = (el?: Element): boolean => {
    return Array.from((el || formRef.value)?.children || [])
      .map((child: Element) => {
        const isValid = !child?.getAttribute('data-errors');

        if (!isValid && isFormValid) {
          isFormValid = false;
          child.scrollIntoView({ block: 'center', behavior: 'smooth', inline: 'center' });
        }

        return [Array.from(child.children || [])?.length ? execute(child) : true, isValid].every((item) => item);
      })
      .every((isValid) => isValid);
  };

  return () => {
    isFormValid = true;
    const isValid = execute();

    if (isSilent) {
      return isValid;
    }

    if (!isValid) {
      formRef.value?.setAttribute('validate', 'errors');
    } else {
      formRef.value?.removeAttribute('validate');
    }

    return isValid;
  };
};
