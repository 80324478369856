<template>
  <vz-overlay
    v-if="isEnabled"
    ref="vzOverlayRef"
    class="employee-modal"
    :size="VzOverlayEnum.LARGE"
    :is-open="isActive || isLoading"
    v-bind="$attrs"
    @close="routeTo({ query: { userId: null } })"
  >
    <div v-if="isLoading" class="d-flex justify-center align-center">
      <vz-spinner size="96px" />
    </div>

    <employee-feed v-else-if="isActive" class="flex-grow-1 overflow-hidden" :fixed-about="!isMobile" />
  </vz-overlay>
</template>

<script setup lang="ts">
import { computed, ref, defineAsyncComponent, watch } from 'vue';
import { useRoute } from 'vue-router';
import { VzOverlayEnum } from '@shared/components/overlay/vz-overlay.enum';
import { routeTo, useAsync } from '@shared/composables';
import { GET_EMPLOYEE_DETAILS } from '@/views/employee/store/employee.constants';
import { EMPLOYEE_VIEW_MODAL_IGNORE_ROUTE_LIST } from '@/views/lobby/modals/employee-view-modal.constants';
import useEmployeeStore from '@/views/employee/composables/use-employee-store';
import { isMobile } from '@shared/helpers';

const EmployeeFeed = defineAsyncComponent(
  () => import(/* webpackChunkName: "profile" */ '@/views/employee/components/profile/components/employee-feed.vue')
);

const { useActions: useEmployeeActions } = useEmployeeStore();
const { [GET_EMPLOYEE_DETAILS]: getEmployeeDetailsAction } = useEmployeeActions([GET_EMPLOYEE_DETAILS]);

const getEmployeeDetailsRequest = useAsync(getEmployeeDetailsAction as (payload: { userId: string }) => Promise<void>);

const route = useRoute();
const vzOverlayRef = ref();

const userId = computed((): string | undefined => (route.query?.userId as string) || undefined);
const isLoading = computed((): boolean => getEmployeeDetailsRequest.loading.value);
const isActive = computed((): boolean => !!userId.value && !getEmployeeDetailsRequest.loading.value);
const isEnabled = computed((): boolean => !EMPLOYEE_VIEW_MODAL_IGNORE_ROUTE_LIST.includes(route.name as string));

watch(
  userId,
  async (id) => {
    if (!id) {
      vzOverlayRef.value?.close();

      return;
    }

    getEmployeeDetailsAction({ userId: id });
  },
  { immediate: true }
);
</script>

<style lang="scss">
.employee-modal {
  .vz-overlay-modal__close-button {
    top: 1.5rem !important;
    @include inline-end(1.75rem);
  }
}
</style>
