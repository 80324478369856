import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "vz-card__toolbar"
}
const _hoisted_2 = { class: "d-flex gap-2 justify-end" }

import { computed, type PropType, ref, useSlots, watch } from 'vue';
import { useAsync, useResizeObserver, useServerErrorsMapper } from '@/shared/composables';
import { useFormValidator } from '@/shared/components/fields/helpers';
import VzErrorAlert from '@shared/components/vz-error-alert.vue';

enum CardMode {
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-card',
  props: {
  removable: { type: Boolean, default: false },
  editable: { type: Boolean, default: true },
  loading: { type: Boolean, default: false },
  nonClickable: { type: Boolean, default: false },
  showLabels: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  flat: { type: Boolean, default: false },
  updateCallback: { type: Function as PropType<(...arg: any) => Promise<any> | any>, default: undefined },
  updatePayload: { type: Object as PropType<any>, default: () => ({}) },
  deleteCallback: { type: Function as PropType<() => Promise<void> | undefined>, default: undefined },
},
  emits: ['edit', 'remove', 'click', 'load', 'update'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;
const slots = useSlots();

const updateCallbackRequest = useAsync(props.updateCallback, {
  successCallback: () => {
    setTimeout(() => (cardMode.value = CardMode.VIEW));
    emit('edit', false);
  },
  errorsCleanTimeout: 30 * 1000,
});

const cardMode = ref<CardMode>(CardMode.VIEW);
const cardRef = ref<Element | undefined>(undefined);
const formRef = ref<Element | undefined>(undefined);
const errors = useServerErrorsMapper(updateCallbackRequest.error);

const { ratio: cardRatio } = useResizeObserver(cardRef, true);

watch(
  () => cardRatio.value,
  () => {
    emit('load', { ratio: cardRatio.value });
  }
);

const onEdit = (): void => {
  if (!slots['edit']) {
    updateCallbackRequest.call(props.updatePayload);

    return;
  }

  cardMode.value = cardMode.value === CardMode.EDIT ? CardMode.VIEW : CardMode.EDIT;
  emit('edit', cardMode.value === CardMode.EDIT);
};

const onRemove = (): void => {
  if (props.deleteCallback) {
    props.deleteCallback();
  } else {
    emit('remove');
  }
};

const onClick = (ev: Event): void => {
  if (props.nonClickable) {
    return;
  }

  if (cardMode.value !== CardMode.VIEW) {
    ev.stopPropagation();
    ev.preventDefault();
  } else {
    emit('click');
  }
};

const onSubmit = async (ev: Event): Promise<void> => {
  ev.stopPropagation();
  ev.preventDefault();

  const isValid = useFormValidator(formRef);

  if (!isValid()) {
    return;
  }

  await updateCallbackRequest.call(props.updatePayload);
};

const onCancel = (ev: Event): void => {
  if (cardMode.value === CardMode.EDIT) {
    setTimeout(() => (cardMode.value = CardMode.VIEW));
    emit('edit', false);
  }

  ev.stopPropagation();
  ev.preventDefault();
};

__expose({ onEdit, onRemove, disabled: computed(() => cardMode.value !== CardMode.VIEW) });

return (_ctx: any,_cache: any) => {
  const _component_vz_button = _resolveComponent("vz-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vz-card", { 'vz-card--clickable': !__props.nonClickable && cardMode.value !== CardMode.EDIT, 'vz-card--flat': __props.flat, skeleton: __props.loading }]),
    onClick: onClick
  }, [
    (!__props.disabled && cardMode.value === CardMode.VIEW && __props.editable && (__props.removable || __props.deleteCallback || _ctx.$slots['edit'] || __props.updateCallback))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (__props.removable || __props.deleteCallback)
            ? (_openBlock(), _createBlock(_component_vz_button, {
                key: 0,
                color: "red-700",
                "icon-name": "svg:trash",
                "icon-size": "1rem",
                "background-color": "mono-100",
                type: "solid",
                text: __props.showLabels ? 'GENERAL.REMOVE' : undefined,
                onClick: onRemove
              }, null, 8, ["text"]))
            : _createCommentVNode("", true),
          (_ctx.$slots['edit'] || __props.updateCallback)
            ? (_openBlock(), _createBlock(_component_vz_button, {
                key: 1,
                color: "primary-900",
                "icon-name": "svg:edit",
                "icon-size": "1rem",
                "background-color": "mono-100",
                type: "solid",
                text: __props.showLabels ? 'GENERAL.EDIT' : undefined,
                onClick: onEdit
              }, null, 8, ["text"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.$slots.static)
      ? _renderSlot(_ctx.$slots, "static", { key: 1 })
      : _createCommentVNode("", true),
    (_ctx.$slots.view || _ctx.$slots.edit || _ctx.$slots.remove)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (cardMode.value === CardMode.VIEW && _ctx.$slots['view'])
            ? _renderSlot(_ctx.$slots, "view", { key: 0 })
            : (cardMode.value === CardMode.EDIT && _ctx.$slots['edit'])
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("form", {
                    ref_key: "formRef",
                    ref: formRef,
                    class: "flex-grow-1 overflow-y-auto",
                    role: "form",
                    autocomplete: "off",
                    onDblclick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop","prevent"])),
                    onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
                  }, [
                    _createVNode(VzErrorAlert, {
                      errors: _unref(updateCallbackRequest).error.value
                    }, null, 8, ["errors"]),
                    _renderSlot(_ctx.$slots, "edit", {
                      errors: _unref(errors),
                      formRef: formRef.value
                    })
                  ], 544),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_vz_button, {
                      text: "GENERAL.SAVE",
                      loading: _unref(updateCallbackRequest).loading.value,
                      onClick: onSubmit
                    }, null, 8, ["loading"]),
                    _createVNode(_component_vz_button, {
                      text: "GENERAL.CANCEL",
                      disabled: _unref(updateCallbackRequest).loading.value,
                      onClick: onCancel
                    }, null, 8, ["disabled"])
                  ])
                ], 64))
              : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "bottom")
  ], 2))
}
}

})