import { ItemFunctionArg, ItemValueProp, ItemValueReturn } from '../models';

export const itemValueFunction = (item: ItemFunctionArg, key: string): any => {
  if (!item) {
    return;
  }

  if (!key) {
    return item;
  }

  return typeof item === 'object' ? item[key] || item : item;
};

export const useGetItemValue = (prop: ItemValueProp): ItemValueReturn => {
  return (item: ItemFunctionArg) => (prop instanceof Function ? prop(item) : itemValueFunction(item, prop));
};
