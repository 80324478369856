import type { ListCurrenciesRes } from '@/store/client/models';
import { computed, type ComputedRef, type Ref } from 'vue';
import { useAuthUser } from '@/views/employee/composables/use-auth-user';
import useClientStore from '@/store/client/composables/use-client-store';
import dayjs from 'dayjs';
import { GlobalVariables } from '@/shared/constants/global-variables';
import { LIST_CURRENCIES } from '@/store/client/client.constants';
import { useAsync } from '@shared/composables/use-async';
import { useCurrencySymbol } from '@shared/composables/use-currency-symbol';

export const useCurrencyConverter = (amount?: Ref<number | undefined>, fromCurrency?: Ref<string | undefined>): ComputedRef<string | null> => {
  const { useActions: useGeneralActions } = useClientStore();

  const { [LIST_CURRENCIES]: listCurrenciesAction } = useGeneralActions([LIST_CURRENCIES]);
  const { call, results } = useAsync<ListCurrenciesRes['list']>(listCurrenciesAction);

  const myCurrency = computed((): string | undefined => useAuthUser().activeUser.value?.currency);

  call();

  return computed((): string | null => {
    if (!amount && !fromCurrency) {
      return dayjs().format(GlobalVariables.DAY_MONTH_YEAR_TIME_FORMAT);
    }

    if (!amount?.value || !fromCurrency?.value || !myCurrency.value || myCurrency.value === fromCurrency.value) {
      return null;
    }

    const exchange = computed(() => results.value?.find(({ code }) => code === fromCurrency.value)?.exchange);

    if (!exchange.value) {
      return null;
    }

    return useCurrencySymbol(+(amount.value * (exchange.value || 1)).toFixed(2), myCurrency.value).value;
  });
};
