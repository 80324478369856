import type { SizeUnit } from '@shared/types';

export const convertRemToPixel = (size: SizeUnit, withSuffix: boolean = false): string | number => {
  if (!size?.includes('rem')) {
    return size;
  }

  const rem = parseFloat(size) * parseFloat(getComputedStyle(document.documentElement).fontSize);

  return withSuffix ? `${rem}px` : rem;
};
