import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "vz-collapse" }

import { computed, type PropType, ref, watch } from 'vue';
import { useResizeObserver } from '@/shared/composables';
import { convertPixelToRem } from '@/shared/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-collapse',
  props: {
  minHeight: { type: [Number, String], required: true },
  unitType: { type: String as PropType<'px' | 'rem'>, default: 'px' },
  transition: { type: Number, default: 0.3 },
},
  setup(__props) {

const props = __props;

const isShorten = ref<boolean>(false);
const isShowMore = ref<boolean>(false);
const containerRef = ref<Element | undefined>(undefined);
const { height: containerHeight } = useResizeObserver(containerRef, true);

const maxHeight = computed(() => {
  switch (props.unitType) {
    case 'rem':
      return convertPixelToRem(`${containerHeight.value}px`);
    default:
      return containerHeight.value;
  }
});

const minHeight = computed(() => {
  switch (props.unitType) {
    case 'rem':
      return convertPixelToRem(`${+props.minHeight}px`);
    default:
      return +props.minHeight;
  }
});

const isExtendable = computed((): boolean => maxHeight.value > minHeight.value);

const containerStyle = computed(() => {
  if (!isExtendable.value) {
    return;
  }

  return isExtendable.value && isShowMore.value ? { maxHeight: `${maxHeight.value}px` } : { maxHeight: `calc(${minHeight.value} - 24px)` };
});

const onCollapse = () => {
  isShowMore.value = !isShowMore.value;

  if (!isShowMore.value) {
    setTimeout(() => (isShorten.value = true), props.transition * 1000);
  } else {
    isShorten.value = false;
  }
};

watch(
  () => containerHeight.value,
  () => (isShorten.value = true)
);

return (_ctx: any,_cache: any) => {
  const _component_vz_button = _resolveComponent("vz-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      ref_key: "containerRef",
      ref: containerRef,
      class: _normalizeClass(["vz-collapse__container", { 'vz-collapse__container--shorten': isShorten.value && isExtendable.value }]),
      style: _normalizeStyle({ ...containerStyle.value, transition: `max-height ${__props.transition}s` })
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 6),
    (isExtendable.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["vz-collapse__control", { 'vz-collapse__control--full': isShowMore.value }])
        }, [
          _createVNode(_component_vz_button, {
            type: "flat",
            "icon-size": "1rem",
            text: `GENERAL.${isShowMore.value ? 'SHOW_LESS' : 'SHOW_MORE'}`,
            "icon-name": isShowMore.value ? 'svg:arrow-up' : 'svg:arrow-down',
            onClick: onCollapse
          }, null, 8, ["text", "icon-name"])
        ], 2))
      : _createCommentVNode("", true)
  ]))
}
}

})