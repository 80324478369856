import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app__container" }

import '@shared/services/api-service/helpers/register-api-service';
import '@shared/services/api-service/helpers/add-api-interceptors';
import { computed, defineAsyncComponent, onMounted, watch } from 'vue';
import { useAuthUser } from '@/views/employee/composables/use-auth-user';
import { useAsync, useVisualViewport } from '@shared/composables';
import ThemeService from '@shared/services/css-service/theme-service';
import { useRoute } from 'vue-router';
import VzPopup from '@shared/components/popup/vz-popup.vue';
import useAuthStore from '@/views/lobby/modals/login-modal/composables/use-auth-store';
import { LOAD_LOCAL_AUTH_STATE } from '@/store/auth/auth.constants';
import { OPEN_SIGN_UP_MODAL } from '@/views/lobby/constants/lobby-events';
import { emitter } from '@/main';
import EmployeeViewModal from '@/views/lobby/modals/employee-view-modal.component.vue';
import useClientStore from '@/store/client/composables/use-client-store';
import { LIST_CURRENCIES } from '@/store/client/client.constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const SignUpModal = defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/views/lobby/modals/login-modal/sign-up-modal.component.vue'));
const SignInModal = defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/views/lobby/modals/login-modal/sign-in-modal.component.vue'));
const TopNavbar = defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/views/lobby/components/top-navbar/app-top-navbar.vue'));
const Promotion = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/promotion/promotion.view.vue'));
const BottomNavbar = defineAsyncComponent(
  () => import(/* webpackChunkName: "main" */ '@/views/lobby/components/bottom-navbar/app-bottom-navbar.vue')
);

const TermsOfServiceModal = defineAsyncComponent(() => import('@/components/terms-of-service-modal.vue'));

const route = useRoute();

const { isLoggedIn, isImpersonate, termOfServiceSignAt, isPartialSignUp } = useAuthUser();
const { [LOAD_LOCAL_AUTH_STATE]: loadLocalAuthState } = useAuthStore().useMutations([LOAD_LOCAL_AUTH_STATE]);
const { [LIST_CURRENCIES]: listCurrenciesAction } = useClientStore().useActions([LIST_CURRENCIES]);

const isSplashScreen = computed(() => route.meta.isSplashScreen);
const isPromotionScreen = computed(() => !isLoggedIn.value && !isSplashScreen.value && !route.meta.isUnprotected);
const listCurrenciesRequest = useAsync(listCurrenciesAction);

onMounted(() => {
  useVisualViewport();
  loadLocalAuthState();
});

onMounted(() => {
  if (isPartialSignUp.value) {
    emitter.emit(OPEN_SIGN_UP_MODAL);
  }

  listCurrenciesRequest.call();
});

watch(
  () => isImpersonate.value,
  (newValue) => {
    ThemeService.set(newValue ? { name: 'warning' } : { name: 'default' });
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (isPromotionScreen.value)
      ? (_openBlock(), _createBlock(_unref(Promotion), { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (!isSplashScreen.value)
            ? (_openBlock(), _createBlock(_unref(TopNavbar), { key: 0 }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_router_view)
          ]),
          _createVNode(EmployeeViewModal),
          (!isSplashScreen.value)
            ? (_openBlock(), _createBlock(_unref(BottomNavbar), { key: 1 }))
            : _createCommentVNode("", true)
        ], 64)),
    _createVNode(VzPopup),
    (_unref(isLoggedIn) && !_unref(termOfServiceSignAt) && !_unref(isPartialSignUp))
      ? (_openBlock(), _createBlock(_unref(TermsOfServiceModal), { key: 2 }))
      : _createCommentVNode("", true),
    (!_unref(isLoggedIn) && !isSplashScreen.value)
      ? (_openBlock(), _createBlock(_unref(SignInModal), { key: 3 }))
      : _createCommentVNode("", true),
    ((!_unref(isLoggedIn) || _unref(isPartialSignUp)) && !isSplashScreen.value)
      ? (_openBlock(), _createBlock(_unref(SignUpModal), { key: 4 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})