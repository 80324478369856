import { openPopup } from '@shared/components/popup/helpers/open-popup';
import { emitter } from '@/main';
import { ColorName } from '@shared/services/css-service/types';
import { IconName } from '@shared/components/icon/icon.type';

export const openWarningPopup = async (options?: {
  title?: string;
  description?: string;
  iconName?: IconName;
  iconColor?: ColorName;
  yesCallback?: () => Promise<unknown> | unknown;
  yesLabel?: string;
  yesColor?: ColorName;
  noCallback?: () => Promise<unknown> | unknown;
  noLabel?: string;
  noColor?: ColorName;
}): Promise<void> => {
  const {
    title = 'WARNING.TITLE',
    description = 'WARNING.CHANGES_WILL_BE_LOST',
    iconName,
    iconColor,
    yesCallback,
    yesLabel = 'GENERAL.YES',
    yesColor,
    noCallback,
    noLabel = 'GENERAL.NO',
    noColor,
  } = options || {};

  return await openPopup({
    title,
    description,
    actions: [
      {
        label: noLabel,
        color: noColor,
        callback: async () => {
          emitter.emit('CLOSE_GLOBAL_MODAL');
          await noCallback?.();

          throw new Error('CANCEL');
        },
      },
      {
        label: yesLabel,
        color: yesColor,
        callback: async () => {
          await yesCallback?.();
          emitter.emit('CLOSE_GLOBAL_MODAL');
        },
      },
    ],
    ...(iconName ? { icon: { name: iconName, color: iconColor } } : {}),
  });
};
