// actions
export const SEARCH_EMPLOYEES = 'search-employees';
export const GET_EMPLOYEE_DETAILS = 'get-employee-details';
export const GET_EMPLOYEE = 'get-employee';
export const UPDATE_EMPLOYEE_PROFILE = 'update-employee-profile';
export const GET_REVIEWS = 'get-reviews';
export const ADD_REVIEW = 'add-review';
export const GET_AVATAR = 'get-avatar';
export const GET_COVER = 'get-cover';

// state
export const EMPLOYEES = 'employees';
export const EMPLOYEES_PAGINATION = 'employees-pagination';
export const EMPLOYEES_TOTAL = 'employees-total';
export const EMPLOYEE_DETAILS = 'employee-details';
export const EMPLOYEE_DETAILS_CACHE = 'employee-details-cache';
export const EMPLOYEE_REVIEWS = 'employee-reviews';

// mutations
export const SET_EMPLOYEES = 'set-employees';
export const SET_EMPLOYEE_DETAILS = 'set-employee-details';
export const SET_EMPLOYEE_DETAILS_CACHE = 'set-employee-details-cache';
export const UPDATE_EMPLOYEE_DETAILS = 'update-employee-details';
export const SET_EMPLOYEE_REVIEWS = 'set-employee-reviews';
export const CLEAR_EMPLOYEE_STATE = 'clear-employee-state';
