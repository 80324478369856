import type { MutationTree } from 'vuex';
import type { JobState } from '@/views/job/store/job.types';
import type { SearchJobOffersFilter, SearchJobOffersRes, GetJobOfferDetailsRes } from '@/views/job/types';
import { initialState } from '@/views/job/store';
import {
  CLEAR_JOB_OFFERS,
  JOB_OFFER_DETAILS,
  JOB_OFFERS,
  JOB_OFFERS_FILTER,
  JOB_OFFERS_PAGINATION,
  SET_JOB_OFFER_DETAILS,
  SET_JOB_OFFERS,
  SET_JOB_OFFERS_FILTER,
} from '@/views/job/store/constants/job-offer.constants';

const mutations: MutationTree<JobState> = {
  [SET_JOB_OFFERS]: (state, payload?: SearchJobOffersRes) => {
    state[JOB_OFFERS] = payload?.data || initialState()[JOB_OFFERS];
    state[JOB_OFFERS_PAGINATION] = payload?.page || initialState()[JOB_OFFERS_PAGINATION];
  },

  [SET_JOB_OFFERS_FILTER]: (state, payload?: SearchJobOffersFilter) => {
    state[JOB_OFFERS_FILTER] = payload || initialState()[JOB_OFFERS_FILTER];
  },

  [CLEAR_JOB_OFFERS]: (state) => {
    state[JOB_OFFERS] = initialState()[JOB_OFFERS];
    state[JOB_OFFERS_FILTER] = initialState()[JOB_OFFERS_FILTER];
    state[JOB_OFFERS_PAGINATION] = initialState()[JOB_OFFERS_PAGINATION];
  },

  [SET_JOB_OFFER_DETAILS]: (state, payload?: GetJobOfferDetailsRes) => {
    state[JOB_OFFER_DETAILS] = payload || null;
  },
};

export default mutations;
