import type { MutationTree } from 'vuex';
import type { GroupsState } from '@/views/job/views/manager/modules/group/store/groups.types';
import type { GetGroupRes } from '@/views/job/views/manager/modules/group/types';
import { ACTIVE_GROUP, SET_ACTIVE_GROUP } from '@/views/job/views/manager/modules/group/store/groups.constants';

const groupsMutations: MutationTree<GroupsState> = {
  [SET_ACTIVE_GROUP]: (state: GroupsState, group?: GetGroupRes) => {
    state[ACTIVE_GROUP] = group || null;
  },
};

export default groupsMutations;
