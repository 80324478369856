// actions
export const CREATE_POST_JOB = 'create-post-job';
export const DELETE_POST_JOB = 'delete-post-job';
export const UPDATE_POST_JOB = 'update-post-job';
export const SEARCH_POST_JOBS = 'search-post-jobs';
export const GET_POST_JOB_DETAILS = 'get-post-job-details';

// state
export const POST_JOBS = 'post-jobs';
export const POST_JOBS_FILTER = 'post-jobs-filter';
export const POST_JOBS_PAGINATION = 'post-jobs-pagination';
export const POST_JOB_DETAILS = 'post-job-details';

// mutations
export const SET_POST_JOBS = 'set-post-jobs';
export const SET_POST_JOB_FILTER = 'set-post-job-filter';
export const SET_POST_JOB_DETAILS = 'set-post-job-details';
export const UPDATE_POST_JOB_DETAILS = 'update-post-job-details';
export const CLEAR_POST_JOBS = 'clear-post-jobs';
