import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import type { UserAssignee } from '@/views/job/types/job-manager-assignee.type';
import useAuthStore from '@/views/lobby/modals/login-modal/composables/use-auth-store';
import { ASSIGNED, GET_ASSIGNEE_LIST, GET_AUTH_KEY, SET_ASSIGNEE } from '@/store/auth/auth.constants';
import { computed, ref, watch } from 'vue';
import EmployeeStrip from '@/views/employee/components/employee-strip.vue';
import { useRoute } from 'vue-router';
import { cloneDeep } from 'lodash';
import useJobStore from '@/views/job/composables/use-job-store';
import { RESET_STATE } from '@/store/constants';
import { routeTo } from '@shared/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'manager-assignee-select',
  props: {
  hideOnInactive: { type: Boolean, default: false },
},
  setup(__props) {



const { useMutations, useState, useGetters } = useAuthStore();
const { [ASSIGNED]: assignedState } = useState([ASSIGNED]);
const { [SET_ASSIGNEE]: setAssigneeMutation } = useMutations([SET_ASSIGNEE]);
const { [GET_ASSIGNEE_LIST]: getAssigneeListGetter, [GET_AUTH_KEY]: getAuthKeyGetter } = useGetters([GET_ASSIGNEE_LIST, GET_AUTH_KEY]);

const { [RESET_STATE]: resetJobStateMutation } = useJobStore().useMutations([RESET_STATE]);

const route = useRoute();

const moduleKey = computed(() => route.name as string);

const saveAssignee = ref<UserAssignee | undefined>(undefined);

const vAssignee = computed({
  get: (): UserAssignee => assignedState.value as UserAssignee,
  set: (value: UserAssignee) => {
    if (!value) {
      saveAssignee.value = undefined;
    }

    setAssigneeMutation(value);
  },
});

const modules = computed((): Array<UserAssignee> => getAssigneeListGetter.value as Array<UserAssignee>);
const isDisabled = computed(() => (getAssigneeListGetter.value as Array<UserAssignee>)?.every(({ modules }) => !modules[moduleKey.value]?.length));

watch(
  () => route.name,
  () => {
    if (vAssignee.value && !vAssignee.value.modules[moduleKey.value]?.length) {
      saveAssignee.value = cloneDeep(vAssignee.value);

      setAssigneeMutation();
    } else if (saveAssignee.value && saveAssignee.value.modules[moduleKey.value]?.length) {
      setAssigneeMutation(saveAssignee.value);
    }
  },
  { immediate: true }
);

watch(
  () => vAssignee.value?.userId,
  (newValue, oldValue) => {
    resetJobStateMutation();

    if (route.name && newValue !== oldValue) {
      routeTo({ name: route.name }, { isOverride: true });
    }
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_vz_select = _resolveComponent("vz-select")!

  return (modules.value?.length || (__props.hideOnInactive && !vAssignee.value))
    ? (_openBlock(), _createBlock(_component_vz_select, {
        key: 0,
        modelValue: vAssignee.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((vAssignee).value = $event)),
        class: _normalizeClass(['manager-assignee-select', { 'manager-assignee-select--active': vAssignee.value }]),
        "hide-details": "",
        placeholder: "AUTH.SELECT_MANAGE_ACCOUNT",
        items: modules.value,
        disabled: isDisabled.value
      }, {
        item: _withCtx(({ item }) => [
          _createVNode(EmployeeStrip, {
            class: "pa-0",
            "avatar-size": "24",
            item: item,
            hid: item?.userId
          }, null, 8, ["item", "hid"])
        ]),
        _: 1
      }, 8, ["modelValue", "class", "items", "disabled"]))
    : _createCommentVNode("", true)
}
}

})