// actions
export const CREATE_PROJECT = 'create-project';
export const UPDATE_PROJECT = 'update-project';
export const GET_PROJECT_DETAILS = 'get-project-details';
export const DELETE_PROJECT = 'delete-project';
export const SEARCH_PROJECTS = 'search-projects';
export const REMOVE_FROM_PROJECT = 'remove-from-project';
export const SUMMARIZE_PROJECT_AGREEMENT = 'summarize-project-agreement';
export const CREATE_AGREEMENT_PDF = 'create-agreement-pdf';
export const SEND_PROJECT_AGREEMENT = 'send-project-agreement';
export const PROJECT_AGREEMENT_MANUAL_APPROVAL = 'project-agreement-manual-approval';
export const UPLOAD_PROJECT_FILES = 'upload-project-files';
export const GET_PROJECT_FILES = 'get-project-files';
export const GET_PROJECT_FILE = 'get-project-file';

// state
export const PROJECTS = 'projects';
export const PROJECT_FILTER = 'project-filter';
export const PROJECT_PAGINATION = 'project-pagination';
export const PROJECT_DETAILS = 'project-details';

// mutations
export const SET_PROJECTS = 'set-projects';
export const SET_PROJECT_FILTER = 'set-project-filter';
export const SET_PROJECT_DETAILS = 'set-project-details';
export const UPDATE_PROJECT_DETAILS = 'update-project-details';
export const CLEAR_PROJECTS = 'clear-projects';

// getters
export const GET_PROJECTS = 'get-projects';
