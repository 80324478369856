import store from '@/store';
import { StoreNamespace } from '@/store/store-namespace';
import { GET_ASSIGNED_MODULES, GET_ASSIGNED_USER_ID } from '@/store/auth/auth.constants';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { AuthActionEnum } from '@/store/auth/constants';

export const useAssignedAuth = () => {
  const route = useRoute();

  const assignedModules = computed(() => store.getters[`${StoreNamespace.AUTH_MODULE}/${GET_ASSIGNED_MODULES}`]);
  const userId = computed(() => store.getters[`${StoreNamespace.AUTH_MODULE}/${GET_ASSIGNED_USER_ID}`] || undefined);

  const $can = (action: keyof typeof AuthActionEnum, name?: string) => {
    if (!userId.value) {
      return true;
    }

    const module = assignedModules.value[name || (route.name as string)];

    return module.includes(action);
  };

  return { $can, userId };
};
