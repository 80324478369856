import type { EmployeeState } from '@/views/employee/store/employee.types';
import type { BaseRecords } from '@/shared/models';
import type { ProfileFullDetails, ProfileExtendDetails, GetReviewsRes } from 'src/views/employee/types';
import { MutationTree } from 'vuex';
import {
  EMPLOYEES,
  SET_EMPLOYEES,
  SET_EMPLOYEE_DETAILS,
  EMPLOYEE_DETAILS,
  EMPLOYEE_REVIEWS,
  SET_EMPLOYEE_REVIEWS,
  UPDATE_EMPLOYEE_DETAILS,
  EMPLOYEES_PAGINATION,
  EMPLOYEES_TOTAL,
  SET_EMPLOYEE_DETAILS_CACHE,
  EMPLOYEE_DETAILS_CACHE,
  CLEAR_EMPLOYEE_STATE,
} from '@/views/employee/store/employee.constants';
import { initialState } from '@/views/employee/store/index';
import store from '@/store';
import { UPDATE_USER } from '@/store/auth/auth.constants';
import { StoreNamespace } from '@/store/store-namespace';
import StoreCache, { type StateCache } from '@/store/services/store.cache';

const employeeMutations: MutationTree<EmployeeState> = {
  [SET_EMPLOYEES]: (state: EmployeeState, payload?: BaseRecords<ProfileExtendDetails>) => {
    if (!payload?.page.index) {
      state[EMPLOYEES] = payload?.data || initialState()[EMPLOYEES];
    } else {
      state[EMPLOYEES] = [...state[EMPLOYEES], ...(payload?.data || [])];
    }

    state[EMPLOYEES_TOTAL] = payload?.total || initialState()[EMPLOYEES_TOTAL];
    state[EMPLOYEES_PAGINATION] = payload?.page || initialState()[EMPLOYEES_PAGINATION];
  },

  [SET_EMPLOYEE_DETAILS]: (state: EmployeeState, employee?: ProfileFullDetails) => {
    state[EMPLOYEE_DETAILS] = employee || initialState()[EMPLOYEE_DETAILS];

    if (!employee) {
      state[EMPLOYEE_REVIEWS] = initialState()[EMPLOYEE_REVIEWS];
    }
  },

  [SET_EMPLOYEE_DETAILS_CACHE]: (state: EmployeeState, bulk: StateCache<ProfileFullDetails>) => {
    state[EMPLOYEE_DETAILS_CACHE] = bulk;
  },

  [UPDATE_EMPLOYEE_DETAILS]: (state: EmployeeState, updated: Partial<ProfileFullDetails>) => {
    const cacheService = new StoreCache(EMPLOYEE_DETAILS_CACHE, SET_EMPLOYEE_DETAILS_CACHE, StoreNamespace.EMPLOYEES_MODULE);
    state[EMPLOYEE_DETAILS] = { ...state[EMPLOYEE_DETAILS]!, ...updated };
    state[EMPLOYEES] = (state[EMPLOYEES] || []).map((employee) =>
      employee._id === state[EMPLOYEE_DETAILS]?._id ? { ...employee, ...updated } : employee
    );

    cacheService.set<string, ProfileFullDetails>(state[EMPLOYEE_DETAILS]!._id, state[EMPLOYEE_DETAILS], { expiredIn: 3 * 60 * 1000 });

    store.commit(`${StoreNamespace.AUTH_MODULE}/${UPDATE_USER}`, updated);
  },

  [SET_EMPLOYEE_REVIEWS]: (state: EmployeeState, reviews: GetReviewsRes) => {
    const { data = [] } = state[EMPLOYEE_REVIEWS];

    state[EMPLOYEE_REVIEWS] = !reviews.page.index ? reviews : { ...reviews, data: [...data, ...reviews.data] };
  },

  [CLEAR_EMPLOYEE_STATE]: (state: EmployeeState) => {
    state[EMPLOYEE_REVIEWS] = initialState()[EMPLOYEE_REVIEWS];
  },
};

export default employeeMutations;
