import { defineComponent as _defineComponent } from 'vue'
import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "d-flex flex-column gap-2 my-1" }
const _hoisted_2 = ["value"]
const _hoisted_3 = { key: 0 }

import { computed, type PropType, StyleValue } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-toggle-switch',
  props: {
  modelValue: { type: Boolean as PropType<boolean | undefined>, required: true },
  label: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  style: { type: Object as PropType<StyleValue | undefined>, default: undefined },
},
  emits: ['update:model-value'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const vModel = computed({
  get: (): boolean => props.modelValue,
  set: (value) => emit('update:model-value', value),
});

const onClick = (): void => {
  if (props.disabled) {
    return;
  }

  emit('update:model-value', !props.modelValue);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", {
      class: _normalizeClass(['vz-toggle-switch text-ellipsis', { 'vz-toggle-switch--checked': vModel.value, 'vz-toggle-switch--disabled': __props.disabled }]),
      style: _normalizeStyle(__props.style)
    }, [
      _createElementVNode("input", {
        type: "checkbox",
        value: vModel.value,
        onChange: _withModifiers(onClick, ["stop"])
      }, null, 40, _hoisted_2),
      _cache[0] || (_cache[0] = _createElementVNode("span", { class: "vz-toggle-switch__switch" }, [
        _createElementVNode("span", { class: "vz-toggle-switch__switch-inner" })
      ], -1)),
      _createElementVNode("div", {
        class: _normalizeClass({ 'c-primary-900': vModel.value, 'c-mono-600': !vModel.value, 'c-mono-400': __props.disabled })
      }, [
        _renderSlot(_ctx.$slots, "label", {}, () => [
          (__props.label)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t(__props.label)), 1))
            : _createCommentVNode("", true)
        ])
      ], 2)
    ], 6)
  ]))
}
}

})