<template>
  <button
    class="vz-close-button"
    role="button"
    :aria-label="t('COMPONENT_LABELS.BUTTON', { value: 'GENERAL.CLOSE' })"
    :style="{ width: `${+size + 5}px`, height: `${+size + 5}px`, ...(color ? { color: getThemeColor(color) } : {}) }"
    @close.stop="$emit('close')"
  >
    <div class="vz-close-button__line-right" :style="lineStyle" />
    <div class="vz-close-button__line-left" :style="lineStyle" />
  </button>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import { type ColorName } from '@/shared/services/css-service/types/colors';
import { useTranslator } from '@/plugins/i18n/helpers';
import getThemeColor from '@shared/services/css-service/helpers/get-theme-color';

const props = defineProps({
  size: { type: [String, Number] as PropType<string | number>, default: 16 },
  color: { type: String as PropType<ColorName | undefined>, default: undefined },
});

defineEmits(['close']);

const t = useTranslator();

const lineStyle = computed(() => {
  return {
    width: (+props.size * 16) / 15 + 'px',
    height: (+props.size * 16) / 15 / 7 + 'px',
  };
});
</script>

<style lang="scss">
.vz-close-button {
  position: absolute;
  z-index: 100;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  border-radius: 50%;
  background-color: var(--color-background-regular);

  @include before {
    border-radius: 50%;
    opacity: 0.7;
    box-shadow: var(--shadow-light);
  }

  &:hover .vz-close-button__line-right,
  &:hover .vz-close-button__line-left {
    transform: rotate(-45deg);
  }

  &:hover .vz-close-button__line-left {
    transform: rotate(45deg);
  }

  &__line-right,
  &__line-left {
    height: 2px;
    width: 16px;
    position: absolute;
    border-radius: 5px;
    transform: rotate(45deg);
    transition: all 0.3s ease-in;
    background-color: currentColor;
  }

  &__line-left {
    transform: rotate(-45deg);
  }
}
</style>
