<template>
  <file-drop class="messenger fill-width" accept="image/jpeg,image/png,application/pdf" @update="onFileDragAndDrop">
    <vz-infinity-scroll
      ref="infinityScrollRef"
      :class="['messenger__content', { 'messenger__content--disabled': disabled }]"
      hide-empty-state
      hide-first-load
      disable-payload-watcher
      reverse
      :callback="getMessagesCallback"
      :payload="{ [chatIdKey]: chatId }"
      @click="onMessagesClick"
    >
      <template v-if="sendMessageRequest.error.value" #header>
        <vz-error-alert :error="sendMessageRequest.error.value" />
      </template>

      <template #default="{ data }">
        <message-card
          v-for="(message, index) in data"
          :key="index"
          :message="message"
          :participants="participants"
          :online="onlineClients"
          @visible="onMessageVisible"
          @hidden="onMessageHidden"
          @reply:message="onReplyMessage"
          @select:participant="$emit('select:participant', $event)"
        />

        <message-typing v-if="userTyping" :online="onlineClients" :user="userTyping" />
      </template>
    </vz-infinity-scroll>
  </file-drop>

  <div class="fill-width border-top-medium">
    <message-input
      ref="inputRef"
      class="min-height-64"
      :loading="isSending"
      :value="payload"
      :participants="participants"
      @send="onSendMessage"
      @force="goToEnd"
      @blur="goToEnd"
      @update:payload="$patchPayload"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onUnmounted, type PropType, ref, watch } from 'vue';
import type { SendMessagePayload } from '@shared/components/messenger/messenger.type';
import type { BaseChat, BaseMessage } from '@/views/messenger/types';
import type { BaseId, BaseRecords } from '@shared/models';
import type { SocketReceivedMessage } from '@shared/services/socket-service/socket-client.types';
import type { FileDropEvent } from '@shared/elements/file-drop';
import type { VzInfinityScrollRef } from '@shared/components/infinity-scroll/infinity-scroll.type';
import { useAuthUser } from '@/views/employee/composables/use-auth-user';
import MessageCard from '@shared/components/messenger/components/message-card.vue';
import SocketClientService from '@shared/services/socket-service/socket-client.service';
import { SEND_MESSAGE } from '@/views/messenger/store/messenger.constants';
import { SocketEnum } from '@shared/services/socket-service/socket.enum';
import ProcessService from '@shared/services/process.service';
import MessageTyping from '@shared/components/messenger/components/message-typing.vue';
import { useAsync } from '@shared/composables';
import { LENGTH } from '@shared/constants/length';
import MessageInput from '@shared/components/messenger/components/message-input.vue';
import { useFirebasePush } from '@shared/composables/use-firebase-push';

const props = defineProps({
  roomKey: { type: String as PropType<'messenger' | 'job-manager-conversation'>, required: true },
  chatId: { type: String as PropType<BaseId | undefined>, required: true },
  chatIdKey: { type: String, required: true },
  participants: { type: Array as PropType<BaseChat['participants']>, required: true },
  maxLength: { type: [String, Number], default: LENGTH.LONG_DESCRIPTION },
  disabled: { type: Boolean, default: false },
  messageIdKey: { type: String, default: 'key' },
  getMessagesCallback: { type: Function as PropType<(...arg: any) => Promise<BaseRecords<BaseMessage>>>, required: true },
  sendMessageCallback: { type: Function as PropType<(...arg: any) => Promise<BaseMessage>>, required: true },
});

const payload = ref<SendMessagePayload>({} as SendMessagePayload);
const sendMessageRequest = useAsync<BaseMessage>(props.sendMessageCallback as (payload: any) => Promise<BaseMessage>, { errorsCleanTimeout: 3000 });

const $patchPayload = (state: Partial<SendMessagePayload>) => {
  payload.value = { ...payload.value, ...state };
};

const emit = defineEmits(['select:participant', 'visible:message', 'hidden:message', 'recent:message', 'user:typing']);

const { isMe, myId } = useAuthUser();

const inputRef = ref();
const infinityScrollRef = ref<VzInfinityScrollRef>(undefined);
const onlineClients = ref<Array<string>>([]);
const typingClients = ref<Array<string>>([]);

const userTyping = computed(() => props.participants?.find(({ _id }) => _id === typingClients.value.find((id) => !isMe(id))));
const isSending = computed(() => sendMessageRequest.loading.value);

const onMessagesClick = (): void => {
  $patchPayload({ coordinates: undefined, attachments: undefined });
};

const onFileDragAndDrop = ({ detail }: FileDropEvent): void => {
  if (props.disabled) {
    return;
  }

  const attachments = [...(payload.value.attachments || []), ...Array.from(detail.files || [])];
  $patchPayload({ attachments });
};

const onReplyMessage = (message: BaseMessage): void => {
  $patchPayload({ replyTo: message });
  nextTick(goToEnd);
};

const onSendMessage = async (state: SendMessagePayload = {}): Promise<void> => {
  const data = { ...payload.value, ...state };
  payload.value = {};
  await sendMessageRequest.call({ [props.chatIdKey]: props.chatId, ...(data || {}) });

  if (!sendMessageRequest.results.value) {
    return;
  }

  infinityScrollRef.value?.push(sendMessageRequest.results.value, { idKey: 'key' });
  goToEnd();
};

const onMessageVisible = (message: BaseMessage): void => {
  SocketClientService.send<{ userId: string; key: string }>(SocketEnum.SEEN, `${props.chatId}:${props.roomKey}`, {
    userId: myId.value,
    key: message.key,
  });

  emit('visible:message', message);
};

const onMessageHidden = (message: BaseMessage): void => {
  emit('hidden:message', message);
};

const goToEnd = (): void => {
  nextTick(() => infinityScrollRef.value?.scrollTo('END'));
};

const initSocket = (): void => {
  if (!props.chatId || !props.roomKey) {
    return;
  }

  SocketClientService.join({ roomId: props.chatId, roomKey: props.roomKey });

  SocketClientService.on(SocketEnum.ONLINE, (online: Array<string>) => {
    onlineClients.value = online;
  });

  SocketClientService.on(
    SEND_MESSAGE,
    (message: BaseMessage) => {
      infinityScrollRef.value?.push(message, { idKey: 'key' });
      emit('recent:message', message);
    },
    true
  );

  SocketClientService.on(SocketEnum.TYPING, (isTyping: boolean, { fromId }: SocketReceivedMessage) => {
    if (isTyping && !typingClients.value.includes(fromId)) {
      typingClients.value = [...new Set([fromId, ...typingClients.value])];
      goToEnd();
    } else if (!isTyping && typingClients.value.includes(fromId)) {
      typingClients.value = typingClients.value.filter((uid) => uid !== fromId);
    }
  });

  SocketClientService.on(SocketEnum.SEEN, ({ key, readBy }: Partial<BaseMessage>) => {
    const item: BaseMessage = infinityScrollRef.value?.items.find((item: Record<string, any>) => item.key === key);
    const update = { ...(item || {}), readBy: { ...(item?.readBy || {}), ...(readBy || {}) } };
    infinityScrollRef.value?.update(update, 'key');
  });

  SocketClientService.on(SocketEnum.PUSH, ({ key, sentTo }: Partial<BaseMessage>) => {
    const item: BaseMessage = infinityScrollRef.value?.items.find((item: Record<string, any>) => item.key === key);
    const update = { ...(item || {}), sentTo: { [myId.value]: Date.now(), ...(sentTo || {}) } };
    infinityScrollRef.value?.update(update, 'key');
  });

  nextTick(() => infinityScrollRef.value?.reset());
};

const onTyping = (): void => {
  if (!typingClients.value.includes(myId.value) && payload.value.message) {
    SocketClientService.send<boolean>(SocketEnum.TYPING, `${props.chatId}:${props.roomKey}`, true);
  } else if (!payload.value.message) {
    SocketClientService.send<boolean>(SocketEnum.TYPING, `${props.chatId}:${props.roomKey}`, false);

    return;
  }

  ProcessService.debounce(
    () => {
      SocketClientService.send<boolean>(SocketEnum.TYPING, `${props.chatId}:${props.roomKey}`, false);
    },
    props.chatId,
    1000
  );
};

watch(() => payload.value.message, onTyping);
watch(() => props.disabled, goToEnd);

watch(
  () => typingClients.value,
  () => {
    emit('user:typing', props.participants?.find(({ _id }) => _id === typingClients.value[0]));
  }
);

watch(
  () => [props.chatId, props.roomKey],
  ([newChatId, _, oldChatId, oldChatType]) => {
    if (oldChatId) {
      SocketClientService.leave({ roomId: oldChatId, roomKey: oldChatType });
    }

    if (!newChatId) {
      return;
    }

    initSocket();
  },
  { immediate: true }
);

onUnmounted(() => {
  if (props.chatId && props.roomKey) {
    SocketClientService.leave({ roomId: props.chatId, roomKey: props.roomKey });
  }
});

useFirebasePush(
  ({ data }: { data: BaseMessage }) => {
    infinityScrollRef.value?.push(data, { idKey: 'key' });
  },
  { type: SEND_MESSAGE, id: props.chatId }
);

defineExpose({ push: (item: BaseMessage) => infinityScrollRef.value?.push(item, { idKey: props.messageIdKey }) });
</script>

<style lang="scss">
.messenger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  height: 0;
  scroll-behavior: smooth;

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;

    > div {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;
    }

    &--disabled {
      user-select: none;
      opacity: 0.3;
    }
  }
}
</style>
