import type { SizeUnit } from '@shared/types';

export const convertPixelToRem = (size: SizeUnit, withSuffix: boolean = false) => {
  if (!size?.includes('px')) {
    return size;
  }

  const pixel = parseFloat(size) / parseFloat(getComputedStyle(document.documentElement).fontSize);

  return withSuffix ? `${pixel}rem` : pixel;
};
