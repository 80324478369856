<template>
  <div class="fill-height d-flex flex-column">
    <div class="d-flex align-center gap-5 pa-4 bg-active">
      <vz-avatar :hid="activeUser?._id" :size="70" />

      <div>
        <div class="text-title-1">{{ $t('GENERAL.WELCOME') }}</div>

        <div class="text-title-2">
          <span>{{ activeUser?.firstName }} {{ activeUser?.lastName }}</span>
          <span v-if="activeUser?.nickName" class="ms-1">({{ activeUser?.nickName }})</span>
        </div>

        <div class="text-subtitle-1">{{ activeUser?.company }}</div>
      </div>

      <lobby-date-now />
    </div>

    <vz-divider />

    <div class="overflow-y-auto flex-grow-1">
      <div class="d-flex flex-column">
        <div class="row-flex-4 vh-65">
          <div class="d-flex flex-column fill-height">
            <p class="text-title-1">{{ $t('MODULE.UPCOMING_EVENTS') }}</p>

            <vz-card class="flex-grow-1 overflow-hidden">
              <upcoming-events />
            </vz-card>
          </div>

          <div class="d-flex flex-column fill-height">
            <p class="text-title-1">{{ $t('MODULE.CALENDAR') }}</p>

            <vz-card class="flex-grow-1">
              <calendar-lobby />
            </vz-card>
          </div>

          <div class="d-flex flex-column fill-height">
            <p class="text-title-1">{{ $t('MODULE.NOTIFICATIONS') }}</p>

            <vz-card class="flex-grow-1 overflow-hidden">
              <notifications />
            </vz-card>
          </div>
        </div>

        <vz-card>
          <job-offer minimal />
        </vz-card>

        <!--        <users-grid />-->
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineAsyncComponent } from 'vue';
import LobbyDateNow from './components/lobby-date-now.vue';
import { useAuthUser } from '@/views/employee/composables/use-auth-user';
import UpcomingEvents from '@/views/calendar/views/upcoming-events.view.vue';
import VzDivider from '@shared/components/vz-divider.vue';
import VzCard from '@shared/components/vz-card.vue';

const UsersGrid = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/lobby/components/users-grid.vue'));
const Notifications = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/notifications/components/notifications-tabs.vue'));
const CalendarLobby = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/lobby/components/calendar-lobby.vue'));
const JobOffer = defineAsyncComponent(
  () => import(/* webpackChunkName: "lobby" */ '@/views/job/views/offer/components/job-offers/job-offers-table.vue')
);

const { activeUser } = useAuthUser();
</script>
