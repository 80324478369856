import type { Module } from 'vuex';
import type { RootState } from '@/store';
import type { JobState } from '@/views/job/store/job.types';
import type { SearchJobOffersFilter } from '@/views/job/types';
import type { BasePagination, BaseOptions } from '@/shared/models';
import type { StateCache } from '@/store/services/store.cache';
import jobActions from './job.actions';
import jobMutations from './job.mutations';
import { JOB_OFFER_DETAILS, JOB_OFFERS, JOB_OFFERS_FILTER, JOB_OFFERS_PAGINATION } from '@/views/job/store/constants/job-offer.constants';
import { CLASSIFICATIONS, SKILLS } from '@/views/job/store/constants/job.constants';
import { CONVERSATION } from '@/views/job/store/constants/conversation.constants';
import jobGetters from '@/views/job/store/job.getters';
import { DEFAULT_TABLE_PAGE_SIZE } from '@shared/components/tables/constants/data-table.constants';

export const initialState = (): JobState => ({
  // job offer
  [JOB_OFFERS]: null,
  [JOB_OFFERS_FILTER]: {} as SearchJobOffersFilter,
  [JOB_OFFERS_PAGINATION]: { size: DEFAULT_TABLE_PAGE_SIZE } as BasePagination,
  [JOB_OFFER_DETAILS]: null,
  // conversation
  [CONVERSATION]: null,
  // global
  [SKILLS]: {} as StateCache<BaseOptions<string>>,
  [CLASSIFICATIONS]: null,
});

const jobStore: Module<JobState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations: jobMutations,
  actions: jobActions,
  getters: jobGetters,
};

export default jobStore;
