<template>
  <vz-select
    v-if="modules?.length || (hideOnInactive && !vAssignee)"
    v-model="vAssignee"
    :class="['manager-assignee-select', { 'manager-assignee-select--active': vAssignee }]"
    hide-details
    placeholder="AUTH.SELECT_MANAGE_ACCOUNT"
    :items="modules"
    :disabled="isDisabled"
  >
    <template #item="{ item }">
      <employee-strip class="pa-0" avatar-size="24" :item="item" :hid="item?.userId" />
    </template>
  </vz-select>
</template>

<script setup lang="ts">
import type { UserAssignee } from '@/views/job/types/job-manager-assignee.type';
import useAuthStore from '@/views/lobby/modals/login-modal/composables/use-auth-store';
import { ASSIGNED, GET_ASSIGNEE_LIST, GET_AUTH_KEY, SET_ASSIGNEE } from '@/store/auth/auth.constants';
import { computed, ref, watch } from 'vue';
import EmployeeStrip from '@/views/employee/components/employee-strip.vue';
import { useRoute } from 'vue-router';
import { cloneDeep } from 'lodash';
import useJobStore from '@/views/job/composables/use-job-store';
import { RESET_STATE } from '@/store/constants';
import { routeTo } from '@shared/composables';

defineProps({
  hideOnInactive: { type: Boolean, default: false },
});

const { useMutations, useState, useGetters } = useAuthStore();
const { [ASSIGNED]: assignedState } = useState([ASSIGNED]);
const { [SET_ASSIGNEE]: setAssigneeMutation } = useMutations([SET_ASSIGNEE]);
const { [GET_ASSIGNEE_LIST]: getAssigneeListGetter, [GET_AUTH_KEY]: getAuthKeyGetter } = useGetters([GET_ASSIGNEE_LIST, GET_AUTH_KEY]);

const { [RESET_STATE]: resetJobStateMutation } = useJobStore().useMutations([RESET_STATE]);

const route = useRoute();

const moduleKey = computed(() => route.name as string);

const saveAssignee = ref<UserAssignee | undefined>(undefined);

const vAssignee = computed({
  get: (): UserAssignee => assignedState.value as UserAssignee,
  set: (value: UserAssignee) => {
    if (!value) {
      saveAssignee.value = undefined;
    }

    setAssigneeMutation(value);
  },
});

const modules = computed((): Array<UserAssignee> => getAssigneeListGetter.value as Array<UserAssignee>);
const isDisabled = computed(() => (getAssigneeListGetter.value as Array<UserAssignee>)?.every(({ modules }) => !modules[moduleKey.value]?.length));

watch(
  () => route.name,
  () => {
    if (vAssignee.value && !vAssignee.value.modules[moduleKey.value]?.length) {
      saveAssignee.value = cloneDeep(vAssignee.value);

      setAssigneeMutation();
    } else if (saveAssignee.value && saveAssignee.value.modules[moduleKey.value]?.length) {
      setAssigneeMutation(saveAssignee.value);
    }
  },
  { immediate: true }
);

watch(
  () => vAssignee.value?.userId,
  (newValue, oldValue) => {
    resetJobStateMutation();

    if (route.name && newValue !== oldValue) {
      routeTo({ name: route.name }, { isOverride: true });
    }
  },
  { immediate: true }
);
</script>

<style lang="scss">
.manager-assignee-select {
  margin-top: 0.25rem;

  &--active {
    .vz-select__container {
      color: var(--color-primary-100);
      background-color: var(--color-primary-900);
    }
  }

  @include max-mobile-layout {
    margin: 0.25rem;
  }
}
</style>
