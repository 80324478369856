import type { ThemeName, Themes } from '@/shared/services/css-service/types';
import { THEMES } from '@/shared/services/css-service/themes';
import LocalStorageService from '@shared/services/local-storage.service';

type Config = Partial<{ name: ThemeName; invert: boolean }>;

export default class ThemeService {
  private static readonly prefix: string = 'color';
  private static themes: Themes = THEMES;
  private static debounce: ReturnType<typeof setTimeout>;
  public static config: Config = LocalStorageService.load<Config>('theme') || { name: 'default', invert: false };

  public static set(config?: Config): void {
    this.config = { ...this.config, ...(config || {}) };
    const { name, invert } = this.config;

    document.getElementById('theme-style')?.remove();
    const themeStyle = document.createElement('style');
    themeStyle.id = 'theme-style';

    const themeContent = Object.entries(this.themes[name || 'default']).reduce((categories, [key, category]) => {
      return (
        categories +
        Object.entries(category).reduce((values, [level, value], currentIndex, bulk) => {
          if (invert) {
            value = bulk[bulk.length - currentIndex - 1][1];
          }

          return `${values}--${this.prefix}-${key}-${level}: ${value};`;
        }, '')
      );
    }, '');

    themeStyle.innerHTML = `:root {${themeContent}`;
    document.head.append(themeStyle);
    this.meta();
    LocalStorageService.save('theme', this.config);
  }

  public static invert(): void {
    clearTimeout(this.debounce);

    this.debounce = setTimeout(() => this.set({ ...this.config, invert: !this.config.invert }), 500);
  }

  private static meta(): void {
    const themeColor = getComputedStyle(document.documentElement).getPropertyValue(`--color-background-light`);

    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', themeColor);
  }
}
