import type { MutationTree } from 'vuex';
import type { JobState } from '@/views/job/store/job.types';
import type { StateCache } from '@/store/services/store.cache';
import type { BaseOption, BaseOptions } from '@/shared/models';
import { initialState } from '@/views/job/store/index';
import { RESET_STATE } from '@/store/constants';
import jobOfferMutations from '@/views/job/store/mutations/job-offer.mutations';
import conversationMutations from '@/views/job/store/mutations/conversation.mutations';
import { JOB_OFFER_DETAILS, JOB_OFFERS, JOB_OFFERS_FILTER, JOB_OFFERS_PAGINATION } from '@/views/job/store/constants/job-offer.constants';
import { CONVERSATION } from '@/views/job/store/constants/conversation.constants';
import { CLASSIFICATIONS, SET_JOB_CLASSIFICATION, SET_SKILLS_CACHE, SKILLS } from '@/views/job/store/constants/job.constants';

const jobMutations: MutationTree<JobState> = {
  [RESET_STATE]: (state) => {
    state[JOB_OFFERS] = initialState()[JOB_OFFERS];
    state[JOB_OFFERS_FILTER] = initialState()[JOB_OFFERS_FILTER];
    state[JOB_OFFERS_PAGINATION] = initialState()[JOB_OFFERS_PAGINATION];
    state[JOB_OFFER_DETAILS] = initialState()[JOB_OFFER_DETAILS];

    state[CONVERSATION] = initialState()[CONVERSATION];
  },

  [SET_SKILLS_CACHE]: (state, payload: StateCache<Array<BaseOption>>) => {
    state[SKILLS] = payload;
  },

  [SET_JOB_CLASSIFICATION]: (state, payload: BaseOptions<string>) => {
    state[CLASSIFICATIONS] = payload || [];
  },

  ...jobOfferMutations,
  ...conversationMutations,
};

export default jobMutations;
