<template>
  <div class="vz-tag" :data-errors="validateMessage">
    <vz-input
      v-model="text"
      class="fill-width"
      hide-details
      appendIcon="svg:plus"
      :label="label"
      :aria-label="ariaLabel"
      :placeholder="placeholder"
      :type="type"
      :autocomplete="autocomplete"
      :nullable="nullable"
      :disabled="disabled"
      :debounce="debounce"
      :loading="loading"
      :readonly="readonly"
      :clearable="clearable"
      :capitalized="capitalized"
      :isStandalone="isStandalone"
      :append-label="appendLabel"
      :append-color="text ? 'primary-900' : 'mono-300'"
      @click:append="onEnter"
      @enter="onEnter"
    >
      <div v-if="vModel?.length" class="d-flex fill-width flex-wrap gap-2 mt-2">
        <template v-for="(item, index) in vModel || []" :key="index">
          <vz-badge v-if="item" class="px-2" clearable :font-size="fontSize" :text="item" :color="color" @clear="onRemoveOption(index)" />
        </template>
      </div>
    </vz-input>

    <div :class="['vz-tag__error', { 'vz-tag__error--hidden': hideDetails && !validateMessage && !externalError }]" role="alert">
      <p v-if="validateMessage" :class="{ 'vz-tag__error-internal': !isTouched && !isStandalone }">{{ $t(validateMessage) }}</p>
      <p v-else-if="externalError && !isTouched">{{ $t(externalError) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ErrorResponse } from '@shared/services/api-service/models';
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { IconName } from '@shared/components/icon/icon.type';
import { computed, type PropType, ref } from 'vue';
import type { ColorsMap } from '@shared/services/css-service/types';
import type { SizeUnit } from '@shared/types';
import { useValidator } from '@shared/components/fields/helpers';
import { useTranslator } from '@/plugins/i18n/helpers';

const props = defineProps({
  name: { type: String as PropType<string | undefined>, default: undefined },
  modelValue: { type: Array as PropType<Array<string> | undefined>, required: true },
  label: { type: String, default: '' },
  ariaLabel: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  type: { type: String as PropType<'text' | 'number' | 'password' | 'email'>, default: 'text' },
  autocomplete: { type: String as PropType<'on' | 'off'>, default: 'off' },
  nullable: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  debounce: { type: [Number, String], default: 0 },
  loading: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
  capitalized: { type: Boolean, default: false },
  errorMessage: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
  hideDetails: { type: Boolean, default: false },
  isStandalone: { type: Boolean, default: false },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  appendIcon: { type: String as PropType<IconName | undefined>, default: undefined },
  appendLabel: { type: String, default: 'GENERAL.BUTTON' },
  color: { type: String as PropType<ColorsMap | string | undefined>, default: 'mono-800' },
  fontSize: { type: String as PropType<SizeUnit>, default: '1rem' },
  fieldErrors: { type: Object as PropType<Record<string, string> | null>, default: null },
});

const emit = defineEmits(['update:model-value']);

const t = useTranslator();

const text = ref<string>('');

const vModel = computed({
  get: () => props.modelValue || [],
  set: (value) => emit('update:model-value', value),
});

const { validateMessage, isTouched } = useValidator(
  computed(() => props.modelValue),
  computed(() => props.rules),
  props.name || props.label
);

const externalError = computed(() => {
  if (props.fieldErrors && props.name && props.fieldErrors[props.name]) {
    return props.fieldErrors[props.name];
  }

  if (!props.errorMessage) {
    return;
  }

  if (typeof props.errorMessage === 'string') {
    return props.errorMessage;
  }

  const { message, ...fields } = props.errorMessage.errorMessage!.pop() || {};

  return message ? t(message, { ...fields, ...(props.label ? { property: props.label } : {}) }) : undefined;
});

const onEnter = () => {
  if (text.value) {
    vModel.value = [...vModel.value, text.value];

    text.value = '';
  }
};

const onRemoveOption = (index: number) => {
  vModel.value.splice(index, 1);
};
</script>

<style scoped lang="scss"></style>
