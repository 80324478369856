import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex gap-2 align-center" }
const _hoisted_2 = { class: "ltr" }
const _hoisted_3 = {
  key: 1,
  class: "d-flex align-center gap-1"
}
const _hoisted_4 = { class: "text-title-1" }

import type { TableHeader } from '@/shared/components/tables/models/table-header';
import type { BasePagination } from '@shared/models';
import type { ProfileBaseDetails, SearchEmployeesReq, SearchEmployeesRes } from '@/views/employee/types';
import { computed } from 'vue';
import { routeTo, useAsync } from '@/shared/composables';
import dayjs from 'dayjs';
import { GlobalVariables } from '@/shared/constants/global-variables';
import { useBackofficeLogStore } from '@/views/backoffice/system-log/store';
import { DELETE_SYSTEM_LOG, GET_SYSTEM_LOG, SEARCH_SYSTEM_LOG } from '@/views/backoffice/system-log/store/system-log.constants';
import { storeToRefs } from 'pinia';
import { openDeletePopup } from '@shared/components/popup/helpers';
import EmployeeStrip from '@/views/employee/components/employee-strip.vue';
import useEmployeeStore from '@/views/employee/composables/use-employee-store';
import { SEARCH_EMPLOYEES } from '@/views/employee/store/employee.constants';
import { LENGTH } from '@shared/constants';
import { SystemLogColorEnum, SystemLogEnum } from '@/views/backoffice/system-log/constants/system-log.enum';
import { useTranslator } from '@/plugins/i18n/helpers';
import { SearchSystemLogRes } from '@/views/backoffice/system-log/store/system-log.types';


export default /*@__PURE__*/_defineComponent({
  __name: 'system-log-table',
  setup(__props) {

const t = useTranslator();

const tableHeaders: Array<TableHeader> = [
  { title: 'GENERAL.TITLE', value: 'title', sortable: true, minWidth: '120px' },
  { title: 'GENERAL.USER', value: 'user', sortable: true, minWidth: '120px', class: 'pa-0' },
  { title: 'GENERAL.CREATED_AT', value: 'createdAt', minWidth: '120px', sortable: true },
];

const { useActions: useEmployeeActions } = useEmployeeStore();
const { [SEARCH_EMPLOYEES]: searchEmployeesAction } = useEmployeeActions([SEARCH_EMPLOYEES]);
const searchEmployeesRequest = searchEmployeesAction as (payload: SearchEmployeesReq) => Promise<SearchEmployeesRes>;

const useBackofficeJobStore = useBackofficeLogStore();

const { data, pagination, filter } = storeToRefs(useBackofficeJobStore);
const {
  resetSystemLog,
  [SEARCH_SYSTEM_LOG]: searchSystemLog,
  [GET_SYSTEM_LOG]: getSystemLog,
  [DELETE_SYSTEM_LOG]: deleteSystemLog,
} = useBackofficeJobStore;

const searchRequest = useAsync<SearchSystemLogRes>(searchSystemLog as (page?: BasePagination) => Promise<SearchSystemLogRes>, {
  errorsCleanTimeout: 30 * 1000,
});

const getRequest = useAsync<void>(getSystemLog as (id: string) => Promise<void>, {
  errorsCleanTimeout: 30 * 1000,
});

const deleteRequest = useAsync<void>(deleteSystemLog as (id: string) => Promise<void>, {
  errorsCleanTimeout: 30 * 1000,
  successCallback: searchRequest.call,
});

const currentPage = computed(() => pagination.value?.index || 0);
const totalItems = computed(() => pagination.value?.total || 0);
const items = computed(() =>
  data.value.map((item) => ({ ...item, createdAt: dayjs(item.createdAt).format(GlobalVariables.DAY_MONTH_YEAR_TIME_FORMAT) }))
);
const logTypes = computed(() => Object.values(SystemLogEnum).map((value) => ({ value, title: t(`BACKOFFICE.SYSTEM_LOG.TYPES.${value}`) })));

const onSearch = (page?: Partial<BasePagination>): void => {
  searchRequest.call(page);
};

const onClear = (): void => {
  resetSystemLog();
  onSearch();
};

const onPageChange = (index: number): void => {
  onSearch({ index });
};

const onSortChange = (sort: BasePagination['sort']): void => {
  onSearch({ sort });
};

const onDelete = async ({ _id, title }: { _id: string; title?: string }): Promise<void> => {
  await openDeletePopup(() => deleteRequest.call(_id), '', title);
};

const onSelect = ({ _id }: { _id: string }): void => {
  getRequest.call(_id);
};

return (_ctx: any,_cache: any) => {
  const _component_vz_select = _resolveComponent("vz-select")!
  const _component_vz_input = _resolveComponent("vz-input")!
  const _component_vz_async_select = _resolveComponent("vz-async-select")!
  const _component_vz_datepicker = _resolveComponent("vz-datepicker")!
  const _component_vz_badge = _resolveComponent("vz-badge")!
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _component_vz_data_table = _resolveComponent("vz-data-table")!

  return (_openBlock(), _createBlock(_component_vz_data_table, {
    class: "system-log-table__container",
    "auto-fetch": "",
    headers: tableHeaders,
    items: items.value,
    page: currentPage.value,
    sort: _unref(pagination).sort,
    "total-items": totalItems.value,
    loading: _unref(searchRequest).loading.value,
    errors: _unref(searchRequest).error.value || {},
    "delete-callback": onDelete,
    onClear: onClear,
    onSearch: onSearch,
    "onSelect:item": onSelect,
    "onUpdate:page": onPageChange,
    "onUpdate:sort": onSortChange
  }, {
    "search-panel": _withCtx(({ errors }) => [
      _createVNode(_component_vz_select, {
        modelValue: _unref(filter).type,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(filter).type) = $event)),
        clearable: "",
        label: "BACKOFFICE.SYSTEM_LOG.TYPE",
        items: logTypes.value
      }, null, 8, ["modelValue", "items"]),
      _createVNode(_component_vz_input, {
        modelValue: _unref(filter).value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(filter).value) = $event)),
        clearable: "",
        label: "GENERAL.SEARCH",
        rules: { maxLength: [_unref(LENGTH).TITLE] },
        "error-message": errors?.value
      }, null, 8, ["modelValue", "rules", "error-message"]),
      _createVNode(_component_vz_async_select, {
        modelValue: _unref(filter).userId,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(filter).userId) = $event)),
        class: "col-flex-6",
        clearable: "",
        label: "BACKOFFICE.SYSTEM_LOG.USER",
        callback: _unref(searchEmployeesRequest),
        "return-value": (value) => value._id,
        "error-message": errors?.value
      }, {
        default: _withCtx(({ item }) => [
          _createVNode(EmployeeStrip, {
            class: "pa-0",
            clickable: "",
            "avatar-size": "28",
            item: item
          }, null, 8, ["item"])
        ]),
        badge: _withCtx(() => [
          _createVNode(EmployeeStrip, {
            class: "pa-0",
            "avatar-size": "28",
            hid: _unref(filter).userId
          }, null, 8, ["hid"])
        ]),
        _: 2
      }, 1032, ["modelValue", "callback", "return-value", "error-message"]),
      _createVNode(_component_vz_datepicker, {
        modelValue: _unref(filter).dateFrom,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(filter).dateFrom) = $event)),
        clearable: "",
        label: "GENERAL.DATE_FROM",
        max: _unref(filter).dateTo,
        "error-message": errors?.dateFrom
      }, null, 8, ["modelValue", "max", "error-message"]),
      _createVNode(_component_vz_datepicker, {
        modelValue: _unref(filter).dateTo,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(filter).dateTo) = $event)),
        clearable: "",
        label: "GENERAL.DATE_TO",
        min: _unref(filter).dateFrom,
        "error-message": errors?.dateTo
      }, null, 8, ["modelValue", "min", "error-message"])
    ]),
    title: _withCtx(({ item }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(item.title), 1),
        _createVNode(_component_vz_badge, {
          "font-size": "0.75rem",
          text: item.type,
          color: _unref(SystemLogColorEnum)[item.type as keyof typeof SystemLogColorEnum]
        }, null, 8, ["text", "color"])
      ])
    ]),
    user: _withCtx(({ item }) => [
      (item.user)
        ? (_openBlock(), _createBlock(EmployeeStrip, {
            key: 0,
            class: "pa-0",
            horizontal: "",
            "show-company": "",
            "font-size": "1rem",
            item: item.user,
            "avatar-size": "42",
            onClick: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["stop"])),
            onSelect: _cache[6] || (_cache[6] = ($event: any) => (_unref(routeTo)({ query: { userId: $event?._id } })))
          }, null, 8, ["item"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_vz_icon, {
              name: "svg:user-settings",
              size: "42px"
            }),
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('GENERAL.SYSTEM')), 1)
            ])
          ]))
    ]),
    _: 1
  }, 8, ["items", "page", "sort", "total-items", "loading", "errors"]))
}
}

})