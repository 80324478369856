import type { ErrorResponse } from '@/shared/services/api-service/models';
import type { StepComponent } from '@/shared/components/stepper/models/step-component';
import { computed, ComputedRef, type Ref, ref, watch } from 'vue';
import ServerError from '@/shared/services/api-service/server-error';
import { useServerErrorsMapper } from '@/shared/composables';
import { useFormValidator } from '@/shared/components/fields/helpers';

export const stepperComponentMapper = (
  components: ComputedRef<Array<StepComponent>>,
  callback: { submit: () => void; cancel?: () => void },
  errors: Ref<ErrorResponse | null> = ref<ErrorResponse | null>(null)
): {
  formRef: Ref;
  dynamicComponent: ComputedRef<StepComponent>;
  nextStep: () => Promise<boolean>;
  previousStep: () => void;
  resetSteps: () => void;
  stepNumber: Ref<number>;
  isLastStep: Ref<boolean>;
  totalSteps: Ref<number>;
  loading: Ref<boolean>;
  errors: ComputedRef<ErrorResponse | null>;
  fields: Ref<Record<string, string>>;
} => {
  const formRef = ref();
  const stepNumber = ref<number>(0);
  const loading = ref<boolean>(false);
  const fields = useServerErrorsMapper(errors);

  const isLastStep = computed((): boolean => stepNumber.value === components.value.length - 1);
  const dynamicComponent = computed((): StepComponent => components.value[stepNumber.value]);
  const totalSteps = computed(() => components.value.length);

  const resetSteps = (): void => {
    errors.value = null;
    stepNumber.value = 0;
    formRef.value?.reset();
  };

  const nextStep = async (): Promise<boolean> => {
    const isValid = useFormValidator(formRef);

    if (!isValid()) {
      return false;
    }

    try {
      loading.value = true;
      await dynamicComponent.value.callback?.();

      if (isLastStep.value) {
        await callback.submit?.();
      } else {
        stepNumber.value++;
      }

      return true;
    } catch (e: any) {
      errorHandler(e);

      return false;
    } finally {
      loading.value = false;
    }
  };

  const errorHandler = (fulfilled?: ServerError | any): void => {
    if (!fulfilled) {
      return;
    }

    errors.value = fulfilled;

    const property = fulfilled.errorMessage?.[0].property;

    if (property && components.value.find(({ properties }) => properties?.some((p) => property.includes(p)))) {
      stepNumber.value = components.value.findIndex(({ properties }) => properties?.some((p) => property.includes(p)));
    }
  };

  const previousStep = (): void => {
    loading.value = true;

    if (!stepNumber.value) {
      callback.cancel?.();
      resetSteps();
    } else {
      stepNumber.value--;
    }

    loading.value = false;
  };

  const restErrors = computed(() => {
    if (!errors.value) {
      return null;
    }

    const properties = Object.keys(fields.value);

    return {
      ...errors.value,
      errorMessage: errors.value?.errorMessage?.filter(({ property }) => !property || !properties.includes(property)),
    };
  });

  watch(
    () => errors.value,
    () => errorHandler(errors.value),
    { deep: true }
  );

  return {
    formRef,
    nextStep,
    previousStep,
    resetSteps,
    isLastStep,
    stepNumber,
    totalSteps,
    dynamicComponent,
    loading,
    errors: restErrors,
    fields,
  };
};
