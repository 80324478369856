import { openPopup } from '@shared/components/popup/helpers/open-popup';
import { emitter } from '@/main';

export const openDeletePopup = async (callback: () => unknown | Promise<unknown>, type: string, name?: string): Promise<void> => {
  const title = name ? `'${name}'` : '';

  return await openPopup({
    title: ['WARNING.DELETE', { type }],
    description: ['WARNING.ARE_YOU_SURE_WANT_TO_DELETE', { name: title, type }],
    actions: [
      {
        label: 'GENERAL.CANCEL',
        callback: () => emitter.emit('CLOSE_GLOBAL_MODAL'),
      },
      {
        label: 'GENERAL.DELETE',
        color: 'red-900',
        callback: async () => {
          try {
            await callback();
          } catch (error) {
            console.error(error);
          }
        },
      },
    ],
    icon: { name: 'svg:trash', color: 'red-600' },
  });
};
