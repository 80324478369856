import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "message-input" }
const _hoisted_2 = {
  key: 0,
  class: "mt-4 pa-4 relative"
}
const _hoisted_3 = { class: "d-flex gap-1 align-center" }
const _hoisted_4 = {
  key: 1,
  class: "flex-grow-1 text-center"
}
const _hoisted_5 = {
  key: 2,
  class: "d-flex align-center gap-4 pe-4"
}

import type { SendMessagePayload } from '@shared/components/messenger/messenger.type';
import type { BaseChat } from '@/views/messenger/types';
import { computed, type PropType, ref, watch } from 'vue';
import { useSwipe } from '@shared/composables/use-swipe';
import { AudioRecorder } from '@shared/services/audio-recorder';
import dayjs from 'dayjs';
import { LENGTH } from '@shared/constants/length';
import MessageShareSheet from '@shared/components/messenger/components/message-share-sheet/message-share-sheet.vue';
import MessageShareLocation from '@shared/components/messenger/components/message-share-sheet/message-share-location.vue';
import MessageCard from '@shared/components/messenger/components/message-card.vue';
import VzAttachment from '@shared/components/vz-attachment.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'message-input',
  props: {
  value: { type: Object as PropType<SendMessagePayload | undefined>, default: undefined },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  maxLength: { type: [String, Number], default: LENGTH.LONG_DESCRIPTION },
  participants: { type: Array as PropType<BaseChat['participants']>, required: true },
},
  emits: ['send', 'force', 'blur', 'update:payload'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const payload = ref<SendMessagePayload>(props.value || {});
const audioRecordRef = ref<HTMLElement | undefined>(undefined);
const isRecording = ref<boolean>(false);
const startAt = ref<number>(0);
const recordTime = ref<number>(0);
const countId = ref<ReturnType<typeof setInterval>>();

const runTimeDisplay = computed(() => {
  const duration = dayjs.duration(recordTime.value);
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
});

const recorder = new AudioRecorder({
  startCallback: () => startCount(),
  stopCallback: async () => stopCount(),
});

const startRecording = async () => {
  if (props.disabled) {
    return;
  }

  await recorder.start();
};

const stopRecording = async (): Promise<void> => {
  await recorder.stop(async () => {
    recordTime.value = recorder.recordTime;
    emit('send', { audio: recorder.getFile(), message: undefined });
  });
};

const onSendMessage = (): void => {
  if (props.disabled) {
    return;
  }

  emit('send', { ...payload.value, audio: undefined });
};

const onUpdatePayload = (update: Partial<SendMessagePayload>): void => {
  emit('update:payload', { ...payload.value, ...update });
};

const cancelRecording = async (): Promise<void> => await recorder.cancel();

const startCount = () => {
  isRecording.value = true;
  startAt.value = Date.now();
  recordTime.value = 0;
  clearInterval(countId.value);

  countId.value = setInterval(() => (recordTime.value = Date.now() - startAt.value), 100);
};

const stopCount = () => {
  isRecording.value = false;
  clearInterval(countId.value);
};

useSwipe(audioRecordRef, {
  minStepSize: 30,
  onNext: cancelRecording,
});

watch(
  () => props.value,
  (value) => (payload.value = value || {})
);

__expose({ isLoading: isRecording, recordTime, startRecording, stopRecording });

return (_ctx: any,_cache: any) => {
  const _component_vz_close_button = _resolveComponent("vz-close-button")!
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _component_vz_spinner = _resolveComponent("vz-spinner")!
  const _component_vz_input = _resolveComponent("vz-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (payload.value.replyTo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_vz_close_button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (onUpdatePayload({ replyTo: undefined })))
          }),
          (payload.value.replyTo)
            ? (_openBlock(), _createBlock(MessageCard, {
                key: 0,
                disabled: "",
                participants: __props.participants,
                message: payload.value.replyTo
              }, null, 8, ["participants", "message"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (payload.value?.coordinates)
      ? (_openBlock(), _createBlock(MessageShareLocation, {
          key: 1,
          class: "mt-4",
          clearable: "",
          coordinates: payload.value?.coordinates,
          onClear: _cache[1] || (_cache[1] = ($event: any) => (onUpdatePayload({ coordinates: undefined })))
        }, null, 8, ["coordinates"]))
      : _createCommentVNode("", true),
    (payload.value.attachments?.length)
      ? (_openBlock(), _createBlock(VzAttachment, {
          key: 2,
          "accept-type": "image/jpeg,image/png,application/pdf",
          value: payload.value.attachments,
          "onUpdate:value": _cache[2] || (_cache[2] = (attachments) => onUpdatePayload({ attachments }))
        }, null, 8, ["value"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      ref_key: "audioRecordRef",
      ref: audioRecordRef,
      class: "message-input__container"
    }, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_vz_icon, {
          name: "svg:microphone",
          size: "1.5rem",
          color: isRecording.value ? 'primary-900' : 'mono-500',
          onMousedown: startRecording,
          onMouseup: stopRecording,
          onTouchstart: startRecording,
          onTouchend: stopRecording
        }, null, 8, ["color"]),
        _createVNode(MessageShareSheet, {
          payload: __props.value,
          "onUpdate:payload": onUpdatePayload
        }, null, 8, ["payload"])
      ]),
      (!isRecording.value && !payload.value.audio)
        ? (_openBlock(), _createBlock(_component_vz_input, {
            key: 0,
            modelValue: payload.value.message,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((payload.value.message) = $event)),
            class: "flex-grow-1",
            "hide-details": "",
            disabled: __props.disabled,
            rules: { maxLength: [+__props.maxLength] },
            onEnter: onSendMessage,
            onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('force'))),
            onBlur: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('blur')))
          }, {
            append: _withCtx(() => [
              (__props.loading)
                ? (_openBlock(), _createBlock(_component_vz_spinner, {
                    key: 0,
                    size: "1.5rem"
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["modelValue", "disabled", "rules"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(runTimeDisplay.value), 1)),
      (isRecording.value || payload.value.audio)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createVNode(_component_vz_icon, {
              name: "svg:check",
              size: "1rem",
              onClick: stopRecording
            }),
            _createVNode(_component_vz_icon, {
              name: "svg:xmark",
              size: "1rem",
              onClick: cancelRecording
            })
          ]))
        : (_openBlock(), _createBlock(_component_vz_icon, {
            key: 3,
            clickable: "",
            name: "svg:send",
            size: "1.5rem",
            color: __props.loading ? 'mono-500' : 'primary-900',
            onClick: onSendMessage
          }, null, 8, ["color"]))
    ], 512)
  ]))
}
}

})