import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

import { computed, type PropType, ref } from 'vue';
import { MenuItem } from '@/shared/components/menus/models/menu-item';
import { IconType } from '@shared/components/icon/icon.type';
import VzPopover from '@shared/components/menus/vz-popover.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-popover-menu',
  props: {
  dim: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  items: { type: Array as PropType<Array<MenuItem>>, default: () => [] },
  size: { type: [String, Number], default: 42 },
  offsetX: { type: [String, Number], default: 4 },
  offsetY: { type: [String, Number], default: 0 },
  uppercase: { type: Boolean, default: true },
  iconType: { type: String as PropType<IconType>, default: 'solid' },
},
  setup(__props) {

const props = __props;

const loadingIndex = ref<number | null>(null);

const itemList = computed(() => (props.items || []).filter((item) => !item.hidden));

const onSelect = async (callback: () => void | Promise<void>, index: number, closeCallback?: () => void): Promise<void> => {
  try {
    loadingIndex.value = index;
    await callback();
    closeCallback?.();
  } finally {
    loadingIndex.value = null;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_vz_divider = _resolveComponent("vz-divider")!
  const _component_vz_button = _resolveComponent("vz-button")!

  return (_openBlock(), _createBlock(VzPopover, { dim: "" }, _createSlots({
    activator: _withCtx(() => [
      _renderSlot(_ctx.$slots, "activator")
    ]),
    default: _withCtx(({ close }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(itemList.value, (item, index) => {
        return (_openBlock(), _createElementBlock("div", { key: index }, [
          (item.divider === 'before')
            ? (_openBlock(), _createBlock(_component_vz_divider, {
                key: 0,
                class: "mt-1"
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_vz_button, {
            class: "text-title-1 my-2",
            type: "flat",
            "icon-color": "primary-900",
            uppercase: __props.uppercase,
            loading: loadingIndex.value === index,
            "icon-name": item.icon?.name,
            "icon-type": item.icon?.type || __props.iconType,
            "icon-size": item.icon?.size || '1.5rem',
            text: item.text,
            onClick: ($event: any) => (onSelect(item.click, index, close))
          }, null, 8, ["uppercase", "loading", "icon-name", "icon-type", "icon-size", "text", "onClick"]),
          (item.divider === 'after')
            ? (_openBlock(), _createBlock(_component_vz_divider, { key: 1 }))
            : _createCommentVNode("", true)
        ]))
      }), 128))
    ]),
    _: 2
  }, [
    (_ctx.$slots['top'])
      ? {
          name: "top",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "top")
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.$slots['bottom'])
      ? {
          name: "bottom",
          fn: _withCtx(() => [
            _renderSlot(_ctx.$slots, "bottom")
          ]),
          key: "1"
        }
      : undefined
  ]), 1024))
}
}

})