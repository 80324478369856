import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["alt", "src"]

import { type PropType, ref, watch } from 'vue';
import { BaseImageResponse } from '@shared/models';
import { useSwipe } from '@shared/composables/use-swipe';
import { isRtl } from '@/plugins/i18n/helpers';
import { ColorFilter } from '@shared/services/css-service/color-filter.service';
import getThemeColor from '@shared/services/css-service/helpers/get-theme-color';
import type { ColorsMap } from '@shared/services/css-service/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-image',
  props: {
  alt: { type: String, default: '' },
  src: { type: String, default: '' },
  color: { type: String as PropType<ColorsMap | string | undefined>, default: undefined },
  srcProvider: { type: Object as PropType<Promise<BaseImageResponse> | undefined>, default: undefined },
  defaultSrc: { type: String, default: () => require('@/assets/images/loader/image.svg') },
},
  emits: ['error', 'load', 'back', 'next'],
  setup(__props, { emit: __emit }) {

const props = __props;
const emit = __emit;

const isLoading = ref<boolean>(true);
const asyncLoading = ref<boolean>(false);
const isFailed = ref<boolean>(false);
const hidAlt = ref<string | undefined>(undefined);
const imgSrc = ref<string | undefined>(props.src || props.defaultSrc);
const filter = ref();

const onError = (event: Event) => {
  if (!isLoading.value) {
    return;
  }

  (event.target as HTMLImageElement).onerror = null;
  isFailed.value = true;
  isLoading.value = false;
  imgSrc.value = props.defaultSrc;
  emit('error');
};

const onLoaded = async (): Promise<void> => {
  isLoading.value = false;
  emit('load');
};

const imgRef = ref<HTMLElement | undefined>(undefined);

useSwipe(imgRef, { isRtl: ref(isRtl()), onBack: () => emit('back'), onNext: () => emit('next') });

watch(
  () => props.srcProvider,
  async (provider) => {
    if (!provider) {
      return;
    }

    try {
      const preview = props.src;
      asyncLoading.value = true;
      const { image, alt } = await provider;

      if (preview !== props.src) {
        return;
      }

      imgSrc.value = image || props.src || props.defaultSrc;
      hidAlt.value = alt;
    } catch (e) {
      imgSrc.value = props.src || props.defaultSrc;
    } finally {
      asyncLoading.value = false;
    }
  },
  { immediate: true }
);

watch(
  () => props.color,
  (color) => {
    if (!color) {
      return;
    }

    const filteredColor = getThemeColor(color);

    if (filteredColor) {
      filter.value = new ColorFilter(filteredColor).filter;
    }
  },
  { immediate: true }
);

watch(
  () => props.src,
  () => (imgSrc.value = props.src)
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("img", _mergeProps({
    ref_key: "imgRef",
    ref: imgRef,
    class: { skeleton: isLoading.value || asyncLoading.value, default: imgSrc.value === __props.defaultSrc },
    alt: __props.alt || hidAlt.value,
    src: imgSrc.value,
    style: { filter: filter.value }
  }, _ctx.$attrs, {
    onError: onError,
    onLoad: onLoaded,
    onLoadstart: _cache[0] || (_cache[0] = ($event: any) => (isLoading.value = true))
  }), null, 16, _hoisted_1))
}
}

})