import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "system-log" }

import SystemLogTable from '@/views/backoffice/system-log/components/system-log-table.vue';
import SystemLogViewModal from '@/views/backoffice/system-log/components/system-log-view-modal.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'system-log.view',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(SystemLogTable)
    ]),
    _createVNode(SystemLogViewModal)
  ], 64))
}
}

})