import type { RouteLocationNormalized, RouteLocationRaw } from 'vue-router';
import { DEFAULT_PAGE_TITLE } from '@/router/constants/modules';
import i18n from '@/plugins/i18n';
import { kebabCaseToScreamingSnakeCase } from '@shared/helpers';

const PROPERTIES: Record<'title' | 'description' | 'image', Array<string>> = {
  title: ['og:site_name', 'twitter:title'],
  description: ['og:description', 'twitter:description'],
  image: ['og:image', 'twitter:card'],
};

export const routeMetaUpdate = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: (to?: RouteLocationRaw | false | void) => void
): Promise<void> => {
  document.title = DEFAULT_PAGE_TITLE;

  const setTag = (tagKey: 'property' | 'name', value: string) => (tagValue: string) => {
    const metaTag = document.querySelector(`meta[${tagKey}="${tagValue}"]`);
    metaTag?.setAttribute('content', value);
  };

  const set = (type: keyof typeof PROPERTIES, value: string) => {
    setTag('name', value)(type);
    PROPERTIES[type].forEach(setTag('property', value));
  };

  Object.entries(to.meta || {}).forEach(([key, value]) => {
    switch (key) {
      case 'title': {
        document.title = value as string;
        set('title', value as string);
        break;
      }
      case 'description': {
        set('description', value as string);
        break;
      }
      case 'image': {
        set('image', value as string);
        break;
      }
      default: {
        setTag('property', value as string)(key);
        break;
      }
    }
  });

  if (!to.meta?.title) {
    const variable = `MODULE.${kebabCaseToScreamingSnakeCase(to.name as string)}`;
    const title = i18n.global.t(variable);

    if (variable !== title) {
      document.title = title;
      set('title', title);
    }
  }

  next();
};
