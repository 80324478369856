import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-management flex-grow-1 fill-height" }

import UserManagementTable from '@/views/backoffice/user-management/components/user-management-table.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'user-management.view',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(UserManagementTable)
  ]))
}
}

})