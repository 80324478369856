<template>
  <vz-overlay
    ref="vzOverlayRef"
    v-model:is-open="vIsOpen"
    :class="['vz-modal', ...contentClass]"
    v-bind="$attrs"
    :non-resizable="nonResizable"
    :non-draggable="nonDraggable"
    :fit-content="fitContent"
    :size="size"
    :loading="loading"
    :disable-escape="disableEscape"
    :hide-close-button="hideCloseButton"
    :open-event="openEvent"
    :open-callback="openModalCallback"
    :close-event="closeEvent"
    :close-callback="closeCallback"
    :warning-callback="warningCallback"
    @close="$emit('close', $event)"
    @open="$emit('open', $event)"
    @update:is-open="$emit('update:is-open', $event)"
  >
    <template #activator="{ toggle }">
      <slot name="activator" :toggle="toggle" />
    </template>

    <template v-if="title || subtitle || errors?.errorMessage || $slots['header']">
      <div class="vz-modal__header">
        <slot name="header" />

        <p v-if="title" class="text-title-3 px-2 py-2">{{ $t(title) }}</p>
        <p v-if="subtitle" class="mt-2 text-body-2">{{ $t(subtitle) }}</p>

        <template v-if="!hideStepper && (steps || []).length > 1">
          <vz-divider class="my-2" />

          <div class="vz-modal__stepper">
            <div
              v-for="(name, index) in titleStep"
              :key="name"
              :class="['stepper-item', { active: activeStepIndex === index }, { completed: activeStepIndex > index }]"
            >
              <div class="step-counter">{{ index + 1 }}</div>
              <div class="step-name">{{ name }}</div>
            </div>
          </div>
        </template>

        <vz-error-alert class="mb-6" :errors="errors" />
      </div>

      <vz-divider v-if="!hideHeaderDivider" class="my-4" />
    </template>

    <div ref="contentRef" :class="['vz-modal__content', { 'overflow-y-scroll pe-1': overflowY }]">
      <div v-if="openLoading" class="fill-width d-flex align-center justify-center">
        <vz-spinner class="mx-auto" size="96px" />
      </div>

      <template v-else>
        <slot name="default" :close="close" :open="open" :field-errors="fieldErrors" :server-errors="errors" />

        <slot :name="activeStep" :close="close" :open="open" :field-errors="fieldErrors" :server-errors="errors" />
      </template>
    </div>

    <template v-if="!hideActions">
      <vz-divider v-if="!hideContentDivider" class="my-2" />

      <div class="vz-modal__actions">
        <slot name="actions" :close="close" :open="open">
          <vz-button
            v-if="!hideCancel"
            background-color="transparent"
            class="min-width-120 height-40 font-weight-600"
            :text="isFirstStep ? cancelText : 'GENERAL.BACK'"
            :disabled="isLoading"
            @click="handleBack(vzOverlayRef?.close)"
          />

          <vz-button
            v-if="!hideSubmit"
            background-color="var(--color-primary-900)"
            color="var(--color-background-regular)"
            class="min-width-120 height-40 font-weight-600"
            :text="isLastStep ? submitText : 'GENERAL.NEXT'"
            :loading="isLoading"
            @click="handleNext(onSubmit)"
          />
        </slot>
      </div>
    </template>
  </vz-overlay>
</template>

<script setup lang="ts">
import type { VzOverlayRef } from '@shared/components/overlay/vz-overlay.type';
import type { ErrorResponse } from '@shared/services/api-service/models';
import { computed, type PropType, ref } from 'vue';
import { useAsync, useServerErrorsMapper } from '@shared/composables';
import { VzOverlayEnum } from '@shared/components/overlay/vz-overlay.enum';
import { useFormValidator } from '@shared/components/fields/helpers';
import { modalStepHandler } from '@shared/components/overlay/helpers/modal-step-handler';

const emit = defineEmits(['update:is-open', 'open', 'close', 'submit']);

const props = defineProps({
  hideHeaderDivider: { type: Boolean, default: false },
  hideContentDivider: { type: Boolean, default: false },
  nonResizable: { type: Boolean, default: false },
  nonDraggable: { type: Boolean, default: false },
  hideCancel: { type: Boolean, default: false },
  cancelText: { type: String, default: 'GENERAL.CANCEL' },
  hideSubmit: { type: Boolean, default: false },
  submitText: { type: String, default: 'GENERAL.SUBMIT' },
  hideActions: { type: Boolean, default: false },
  overflowY: { type: Boolean, default: true },
  isOpen: { type: Boolean, default: false },
  title: { type: String as PropType<string | undefined>, default: undefined },
  subtitle: { type: String as PropType<string | undefined>, default: undefined },
  fitContent: { type: Boolean, default: false },
  errors: { type: Object as PropType<ErrorResponse | null>, default: () => null },
  loading: { type: Boolean, default: false },
  disableEscape: { type: Boolean, default: false },
  warningCallback: {
    type: [Boolean, Function] as PropType<boolean | ((payload?: any) => Promise<unknown>)>,
    default: false,
  },
  hideCloseButton: { type: Boolean, default: false },
  openEvent: { type: String as PropType<string | undefined>, default: undefined },
  closeEvent: { type: String as PropType<string | undefined>, default: undefined },
  openCallback: { type: Function as PropType<(payload?: any) => Promise<unknown> | unknown>, default: undefined },
  closeCallback: { type: Function as PropType<(payload?: any) => Promise<unknown> | unknown>, default: undefined },
  submitCallback: { type: Function as PropType<() => Promise<unknown> | unknown | undefined>, default: undefined },
  size: { type: String as PropType<keyof typeof VzOverlayEnum | undefined>, default: VzOverlayEnum.SMALL },
  steps: { type: Array as PropType<Array<string> | undefined>, default: undefined },
  hideStepper: { type: Boolean, default: false },
  nextCallbacks: {
    type: [Object] as PropType<Record<string, () => void | Promise<void>> | undefined>,
    default: undefined,
  },
  backCallbacks: {
    type: [Object] as PropType<Record<string, () => void | Promise<void>> | undefined>,
    default: undefined,
  },
  class: { type: [Object, Array, String] as PropType<string | Record<string, any> | Array<string | Record<string, any>>>, default: () => [] },
  type: { type: String, default: '' },
  titleStep: { type: Array as PropType<Array<string> | undefined>, default: undefined },
});

const contentClass = computed(() => (Array.isArray(props.class) ? props.class : [props.class]));
const isLoading = computed(() => submitLoading.value || isStepLoading.value);
const errors = computed(() => openError.value || vzOverlayRef.value?.errors || props.errors || submitErrors.value || null);
const fieldErrors = useServerErrorsMapper(computed(() => vzOverlayRef.value?.errors || props.errors || null));

const {
  call: onSubmit,
  loading: submitLoading,
  error: submitErrors,
} = useAsync(
  async () => {
    if (!validate()) {
      return;
    }

    await props.submitCallback?.();
    emit('submit');
  },
  { successCallback: () => vzOverlayRef.value?.close(true) }
);

const {
  call: openModalCallback,
  loading: openLoading,
  error: openError,
} = useAsync(async (payload: any) => {
  resetStep();
  await props.openCallback?.(payload);
});

const contentRef = ref<Element | undefined>(undefined);
const vzOverlayRef = ref<VzOverlayRef>(undefined);

const validate = (isSilent?: boolean): boolean => {
  const isValid = useFormValidator(contentRef, isSilent);

  return isValid();
};

const vIsOpen = computed({
  get: () => props.isOpen,
  set: (value) => emit('update:is-open', value),
});

const { handleNext, handleBack, resetStep, isLastStep, isFirstStep, activeStep, activeStepIndex, isStepLoading } = modalStepHandler({
  validate,
  steps: computed(() => props.steps),
  nextCallbacks: props.nextCallbacks,
  backCallbacks: props.backCallbacks,
});

const close = (isForce?: boolean) => vzOverlayRef.value?.close(isForce);
const open = () => vzOverlayRef.value?.open();

defineExpose({
  validate,
  close: (isForce?: boolean) => vzOverlayRef.value?.close(isForce),
  open: () => vzOverlayRef.value?.open(),
  errors: computed(() => vzOverlayRef.value?.errors || props.errors),
});
</script>

<style lang="scss">
.vz-modal {
  &__header {
    position: relative;
    width: 100%;
  }

  &__stepper {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    color: #ffff;
  }

  &__progress_bar {
    user-select: none;
    --modal-progress-bar-width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: var(--modal-progress-bar-width);
    height: 2rem;
    margin-bottom: 0.5rem;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 1rem;
      width: calc(var(--modal-progress-bar-width) - 2rem);
      height: 100%;
      border-top: 1px solid var(--color-mono-400);
    }

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      border: var(--border-regular);
      border-radius: 50%;
      font-weight: 500;
      z-index: 1;
      transition:
        height 0.3s,
        width 0.3s,
        background-color 0.3s,
        margin-top 0.3s;

      &--active {
        height: 32px;
        width: 32px;
        color: var(--color-mono-200);
        background-color: var(--color-primary-900);
      }

      &:not(&--active) {
        margin-top: 2px;
        height: 28px;
        width: 28px;
        color: var(--color-mono-400);
      }

      &--passed {
        background-color: var(--color-primary-200);
      }

      &:not(&--passed):not(&--active) {
        background-color: var(--color-mono-200);
      }
    }
  }

  &__content {
    overflow: hidden;
    flex-grow: 1;
    padding: 0 1rem;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    padding: 0.5rem 2rem 2rem 2rem;
    position: relative;
    height: fit-content;
    width: 100%;

    @include max-tablet-layout {
      padding-bottom: 2rem;
    }
  }

  .vz-overlay-modal__close-button {
    top: 0.5rem;
  }
}
</style>
