export const addScriptOnce = (src: Array<string> | string, attr?: Array<Record<string, string> | null> | Record<string, string>): void => {
  (Array.isArray(src) ? src : [src]).forEach((url, index) => {
    if (!document.querySelector(`script[src="${url}"]`)) {
      const script = document.createElement('script');
      script.src = url;
      Object.entries((Array.isArray(attr) ? attr[index] : attr) || []).forEach(([key, value]) => script.setAttribute(key, value));
      document.body.appendChild(script);
    }
  });
};
