<template>
  <div class="vz-input" :class="{ 'vz-input--loading': loading, 'vz-input--disabled': disabled }" :data-errors="validateMessage">
    <label class="text-ellipsis">{{ $t(label) }}</label>

    <div class="vz-input__container">
      <slot name="prefix" />

      <input
        ref="inputRef"
        tabindex="0"
        :class="['text-auto', { ltr }]"
        :value="vModel"
        :placeholder="$t(placeholder)"
        :disabled="disabled"
        :autocomplete="autocomplete"
        :readonly="readonly"
        :type="inputType"
        :aria-label="t('COMPONENT_LABELS.TEXT_FIELD', { value: ariaLabel || label || placeholder })"
        @focus="onFocus"
        @blur="onBlur"
        @input="onUpdate"
        @keydown="onKeydown"
      />

      <vz-icon
        v-if="clearable && isClearable"
        class="vz-input__icon"
        clickable
        role="button"
        name="svg:xmark"
        size="0.75rem"
        color="primary-900"
        :aria-label="t('COMPONENT_LABELS.BUTTON', { value: 'GENERAL.CLEAR' })"
        @click="$emit('update:model-value', null)"
      />

      <slot name="append">
        <vz-icon
          v-if="appendIcon"
          clickable
          role="button"
          :size="appendSize"
          :name="appendIcon"
          :color="appendColor"
          :aria-label="t('COMPONENT_LABELS.BUTTON', { value: appendLabel })"
          @click="$emit('click:append')"
        />
      </slot>
    </div>

    <slot />

    <div :class="['vz-input__error', { 'vz-input__error--hidden': hideDetails && !validateMessage && !externalError }]" role="alert">
      <p v-if="validateMessage" :class="{ 'vz-input__error-internal': !isTouched && !isStandalone }">{{ $t(validateMessage) }}</p>
      <p v-else-if="externalError && !isTouched">{{ $t(externalError) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { ErrorResponse } from '@/shared/services/api-service/models';
import type { IconName } from '@/shared/components/icon/icon.type';
import { computed, type PropType, ref, watch } from 'vue';
import { useValidator } from '@/shared/components/fields/helpers';
import { useTranslator } from '@/plugins/i18n/helpers';
import { ColorName } from '@shared/services/css-service/types';
import { SizeUnit } from '@shared/types';

const t = useTranslator();

const props = defineProps({
  ltr: { type: Boolean as PropType<boolean>, default: false },
  name: { type: String as PropType<string | undefined>, default: undefined },
  modelValue: { type: [Object, Number, String, Array] as PropType<Record<string, any> | number | string | Array<any> | undefined>, required: true },
  label: { type: String, default: '' },
  ariaLabel: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  type: { type: String as PropType<'text' | 'number' | 'password' | 'email'>, default: 'text' },
  autocomplete: { type: String as PropType<'on' | 'off'>, default: 'off' },
  nullable: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  debounce: { type: [Number, String], default: 0 },
  loading: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
  capitalized: { type: Boolean, default: false },
  errorMessage: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
  hideDetails: { type: Boolean, default: false },
  isStandalone: { type: Boolean, default: false },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  appendIcon: { type: String as PropType<IconName | undefined>, default: undefined },
  appendColor: { type: String as PropType<ColorName | undefined>, default: undefined },
  appendSize: { type: String as PropType<SizeUnit | undefined>, default: '0.75rem' },
  appendLabel: { type: String, default: 'GENERAL.BUTTON' },
  fieldErrors: { type: Object as PropType<Record<string, string> | null>, default: null },
});

const emit = defineEmits(['update:model-value', 'click:append', 'on-timeout', 'focus', 'blur', 'enter']);

const debounceTimeout = ref<ReturnType<typeof setTimeout>>();
const inputRef = ref<HTMLInputElement | undefined>(undefined);
const isFocus = ref<boolean>(false);
const blurTimeout = ref<ReturnType<typeof setTimeout>>();

const { validateMessage, isTouched } = useValidator(
  computed(() => props.modelValue),
  computed(() => props.rules),
  props.name || props.label
);

const externalError = computed(() => {
  if (props.fieldErrors && props.name && props.fieldErrors[props.name]) {
    return props.fieldErrors[props.name];
  }

  if (!props.errorMessage) {
    return;
  }

  if (typeof props.errorMessage === 'string') {
    return props.errorMessage;
  }

  const { message, ...fields } = props.errorMessage.errorMessage!.pop() || {};

  return message ? t(message, { ...fields, ...(props.label ? { property: props.label } : {}) }) : undefined;
});

const isClearable = computed(() => !!props.modelValue);

const inputType = computed(() => {
  switch (props.type) {
    case 'number':
      return 'number';
    case 'email':
      return 'text';
    default:
      return props.type;
  }
});

const vModel = computed({
  get: (): any => props.modelValue,
  set: (value) => emit('update:model-value', value),
});

const onUpdate = (event: Event): void => {
  const value = (event.target as HTMLInputElement).value;

  if (!value) {
    vModel.value = props.nullable ? null : undefined;
  }

  switch (props.type) {
    case 'text':
      vModel.value = props.capitalized ? value.charAt(0).toUpperCase() + value.slice(1) : value;
      break;
    case 'number':
      vModel.value = !value || isNaN(+value) ? undefined : +value;
      break;
    default:
      vModel.value = value;
      break;
  }
};

const onKeydown = (event: KeyboardEvent): void => {
  if (event.key === 'Enter') {
    event.stopPropagation();
    event.preventDefault();

    emit('enter');
  }
};

const onFocus = (): void => {
  if (blurTimeout.value) {
    clearTimeout(blurTimeout.value);
  }

  isFocus.value = true;
  emit('focus');
};

const onBlur = (): void => {
  blurTimeout.value = setTimeout(() => {
    isFocus.value = false;
    emit('blur');
  }, 500);
};

watch(
  () => vModel.value,
  (value) => {
    if (!props.debounce) {
      return;
    }

    clearTimeout(debounceTimeout.value);

    debounceTimeout.value = setTimeout(() => {
      emit('on-timeout', value);
    }, +props.debounce);
  }
);
</script>

<style lang="scss">
.vz-input {
  position: relative;
  display: flex;
  flex-direction: column;

  &--loading {
    .vz-input__container {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0.125rem;
        left: 0;
        width: 100%;
        height: 0.125rem;
        background-image: linear-gradient(100deg, var(--color-primary-300) 2%, var(--color-primary-900) 44%, var(--color-primary-300) 98%);
        background-repeat: no-repeat;
        background-size: 35% 100%;
        background-position: 0 0;
        animation: skeletonOverlay 2s linear infinite;
      }

      @keyframes skeletonOverlay {
        0% {
          background-position: -100% 0;
        }
        100% {
          background-position: 200% 0;
        }
      }
    }
  }

  &--disabled {
    .vz-input__container {
      color: var(--color-disabled);
      background-color: var(--color-background-disabled);
    }
  }

  &__container {
    display: flex;
    min-height: 36px !important;
    padding: 8px 8px 8px 8px !important;
    border-radius: var(--border-radius-regular);
    align-items: center;
    background-color: var(--color-background-regular);

    > *:not(input) {
      max-width: fit-content;
    }

    input {
      outline: none !important;
      flex-grow: 1;
      min-width: 1ch;
    }

    &:has(input:focus) {
      outline: var(--outline-focus);
    }

    &:has(input:not(:focus)) {
      outline: var(--outline-regular);
    }
  }
}
</style>
