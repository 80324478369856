import type { ActionTree } from 'vuex';
import type { JobState } from '@/views/job/store/job.types';
import type { BaseOption, BaseOptions } from '@/shared/models';
import type { ListSkillsReq } from '@/views/job/types';
import type { RootState } from '@/store';
import { container } from 'tsyringe';
import JobService from '@/views/job/service/job.service';
import StoreCache from '@/store/services/store.cache';
import { StoreNamespace } from '@/store/store-namespace';
import jobOfferActions from '@/views/job/store/actions/job-offer.actions';
import conversationActions from '@/views/job/store/actions/conversation.actions';
import {
  CLASSIFICATIONS,
  LIST_CLASSIFICATIONS,
  LIST_SKILLS,
  SET_JOB_CLASSIFICATION,
  SET_SKILLS_CACHE,
  SKILLS,
} from '@/views/job/store/constants/job.constants';

const jobService = container.resolve(JobService);

const jobActions: ActionTree<JobState, RootState> = {
  [LIST_CLASSIFICATIONS]: async ({ commit, state }): Promise<BaseOptions<string> | null> => {
    if (!state[CLASSIFICATIONS]) {
      const { options = [] } = await jobService[LIST_CLASSIFICATIONS]();
      commit(SET_JOB_CLASSIFICATION, options);
    }

    return state[CLASSIFICATIONS];
  },

  [LIST_SKILLS]: async (_, payload: ListSkillsReq): Promise<Array<BaseOption>> => {
    if (!(payload?.groups || []).filter((value) => !!value).length) {
      return [];
    }

    const cacheService = new StoreCache(SKILLS, SET_SKILLS_CACHE, StoreNamespace.JOBS_MODULE);
    const cache = cacheService.get<ListSkillsReq, Array<BaseOption>>(payload);

    if (cache) {
      return cache;
    }

    const { options = [] } = await jobService[LIST_SKILLS](payload);
    cacheService.set<ListSkillsReq, Array<BaseOption>>(payload, options);

    return options;
  },

  ...jobOfferActions,
  ...conversationActions,
};

export default jobActions;
