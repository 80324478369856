import { type DirectiveBinding } from 'vue';

type Callback = (el?: HTMLElement, observer?: IntersectionObserver, entry?: IntersectionObserverEntry) => void;

export const vueObserver = {
  mounted(el: HTMLElement, binding: DirectiveBinding<Callback | [Callback, number?]>) {
    const [cb, timeout = 0] = Array.isArray(binding.value) ? binding.value : [binding.value, 0];

    let observerTimeoutId: ReturnType<typeof setTimeout> | undefined = el.__observer__?.timeoutId;

    const handleCallback = (observer: IntersectionObserver, entry: IntersectionObserverEntry): void => {
      clearTimeout(observerTimeoutId);

      observerTimeoutId = setTimeout(() => cb?.(el, observer, entry), timeout);
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          handleCallback(observer, entry);
        });
      },
      { root: document.documentElement }
    );

    observer.observe(el);
    el.__observer__ = { observer, timeoutId: observerTimeoutId };
  },
  unmounted(el: HTMLElement) {
    el.__observer__?.observer.disconnect();
    clearTimeout(el.__observer__?.timeoutId);
  },
};
