export type FileDropEvent = { files: Array<File>; blocked: Array<File> };

class FileDrop extends HTMLElement {
  private acceptTypes: Array<string> = [];
  private folderDrillDownDeepLevel: number = 0;
  private maxSize: number = 0;
  private maxFiles: number = 0;
  private disabled: boolean = false;

  constructor() {
    super();
  }

  static get observedAttributes(): string[] {
    return ['accept', 'max-size', 'max-files', 'folder', 'disabled'];
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string): void {
    if (newValue === oldValue) {
      return;
    }

    switch (name) {
      case 'folder':
        this.folderDrillDownDeepLevel = newValue === 'true' ? 255 : parseInt(newValue || '0', 10);
        break;
      case 'accept':
        this.acceptTypes = (newValue || '').split(',').map((type) => type.trim());
        break;
      case 'max-size':
        this.maxSize = parseInt(newValue || '0', 10);
        break;
      case 'max-files':
        this.maxFiles = parseInt(newValue || '0', 10);
        break;
      case 'disabled':
        this.disabled = newValue === 'true';
        this.initEventListeners();
        break;
    }
  }

  connectedCallback(): void {
    this.style.display = 'block';
    const folder = this.getAttribute('folder');
    this.folderDrillDownDeepLevel = folder === 'true' ? 255 : parseInt(folder || '0');
    this.maxFiles = parseInt(this.getAttribute('max-files') || '0');
    this.maxSize = parseInt(this.getAttribute('max-size') || '0');
    this.acceptTypes = (this.getAttribute('accept') || '').split(',').map((type) => type.trim());
    this.disabled = this.getAttribute('disabled') === 'true';

    this.initEventListeners();
  }

  disconnectedCallback(): void {
    this.removeEventListeners();
  }

  private initEventListeners(): void {
    if (!this.disabled) {
      this.addEventListeners();
    } else {
      this.removeEventListeners();
    }
  }

  private addEventListeners(): void {
    this.addEventListener('dragover', this.handleDragOver.bind(this));
    this.addEventListener('dragleave', this.handleDragLeave.bind(this));
    this.addEventListener('drop', this.handleDrop.bind(this));
  }

  private removeEventListeners(): void {
    this.removeEventListener('dragover', this.handleDragOver.bind(this));
    this.removeEventListener('dragleave', this.handleDragLeave.bind(this));
    this.removeEventListener('drop', this.handleDrop.bind(this));
  }

  private handleDragOver(event: DragEvent): void {
    event.preventDefault();
    this.setAttribute('drag-over', '');
  }

  private handleDragLeave(): void {
    this.removeAttribute('drag-over');
  }

  private async handleDrop(event: DragEvent): Promise<void> {
    event.preventDefault();
    this.removeAttribute('drag-over');

    if (!event.dataTransfer?.items?.length) {
      return;
    }

    const files = await this.handleFiles(event.dataTransfer.items);
    const acceptTypes = this.acceptTypes?.map((value) => value.trim()).filter((value) => !!value);

    const allowedFiles = files.filter((file) => {
      const isSizeAllowed = !this.maxSize || file.size <= this.maxSize;
      const isTypeAllowed = !acceptTypes?.length || acceptTypes.some((type) => file.type.includes(type));

      return isSizeAllowed && isTypeAllowed;
    });

    const notAllowedFiles = files.filter((file) => {
      const allowedDetails = allowedFiles.map(({ type, size, name }) => ({ type, size, name }));

      return !allowedDetails.some(({ type, size, name }) => type === file.type && size === file.size && name === file.name);
    });

    const dataTransfer = new DataTransfer();
    allowedFiles.forEach((file) => dataTransfer.items.add(file));

    if (files) {
      this.dispatchEvent(new InputEvent('input', { bubbles: true, composed: true, dataTransfer }));

      this.dispatchEvent(
        new CustomEvent<FileDropEvent>('update', {
          detail: {
            blocked: notAllowedFiles,
            files: files,
          },
        })
      );
    }
  }

  private async handleFiles(items: DataTransferItemList, maxDeepLevel: number = this.folderDrillDownDeepLevel): Promise<Array<File>> {
    const files: Array<File> = [];

    const processItem = async (item: DataTransferItem | FileSystemEntry, fullPath?: string, level: number = 0): Promise<void> => {
      const fileEntryToFile = async (entry: FileSystemFileEntry): Promise<File> => new Promise((resolve, reject) => entry.file(resolve, reject));
      const entry = item instanceof DataTransferItem ? item.webkitGetAsEntry() : item;

      if (entry?.isFile) {
        const file = await fileEntryToFile(entry as FileSystemFileEntry);

        files.push(fullPath ? new File([file], `${fullPath.slice(1)}/${file.name}`, { type: file.type, lastModified: file.lastModified }) : file);
      } else if (entry?.isDirectory && level < maxDeepLevel) {
        await processDirectory(entry as FileSystemDirectoryEntry, level + 1);
      }
    };

    const processDirectory = async (directory: FileSystemDirectoryEntry, level: number): Promise<void> => {
      const reader = directory.createReader();
      const readEntries = async (): Promise<Array<FileSystemEntry>> => new Promise((resolve, reject) => reader.readEntries(resolve, reject));

      let entries: Array<FileSystemEntry>;

      do {
        entries = await readEntries();

        for (const entry of entries) {
          await processItem(entry, directory.fullPath, level);
        }
      } while (entries.length > 0);
    };

    await Promise.all([...Array.from(items)].map(async (item) => await processItem(item)));

    return files;
  }
}

customElements.define('file-drop', FileDrop);
