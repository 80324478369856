import { RouteRecordRaw } from 'vue-router';
import Modules from '@/router/constants/modules';
import UserManagement from '@/views/backoffice/user-management/user-management.view.vue';

const backofficeUsersRoutes: Readonly<RouteRecordRaw> = {
  path: Modules.PATHS[Modules.BACKOFFICE_USERS],
  name: Modules.BACKOFFICE_USERS,
  component: UserManagement,
  meta: {
    isUnprotected: true,
    parentName: Modules.BACKOFFICE_ADMIN,
  },
};

export default backofficeUsersRoutes;
