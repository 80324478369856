import type { ActionTree } from 'vuex';
import type { JobState } from '@/views/job/store/job.types';
import type {
  UpdateConversationStatusReq,
  SendConversationMessageReq,
  GetConversationReq,
  CreateConversationReq,
  UpdateConversationAgreementReq,
  GetConversationMessagesReq,
  InvitesConversationReq,
  AddTransactionToConversationReq,
} from '@/views/job/types';
import type { BaseRecords } from '@shared/models';
import type { BaseMessage } from '@/views/messenger/types';
import store, { type RootState } from '@/store';
import SocketClientService from '@shared/services/socket-service/socket-client.service';
import { container } from 'tsyringe';
import JobService from '@/views/job/service/job.service';
import { objectToFormData } from '@shared/helpers';
import {
  ADD_TRANSACTION_TO_CONVERSATION,
  CONVERSATION,
  CREATE_CONVERSATION,
  GET_CONVERSATION,
  GET_CONVERSATION_MESSAGES,
  INVITES_CONVERSATION,
  LEAVE_CONVERSATION,
  SEND_CONVERSATION_MESSAGE,
  SET_CONVERSATION,
  UPDATE_CONVERSATION_AGREEMENTS,
  UPDATE_CONVERSATION_STATUS,
} from '@/views/job/store/constants/conversation.constants';
import { GET_JOB_OFFER_DETAILS, JOB_OFFER_DETAILS } from '@/views/job/store/constants/job-offer.constants';
import { StoreNamespace } from '@/store/store-namespace';
import { GET_POST_JOB_DETAILS } from '@/views/job/views/manager/modules/post/store/job-manager-post.constants';

const jobService = container.resolve(JobService);

const actions: ActionTree<JobState, RootState> = {
  [INVITES_CONVERSATION]: async (_, payload: InvitesConversationReq): Promise<void> => {
    await jobService[INVITES_CONVERSATION](payload);
    await store.dispatch(`${StoreNamespace.JOB_MANAGER_POST_MODULE}/${GET_POST_JOB_DETAILS}`);
  },

  [CREATE_CONVERSATION]: async ({ dispatch }, payload: CreateConversationReq): Promise<void> => {
    const { conversationId } = await jobService[CREATE_CONVERSATION](payload);

    if (!conversationId) {
      return;
    }

    await dispatch(GET_JOB_OFFER_DETAILS);
  },

  [GET_CONVERSATION]: async ({ commit, dispatch, state }, payload?: GetConversationReq | null): Promise<void> => {
    const currentId = state[CONVERSATION]?._id;
    const { conversationId = currentId } = payload || {};

    if (payload === null) {
      commit(SET_CONVERSATION);
      return;
    } else if (conversationId !== currentId) {
      await dispatch(LEAVE_CONVERSATION);
    }

    if (!conversationId) {
      return;
    }

    const res = await jobService[GET_CONVERSATION](conversationId);

    SocketClientService.on(UPDATE_CONVERSATION_STATUS, () => dispatch(GET_CONVERSATION), true);
    SocketClientService.on(UPDATE_CONVERSATION_AGREEMENTS, () => dispatch(GET_CONVERSATION), true);
    SocketClientService.join({ roomId: res._id, roomKey: 'job-manager-conversation' });

    commit(SET_CONVERSATION, res);
  },

  [LEAVE_CONVERSATION]: async ({ state, commit }, conversationId?: string): Promise<void> => {
    const id = conversationId || state[CONVERSATION]?._id;

    if (id) {
      SocketClientService.leave({ roomId: id, roomKey: 'job-manager-conversation' });
      SocketClientService.off(UPDATE_CONVERSATION_STATUS);
      SocketClientService.off(UPDATE_CONVERSATION_AGREEMENTS);
    }

    if (!id) {
      return;
    }

    await jobService[LEAVE_CONVERSATION](id);
  },

  [UPDATE_CONVERSATION_STATUS]: async ({ commit, state, dispatch }, key: UpdateConversationStatusReq['key']): Promise<void> => {
    const conversationId = state[CONVERSATION]?._id || state[JOB_OFFER_DETAILS]?.conversationId;
    const agreement = state[CONVERSATION]?.agreement || state[JOB_OFFER_DETAILS]?.agreement;

    if (!conversationId || !agreement) {
      return;
    }

    try {
      await jobService[UPDATE_CONVERSATION_STATUS]({ conversationId, key, agreement });
    } finally {
      if (state[CONVERSATION]) {
        await dispatch(GET_CONVERSATION);
      }

      if (state[JOB_OFFER_DETAILS]) {
        await dispatch(GET_JOB_OFFER_DETAILS);
      }
    }
  },

  [SEND_CONVERSATION_MESSAGE]: async (_, payload: SendConversationMessageReq): Promise<BaseMessage> => {
    return await jobService[SEND_CONVERSATION_MESSAGE](objectToFormData(payload));
  },

  [UPDATE_CONVERSATION_AGREEMENTS]: async ({ state, dispatch }, payload: UpdateConversationAgreementReq): Promise<void> => {
    const id = state[CONVERSATION]?._id || state[JOB_OFFER_DETAILS]?.conversationId;

    if (!id) {
      return;
    }

    await jobService[UPDATE_CONVERSATION_AGREEMENTS](payload, id);
    await dispatch(GET_CONVERSATION);
  },

  [GET_CONVERSATION_MESSAGES]: async (_, payload: GetConversationMessagesReq): Promise<BaseRecords<BaseMessage>> => {
    const { conversationId, page } = payload;

    return await jobService[GET_CONVERSATION_MESSAGES]({ conversationId, page });
  },

  [ADD_TRANSACTION_TO_CONVERSATION]: async ({ dispatch }, payload: AddTransactionToConversationReq): Promise<void> => {
    await jobService[ADD_TRANSACTION_TO_CONVERSATION](payload);
    await dispatch(GET_CONVERSATION);
  },
};

export default actions;
