import type { RouteRecordRaw } from 'vue-router';
import Modules from '@/router/constants/modules';

const GroupManager = () => import(/* webpackChunkName: "job-manager" */ '@/views/job/views/manager/modules/group/groups.view.vue');

const groupManagerRoutes: Readonly<RouteRecordRaw> = {
  path: Modules.PATHS[Modules.MANAGER.JOB_MANAGER_GROUP],
  name: Modules.MANAGER.JOB_MANAGER_GROUP,
  component: GroupManager,
  meta: {
    parentName: Modules.MANAGER.JOB_MANAGER,
  },
};

export default groupManagerRoutes;
