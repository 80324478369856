import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label"]

import { computed, type PropType } from 'vue';
import { type ColorName } from '@/shared/services/css-service/types/colors';
import { useTranslator } from '@/plugins/i18n/helpers';
import getThemeColor from '@shared/services/css-service/helpers/get-theme-color';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-close-button',
  props: {
  size: { type: [String, Number] as PropType<string | number>, default: 16 },
  color: { type: String as PropType<ColorName | undefined>, default: undefined },
},
  emits: ['close'],
  setup(__props) {

const props = __props;



const t = useTranslator();

const lineStyle = computed(() => {
  return {
    width: (+props.size * 16) / 15 + 'px',
    height: (+props.size * 16) / 15 / 7 + 'px',
  };
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: "vz-close-button",
    role: "button",
    "aria-label": _unref(t)('COMPONENT_LABELS.BUTTON', { value: 'GENERAL.CLOSE' }),
    style: _normalizeStyle({ width: `${+__props.size + 5}px`, height: `${+__props.size + 5}px`, ...(__props.color ? { color: _unref(getThemeColor)(__props.color) } : {}) }),
    onClose: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('close')), ["stop"]))
  }, [
    _createElementVNode("div", {
      class: "vz-close-button__line-right",
      style: _normalizeStyle(lineStyle.value)
    }, null, 4),
    _createElementVNode("div", {
      class: "vz-close-button__line-left",
      style: _normalizeStyle(lineStyle.value)
    }, null, 4)
  ], 44, _hoisted_1))
}
}

})