type Option = {
  startCallback?: () => unknown | Promise<unknown>;
  stopCallback?: () => unknown | Promise<unknown>;
  maxDuration?: number;
};

export class AudioRecorder {
  public recordTime: number = 0;
  private mediaRecorder: MediaRecorder | null = null;
  private audioChunks: Blob[] = [];
  private mimeType: string = 'audio/webm';
  private recording = false;
  private timeoutId: ReturnType<typeof setTimeout>;
  private startAt: number = 0;
  private readonly maxDuration: number;
  private readonly startCallback: Option['startCallback'];
  private readonly stopCallback: Option['stopCallback'];

  constructor(option?: Option) {
    const { maxDuration = 60, stopCallback, startCallback } = option || {};

    this.maxDuration = maxDuration;
    this.startCallback = startCallback;
    this.stopCallback = stopCallback;
  }

  async start(): Promise<void> {
    if (this.recording) {
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);
      this.audioChunks = [];

      this.mediaRecorder.ondataavailable = (blob) => {
        if (blob.data.size > 0) {
          this.audioChunks.push(blob.data);
        }
      };

      this.mediaRecorder.onstart = () => {
        this.startAt = Date.now();
        this.recording = true;
        this.startCallback?.();
        this.mimeType = this.mediaRecorder?.mimeType?.split(';')[0] || 'audio/webm';
        this.timeoutId = setTimeout(() => this.stop(), this.maxDuration * 1000);
      };

      this.mediaRecorder.start();
    } catch (error) {
      console.error('AudioRecorder:start', error);
    }
  }

  async stop(callback?: () => unknown | Promise<unknown>) {
    try {
      await new Promise<void>((resolve, reject) => {
        if (this.recording && this.mediaRecorder) {
          this.mediaRecorder.onstop = () => {
            this.recording = false;
            this.recordTime = Date.now() - this.startAt;

            if (this.mediaRecorder?.state !== 'inactive') {
              reject();
            }

            clearTimeout(this.timeoutId);
            resolve();
          };

          this.mediaRecorder.stop();
          this.mediaRecorder.stream.getTracks().forEach((track) => track.stop());
        }
      });

      this.mediaRecorder = null;

      await this.stopCallback?.();
      await callback?.();
    } catch (error) {
      console.error('AudioRecorder:stop', error);
    }
  }

  async cancel(): Promise<void> {
    await this.stop();
  }

  getFile(): File | null {
    const blob = this.getBlob();
    const extension = this.mimeType.split('/')[1];

    return blob ? new File([blob], `audio-${Date.now()}.${extension}`, { type: this.mimeType }) : null;
  }

  getBlob(): Blob | null {
    return this.audioChunks.length ? new Blob(this.audioChunks, { type: this.mimeType }) : null;
  }

  async getBase64(): Promise<string | null> {
    const audioBlob = this.getBlob();

    if (!audioBlob) {
      return null;
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(audioBlob);
    });
  }
}
