import type { GetterTree } from 'vuex';
import type { RootState } from '@/store';
import type { JobState } from '@/views/job/store/job.types';
import type { StateCache } from '@/store/services/store.cache';
import type { BaseOptions } from '@shared/models';
import { SKILLS } from '@/views/job/store/constants/job.constants';

const jobGetters: GetterTree<JobState, RootState> = {
  [SKILLS](state): StateCache<BaseOptions<string>> {
    return state[SKILLS] || ({} as StateCache<BaseOptions<string>>);
  },
};

export default jobGetters;
