import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "px-4 pt-1 d-flex gap-4" }
const _hoisted_2 = { class: "text-title-1 ltr" }

import { VzOverlayEnum } from '@shared/components/overlay/vz-overlay.enum';
import { SystemLogColorEnum } from '@/views/backoffice/system-log/constants/system-log.enum';
import EmployeeStrip from '@/views/employee/components/employee-strip.vue';
import VzJsonViewer from '@shared/components/vz-json-viewer.vue';
import { useBackofficeLogStore } from '@/views/backoffice/system-log/store';
import { storeToRefs } from 'pinia';


export default /*@__PURE__*/_defineComponent({
  __name: 'system-log-view-modal',
  setup(__props) {

const useBackofficeJobStore = useBackofficeLogStore();

const { log } = storeToRefs(useBackofficeJobStore);

return (_ctx: any,_cache: any) => {
  const _component_vz_badge = _resolveComponent("vz-badge")!
  const _component_vz_modal = _resolveComponent("vz-modal")!

  return (_openBlock(), _createBlock(_component_vz_modal, {
    title: "BACKOFFICE.SYSTEM_LOG.TITLE",
    "hide-submit": "",
    "cancel-text": "GENERAL.CLOSE",
    "is-open": !!_unref(log),
    size: _unref(VzOverlayEnum).XLARGE,
    onClose: _cache[0] || (_cache[0] = () => (log.value = null))
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(log)?.title), 1),
        _createVNode(_component_vz_badge, {
          text: _unref(log)?.type,
          color: _unref(SystemLogColorEnum)[_unref(log)?.type as keyof typeof SystemLogColorEnum]
        }, null, 8, ["text", "color"])
      ]),
      (_unref(log)?.user)
        ? (_openBlock(), _createBlock(EmployeeStrip, {
            key: 0,
            horizontal: "",
            "show-company": "",
            "font-size": "1rem",
            item: _unref(log).user,
            "avatar-size": "42"
          }, null, 8, ["item"]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createVNode(VzJsonViewer, {
        "auto-tab": "",
        value: _unref(log)?.data
      }, null, 8, ["value"])
    ]),
    _: 1
  }, 8, ["is-open", "size"]))
}
}

})