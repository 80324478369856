export const LENGTH = {
  APP_NAME: 18,
  NAME: 64,
  EMAIL: 256,
  URL: 256,
  TITLE: 128,
  COMMENT: 256,
  DESCRIPTION: 512,
  LONG_DESCRIPTION: 2048,
  RICH_TEXT: 32768,
  META: 150,
  TRANSLATE: 200,
};
