import ApiService from '@shared/services/api-service/api.service';
import { container } from 'tsyringe';
import { removeReturnCodeInterceptor } from '@shared/services/api-service/interceptors/remove-return-code-interceptor';
import { addUserDetailsInterceptor } from '@shared/services/api-service/interceptors/add-user-details-interceptor';
import { addDeveloperInterceptor } from '@shared/services/api-service/interceptors/add-developer-interceptor';
import { errorsInterceptor } from '@shared/services/api-service/interceptors/errors-interceptor';

const apiService = container.resolve<ApiService>('ApiService');

// Request interceptors
apiService.addRequestInterceptor([addDeveloperInterceptor, addUserDetailsInterceptor]);

// Response interceptors
apiService.addResponseInterceptor([removeReturnCodeInterceptor, errorsInterceptor]);
