import type { RouteRecordRaw } from 'vue-router';
import Modules from '@/router/constants/modules';

const TemplateManager = () =>
  import(/* webpackChunkName: "template-manager" */ '@/views/job/views/manager/modules/template/template-manager.view.vue');

const templateManagerRoutes: Readonly<RouteRecordRaw> = {
  path: `${Modules.PATHS[Modules.MANAGER.JOB_MANAGER_TEMPLATE]}/:templateId?`,
  name: Modules.MANAGER.JOB_MANAGER_TEMPLATE,
  component: TemplateManager,
  meta: {
    parentName: Modules.MANAGER.JOB_MANAGER,
  },
};

export default templateManagerRoutes;
