/* eslint-disable */
import RegexPattern from '@shared/constants/regex-pattern';
import { addScriptOnce } from '@shared/helpers/add-script-once';

const LINK_IGNORE_LIST = ['youtube', 'youtu', 'vimeo', 'instagram'];

const webLinkify = (inputText: string): string => {
  const urlPattern = /(?<url>https?:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;

  let replacedText = inputText.replace(urlPattern, (match, ...args) => {
    const { url } = args.pop();

    if (LINK_IGNORE_LIST.some((value) => url.includes(value))) {
      return url;
    }

    return `<a href="${url}" target="_blank">${url}</a>`;
  });

  const wwwPattern = /(?<prefix>(^|[^/]))(?<www>www\.[\S]+(\b|$))/gim;

  replacedText = replacedText.replace(wwwPattern, (match, ...args) => {
    const { prefix, www } = args.pop();

    if (LINK_IGNORE_LIST.some((value) => www.includes(value))) {
      return `${prefix}${www}`;
    }

    return `${prefix}<a href="http://${www}" target="_blank">${www}</a>`;
  });

  return replacedText;
};

const telLinkify = (input: string): string => {
  return Object.values(RegexPattern.PHONE).reduce((text) => {
    return text.replace(RegexPattern.PHONE.IL, (match) => `<a href="tel:${match}">${match}</a>`);
  }, input);
};

const emailLinkify = (inputText: string): string => {
  const emailPattern = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;

  return inputText.replace(emailPattern, '<a href="mailto:$1">$1</a>');
};

const embedYouTubeLinks = (input: string): string => {
  const youtubePattern = /https?:\/\/(?:www\.)?(?:youtube\.com\/watch\?v=([^&\s]+)(?:&[\S]+)?|youtu\.be\/([^?\s]+)(?:\?[\S]+)?)/g;

  return input.replace(youtubePattern, (_, videoId1, videoId2) => {
    return `<iframe src="https://www.youtube.com/embed/${videoId1 || videoId2}" frameborder="0" allowfullscreen></iframe>`;
  });
};

const embedVimeoLinks = (input: string): string => {
  const vimeoPattern = /https?:\/\/(?:www\.)?vimeo\.com\/(\d+)(?:[\S]+)?/g;

  return input.replace(vimeoPattern, (_, videoId) => {
    return `<iframe src="https://player.vimeo.com/video/${videoId}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
  });
};

const embedInstagramLinks = (input: string): string => {
  const instagramPattern = /https?:\/\/(?:www\.)?instagram\.com\/p\/([a-zA-Z0-9_-]+)(?:\/\?[\S]+)?/g;

  let replacedText = input;

  replacedText = replacedText.replace(instagramPattern, (_, postId) => {
    addScriptOnce('https://www.instagram.com/embed.js', { async: 'true', defer: 'true' });

    return `<blockquote class='instagram-media' data-instgrm-version='14' style="max-width:540px; width: calc(100% - 2px); border: none; overflow: hidden;"><a href='https://www.instagram.com/p/${postId}/' /></blockquote>`;
  });

  const reelsPattern = /https?:\/\/(?:www\.)?instagram\.com\/reel\/([a-zA-Z0-9_-]+)(?:\/\?[\S]+)?/g;

  replacedText = replacedText.replace(reelsPattern, (_, postId) => {
    addScriptOnce('https://www.instagram.com/embed.js', { async: 'true', defer: 'true' });

    return `<blockquote class='instagram-media' data-instgrm-version='14' style="max-width:540px; width: calc(100% - 2px); border: none; overflow: hidden;"><a href='https://www.instagram.com/p/${postId}/' /></blockquote>`;
  });

  return replacedText;
};

export const linkify = (input: string): string => {
  let replacedText = input;

  replacedText = embedInstagramLinks(replacedText) || replacedText;
  replacedText = embedYouTubeLinks(replacedText) || replacedText;
  replacedText = embedVimeoLinks(replacedText) || replacedText;

  replacedText = webLinkify(replacedText);
  replacedText = emailLinkify(replacedText);
  replacedText = telLinkify(replacedText);

  return replacedText;
};
