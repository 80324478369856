import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex flex-column align-center justify-center fill-height" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "upcoming-events__day-header text-uppercase text-title-1"
}
const _hoisted_4 = { class: "upcoming-events__day-grid" }

import type { GetUpcomingEventsRes, GetUpcomingEventsReq } from 'src/views/calendar/types';
import type { CalendarEvent } from 'src/views/calendar/types';
import type { VzInfinityScrollRef } from '@shared/components/infinity-scroll/infinity-scroll.type';
import { defineAsyncComponent, ref, watch } from 'vue';
import { GET_UPCOMING_EVENTS } from '@/views/calendar/store/calendar.constants';
import { useAuthUser } from '@/views/employee/composables/use-auth-user';
import useCalendarStore from '@/views/calendar/composables/use-calendar-store';
import { eventsMapper } from '@/views/calendar/helpers/events-mapper';
import { formattedDate } from '../helpers';
import { isToday } from '@/views/calendar/helpers/is-today';
import { useRouter } from 'vue-router';
import { routeTo } from '@shared/composables';
import { useAssignedAuth } from '@/store/auth/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'upcoming-events.view',
  setup(__props) {

const EventCard = defineAsyncComponent(() => import(/* webpackChunkName: "calendar" */ '@/views/calendar/components/event-card.vue'));

const { useActions: useEmployeeActions } = useCalendarStore();
const { [GET_UPCOMING_EVENTS]: getUpcomingEventsAction } = useEmployeeActions([GET_UPCOMING_EVENTS]);

const getUpcomingEventsRequest = getUpcomingEventsAction as (payload?: GetUpcomingEventsReq) => Promise<GetUpcomingEventsRes>;

const router = useRouter();
const { activeUser } = useAuthUser();
const { userId } = useAssignedAuth();
const infinityScrollRef = ref<VzInfinityScrollRef>(undefined);

const isUpcomingIndex = ref<number | undefined>(undefined);

const getData = (data: Array<CalendarEvent>): Record<string, Array<CalendarEvent>> => {
  const map = eventsMapper(data);
  isUpcomingIndex.value = Object.keys(map).findIndex((date) => +date > Date.now());

  return map;
};

const onClick = (item: CalendarEvent): void => {
  if (!item.route) {
    return;
  }

  routeTo({ ...item.route }, { addFromFlag: true });
};

watch(
  () => [activeUser.value?._id],
  () => {
    if (!activeUser.value?._id) {
      return;
    }

    infinityScrollRef.value?.reset();
  }
);

return (_ctx: any,_cache: any) => {
  const _component_vz_svg_href = _resolveComponent("vz-svg-href")!
  const _component_vz_icon = _resolveComponent("vz-icon")!
  const _component_vz_infinity_scroll = _resolveComponent("vz-infinity-scroll")!

  return (_openBlock(), _createBlock(_component_vz_infinity_scroll, {
    ref_key: "infinityScrollRef",
    ref: infinityScrollRef,
    class: "upcoming-events",
    callback: _unref(getUpcomingEventsRequest),
    payload: { userId: _unref(userId) }
  }, {
    "no-data": _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_vz_svg_href, {
          class: "fill-width height-p50",
          name: "calendar"
        }),
        _createElementVNode("p", null, _toDisplayString(_ctx.$t('CALENDAR.THERE_IS_NO_UPCOMING_EVENT')), 1)
      ])
    ]),
    default: _withCtx(({ data }) => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getData(data), (events, date, dateIndex) => {
        return (_openBlock(), _createElementBlock("div", {
          key: dateIndex,
          class: "upcoming-events__day"
        }, [
          (events.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (!_unref(isToday)(+date))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createVNode(_component_vz_icon, {
                        name: "svg:calendar",
                        size: "1.25rem"
                      }),
                      _createElementVNode("span", null, _toDisplayString(_unref(formattedDate)({ dateFrom: +date })), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(events, (event, eventIndex) => {
              return (_openBlock(), _createBlock(_unref(EventCard), {
                key: eventIndex,
                class: "clickable pa-4",
                item: event,
                onClick: ($event: any) => (onClick(event))
              }, null, 8, ["item", "onClick"]))
            }), 128))
          ])
        ]))
      }), 128))
    ]),
    _: 1
  }, 8, ["callback", "payload"]))
}
}

})