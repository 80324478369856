<template>
  <div class="vz-search-panel">
    <form ref="formRef" role="form" autocomplete="off" @submit.prevent>
      <div ref="containerRef" class="row-flex-3 overflow-hidden" :style="containerStyle">
        <slot />
      </div>
    </form>

    <div v-if="!hideControls" class="vz-search-panel__controls">
      <vz-button tabindex="2" text="GENERAL.CLEAR" @click="$emit('clear')" />

      <vz-button tabindex="1" text="GENERAL.SEARCH" @click="onSearch" />

      <vz-button
        v-if="isExtendable"
        class="vz-search-panel__fields--extend"
        type="flat"
        icon-size="1rem"
        :text="`GENERAL.${isShowMore ? 'SHOW_LESS' : 'SHOW_MORE'}`"
        :icon-name="isShowMore ? 'svg:arrow-up' : 'svg:arrow-down'"
        @click="isShowMore = !isShowMore"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useResizeObserver } from '@/shared/composables';
import { useFormValidator } from '@/shared/components/fields/helpers';

defineProps({ hideControls: { type: Boolean, default: false } });

const MAX_HEIGHT = 88;

const formRef = ref<Element | undefined>(undefined);
const emit = defineEmits(['search', 'clear']);

const containerRef = ref<Element | undefined>(undefined);
const { maxHeight: containerHeight } = useResizeObserver(containerRef);

const isShowMore = ref<boolean>(false);

const isExtendable = computed((): boolean => containerHeight.value > MAX_HEIGHT * 1.5);
const containerStyle = computed(() => {
  if (!isExtendable.value) {
    return;
  }

  return isExtendable.value && isShowMore.value ? { maxHeight: `${containerHeight.value}px` } : { maxHeight: `${MAX_HEIGHT}px` };
});

const onSearch = async (): Promise<void> => {
  const isValid = useFormValidator(formRef);

  if (!isValid()) {
    return;
  }

  emit('search');
};
</script>

<style lang="scss">
.vz-search-panel {
  position: relative;
  margin: 0 !important;
  padding: 1rem;

  &__fields {
    > :first-child {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
      margin-bottom: 0.25rem;
      overflow: hidden;
      transition: max-height 0.3s;

      > * {
        padding: 0.25rem 0.5rem;
        height: fit-content;
      }
    }

    &--extend {
      position: absolute;
      display: flex;
      bottom: -0.25rem;
      @include rtl(left, initial, 0);
      @include rtl(right, 0, initial);
    }
  }

  &__controls {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-inline-end: 0.25rem;
    z-index: 1;

    > * {
      min-width: 6rem;
      margin-inline-start: 0.5rem;
    }
  }
}
</style>
