import type { Module } from 'vuex';
import type { RootState } from '@/store';
import type { BasePagination } from '@/shared/models';
import type { JobManagerProjectState } from '@/views/job/views/manager/modules/project/store/job-manager-project.types';
import { SearchProjectReq } from '@/views/job/types/project.types';
import jobManagerProjectActions from '@/views/job/views/manager/modules/project/store/job-manager-project.actions';
import jobManagerProjectMutations from '@/views/job/views/manager/modules/project/store/job-manager-project.mutations';
import {
  PROJECT_DETAILS,
  PROJECT_FILTER,
  PROJECT_PAGINATION,
  PROJECTS,
} from '@/views/job/views/manager/modules/project/store/job-manager-project.constants';
import { DEFAULT_TABLE_PAGE_SIZE } from '@shared/components/tables/constants/data-table.constants';

export const initialState = (): JobManagerProjectState => ({
  [PROJECTS]: null,
  [PROJECT_FILTER]: {} as Omit<SearchProjectReq, 'page'>,
  [PROJECT_PAGINATION]: { size: DEFAULT_TABLE_PAGE_SIZE } as BasePagination,
  [PROJECT_DETAILS]: null,
});

const jobManagerProjectStore: Module<JobManagerProjectState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations: jobManagerProjectMutations,
  actions: jobManagerProjectActions,
};

export default jobManagerProjectStore;
