import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.info('serviceWorker:ready');
    },
    registered() {
      console.info('serviceWorker:registered');
    },
    cached() {
      console.info('serviceWorker:cached');
    },
    updatefound() {
      console.info('serviceWorker:updatefound');
    },
    updated() {
      console.info('serviceWorker:updated', 'refreshing');

      window.location.reload();
    },
    offline() {
      console.info('serviceWorker:offline');
    },
    error(error) {
      console.error('serviceWorker:error', error);
    },
  });
}
