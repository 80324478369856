import type { BasePagination } from '@/shared/models';
import employeeActions from './employee.actions';
import employeeMutations from './employee.mutations';
import employeeGetters from './employee.getters';
import {
  EMPLOYEE_DETAILS,
  EMPLOYEE_DETAILS_CACHE,
  EMPLOYEE_REVIEWS,
  EMPLOYEES,
  EMPLOYEES_PAGINATION,
  EMPLOYEES_TOTAL,
} from '@/views/employee/store/employee.constants';
import type { Module } from 'vuex';
import type { RootState } from '@/store';
import type { EmployeeState } from '@/views/employee/store/employee.types';
import type { StateCache } from '@/store/services/store.cache';
import type { GetReviewsRes, ProfileFullDetails } from '@/views/employee/types';

export const initialState = (): EmployeeState => ({
  [EMPLOYEES]: [],
  [EMPLOYEES_PAGINATION]: {} as BasePagination,
  [EMPLOYEES_TOTAL]: null,
  [EMPLOYEE_DETAILS]: null,
  [EMPLOYEE_DETAILS_CACHE]: {} as StateCache<ProfileFullDetails>,
  [EMPLOYEE_REVIEWS]: {} as GetReviewsRes,
});

const employeeStore: Module<EmployeeState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations: employeeMutations,
  actions: employeeActions,
  getters: employeeGetters,
};

export default employeeStore;
