import { defineComponent as _defineComponent } from 'vue'
import { mergeProps as _mergeProps, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, Teleport as _Teleport, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex align-center justify-center flex-grow-1 min-height-320" }
const _hoisted_2 = { class: "vz-overlay-modal__close-button" }

import type { ErrorResponse } from '@/shared/services/api-service/models';
import { computed, onBeforeMount, onUnmounted, type PropType, ref, type StyleValue, watch } from 'vue';
import { emitter } from '@/main';
import { VzOverlayEnum } from '@shared/components/overlay/vz-overlay.enum';
import { useAsync, useServerErrorsMapper } from '@shared/composables';
import { useAsyncMiddleware } from '@shared/composables/use-async-middleware';
import ServerError from '@shared/services/api-service/server-error';
import { openWarningPopup } from '@shared/components/popup/helpers/open-warning-popup';
import { useOverlayState } from '@shared/components/overlay/use-overlay-state';
import { isMobile, uniqueKey } from '@shared/helpers';
import { SizeUnit } from '@shared/types';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-overlay',
  props: {
  title: { type: String, default: '' },
  nonResizable: { type: Boolean, default: false },
  nonDraggable: { type: Boolean, default: false },
  transperant: { type: Boolean, default: false },
  isOpen: { type: Boolean as PropType<boolean | null>, default: null },
  openEvent: { type: String as PropType<string | undefined>, default: undefined },
  closeEvent: { type: String as PropType<string | undefined>, default: undefined },
  openCallback: { type: Function as PropType<(payload?: any) => Promise<unknown> | unknown>, default: undefined },
  closeCallback: { type: Function as PropType<(payload?: any) => Promise<unknown> | unknown>, default: undefined },
  warningCallback: { type: [Boolean, Function] as PropType<boolean | ((payload?: any) => Promise<unknown>)>, default: false },
  loading: { type: Boolean, default: false },
  disableEscape: { type: Boolean, default: false },
  fitContent: { type: Boolean, default: false },
  hideCloseButton: { type: Boolean, default: false },
  size: { type: String as PropType<keyof typeof VzOverlayEnum | undefined>, default: undefined },
  errors: { type: Object as PropType<ErrorResponse | null>, default: () => null },
  style: { type: Object as PropType<StyleValue | undefined>, default: undefined },
  class: { type: [Object, Array, String] as PropType<string | Record<string, any> | Array<string | Record<string, any>>>, default: () => [] },
  height: { type: String as PropType<SizeUnit | undefined>, default: undefined },
  width: { type: String as PropType<SizeUnit | undefined>, default: undefined },
},
  emits: ['update:is-open', 'open', 'close'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const initContentStyle = (() => {
  const style = !isMobile
    ? {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }
    : {};

  const height = props.fitContent ? 'fit-content' : props.height;
  const width = props.width;

  switch (props.size) {
    case VzOverlayEnum.SMALL:
      return {
        width: width || '512px',
        height: height || 'fit-content',
        ...style,
      };
    case VzOverlayEnum.MEDIUM:
      return {
        width: width || '900px',
        height: height || '90vh',
        ...style,
      };
    case VzOverlayEnum.LARGE:
      return {
        width: width || '1024px',
        height: height || '90vh',
        ...style,
      };
    case VzOverlayEnum.XLARGE:
      return {
        width: width || '1280px',
        height: height || '95vh',
        ...style,
      };
    default:
      return {
        width: width || '100vw',
        height: height || '100vh',
      };
  }
})();

const emit = __emit;
const key = uniqueKey();
const state = useOverlayState(key);

const contentClass = computed(() => [
  `vz-overlay-modal`,
  {
    'vz-overlay-modal--hidden': !isShown.value,
    [`vz-overlay-modal--${props.size?.toLowerCase()}`]: !!props.size,
    'vz-overlay-modal--fit-height': props.fitContent,
  },
  ...(Array.isArray(props.class) ? props.class : [props.class]),
]);

const closeErrors = ref<ServerError | null>(null);
const serverErrors = computed(() => props.errors || closeErrors.value);
const errorFields = useServerErrorsMapper(serverErrors);

const isShown = ref<boolean>(false);
const isModalOpen = ref<boolean>(false);

const openCallbackRequest = useAsync(props.openCallback as (payload?: any) => Promise<unknown>);

onBeforeMount(() => {
  if (props.openEvent) {
    emitter.on(props.openEvent, setShownOn);
  }
});

onUnmounted(() => {
  if (props.openEvent) {
    emitter.off(props.openEvent, setShownOn);
  }

  if (props.closeEvent) {
    emitter.off(props.closeEvent, setShownOff);
  }
});

const onEscape = (ev: KeyboardEvent) => {
  if (props.disableEscape || ev.key !== 'Escape' || state.active.value !== key) {
    return;
  }

  const richTextActive = document.querySelector('.rich-text--full-screen');

  if (richTextActive) {
    return;
  }

  ev.preventDefault();
  ev.stopPropagation();
  setShownOff();
};

const open = (payload?: any) => {
  state.set();
  if (props.openEvent) {
    emitter.off(props.openEvent, setShownOn);
  }

  if (props.closeEvent) {
    emitter.on(props.closeEvent, setShownOff);
  }

  if (props.isOpen !== null) {
    emit('update:is-open', true);
  }

  isModalOpen.value = true;
  emit('open', payload);
  setTimeout(() => (isShown.value = true), 500);

  window.addEventListener('keydown', onEscape);
};
const close = (payload?: any) => {
  props.closeCallback?.(payload);

  if (props.openEvent) {
    emitter.on(props.openEvent, setShownOn);
  }

  if (props.closeEvent) {
    emitter.off(props.closeEvent, setShownOff);
  }

  if (props.isOpen !== null) {
    emit('update:is-open', false);
  }

  closeErrors.value = null;
  isShown.value = false;
  emit('close', payload);
  window.removeEventListener('keydown', onEscape);

  setTimeout(() => (isModalOpen.value = false), 500);
  state.unset();
};

const setShownOn = async (payload?: any): Promise<void> => {
  openCallbackRequest.call(payload);

  open(payload);
};

const setShownOff = async (forceClose: boolean = false): Promise<void> => {
  if (!isShown.value) {
    return;
  } else if (props.warningCallback && !forceClose) {
    const isFunction = typeof props.warningCallback === 'function';

    closeErrors.value = await useAsyncMiddleware(
      isFunction ? props.warningCallback?.() : openWarningPopup({ iconName: 'svg:warning', iconColor: 'red-600', yesColor: 'red-900' }),
      close,
      isFunction
    );
  } else {
    close();
  }
};

const activator = (state: boolean = isShown.value): Promise<void> => (state ? setShownOn : setShownOff)();
const activatorBind = { open: () => activator(true), close: () => activator(false) };

const toggle = () => (!isShown.value ? setShownOn : setShownOff)();

watch(
  () => props.isOpen,
  (isOpen) => (isOpen ? setShownOn : setShownOff)(),
  { immediate: true }
);

__expose({ open: setShownOn, close: (isForce?: boolean) => setShownOff(isForce), toggle, errors: serverErrors });

return (_ctx: any,_cache: any) => {
  const _component_vz_spinner = _resolveComponent("vz-spinner")!
  const _component_vz_close_button = _resolveComponent("vz-close-button")!
  const _directive_drag_resize = _resolveDirective("drag-resize")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.$slots['activator'])
      ? _renderSlot(_ctx.$slots, "activator", _mergeProps({
          key: 0,
          toggle: toggle,
          isShown: isShown.value
        }, activatorBind))
      : _createCommentVNode("", true),
    (isModalOpen.value)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 1,
          to: "body"
        }, [
          _createElementVNode("div", _mergeProps({
            class: ['vz-overlay', { 'vz-overlay--hidden': !isShown.value, 'vz-overlay--background': __props.transperant }]
          }, _ctx.$attrs, { style: __props.style }), [
            _withDirectives((_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(contentClass.value),
              style: _normalizeStyle(_unref(initContentStyle))
            }, [
              (__props.loading || _unref(openCallbackRequest).loading.value)
                ? _renderSlot(_ctx.$slots, "loader", { key: 0 }, () => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode(_component_vz_spinner, { size: "96px" })
                    ])
                  ])
                : (isShown.value)
                  ? _renderSlot(_ctx.$slots, "default", _mergeProps({ key: 1 }, activatorBind, {
                      errors: _unref(errorFields),
                      serverErrors: serverErrors.value,
                      close: () => setShownOff()
                    }))
                  : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_2, [
                _renderSlot(_ctx.$slots, "close-button", {}, () => [
                  (!__props.hideCloseButton)
                    ? (_openBlock(), _createBlock(_component_vz_close_button, {
                        key: 0,
                        size: "18",
                        onClick: _cache[0] || (_cache[0] = () => activator(false))
                      }))
                    : _createCommentVNode("", true)
                ])
              ])
            ], 6)), [
              [_directive_drag_resize, { isResizable: !__props.nonResizable, isDraggable: !__props.nonDraggable, title: _ctx.$t(__props.title) }]
            ])
          ], 16)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})