import type { RouteRecordRaw } from 'vue-router';
import Modules from '@/router/constants/modules';

const PermissionManager = () => import(/* webpackChunkName: "job-manager" */ '@/views/job/views/manager/modules/assignee/assignee-manager.view.vue');

const permissionManagerRoutes: Readonly<RouteRecordRaw> = {
  path: Modules.PATHS[Modules.MANAGER.JOB_MANAGER_ASSIGNEE],
  name: Modules.MANAGER.JOB_MANAGER_ASSIGNEE,
  component: PermissionManager,
  meta: {
    parentName: Modules.MANAGER.JOB_MANAGER,
  },
};

export default permissionManagerRoutes;
