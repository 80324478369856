<template>
  <div class="d-flex flex-column gap-2 my-1">
    <label
      :class="['vz-toggle-switch text-ellipsis', { 'vz-toggle-switch--checked': vModel, 'vz-toggle-switch--disabled': disabled }]"
      :style="style"
    >
      <input type="checkbox" :value="vModel" @change.stop="onClick" />

      <span class="vz-toggle-switch__switch">
        <span class="vz-toggle-switch__switch-inner"></span>
      </span>

      <div :class="{ 'c-primary-900': vModel, 'c-mono-600': !vModel, 'c-mono-400': disabled }">
        <slot name="label">
          <span v-if="label">{{ $t(label) }}</span>
        </slot>
      </div>
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType, StyleValue } from 'vue';

const props = defineProps({
  modelValue: { type: Boolean as PropType<boolean | undefined>, required: true },
  label: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  style: { type: Object as PropType<StyleValue | undefined>, default: undefined },
});

const emit = defineEmits(['update:model-value']);

const vModel = computed({
  get: (): boolean => props.modelValue,
  set: (value) => emit('update:model-value', value),
});

const onClick = (): void => {
  if (props.disabled) {
    return;
  }

  emit('update:model-value', !props.modelValue);
};
</script>

<style lang="scss" scoped>
.vz-toggle-switch {
  display: inline-flex;
  align-items: center;
  user-select: none;
  gap: 0.25rem;

  input {
    display: none;
  }

  .vz-toggle-switch__switch {
    width: 36px;
    height: 20px;
    background-color: #01014326;
    border-radius: 50px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;

    &::before {
      content: '';
      width: 16px;
      height: 16px;
      background-color: white;
      border-radius: 50%;
      position: absolute;
      top: 2px;
      left: 2px;
      transition: transform 0.3s;
    }
  }

  &--checked {
    .vz-toggle-switch__switch {
      background-color: var(--color-primary-900);

      &::before {
        transform: translateX(15px);
      }
    }
  }

  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
</style>
