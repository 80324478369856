import type { RouteRecordRaw, RouteRecordNormalized } from 'vue-router';
import router from '@/router';

export const findAllChildrenRoutes = (
  routeName?: string,
  routes: Array<RouteRecordNormalized> = router.getRoutes()
): Array<RouteRecordNormalized> => {
  const findNestedChildren = (routes: Array<RouteRecordNormalized>): Array<RouteRecordNormalized> => {
    return routes.map((route) => {
      const children = route.children as RouteRecordNormalized[]; // Ensure children are treated as normalized routes
      if (children && children.length > 0) {
        return {
          ...route,
          children: findNestedChildren(children),
        };
      }
      return route;
    });
  };

  if (!routeName) {
    return findNestedChildren(routes);
  }

  const route = routes.find((r: RouteRecordNormalized) => r.name === routeName);

  return route ? findNestedChildren((route.children as RouteRecordNormalized[]) || []) : [];
};

export const getChildrenRouteNames = (
  routeName?: string,
  routes: Array<RouteRecordNormalized> = router.getRoutes()
): Array<{ name: string; subitems: Array<any> }> => {
  const buildNestedStructure = (routes: Array<RouteRecordRaw>): Array<{ name: string; subitems: Array<any> }> => {
    return routes.map((route) => {
      const subitems = route.children && route.children.length > 0 ? buildNestedStructure(route.children) : [];
      return {
        name: route.name as string,
        subitems,
      };
    });
  };

  const allRoutes = findAllChildrenRoutes(routeName, routes);
  return buildNestedStructure(allRoutes);
};
