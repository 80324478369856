import type { GetterTree } from 'vuex';
import type { RootState } from '@/store';
import type { EmployeeState } from '@/views/employee/store/employee.types';
import { StateCache } from '@/store/services/store.cache';
import type { ProfileFullDetails } from '@/views/employee/types';
import { EMPLOYEE_DETAILS_CACHE } from '@/views/employee/store/employee.constants';

const employeeGetters: GetterTree<EmployeeState, RootState> = {
  [EMPLOYEE_DETAILS_CACHE](state): StateCache<ProfileFullDetails> {
    return state[EMPLOYEE_DETAILS_CACHE] || ({} as StateCache<ProfileFullDetails>);
  },
};

export default employeeGetters;
