<template>
  <div class="message-share-attachment">
    <div class="flex-grow-1 mx-4 d-flex">
      <vz-attachment v-model:value="files" accept-type="image/jpeg,image/png,application/pdf" />
    </div>

    <div v-if="!readonly" class="d-flex gap-4 justify-center flex-ch-1 mt-4 mx-4">
      <vz-button text="GENERAL.CANCEL" @click="$emit('cancel')" />

      <vz-button text="GENERAL.CONFIRM" @click="onConfirm" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import VzAttachment from '@shared/components/vz-attachment.vue';

defineProps({
  clearable: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
});

const emit = defineEmits(['cancel', 'clear', 'update:attachments']);

const files = ref<Array<File>>([]);

const onConfirm = () => {
  emit('update:attachments', { attachments: files.value });
};
</script>

<style scoped lang="scss">
.message-share-attachment {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 10rem;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
}
</style>
