import type { BaseResponse } from '@shared/models';
import type {
  GetAuthSessionRes,
  MethodOtpValidateReq,
  AuthForgotPasswordReq,
  AuthSecuredRecoverPasswordReq,
  AuthSecuredSignInReq,
  AuthSecuredSignUpReq,
  SignOutReq,
  GoogleAuthorizationRes,
  CompleteSignUpReq,
} from '@/store/auth/auth.type';
import { inject, injectable } from 'tsyringe';
import {
  GET_AUTH_SESSION,
  CHANGE_PASSWORD,
  METHOD_OTP_VALIDATE,
  FORGOT_PASSWORD,
  IMPERSONATE_TO,
  SIGN_IN,
  SIGN_OUT,
  SIGN_UP,
  SAVE_TOKEN,
  GOOGLE_AUTHORIZATION,
  COMPLETE_SIGN_UP,
} from '@/store/auth/auth.constants';
import ApiService from '@shared/services/api-service/api.service';

@injectable()
export default class AuthService {
  public constructor(@inject('ApiService') private apiService: ApiService) {}

  private baseUrl = '/auth';

  public async [GET_AUTH_SESSION](): Promise<GetAuthSessionRes> {
    return this.apiService.get<GetAuthSessionRes>(`${this.baseUrl}/${GET_AUTH_SESSION}`);
  }

  public async [IMPERSONATE_TO](userId?: string): Promise<Partial<GetAuthSessionRes>> {
    return this.apiService.post<Partial<GetAuthSessionRes>>(
      userId ? `${this.baseUrl}/${IMPERSONATE_TO}?userId=${userId}` : `${this.baseUrl}/${IMPERSONATE_TO}`
    );
  }
  public async [SIGN_IN](payload: AuthSecuredSignInReq): Promise<BaseResponse> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${SIGN_IN}`, payload);
  }

  public async [SIGN_UP](payload: AuthSecuredSignUpReq): Promise<BaseResponse> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${SIGN_UP}`, payload);
  }

  public async [COMPLETE_SIGN_UP](payload: CompleteSignUpReq): Promise<BaseResponse> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${COMPLETE_SIGN_UP}`, payload);
  }

  public async [SIGN_OUT](payload?: SignOutReq): Promise<BaseResponse> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${SIGN_OUT}`, payload);
  }

  public async [FORGOT_PASSWORD](payload: AuthForgotPasswordReq): Promise<{ expiredAt: number; enableIn: number }> {
    return this.apiService.post<{ expiredAt: number; enableIn: number }>(`${this.baseUrl}/${FORGOT_PASSWORD}`, payload);
  }

  public async [CHANGE_PASSWORD](payload: AuthSecuredRecoverPasswordReq): Promise<BaseResponse> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${CHANGE_PASSWORD}`, payload);
  }

  public async [METHOD_OTP_VALIDATE](payload: MethodOtpValidateReq): Promise<{ expiredAt: number; enableIn: number }> {
    return this.apiService.post<{ expiredAt: number; enableIn: number }>(`${this.baseUrl}/${METHOD_OTP_VALIDATE}`, payload);
  }

  public async [SAVE_TOKEN](token: string): Promise<void> {
    return this.apiService.post<void>(`${this.baseUrl}/${SAVE_TOKEN}`, { token });
  }

  public async [GOOGLE_AUTHORIZATION](url: string): Promise<GoogleAuthorizationRes> {
    return this.apiService.post<GoogleAuthorizationRes>(`${this.baseUrl}/${GOOGLE_AUTHORIZATION}`, { url });
  }
}
