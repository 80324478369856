import type { AuthState } from '@/store/auth/index';
import type { MutationTree } from 'vuex';
import type { GetAuthSessionRes } from '@/store/auth/auth.type';
import type { UserAssignee } from '@/views/job/types/job-manager-assignee.type';
import {
  SET_SESSION,
  SET_VERIFICATION_EXPIRED_AT,
  SESSION,
  VERIFICATION_EXPIRED_AT,
  SET_ASSIGNEE,
  ASSIGNED,
  LOAD_LOCAL_AUTH_STATE,
} from '@/store/auth/auth.constants';
import authLocalStore from '@/store/auth/helpers/auth-local-store';

const authMutations: MutationTree<AuthState> = {
  [LOAD_LOCAL_AUTH_STATE]: (state): void => {
    const localState = authLocalStore.load();

    Object.assign(state, localState);
  },

  [SET_SESSION]: (state, payload?: GetAuthSessionRes): void => {
    state[SESSION] = payload || null;
  },

  [SET_VERIFICATION_EXPIRED_AT]: (state, expiredAt?: number): void => {
    state[VERIFICATION_EXPIRED_AT] = expiredAt || null;
  },

  [SET_ASSIGNEE](state, payload?: UserAssignee): void {
    state[ASSIGNED] = payload || null;
    authLocalStore.save(state);
  },
};

export default authMutations;
