import type { BasePagination } from '@shared/models';
import type { BackofficeSystemLog, SearchSystemLogReq, SearchSystemLogRes } from '@/views/backoffice/system-log/store/system-log.types';
import { defineStore } from 'pinia';
import { StoreNamespace } from '@/store/store-namespace';
import { container } from 'tsyringe';
import BackofficeLogService from '@/views/backoffice/system-log/service/backoffice-log.service';
import { ref } from 'vue';
import { DELETE_SYSTEM_LOG, GET_SYSTEM_LOG, SEARCH_SYSTEM_LOG } from '@/views/backoffice/system-log/store/system-log.constants';
import { SystemLogEnum } from '@/views/backoffice/system-log/constants/system-log.enum';

const backofficeLogService = container.resolve(BackofficeLogService);

export const useBackofficeLogStore = defineStore(StoreNamespace.BACKOFFICE_LOG_MODULE, () => {
  const DEFAULT_FILTER = { type: SystemLogEnum.ERROR };
  const DEFAULT_PAGINATION: BasePagination = { sort: { createdAt: -1 } };

  const systemLog = ref<BackofficeSystemLog | null>(null);
  const systemLogData = ref<SearchSystemLogRes['data']>([]);
  const systemLogFilter = ref<Omit<SearchSystemLogReq, 'page'>>({ ...DEFAULT_FILTER });
  const systemLogPagination = ref<BasePagination>({ ...DEFAULT_PAGINATION });

  const resetSystemLog = (): void => {
    systemLogFilter.value = { ...DEFAULT_FILTER };
    systemLogPagination.value = { ...DEFAULT_PAGINATION };
    systemLogData.value = [];
  };

  const searchSystemLog = async (page?: BasePagination): Promise<SearchSystemLogRes> => {
    systemLogData.value = [];
    const payload = { ...systemLogFilter.value, page: { ...systemLogPagination.value, ...(page || {}) } };

    const res = await backofficeLogService[SEARCH_SYSTEM_LOG](payload);
    systemLogPagination.value = res.page;
    systemLogData.value = res.data;

    const { index = 0, total = 0 } = res.page;
    if (total > 0 && index >= total) {
      await searchSystemLog({ ...res.page, index: total - 1 });
    }

    return res;
  };

  const getSystemLog = async (id: string): Promise<void> => {
    systemLog.value = await backofficeLogService[GET_SYSTEM_LOG](id);
  };

  const deleteSystemLog = async (id: string): Promise<void> => {
    return await backofficeLogService[DELETE_SYSTEM_LOG](id);
  };

  return {
    log: systemLog,
    filter: systemLogFilter,
    pagination: systemLogPagination,
    data: systemLogData,
    resetSystemLog,
    [SEARCH_SYSTEM_LOG]: searchSystemLog,
    [GET_SYSTEM_LOG]: getSystemLog,
    [DELETE_SYSTEM_LOG]: deleteSystemLog,
  };
});
