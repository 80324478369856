// actions
export const SEND_MESSAGE = 'send-message';
export const DELETE_MESSAGE = 'delete-message';
export const GET_CHAT = 'get-chat';
export const GET_MESSAGES = 'get-messages';
export const LIST_CHATS = 'list-chats';

// state
export const ACTIVE_CHAT = 'active-chat';

// mutation
export const SET_ACTIVE_CHAT = 'set-active-chat';
