<template>
  <img
    ref="imgRef"
    :class="{ skeleton: isLoading || asyncLoading, default: imgSrc === defaultSrc }"
    :alt="alt || hidAlt"
    :src="imgSrc"
    :style="{ filter }"
    v-bind="$attrs"
    @error="onError"
    @load="onLoaded"
    @loadstart="isLoading = true"
  />
</template>

<script setup lang="ts">
import { type PropType, ref, watch } from 'vue';
import { BaseImageResponse } from '@shared/models';
import { useSwipe } from '@shared/composables/use-swipe';
import { isRtl } from '@/plugins/i18n/helpers';
import { ColorFilter } from '@shared/services/css-service/color-filter.service';
import getThemeColor from '@shared/services/css-service/helpers/get-theme-color';
import type { ColorsMap } from '@shared/services/css-service/types';

const props = defineProps({
  alt: { type: String, default: '' },
  src: { type: String, default: '' },
  color: { type: String as PropType<ColorsMap | string | undefined>, default: undefined },
  srcProvider: { type: Object as PropType<Promise<BaseImageResponse> | undefined>, default: undefined },
  defaultSrc: { type: String, default: () => require('@/assets/images/loader/image.svg') },
});
const emit = defineEmits(['error', 'load', 'back', 'next']);

const isLoading = ref<boolean>(true);
const asyncLoading = ref<boolean>(false);
const isFailed = ref<boolean>(false);
const hidAlt = ref<string | undefined>(undefined);
const imgSrc = ref<string | undefined>(props.src || props.defaultSrc);
const filter = ref();

const onError = (event: Event) => {
  if (!isLoading.value) {
    return;
  }

  (event.target as HTMLImageElement).onerror = null;
  isFailed.value = true;
  isLoading.value = false;
  imgSrc.value = props.defaultSrc;
  emit('error');
};

const onLoaded = async (): Promise<void> => {
  isLoading.value = false;
  emit('load');
};

const imgRef = ref<HTMLElement | undefined>(undefined);

useSwipe(imgRef, { isRtl: ref(isRtl()), onBack: () => emit('back'), onNext: () => emit('next') });

watch(
  () => props.srcProvider,
  async (provider) => {
    if (!provider) {
      return;
    }

    try {
      const preview = props.src;
      asyncLoading.value = true;
      const { image, alt } = await provider;

      if (preview !== props.src) {
        return;
      }

      imgSrc.value = image || props.src || props.defaultSrc;
      hidAlt.value = alt;
    } catch (e) {
      imgSrc.value = props.src || props.defaultSrc;
    } finally {
      asyncLoading.value = false;
    }
  },
  { immediate: true }
);

watch(
  () => props.color,
  (color) => {
    if (!color) {
      return;
    }

    const filteredColor = getThemeColor(color);

    if (filteredColor) {
      filter.value = new ColorFilter(filteredColor).filter;
    }
  },
  { immediate: true }
);

watch(
  () => props.src,
  () => (imgSrc.value = props.src)
);
</script>

<style scoped>
.default {
  mix-blend-mode: multiply;
}
</style>
