import type { BaseResponse } from '@shared/models';
import type { GetSystemLogRes, SearchSystemLogReq, SearchSystemLogRes } from '@/views/backoffice/system-log/store/system-log.types';
import ApiService from '@/shared/services/api-service/api.service';
import { inject, injectable } from 'tsyringe';
import { DELETE_SYSTEM_LOG, GET_SYSTEM_LOG, SEARCH_SYSTEM_LOG } from '@/views/backoffice/system-log/store/system-log.constants';

@injectable()
export default class BackofficeLogService {
  public constructor(@inject('ApiService') private apiService: ApiService) {}

  private baseUrl = `/system/log`;

  public [SEARCH_SYSTEM_LOG](payload: SearchSystemLogReq): Promise<SearchSystemLogRes> {
    return this.apiService.post<SearchSystemLogRes>(`${this.baseUrl}/${SEARCH_SYSTEM_LOG}`, payload, { allowOnceAtTime: true });
  }

  public [GET_SYSTEM_LOG](id: string): Promise<GetSystemLogRes> {
    return this.apiService.get<GetSystemLogRes>(`${this.baseUrl}/${GET_SYSTEM_LOG}/${id}`);
  }

  public [DELETE_SYSTEM_LOG](id: string): Promise<BaseResponse> {
    return this.apiService.delete<BaseResponse>(`${this.baseUrl}/${DELETE_SYSTEM_LOG}/${id}`);
  }
}
