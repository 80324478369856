import { createRouter, createWebHistory, Router, type RouteRecordRaw, RouteRecordRedirect } from 'vue-router';
import Home from '@/views/lobby/lobby.view.vue';
import Modules from '@/router/constants/modules';
import { routeMetaUpdate } from '@/router/helpers';
import jobRoutes from 'src/views/job';
import calendarRoutes from '@/views/calendar';
import messengerRoutes from '@/views/messenger';
import authLocalStore from '@/store/auth/helpers/auth-local-store';
import employeeRoutes from '@employee/*';
import notificationsRoutes from '@/views/notifications';
import privacyPolicy from '@/views/privacy-policy';
import termsConditions from '@/views/terms-conditions';
import backofficeRoutes from '@/views/backoffice';

const routes: Readonly<Array<RouteRecordRaw>> = [
  {
    path: Modules.PATHS[Modules.MAINTENANCE],
    name: Modules.MAINTENANCE,
    component: Modules.COMPONENT[Modules.MAINTENANCE],
    meta: { isUnprotected: true, isSplashScreen: true },
  },
  {
    path: Modules.PATHS[Modules.OFFLINE],
    name: Modules.OFFLINE,
    component: Modules.COMPONENT[Modules.OFFLINE],
    meta: { isUnprotected: true, isSplashScreen: true },
  },
  {
    path: Modules.PATHS[Modules.ERROR],
    name: Modules.ERROR,
    component: Modules.COMPONENT[Modules.ERROR],
    meta: { isUnprotected: true, isSplashScreen: true },
  },
  {
    path: Modules.PATHS[Modules.FORBIDDEN],
    name: Modules.FORBIDDEN,
    component: Modules.COMPONENT[Modules.FORBIDDEN],
    meta: { isUnprotected: true, isSplashScreen: true },
  },
  {
    path: Modules.PATHS[Modules.HOME],
    name: Modules.HOME,
    component: Home,
  },
  employeeRoutes,
  notificationsRoutes,
  jobRoutes,
  calendarRoutes,
  messengerRoutes,
  backofficeRoutes,
  {
    path: Modules.PATHS[Modules.PRIVACY_POLICY],
    name: Modules.PRIVACY_POLICY,
    component: privacyPolicy,
    meta: { isUnprotected: true },
  },
  {
    path: Modules.PATHS[Modules.TERMS_CONDITIONS],
    name: Modules.TERMS_CONDITIONS,
    component: termsConditions,
    meta: { isUnprotected: true },
  },
  {
    path: '/:pathMatch(.*)*',
    name: Modules.ERROR,
    component: Modules.COMPONENT[Modules.ERROR],
  },
];

const getRouteModules = (routes: Readonly<Array<RouteRecordRaw>>): Array<RouteRecordRaw> => {
  const { session } = authLocalStore.load();
  const modules = session?.modules || [];
  const modulesMeta = session?.meta;

  return routes.reduce((filteredRoutes: Array<RouteRecordRaw>, route: RouteRecordRaw) => {
    const hasChildRedirect =
      (route.redirect as RouteRecordRedirect)?.name &&
      route.children?.find(({ name }) => name === (route.redirect as RouteRecordRedirect).name)?.name;

    if (route.children?.length) {
      route.children = getRouteModules(route.children);
    }

    if (route.children?.length || route.meta?.isUnprotected || (route.name && modules.includes(route.name as string))) {
      const tags = ['title', 'description', 'image'];
      const meta = { ...(route.meta || {}), ...(modulesMeta?.[route.name as keyof typeof modulesMeta] || {}) };
      const hasMetaTag = Object.keys(meta).some((key: string) => tags.includes(key));

      if (hasChildRedirect && !route.children?.find(({ name }) => name === (route.redirect as RouteRecordRedirect).name)) {
        route.redirect = { name: route.children?.[0].name as string };
      }

      filteredRoutes.push({ ...route, meta, ...(hasMetaTag ? { beforeEnter: routeMetaUpdate } : {}) });
    }

    return filteredRoutes;
  }, []);
};

const router: Router = createRouter({
  history: createWebHistory(),
  routes: getRouteModules(routes),
});

export default router;
