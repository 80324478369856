export const scrollToView = (element?: string | Element, options?: ScrollIntoViewOptions): void => {
  if (!element) {
    return;
  }

  (element instanceof Element ? element : document.querySelector(element))?.scrollIntoView({
    block: 'center',
    behavior: 'smooth',
    inline: 'center',
    ...(options || {}),
  });
};
