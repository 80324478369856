import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "fill-height d-flex flex-column" }
const _hoisted_2 = { class: "d-flex align-center gap-5 pa-4 bg-active" }
const _hoisted_3 = { class: "text-title-1" }
const _hoisted_4 = { class: "text-title-2" }
const _hoisted_5 = {
  key: 0,
  class: "ms-1"
}
const _hoisted_6 = { class: "text-subtitle-1" }
const _hoisted_7 = { class: "overflow-y-auto flex-grow-1" }
const _hoisted_8 = { class: "d-flex flex-column" }
const _hoisted_9 = { class: "row-flex-4 vh-65" }
const _hoisted_10 = { class: "d-flex flex-column fill-height" }
const _hoisted_11 = { class: "text-title-1" }
const _hoisted_12 = { class: "d-flex flex-column fill-height" }
const _hoisted_13 = { class: "text-title-1" }
const _hoisted_14 = { class: "d-flex flex-column fill-height" }
const _hoisted_15 = { class: "text-title-1" }

import { defineAsyncComponent } from 'vue';
import LobbyDateNow from './components/lobby-date-now.vue';
import { useAuthUser } from '@/views/employee/composables/use-auth-user';
import UpcomingEvents from '@/views/calendar/views/upcoming-events.view.vue';
import VzDivider from '@shared/components/vz-divider.vue';
import VzCard from '@shared/components/vz-card.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'lobby.view',
  setup(__props) {

const UsersGrid = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/lobby/components/users-grid.vue'));
const Notifications = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/notifications/components/notifications-tabs.vue'));
const CalendarLobby = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/lobby/components/calendar-lobby.vue'));
const JobOffer = defineAsyncComponent(
  () => import(/* webpackChunkName: "lobby" */ '@/views/job/views/offer/components/job-offers/job-offers-table.vue')
);

const { activeUser } = useAuthUser();

return (_ctx: any,_cache: any) => {
  const _component_vz_avatar = _resolveComponent("vz-avatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_vz_avatar, {
        hid: _unref(activeUser)?._id,
        size: 70
      }, null, 8, ["hid"]),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('GENERAL.WELCOME')), 1),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", null, _toDisplayString(_unref(activeUser)?.firstName) + " " + _toDisplayString(_unref(activeUser)?.lastName), 1),
          (_unref(activeUser)?.nickName)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, "(" + _toDisplayString(_unref(activeUser)?.nickName) + ")", 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(activeUser)?.company), 1)
      ]),
      _createVNode(LobbyDateNow)
    ]),
    _createVNode(VzDivider),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('MODULE.UPCOMING_EVENTS')), 1),
            _createVNode(VzCard, { class: "flex-grow-1 overflow-hidden" }, {
              default: _withCtx(() => [
                _createVNode(UpcomingEvents)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('MODULE.CALENDAR')), 1),
            _createVNode(VzCard, { class: "flex-grow-1" }, {
              default: _withCtx(() => [
                _createVNode(_unref(CalendarLobby))
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t('MODULE.NOTIFICATIONS')), 1),
            _createVNode(VzCard, { class: "flex-grow-1 overflow-hidden" }, {
              default: _withCtx(() => [
                _createVNode(_unref(Notifications))
              ]),
              _: 1
            })
          ])
        ]),
        _createVNode(VzCard, null, {
          default: _withCtx(() => [
            _createVNode(_unref(JobOffer), { minimal: "" })
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})