import { Color } from '@shared/services/css-service/color.service';

export class ColorFilter {
  public constructor(target: [number, number, number] | string) {
    this.target = new Color(target);

    const result = this.narrow(this.wide());
    this.filter = this.css(result.values);
  }

  public filter: string;
  private readonly target: Color;

  private wide(): { loss: number; values: Array<number> } {
    const A = 5;
    const c = 15;
    const a = [60, 180, 18000, 600, 1.2, 1.2];

    const best: { loss: number; values: Array<number> } = { loss: Infinity, values: [] };
    for (let i = 0; best.loss > 25 && i < 3; i++) {
      const initial = [50, 20, 3750, 50, 100, 100];
      const result = this.spsa(A, a, c, initial, 1000);
      if (result.loss < best.loss) {
        best.loss = result.loss;
        best.values = result.values;
      }
    }

    return best;
  }

  private narrow(wide: { loss: number; values?: Array<number> }) {
    const A = wide.loss;
    const c = 2;
    const A1 = A + 1;
    const a = [0.25 * A1, 0.25 * A1, A1, 0.25 * A1, 0.2 * A1, 0.2 * A1];

    return this.spsa(A, a, c, wide.values || [], 500);
  }

  private spsa(A: number, a: Array<number>, c: number, values: Array<number>, iters: number): { values: Array<number>; loss: number } {
    const alpha = 1;
    const gamma = 0.16666666666666666;

    let best: Array<number> = [];
    let bestLoss = Infinity;
    const deltas = new Array(6);
    const highArgs = new Array(6);
    const lowArgs = new Array(6);

    for (let k = 0; k < iters; k++) {
      const ck = c / Math.pow(k + 1, gamma);
      for (let i = 0; i < 6; i++) {
        deltas[i] = Math.random() > 0.5 ? 1 : -1;
        highArgs[i] = values[i] + ck * deltas[i];
        lowArgs[i] = values[i] - ck * deltas[i];
      }

      const lossDiff = this.loss(highArgs) - this.loss(lowArgs);
      for (let i = 0; i < 6; i++) {
        const g = (lossDiff / (2 * ck)) * deltas[i];
        const ak = a[i] / Math.pow(A + k + 1, alpha);
        values[i] = fix(values[i] - ak * g, i);
      }

      const loss = this.loss(values);
      if (loss < bestLoss) {
        best = values.slice(0);
        bestLoss = loss;
      }
    }

    return { values: best, loss: bestLoss };

    function fix(value: number, idx: number) {
      let max = 100;

      // saturate
      if (idx === 2) {
        max = 7500;
        // brightness || contrast
      } else if (idx === 4 || idx === 5) {
        max = 200;
      }

      // hue-rotate
      if (idx === 3) {
        if (value > max) {
          value %= max;
        } else if (value < 0) {
          value = max + (value % max);
        }
      } else if (value < 0) {
        value = 0;
      } else if (value > max) {
        value = max;
      }

      return value;
    }
  }

  private loss(filters: Array<number>) {
    const color = new Color([0, 0, 0]);
    color.set(0, 0, 0);

    color.invert(filters[0] / 100);
    color.sepia(filters[1] / 100);
    color.saturate(filters[2] / 100);
    color.hueRotate(filters[3] * 3.6);
    color.brightness(filters[4] / 100);
    color.contrast(filters[5] / 100);

    const [sourceRed, sourceGreen, sourceBlue] = color.rgb;
    const [targetRed, targetGreen, targetBlue] = this.target.rgb;

    const [h, s, l] = color.hsl();
    const [tH, tS, tL] = this.target.hsl();

    return (
      Math.abs(sourceRed - targetRed) +
      Math.abs(sourceGreen - targetGreen) +
      Math.abs(sourceBlue - targetBlue) +
      Math.abs(h - tH) +
      Math.abs(s - tS) +
      Math.abs(l - tL)
    );
  }

  private css(filters: Array<number>) {
    function fmt(idx: number, multiplier = 1) {
      return Math.round(filters[idx] * multiplier);
    }

    const filter = [
      'brightness(0)',
      'saturate(100%)',
      `invert(${fmt(0)}%)`,
      `sepia(${fmt(1)}%)`,
      `saturate(${fmt(2)}%)`,
      `hue-rotate(${fmt(3, 3.6)}deg)`,
      `brightness(${fmt(4)}%)`,
      `contrast(${fmt(5)}%)`,
    ];

    return filter.join(' ');
  }
}
