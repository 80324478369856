import type { ErrorResponse } from '@/shared/services/api-service/models';
import { computed, ComputedRef, type Ref } from 'vue';
import { useI18n } from 'vue-i18n';

export const useServerErrorsMapper = (errors: Ref<ErrorResponse | null> | ComputedRef<ErrorResponse | null>): Ref<Record<string, string>> => {
  const { t } = useI18n();

  const dynamic = (value: string, fields: Record<string, any> = {}): string => {
    try {
      return t(value).replace(/(<.*?>)/g, (_, key) => {
        const strippedKey = key.slice(1, -1);
        return t(fields[strippedKey] || key) || key;
      });
    } catch (e) {
      console.error(e);

      return value;
    }
  };

  return computed(() => {
    return (errors.value?.errorMessage || []).reduce((result: Record<string, string>, { message, property, value }) => {
      return property ? { ...result, [property]: dynamic(t(message), { value, property }) } : result;
    }, {});
  });
};
