import axios, { type AxiosError, type AxiosResponse } from 'axios';
import type { AxiosResponseInterceptor } from '@/shared/services/api-service/models';
import type { ErrorResponse } from '@/shared/services/api-service/models';
import { useErrorsState } from '@shared/composables/use-errors-state';
import Modules from '@/router/constants/modules';
import ServerError from '@shared/services/api-service/server-error';
import router from '@/router';

export const errorsInterceptor: AxiosResponseInterceptor = {
  onFulfilled: (response: AxiosResponse) => {
    return response;
  },
  onRejected: async (error: AxiosError) => {
    const err: AxiosError<ErrorResponse> = error as AxiosError<ErrorResponse>;
    const serverError = new ServerError(err);

    if (axios.isCancel(error)) {
      return;
    }

    if (!err.response || err.response.status === 503) {
      await router.replace({ name: !navigator.onLine ? Modules.OFFLINE : Modules.MAINTENANCE });

      return;
    }

    useErrorsState(serverError);

    switch (err.response.status) {
      case 404:
        await router.replace({ name: Modules.ERROR });
        break;
      case 500:
        await router.replace({ name: Modules.ERROR });
        break;
      default:
        throw serverError;
    }
  },
};
