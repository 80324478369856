<template>
  <div v-if="value || !hideEmpty" :class="['d-flex gap-1', { 'flex-column': !flat }]">
    <p v-if="label" class="text-title-1">{{ $t(label) }}</p>

    <a v-if="link && value" :class="['text-body-1 text-auto', { ltr }]" :href="link">{{ $t(valueDisplay) }}</a>

    <div v-else :class="['text-body-1 d-flex gap-1 flex-wrap text-auto', { 'text-ellipsis': textEllipsis, ltr }]">
      <span v-if="value && !refer" :class="{ ltr: isNumber }">{{ $t(valueDisplay) }}</span>

      <vz-button v-else-if="value && refer" class="pa-0" type="flat" :text="valueDisplay" @click="routeTo(refer)" />

      <slot v-if="$slots['default']" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import type { RouteLocation } from 'vue-router';
import VzButton from '@shared/components/buttons/vz-button.vue';
import { routeTo, useCurrencySymbol } from '@shared/composables';
import { numberWithCommas } from '@shared/helpers';
import { phonePrettier } from '@shared/helpers/phone-prettier';
import { BasePhone } from '@shared/models';

const props = defineProps({
  hideEmpty: { type: Boolean, default: false },
  flat: { type: Boolean, default: false },
  ltr: { type: Boolean, default: false },
  textEllipsis: { type: Boolean, default: false },
  value: {
    type: [String, Array, Object] as PropType<BasePhone | string | number | boolean | Array<string | number | boolean> | null | undefined>,
    default: undefined,
  },
  label: { type: String as PropType<string | undefined>, default: undefined },
  type: { type: String as PropType<'phone' | 'email' | 'currency'>, default: undefined },
  refer: { type: Object as PropType<Partial<Pick<RouteLocation, 'name' | 'path' | 'params' | 'query'>> | undefined>, default: undefined },
});

const isNumber = computed(() => typeof props.value === 'number');

const link = computed(() => {
  if (!props.value) {
    return null;
  }

  switch (props.type) {
    case 'phone':
      return `tel:${props.value}`;
    case 'email':
      return `mailto:${props.value}`;
    default:
      return null;
  }
});

const valueDisplay = computed((): string => {
  if (props.value === undefined || props.value === null) {
    return '-';
  }

  const valueToString = (value: string | number | boolean | BasePhone) => {
    switch (props.type) {
      case 'currency':
        return useCurrencySymbol(value as number).value;
      case 'phone':
        return phonePrettier(props.value as BasePhone);
    }

    switch (typeof value) {
      case 'boolean':
        return value ? 'GENERAL.YES' : 'GENERAL.NO';
      case 'number':
        return numberWithCommas(value);
      default:
        return value.toString();
    }
  };

  return Array.isArray(props.value) ? props.value.map(valueToString).join(', ') : valueToString(props.value);
});
</script>
