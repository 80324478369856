import type { GetterTree } from 'vuex';
import type { RootState } from '@/store';
import type { GeneralState } from '@/store/client/client.types';
import type { BaseAddress, BaseOptions } from '@/shared/models';
import type { ListCompaniesRes } from 'src/views/employee/types';
import { ADDRESSES_CACHE, COMPANIES_CACHE } from '@/store/client/client.constants';
import { StateCache } from '@/store/services/store.cache';

const clientGetters: GetterTree<GeneralState, RootState> = {
  [COMPANIES_CACHE](state): StateCache<ListCompaniesRes['companies']> {
    return state[COMPANIES_CACHE] || ({} as StateCache<ListCompaniesRes['companies']>);
  },

  [ADDRESSES_CACHE](state): StateCache<BaseOptions<BaseAddress>> {
    return state[ADDRESSES_CACHE] || ({} as StateCache<BaseOptions<BaseAddress>>);
  },
};

export default clientGetters;
