import type { RouteRecordNormalized } from 'vue-router';
import type { RouteItem } from '@/router/models/route-item';
import type { IconName } from '@shared/components/icon/icon.type';
import { computed, type ComputedRef, ref, type Ref } from 'vue';
import { GET_AUTH_MODULES, IS_LOGGED_IN } from '@/store/auth/auth.constants';
import useAuthStore from '@/views/lobby/modals/login-modal/composables/use-auth-store';
import { findAllChildrenRoutes, getChildrenRouteNames } from '@/router/helpers';
import { kebabCaseToScreamingSnakeCase } from '@/shared/helpers';
import router from '@/router';
import store from '@/store';
import { StoreNamespace } from '@/store/store-namespace';

export const useMenuItems = (
  options: Partial<{ routeName: ComputedRef<string>; items: Ref<Array<RouteItem | string>>; authModules?: ComputedRef<Array<string> | null> }> = {}
): ComputedRef<Array<RouteItem>> => {
  const { useGetters } = useAuthStore();
  const { [GET_AUTH_MODULES]: authModulesGetter } = useGetters([GET_AUTH_MODULES]);

  const buildMenuItems = (routes: Array<RouteRecordNormalized>, items: Array<RouteItem | string>): Array<RouteItem> => {
    return items
      .map((item) => {
        const route = routes.find(({ name }) => (typeof item === 'object' ? name === item.name : name === item));
        const childrenRoutes = route ? findAllChildrenRoutes(route.name as string) : [];

        const menuItem: RouteItem =
          typeof item === 'object'
            ? {
                label: (route?.meta?.title as string) || `MODULE.${kebabCaseToScreamingSnakeCase(item.name)}`,
                icon: route?.meta?.icon as IconName,
                ...item,
                subitems: buildMenuItems(
                  childrenRoutes,
                  childrenRoutes.map((child) => child.name as string)
                ),
              }
            : {
                name: item,
                label: (route?.meta?.title as string) || `MODULE.${kebabCaseToScreamingSnakeCase(item)}`,
                icon: route?.meta?.icon as IconName,
                subitems: buildMenuItems(
                  childrenRoutes,
                  childrenRoutes.map((child) => child.name as string)
                ),
              };

        return menuItem;
      })
      .filter(({ name }) => {
        // Safeguard: Check if the route name exists and is a string
        if (!name || typeof name !== 'string') {
          return false;
        }

        // Check if the page is available only for signed-in users
        const routeMeta = routes.find((r) => r.name === name)?.meta;
        if (routeMeta?.isSignInRequired && !store.getters[`${StoreNamespace.AUTH_MODULE}/${IS_LOGGED_IN}`]) {
          return false;
        }

        // Check if the page is unprotected
        if (routeMeta?.isUnprotected) {
          return true;
        }

        // Check if the user is allowed to access the page
        const route = routes.find((route) => route.name === name);
        const children = route?.children?.map(({ name }) => name as string) || [];

        return (
          (options.authModules?.value || authModulesGetter.value)?.includes(name) ||
          children.some((child: string) => (options.authModules?.value || authModulesGetter.value)?.includes(child))
        );
      });
  };

  return computed((): Array<RouteItem> => {
    const { routeName, items = ref(getChildrenRouteNames(routeName?.value)) } = options;
    const routeChildren = routeName?.value ? findAllChildrenRoutes(routeName.value) : router.getRoutes();

    return buildMenuItems(routeChildren, items.value);
  });
};
