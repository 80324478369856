import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import { onMounted, ref } from 'vue';
import { locale } from '@shared/locale';
import { loadStaticFile } from '@shared/helpers';

const FILE_PREFIX = 'privacy-policy';


export default /*@__PURE__*/_defineComponent({
  __name: 'privacy-policy',
  setup(__props) {

const contentHtml = ref<string>();

onMounted(async () => {
  const res = await loadStaticFile(`${FILE_PREFIX}-${locale.active}.html`);
  contentHtml.value = await res.text();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "fill-height overflow-y-auto",
    innerHTML: contentHtml.value
  }, null, 8, _hoisted_1))
}
}

})