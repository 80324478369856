import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ms-1"
}

import type { ErrorResponse } from '@/shared/services/api-service/models';
import { computed, type PropType } from 'vue';
import { useTranslator } from '@/plugins/i18n/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-error-alert',
  props: {
  flat: { type: Boolean, default: false },
  errors: { type: Object as PropType<ErrorResponse | null>, default: () => null },
  maxShown: { type: [Number, String], default: 1 },
},
  setup(__props) {

const t = useTranslator();

const props = __props;

const hasMore = computed(() => {
  return props.maxShown && (props.errors?.errorMessage?.length || 0) - +props.maxShown > 0;
});

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!

  return (__props.errors?.errorMessage?.length)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(['vz-error-alert', { 'vz-error-alert--flat': __props.flat }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.errors.errorMessage, ({ message, ...rest }, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (!__props.maxShown || index < +__props.maxShown)
              ? (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "vz-error-alert__message"
                }, [
                  _createVNode(_component_vz_icon, {
                    class: "pe-1",
                    name: "svg:warning",
                    color: "red-600",
                    size: "1.5rem"
                  }),
                  _createElementVNode("span", null, _toDisplayString(_unref(t)(message, { ...rest })), 1),
                  (hasMore.value && index === +__props.maxShown - 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_1, "(+" + _toDisplayString(__props.errors.errorMessage.length - +__props.maxShown) + ")", 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ], 64))
        }), 256))
      ], 2))
    : _createCommentVNode("", true)
}
}

})