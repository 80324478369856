export enum SystemLogEnum {
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  LOG = 'LOG',
}

export enum SystemLogColorEnum {
  ERROR = 'red-900',
  WARNING = 'yellow-800',
  LOG = 'green-900',
  INFORMATION = 'mint-900',
}
