export class Color {
  public constructor(color: [number, number, number] | string) {
    const value = (() => {
      if (Array.isArray(color)) {
        return color.length === 3 && color.every((val) => !isNaN(val)) ? color : this.defaultColor;
      }

      const extract = /(^var\()(.*?)(?=\)$)/g.exec(color)?.shift();
      const variable = getComputedStyle(document.documentElement).getPropertyValue(extract?.slice(4) || '');

      return this.toRGB(variable?.trim() || color.trim()) || this.defaultColor;
    })();

    const [r, g, b] = value;
    this.set(r, g, b);
  }

  public rgb: [number, number, number];
  private readonly defaultColor = [255, 255, 255];

  public toHex() {
    return '#' + this.rgb.map((c) => ('0' + c.toString(16)).slice(-2)).join('');
  }

  public set(r: number, g: number, b: number): void {
    this.rgb = [this.clamp(r), this.clamp(g), this.clamp(b)];
  }

  public hueRotate(angle: number = 0): void {
    angle = (angle / 180) * Math.PI;
    const sin = Math.sin(angle);
    const cos = Math.cos(angle);

    this.multiply([
      0.213 + cos * 0.787 - sin * 0.213,
      0.715 - cos * 0.715 - sin * 0.715,
      0.072 - cos * 0.072 + sin * 0.928,
      0.213 - cos * 0.213 + sin * 0.143,
      0.715 + cos * 0.285 + sin * 0.14,
      0.072 - cos * 0.072 - sin * 0.283,
      0.213 - cos * 0.213 - sin * 0.787,
      0.715 - cos * 0.715 + sin * 0.715,
      0.072 + cos * 0.928 + sin * 0.072,
    ]);
  }

  public grayscale(value: number = 1) {
    this.multiply([
      0.2126 + 0.7874 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 + 0.2848 * (1 - value),
      0.0722 - 0.0722 * (1 - value),
      0.2126 - 0.2126 * (1 - value),
      0.7152 - 0.7152 * (1 - value),
      0.0722 + 0.9278 * (1 - value),
    ]);
  }

  public sepia(value: number = 1) {
    this.multiply([
      0.393 + 0.607 * (1 - value),
      0.769 - 0.769 * (1 - value),
      0.189 - 0.189 * (1 - value),
      0.349 - 0.349 * (1 - value),
      0.686 + 0.314 * (1 - value),
      0.168 - 0.168 * (1 - value),
      0.272 - 0.272 * (1 - value),
      0.534 - 0.534 * (1 - value),
      0.131 + 0.869 * (1 - value),
    ]);
  }

  public saturate(value: number = 1) {
    this.multiply([
      0.213 + 0.787 * value,
      0.715 - 0.715 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 + 0.285 * value,
      0.072 - 0.072 * value,
      0.213 - 0.213 * value,
      0.715 - 0.715 * value,
      0.072 + 0.928 * value,
    ]);
  }

  public multiply(matrix: Array<number>) {
    const [r, g, b] = this.rgb;

    this.rgb = [
      this.clamp(r * matrix[0] + g * matrix[1] + b * matrix[2]),
      this.clamp(r * matrix[3] + g * matrix[4] + b * matrix[5]),
      this.clamp(r * matrix[6] + g * matrix[7] + b * matrix[8]),
    ];
  }

  public brightness(value: number = 1): void {
    this.linear(value);
  }
  public contrast(value: number = 1): void {
    this.linear(value, -(0.5 * value) + 0.5);
  }

  public linear(slope: number = 1, intercept: number = 0) {
    const [r, g, b] = this.rgb;

    this.rgb = [this.clamp(r * slope + intercept * 255), this.clamp(g * slope + intercept * 255), this.clamp(b * slope + intercept * 255)];
  }

  public invert(value: number = 1) {
    const [r, g, b] = this.rgb;

    this.rgb = [
      this.clamp((value + (r / 255) * (1 - 2 * value)) * 255),
      this.clamp((value + (g / 255) * (1 - 2 * value)) * 255),
      this.clamp((value + (b / 255) * (1 - 2 * value)) * 255),
    ];
  }

  public hsl(): [number, number, number] {
    const [r, g, b] = this.rgb.map((val) => val / 255);

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);

    const hsl: [number, number, number] = [0, 0, (max + min) / 2];

    if (max !== min) {
      const d = max - min;
      hsl[1] = hsl[2] > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          hsl[0] = (g - b) / d + (g < b ? 6 : 0);
          break;

        case g:
          hsl[0] = (b - r) / d + 2;
          break;

        case b:
          hsl[0] = (r - g) / d + 4;
          break;
      }
      hsl[0] /= 6;
    }

    return hsl;
  }

  public clamp(value: number) {
    if (value > 255) {
      value = 255;
    } else if (value < 0) {
      value = 0;
    }

    return value;
  }

  private toRGB(hex: string): [number, number, number] | null {
    if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex)) {
      return null;
    }

    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return [parseInt(result![1], 16), parseInt(result![2], 16), parseInt(result![3], 16)];
  }
}
