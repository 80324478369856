<template>
  <div class="fill-height overflow-y-auto" v-html="contentHtml" />
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { locale } from '@shared/locale';
import { loadStaticFile } from '@shared/helpers';

const FILE_PREFIX = 'terms-of-service';

const contentHtml = ref<string>();

onMounted(async () => {
  const res = await loadStaticFile(`${FILE_PREFIX}-${locale.active}.html`);
  contentHtml.value = await res.text();
});
</script>
