import type { Module } from 'vuex';
import type { RootState } from '@/store';
import type { GetCalendarEventsReq, GetCalendarEventsRes, GetUpcomingEventsRes } from 'src/views/calendar/types';
import calendarActions from './calendar.actions';
import calendarMutations from './calendar.mutations';
import calendarGetters from './calendar.getters';
import AugmentedActionContext from '@/store/types/augmented-action-context';
import { GET_CALENDAR_EVENTS, GET_UPCOMING_EVENTS } from '@/views/calendar/store/calendar.constants';

type StoreContext = AugmentedActionContext<CalendarState, CalendarMutations, CalendarActions>;

export type CalendarState = Record<never, never>;

export interface CalendarActions {
  [GET_CALENDAR_EVENTS](_: StoreContext, payload?: GetCalendarEventsReq): Promise<GetCalendarEventsRes>;
  [GET_UPCOMING_EVENTS]({ state, commit }: StoreContext): Promise<GetUpcomingEventsRes>;
}

export type CalendarMutations<S = CalendarState> = Record<never, never>;

export type CalendarGetters<S = CalendarState> = Record<never, never>;

export const initialState = (): CalendarState => ({});

const calendarStore: Module<CalendarState, RootState> = {
  namespaced: true,
  state: initialState(),
  mutations: calendarMutations,
  actions: calendarActions,
  getters: calendarGetters,
};

export default calendarStore;
