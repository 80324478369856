import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

import type { Dimensions } from '@/shared/types';
import { CanvasQuality } from '@/shared/services/image-service/models/image-resize';
import { computed, onMounted, PropType, ref, watch } from 'vue';
import 'vue-advanced-cropper/dist/style.css';
import ImageService from '@/shared/services/image-service/image.service';
import { Cropper, Coordinates } from 'vue-advanced-cropper';
import FileService from '@/shared/services/file.service';
import { debounce } from 'lodash';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-image-cropper',
  props: {
  src: { type: String as PropType<string | null | undefined>, default: undefined },
  defaultSrc: { type: String, default: '' },
  loading: { type: Boolean, default: false },
  file: { type: File, default: null },
  name: { type: String, default: '' },
  size: { type: Number, default: 0 },
  thumbnailSize: { type: Number, default: 0 },
  quality: { type: Number as PropType<CanvasQuality>, default: 0.9 },
  ratio: { type: Number, default: 0 },
  autoZoom: { type: Boolean, default: false },
  autoSave: { type: Boolean, default: false },
  disableAutoUpload: { type: Boolean, default: false },
  previewWidth: { type: [String, Number], default: 0 },
  previewHeight: { type: [String, Number], default: 0 },
  stencilWidth: { type: Number, default: 0 },
  stencilHeight: { type: Number, default: 0 },
  stencilMovable: { type: Boolean, default: false },
  stencilResizable: { type: Boolean, default: false },
  exportType: { type: String as PropType<'file' | 'base64'>, default: 'base64' },
  width: { type: [String, Number], default: 0 },
  height: { type: [String, Number], default: 0 },
},
  emits: ['save', 'cancel'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const imgSrc = ref<string | null>(null);
const cropperRef = ref();
const containerRef = ref<HTMLDivElement>();
const cropperCanvas = ref<HTMLCanvasElement>();
const cropperCoordinates = ref<Coordinates>();
const isProcess = ref<boolean>(true);
const defaultSize = ref<Dimensions | null>(null);

const containerWidth = computed(() => containerRef.value?.clientWidth);

const containerSize = computed(() => ({
  ...(props.width ? { width: props.width + 'px' } : {}),
  ...(props.height ? { height: props.height + 'px' } : {}),
}));

const previewSize = computed(() => {
  if (props.previewWidth || props.previewHeight) {
    return {
      ...(props.previewWidth ? { width: props.previewWidth || +props.previewHeight * (props.ratio || 1) + 'px' } : {}),
      ...(props.previewHeight ? { height: (props.previewHeight || +props.previewWidth / (props.ratio || 1)) + 'px' } : {}),
    };
  }

  if (!containerWidth.value) {
    return props.ratio ? { width: '100%', height: 100 / (props.ratio || 1) + '%' } : {};
  }

  return props.ratio ? { width: containerWidth.value + 'px', height: containerWidth.value / (props.ratio || 1) + 'px' } : {};
});

const onUpload = async (): Promise<void> => {
  const files = await FileService.uploadFile({ accept: ['image/*'] });

  imgSrc.value = files?.length ? (await ImageService.loadImageFile(files[0])).src || null : null;
};

const onRemove = () => {
  imgSrc.value = null;

  onSave();
};

const onChange = ({ canvas, coordinates }: { canvas: HTMLCanvasElement; coordinates: Coordinates }): void => {
  cropperCoordinates.value = coordinates;
  cropperCanvas.value = canvas;

  if (props.autoSave) {
    debounce(onSave, 500)();
  }
};

const onSave = async (): Promise<void> => {
  if (!cropperCanvas.value || !imgSrc.value) {
    emit('save', { data: null, thumb: null, name: null });

    return;
  }

  isProcess.value = true;

  const canvas = ImageService.canvasResize(cropperCanvas.value, { size: props.size });
  const name = props.name || props.file?.name || 'image.jpg';
  const type = props.file?.type || 'image/jpeg';
  const lastModified = props.file?.lastModified || 0;
  const quality = props.quality;

  const data =
    props.exportType === 'file'
      ? await ImageService.canvasToFile(canvas, { name, type, lastModified, quality })
      : ImageService.canvasToBase64(canvas, { quality, type });

  const res = {
    thumb: await (async (): Promise<File | string | undefined> => {
      if (!props.thumbnailSize) {
        return undefined;
      }

      const thumbCanvas = ImageService.canvasResize(canvas, { size: props.thumbnailSize });

      return props.exportType === 'file'
        ? await ImageService.canvasToFile(thumbCanvas, { name, type, lastModified, quality })
        : ImageService.canvasToBase64(thumbCanvas, { quality, type });
    })(),
  };

  emit('save', { data, name, ...res });

  isProcess.value = false;
};

const getStencilProps = computed(() => ({
  handlers: props.stencilResizable ? undefined : {},
  aspectRatio: props.ratio,
  resizable: props.stencilResizable,
  movable: props.stencilMovable,
}));

const getStencilSize = computed((): { width?: number; height?: number } | null => {
  if (!props.stencilWidth && !props.stencilHeight) {
    return null;
  }

  return {
    width: props.stencilWidth,
    height: props.stencilHeight,
  };
});

watch(
  () => props.src,
  async () => {
    if (!props.autoSave) {
      imgSrc.value = props.src || null;
    }
  },
  { immediate: true }
);

watch(
  () => [cropperRef.value, imgSrc.value],
  async (): Promise<void> => {
    if (!imgSrc.value || props.stencilWidth || props.stencilHeight) {
      return;
    }

    defaultSize.value = await ImageService.imageDimensions(imgSrc.value);
  }
);

onMounted(async () => {
  imgSrc.value = props.src || null;

  if (!props.src && !props.disableAutoUpload) {
    await onUpload();
  }
});

return (_ctx: any,_cache: any) => {
  const _component_vz_button = _resolveComponent("vz-button")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "containerRef",
    ref: containerRef,
    class: "vz-image-cropper d-flex flex-column justify-end",
    style: _normalizeStyle(containerSize.value)
  }, [
    (imgSrc.value)
      ? (_openBlock(), _createBlock(_unref(Cropper), {
          key: 0,
          ref_key: "cropperRef",
          ref: cropperRef,
          class: "vz-image-cropper__preview",
          debounce: "",
          style: _normalizeStyle(previewSize.value),
          "auto-zoom": __props.autoZoom,
          src: imgSrc.value,
          "stencil-props": getStencilProps.value,
          "resize-image": { adjustStencil: __props.stencilResizable },
          "stencil-size": getStencilSize.value,
          "transition-time": 50,
          "default-size": defaultSize.value,
          onChange: onChange
        }, null, 8, ["style", "auto-zoom", "src", "stencil-props", "resize-image", "stencil-size", "default-size"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "vz-image-cropper__preview vz-image-cropper__preview--default",
          style: _normalizeStyle(previewSize.value)
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 4)),
    _createElementVNode("div", {
      class: _normalizeClass(["vz-image-cropper__actions", { 'vz-image-cropper__actions--auto-save': __props.autoSave }])
    }, [
      (imgSrc.value)
        ? (_openBlock(), _createBlock(_component_vz_button, {
            key: 0,
            "icon-size": "1rem",
            "icon-name": "svg:trash",
            onClick: onRemove
          }))
        : (_openBlock(), _createBlock(_component_vz_button, {
            key: 1,
            "icon-name": "svg:plus",
            "icon-size": "1rem",
            onClick: onUpload
          })),
      (!__props.autoSave)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createVNode(_component_vz_button, {
              text: "GENERAL.SAVE",
              onClick: onSave
            }),
            _createVNode(_component_vz_button, {
              text: "GENERAL.CANCEL",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
            })
          ], 64))
        : _createCommentVNode("", true)
    ], 2)
  ], 4))
}
}

})