<template>
  <promotion v-if="isPromotionScreen" />

  <template v-else>
    <top-navbar v-if="!isSplashScreen" />

    <div class="app__container">
      <router-view />
    </div>

    <employee-view-modal />

    <bottom-navbar v-if="!isSplashScreen" />
  </template>

  <vz-popup />

  <terms-of-service-modal v-if="isLoggedIn && !termOfServiceSignAt && !isPartialSignUp" />

  <sign-in-modal v-if="!isLoggedIn && !isSplashScreen" />
  <sign-up-modal v-if="(!isLoggedIn || isPartialSignUp) && !isSplashScreen" />
</template>
<script setup lang="ts">
import '@shared/services/api-service/helpers/register-api-service';
import '@shared/services/api-service/helpers/add-api-interceptors';
import { computed, defineAsyncComponent, onMounted, watch } from 'vue';
import { useAuthUser } from '@/views/employee/composables/use-auth-user';
import { useAsync, useVisualViewport } from '@shared/composables';
import ThemeService from '@shared/services/css-service/theme-service';
import { useRoute } from 'vue-router';
import VzPopup from '@shared/components/popup/vz-popup.vue';
import useAuthStore from '@/views/lobby/modals/login-modal/composables/use-auth-store';
import { LOAD_LOCAL_AUTH_STATE } from '@/store/auth/auth.constants';
import { OPEN_SIGN_UP_MODAL } from '@/views/lobby/constants/lobby-events';
import { emitter } from '@/main';
import EmployeeViewModal from '@/views/lobby/modals/employee-view-modal.component.vue';
import useClientStore from '@/store/client/composables/use-client-store';
import { LIST_CURRENCIES } from '@/store/client/client.constants';

const SignUpModal = defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/views/lobby/modals/login-modal/sign-up-modal.component.vue'));
const SignInModal = defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/views/lobby/modals/login-modal/sign-in-modal.component.vue'));
const TopNavbar = defineAsyncComponent(() => import(/* webpackChunkName: "main" */ '@/views/lobby/components/top-navbar/app-top-navbar.vue'));
const Promotion = defineAsyncComponent(() => import(/* webpackChunkName: "lobby" */ '@/views/promotion/promotion.view.vue'));
const BottomNavbar = defineAsyncComponent(
  () => import(/* webpackChunkName: "main" */ '@/views/lobby/components/bottom-navbar/app-bottom-navbar.vue')
);

const TermsOfServiceModal = defineAsyncComponent(() => import('@/components/terms-of-service-modal.vue'));

const route = useRoute();

const { isLoggedIn, isImpersonate, termOfServiceSignAt, isPartialSignUp } = useAuthUser();
const { [LOAD_LOCAL_AUTH_STATE]: loadLocalAuthState } = useAuthStore().useMutations([LOAD_LOCAL_AUTH_STATE]);
const { [LIST_CURRENCIES]: listCurrenciesAction } = useClientStore().useActions([LIST_CURRENCIES]);

const isSplashScreen = computed(() => route.meta.isSplashScreen);
const isPromotionScreen = computed(() => !isLoggedIn.value && !isSplashScreen.value && !route.meta.isUnprotected);
const listCurrenciesRequest = useAsync(listCurrenciesAction);

onMounted(() => {
  useVisualViewport();
  loadLocalAuthState();
});

onMounted(() => {
  if (isPartialSignUp.value) {
    emitter.emit(OPEN_SIGN_UP_MODAL);
  }

  listCurrenciesRequest.call();
});

watch(
  () => isImpersonate.value,
  (newValue) => {
    ThemeService.set(newValue ? { name: 'warning' } : { name: 'default' });
  },
  { immediate: true }
);
</script>

<style scoped lang="scss">
.app {
  &___container {
    height: var(--container-height);
  }
}
</style>
