export const useVisualViewport = () => {
  let maxHeight: number = 0;
  let height: number | undefined = window.visualViewport?.height;
  let width: number | undefined = window.visualViewport?.width;

  const scrollToView = (element: HTMLElement): void => {
    setTimeout(() => element.scrollIntoView({ block: 'end', behavior: 'auto' }), 100);
  };

  const onResize = () =>
    setTimeout((): void => {
      const visualViewport = window.visualViewport!;
      height = visualViewport.height;
      width = visualViewport.width;
      maxHeight = Math.max(visualViewport.height, maxHeight);

      document.getElementById('visual-viewport')?.remove();
      const visualViewportStyle = document.createElement('style');
      visualViewportStyle.id = 'visual-viewport';
      visualViewportStyle.innerHTML = `:root {--viewport-height: ${height}px; --viewport-width: ${width}px;}`;
      document.head.append(visualViewportStyle);

      const container: HTMLElement | null = document.querySelector('body');

      if (!container) {
        return;
      }

      if (height < maxHeight) {
        container.setAttribute('screen', 'minimal');
        container.setAttribute('style', `height:${Math.floor(height)}px`);
        container.addEventListener('click', () => scrollToView(container));
        container.style.height = `${Math.floor(height)}px`;

        scrollToView(container);
      } else {
        container.removeAttribute('screen');
        container.removeAttribute('style');
        container.removeEventListener('click', () => scrollToView(container));
        container.style.height = '';
      }

      window.scrollTo(0, 0);
    });

  if (!window.visualViewport) {
    return;
  }

  maxHeight = window.visualViewport.height;
  window.visualViewport.removeEventListener('resize', onResize);
  window.visualViewport.addEventListener('resize', onResize);

  onResize();
};
