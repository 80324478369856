import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["data-errors"]
const _hoisted_2 = {
  key: 0,
  class: "d-flex gap-4 justify-center flex-ch-1"
}
const _hoisted_3 = {
  key: 1,
  class: "d-flex gap-4 flex-ch-1"
}
const _hoisted_4 = { key: 1 }

import { computed, type PropType } from 'vue';
import dayjs from 'dayjs';
import { useTranslator } from '@/plugins/i18n/helpers';
import { useValidator } from '@shared/components/fields/helpers';
import { GlobalVariables } from '@shared/constants';
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-combo-datepicker',
  props: {
  hideDate: { type: Boolean, default: false },
  hideTime: { type: Boolean, default: false },
  hideDetails: { type: Boolean, default: false },
  noTime: { type: Boolean, default: false },
  singleDay: { type: Boolean, default: false },
  initTimeFrom: { type: String as PropType<`${string}:${string}` | undefined>, default: undefined },
  initTimeTo: { type: String as PropType<`${string}:${string}` | undefined>, default: undefined },
  dateFrom: { type: Number as PropType<number | null | undefined>, required: true },
  dateTo: { type: Number as PropType<number | null | undefined>, required: true },
  errorMessage: { type: String as PropType<string | null>, default: null },
  rules: { type: Object as PropType<ValidatorFieldRules>, default: () => ({}) },
},
  emits: ['update:date-from', 'update:date-to'],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const t = useTranslator();

const getInitDateAndTime = (key: 'initTimeFrom' | 'initTimeTo') => {
  const [hours, minutes] = props[key]?.split(':')?.map((val) => +val) || [0, 0];
  const date = new Date().setHours(hours, minutes, 0, 0);

  return date.valueOf();
};

const vDateFrom = computed({
  get: (): number => (props.dateFrom ? props.dateFrom : getInitDateAndTime('initTimeFrom')),
  set: (value) => {
    if (props.noTime) {
      new Date(value).setHours(0, 0, 0, 0);
    }

    if (props.singleDay) {
      vDateTo.value = new Date(value).setHours(new Date(vDateTo.value).getHours());
    }

    emit('update:date-from', value.valueOf());
  },
});

const vDateTo = computed({
  get: (): number => (props.dateTo ? props.dateTo : getInitDateAndTime('initTimeTo')),
  set: (value) => emit('update:date-to', value.valueOf()),
});

const { validateMessage: validateMessage1, isTouched: isTouched1 } = useValidator(
  computed(() => props.dateFrom),
  computed(() => {
    return {
      ...props.rules,
      max: [
        vDateTo.value,
        props.singleDay
          ? t('VALIDATE.TIME_FROM', { time: dayjs(vDateTo.value).format('H:mm') })
          : t('VALIDATE.DATE_FROM', { date: dayjs(vDateTo.value).format(GlobalVariables.DAY_MONTH_YEAR_TIME_FORMAT) }),
      ],
    };
  }),
  'dateFrom'
);

const { validateMessage: validateMessage2, isTouched: isTouched2 } = useValidator(
  computed(() => props.dateTo),
  computed(() => {
    return {
      ...props.rules,
      min: [
        vDateFrom.value,
        props.singleDay
          ? t('VALIDATE.TIME_TO', { time: dayjs(vDateFrom.value).format('H:mm') })
          : t('VALIDATE.DATE_TO', { date: dayjs(vDateFrom.value).format(GlobalVariables.DAY_MONTH_YEAR_TIME_FORMAT) }),
      ],
    };
  }),
  'dateTo'
);

const isTouched = computed(() => isTouched1.value || isTouched2.value);
const validateMessage = computed(() => [validateMessage1.value, validateMessage2.value].filter((val) => val).join(` ${t('GENERAL.OR')} `));

return (_ctx: any,_cache: any) => {
  const _component_vz_calendar = _resolveComponent("vz-calendar")!
  const _component_vz_datepicker = _resolveComponent("vz-datepicker")!

  return (_openBlock(), _createElementBlock("div", {
    class: "d-flex flex-column gap-4",
    "data-errors": validateMessage.value
  }, [
    (!__props.hideDate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_vz_calendar, {
            modelValue: vDateFrom.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((vDateFrom).value = $event)),
            class: "aspect-1-1 max-width-320",
            "hide-details": ""
          }, null, 8, ["modelValue"]),
          (!__props.singleDay)
            ? (_openBlock(), _createBlock(_component_vz_calendar, {
                key: 0,
                modelValue: vDateTo.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((vDateTo).value = $event)),
                class: "aspect-1-1 max-width-320",
                "hide-details": ""
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (!__props.hideTime)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_vz_datepicker, {
            modelValue: vDateFrom.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((vDateFrom).value = $event)),
            "hide-details": "",
            type: "time",
            label: "GENERAL.TIME_FROM"
          }, null, 8, ["modelValue"]),
          _createVNode(_component_vz_datepicker, {
            modelValue: vDateTo.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((vDateTo).value = $event)),
            "hide-details": "",
            type: "time",
            label: "GENERAL.TIME_TO"
          }, null, 8, ["modelValue"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(['vz-datepicker__error', { 'vz-datepicker__error--hidden': __props.hideDetails }])
    }, [
      (validateMessage.value)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass({ 'vz-datepicker__error-internal': !isTouched.value })
          }, _toDisplayString(_ctx.$t(validateMessage.value)), 3))
        : (__props.errorMessage)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.$t(__props.errorMessage)), 1))
          : _createCommentVNode("", true)
    ], 2)
  ], 8, _hoisted_1))
}
}

})