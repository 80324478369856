<template>
  <vz-select
    v-model="vModel"
    fixed
    debounce="500"
    type="select"
    item-id-key="key"
    :item-text="['title', 'address', 'place']"
    :rules="rules"
    :items="listPlacesRequest.results.value || []"
    :loading="listPlacesRequest.loading.value"
    :error-message="errorMessage"
    v-bind="$attrs"
    @search="listPlacesRequest.call"
  />
</template>

<script setup lang="ts">
import type { BaseAddress, BaseOptions } from '@shared/models';
import type { ErrorResponse } from '@shared/services/api-service/models';
import { computed, onMounted, type PropType } from 'vue';
import useClientStore from '@/store/client/composables/use-client-store';
import { LIST_ADDRESSES } from '@/store/client/client.constants';
import { useAsync } from '@shared/composables';
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';

const props = defineProps({
  itemText: { type: String as PropType<keyof BaseAddress>, default: 'title' },
  modelValue: { type: Object as PropType<any | Array<any>>, required: true },
  errorMessage: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
});

const emit = defineEmits(['update:model-value']);

const vModel = computed({
  get: (): BaseAddress | null => props.modelValue || null,
  set: (value) => emit('update:model-value', value),
});

const { useActions } = useClientStore();
const { [LIST_ADDRESSES]: listPlacesAction } = useActions([LIST_ADDRESSES]);

const listPlacesRequest = useAsync<BaseOptions<BaseAddress>>(listPlacesAction as (query: string) => Promise<BaseOptions<BaseAddress>>, {
  initState: { results: (!props.modelValue || Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]) || null },
});

onMounted(() => {
  listPlacesRequest.results.value = (!props.modelValue || Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]) || null;
});
</script>
