<template>
  <button
    :class="['vz-button', `vz-button--${type}`, { [`vz-button--${type}--background`]: backgroundColor, 'vz-button--action': callToAction }]"
    role="button"
    :style="style"
    :disabled="disabled || loading"
    :aria-label="t('COMPONENT_LABELS.BUTTON', { value: ariaLabel || label || '' })"
    @click="onClick"
  >
    <div class="d-flex align-center justify-center">
      <vz-spinner v-if="loading" class="vz-button__loader" :aria-label="ariaLabel || label" />

      <div :class="['vz-button__content', { 'vz-button__content--uppercase': uppercase, 'vz-button__content--loading': loading }]">
        <vz-icon v-if="iconName" :clickable="!disabled" :name="iconName" :color="iconColor" :size="iconSize" :type="iconType" />

        <slot>
          <span v-if="text" :class="['vz-button__content-text text-ellipsis', { 'vz-button__content-text--minimizable': minimizable }]">
            {{ $t(text) }}
          </span>
        </slot>
      </div>
    </div>
  </button>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import { type ColorsMap } from '@/shared/services/css-service/types/colors';
import type { IconName, IconType } from '@/shared/components/icon/icon.type';
import { useTranslator } from '@/plugins/i18n/helpers';
import { SizeUnit } from '@shared/types';
import getThemeColor from '@shared/services/css-service/helpers/get-theme-color';

const props = defineProps({
  callToAction: { type: Boolean, default: false },
  active: { type: Boolean, default: false },
  color: { type: String as PropType<ColorsMap | string | undefined>, default: 'primary-900' },
  backgroundColor: { type: String as PropType<ColorsMap | string | undefined>, default: undefined },
  type: { type: String as PropType<'default' | 'flat' | 'rounded' | 'solid'>, default: 'solid' },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  minimizable: { type: Boolean, default: false },
  label: { type: String, default: '' },
  ariaLabel: { type: String, default: '' },
  text: { type: String, default: '' },
  iconColor: { type: String as PropType<ColorsMap | string | undefined>, default: undefined },
  iconName: { type: String as PropType<IconName | undefined>, default: undefined },
  iconSize: { type: String as PropType<SizeUnit | undefined>, default: undefined },
  iconType: { type: String as PropType<IconType>, default: 'solid' },
  uppercase: { type: Boolean, default: true },
});

const emit = defineEmits(['click']);

const t = useTranslator();

const style = computed(() => ({
  ...(props.backgroundColor ? { backgroundColor: getThemeColor(props.backgroundColor) } : {}),
  ...(props.color ? { color: getThemeColor(props.color) } : {}),
}));

const onClick = (ev: Event): void => {
  if (!props.disabled && !props.loading) {
    emit('click', ev);
  }
};
</script>

<style lang="scss">
@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}

.vz-button {
  position: relative;
  border-radius: var(--border-radius-regular);
  width: fit-content;
  height: fit-content;

  &--action {
    animation: flickerAnimation 1s infinite;
  }

  &__loader {
    left: 50%;
    transform: translateX(-50%);
    position: absolute !important;
    height: 100% !important;
  }

  &:disabled {
    cursor: initial;

    .vz-button__content {
      &:not(&--loading) {
        opacity: 0.3 !important;
      }
    }
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    > *:not(:last-child) {
      margin-inline-end: 4px;
    }

    &--loading {
      opacity: 0;
    }

    &--uppercase {
      text-transform: uppercase;
    }

    &-text {
      &--minimizable {
        @include max-mobile-layout {
          display: none;
        }
      }
    }

    @include mobile-layout {
      overflow: hidden;

      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      span.vz-button__content-text--minimizable {
        display: none;
      }
    }
  }

  &--solid {
    position: relative;
    transition: background-color 0.3s;
    padding: 0.25rem 0.5rem;
    box-shadow: var(--shadow-light);
    border: 1px solid currentColor;

    &:not(&--background) {
      @include before {
        border-radius: var(--border-radius-regular);
        background-color: currentColor;
        opacity: 0.05;
        transition: opacity 0.3s;
      }

      @include after {
        opacity: 0.125;
        border-radius: var(--border-radius-regular);
      }
    }

    &:hover:not(:disabled) {
      background-color: transparent;

      &:before {
        opacity: 0.2;
      }

      &:after {
        opacity: 1;
      }
    }
  }

  &--default {
    position: relative;
    transition:
      background-color 0.3s,
      color 0.2s;
    padding: 2px 8px;

    @include before {
      background-color: currentColor;
      border-radius: var(--border-radius-regular);
      opacity: 0;
      transition: opacity 0.3s;
    }

    @include after {
      border: 1px solid currentColor;
      border-radius: var(--border-radius-regular);
    }

    &:hover:not(:disabled) {
      &:before {
        opacity: 0.15;
      }
    }
  }

  &--rounded {
    border-radius: 50%;
    transition: background-color 0.3s;
    overflow: hidden;
    padding: 0.5rem;
    background-color: var(--color-background-regular);
    box-shadow: var(--shadow-light);

    * {
      margin: 0;
    }
  }

  &--flat {
    padding: 0.25rem 0.5rem;
    height: max-content !important;
    background-color: transparent !important;

    &:hover:not(:disabled) {
      text-decoration: underline;
    }
  }
}
</style>
