<template>
  <div v-if="errors?.errorMessage?.length" :class="['vz-error-alert', { 'vz-error-alert--flat': flat }]">
    <template v-for="({ message, ...rest }, index) in errors.errorMessage">
      <div v-if="!maxShown || index < +maxShown" :key="index" class="vz-error-alert__message">
        <vz-icon class="pe-1" name="svg:warning" color="red-600" size="1.5rem" />

        <span>{{ t(message, { ...rest }) }}</span>

        <span v-if="hasMore && index === +maxShown - 1" class="ms-1">(+{{ errors.errorMessage.length - +maxShown }})</span>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { ErrorResponse } from '@/shared/services/api-service/models';
import { computed, type PropType } from 'vue';
import { useTranslator } from '@/plugins/i18n/helpers';

const t = useTranslator();

const props = defineProps({
  flat: { type: Boolean, default: false },
  errors: { type: Object as PropType<ErrorResponse | null>, default: () => null },
  maxShown: { type: [Number, String], default: 1 },
});

const hasMore = computed(() => {
  return props.maxShown && (props.errors?.errorMessage?.length || 0) - +props.maxShown > 0;
});
</script>

<style lang="scss">
.vz-error-alert {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0.125rem;
  border-radius: var(--border-radius-regular);

  &:not(&--flat) {
    border: var(--outline-error);
  }

  &__message {
    display: flex;
    align-content: center;
    color: var(--color-red-600);
    font-weight: 500;
  }
}
</style>
