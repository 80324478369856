import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "vz-json-viewer" }
const _hoisted_2 = {
  key: 0,
  class: "text-ellipsis"
}

import { computed, type PropType, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-json-viewer',
  props: {
  autoTab: { type: Boolean, default: false },
  label: { type: String, default: '' },
  value: { type: Object as PropType<{ [key: string]: any } | undefined>, default: undefined },
},
  setup(__props) {

const props = __props;

const activeKey = ref<string | null>(props.autoTab ? Object.keys(props.value || {})[0] : null);

const json = computed(() => {
  const content = activeKey.value ? props.value?.[activeKey.value] : props.value;

  return JSON.stringify(content, null, 2);
});

const tabs = computed(() => {
  if (!props.autoTab) {
    return [];
  }

  return Object.keys(props.value || {}).map((key) => ({
    name: key,
    label: key,
  }));
});

const copyToClipboard = (): void => {
  navigator.clipboard.writeText(json.value);
};

return (_ctx: any,_cache: any) => {
  const _component_vz_button = _resolveComponent("vz-button")!
  const _component_vz_tab_switcher = _resolveComponent("vz-tab-switcher")!
  const _component_json_viewer = _resolveComponent("json-viewer")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.$t(__props.label)), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_vz_tab_switcher, {
      fit: "",
      flat: "",
      tabs: tabs.value,
      "onUpdate:name": _cache[0] || (_cache[0] = ($event: any) => (activeKey.value = $event))
    }, {
      append: _withCtx(() => [
        _withDirectives(_createVNode(_component_vz_button, {
          type: "flat",
          "icon-name": "svg:copy",
          onClick: copyToClipboard
        }, null, 512), [
          [_directive_tooltip, _ctx.$t('GENERAL.COPY')]
        ])
      ]),
      _: 1
    }, 8, ["tabs"]),
    _createVNode(_component_json_viewer, {
      "json-data": json.value,
      dark: false
    }, null, 8, ["json-data"])
  ]))
}
}

})