import { computed, ref } from 'vue';

const stack = ref<Array<string>>([]);

export const useOverlayState = (key: string) => {
  const set = (overlayId: string = key) => stack.value.push(overlayId);
  const unset = (overlayId: string = key) => stack.value.splice(stack.value.indexOf(overlayId), 1);

  return {
    set,
    unset,
    stack: computed((): Array<string> => stack.value),
    active: computed((): string => stack.value[stack.value.length - 1]),
  };
};
