import type { RouteRecordRaw } from 'vue-router';
import Modules from '@/router/constants/modules';
import postJobRoutes from '@/views/job/views/manager/modules/post';
import projectManagerRoutes from 'src/views/job/views/manager/modules/project';
import groupManagerRoutes from '@/views/job/views/manager/modules/group';
import permissionManagerRoutes from 'src/views/job/views/manager/modules/assignee';
import templateManagerRoutes from '@/views/job/views/manager/modules/template';

const JobManager = () => import(/* webpackChunkName: "job-manager" */ '@/views/job/views/manager/job-manager.view.vue');

const Calendar = () => import(/* webpackChunkName: "calendar" */ '@/views/calendar/views/calendar-grid.view.vue');

const jobManagerRoutes: Readonly<RouteRecordRaw> = {
  path: Modules.MANAGER.JOB_MANAGER_POST,
  name: Modules.MANAGER.JOB_MANAGER,
  redirect: { name: Modules.MANAGER.JOB_MANAGER_POST },
  component: JobManager,
  meta: {
    parentName: Modules.JOBS,
  },
  children: [
    {
      path: Modules.PATHS[Modules.MANAGER.JOB_CALENDAR],
      name: Modules.MANAGER.JOB_CALENDAR,
      component: Calendar,
      meta: {
        parentName: Modules.MANAGER.JOB_MANAGER,
      },
    },
    postJobRoutes,
    projectManagerRoutes,
    groupManagerRoutes,
    permissionManagerRoutes,
    templateManagerRoutes,
  ],
};

export default jobManagerRoutes;
