<template>
  <div class="ms-auto">
    <div class="text-title-1">{{ day }}</div>
    <div class="text-title-1">{{ date }}</div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';

const day = ref('');
const date = ref('');

onMounted(() => {
  const today = new Date();

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  // Ambil hari dan tanggal hari ini
  day.value = days[today.getDay()]; // Mendapatkan hari (misal: Tuesday)
  const dayOfMonth = String(today.getDate()).padStart(2, '0'); // Mendapatkan tanggal dengan dua digit
  const month = months[today.getMonth()]; // Mendapatkan nama bulan
  const year = today.getFullYear(); // Mendapatkan tahun

  // Formatkan tanggal (misal: 01 October, 2024)
  date.value = `${dayOfMonth} ${month}, ${year}`;
});
</script>
