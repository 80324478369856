import type { BasePhone } from '@shared/models';

export const phonePrettier = ({ number, prefix }: Partial<BasePhone>) => {
  if (!number) {
    return '';
  }

  const initialPart = number.slice(0, 2); // Extract the first two digits
  const numStr = number.slice(2).toString();
  let formattedNumber = '';

  let i = 0;
  while (i < numStr.length) {
    const remainingLength = numStr.length - i;

    if (remainingLength <= 4) {
      formattedNumber += numStr.slice(i);
      break;
    } else if (remainingLength === 3) {
      formattedNumber += numStr.slice(i, i + 2) + '-' + numStr.slice(i + 2);
      break;
    } else {
      formattedNumber += numStr.slice(i, i + 3) + '-';
      i += 3;
    }
  }

  return '+' + [prefix, initialPart, formattedNumber].filter(Boolean).join('-');
};
