<template>
  <vz-badge class="vz-status-badge" v-bind="{ ...$attrs, ...(override || bind) }" :prefix="prefix" />
</template>

<script setup lang="ts">
import type { ColorsMap } from '@shared/services/css-service/types';
import { computed, type PropType } from 'vue';
import { StatusEnum } from '@/constants';

const props = defineProps({
  status: { type: String as PropType<keyof typeof StatusEnum>, required: true },
  prefix: { type: String as PropType<string | undefined>, default: undefined },
  override: { type: Object as PropType<{ text: string; color?: ColorsMap | string } | undefined>, default: undefined },
});

const bind = computed(() => {
  switch (props.status) {
    case StatusEnum.PENDING:
      return {
        text: 'STATUS.PENDING',
        color: 'red-600',
      };
    case StatusEnum.REJECTED:
      return {
        text: 'STATUS.REJECTED',
        color: 'red-900',
      };
    case StatusEnum.APPROVED:
      return {
        text: 'STATUS.APPROVED',
        color: 'green-700',
      };
    case StatusEnum.EXPIRED:
      return {
        text: 'STATUS.EXPIRED',
        color: 'yellow-900',
      };
    default:
      return {
        text: 'GENERAL.DRAFT',
        color: 'mono-600',
      };
  }
});
</script>

<style lang="scss">
.vz-status-badge {
  width: fit-content;

  @include after() {
    border: 1px solid currentColor;
    border-radius: var(--border-radius-regular);
  }
}
</style>
