import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label"]

import type { PropType } from 'vue';
import type { SizeUnit } from '@shared/types';
import { useTranslator } from '@/plugins/i18n/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-spinner',
  props: {
  loading: { type: Boolean, default: true },
  ariaLabel: { type: String, default: 'GENERAL.LOADING' },
  size: { type: String as PropType<SizeUnit | undefined>, default: undefined },
},
  setup(__props) {



const t = useTranslator();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "vz-spinner",
    "aria-label": _unref(t)('COMPONENT_LABELS.LOADING', { value: __props.ariaLabel }),
    style: _normalizeStyle(__props.size ? { height: __props.size, width: __props.size } : {})
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 12, _hoisted_1))
}
}

})