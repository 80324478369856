import type { AuthState } from '@/store/auth';
import LocalStorageService from '@shared/services/local-storage.service';
import { SESSION } from '@/store/auth/auth.constants';
import { uniqueKey } from '@shared/helpers';

const save = (state: Partial<AuthState>): void => {
  const key = uniqueKey(state[SESSION]?._id);
  LocalStorageService.save('_key', key);
  LocalStorageService.save('_auth', state, key);
};

const load = (): Partial<AuthState> => {
  const key = LocalStorageService.load<string>('_key');

  return LocalStorageService.load<Partial<AuthState>>('_auth', key) || {};
};

const check = (): boolean => !!LocalStorageService.load('_key');

export default { save, load, check };
