<template>
  <div
    :class="['vz-select', { 'vz-select--loading': loading, 'vz-select--disabled': disabled }, `vz-select-${inputId}`]"
    :data-errors="validateMessage"
  >
    <label v-if="label" class="text-ellipsis">{{ $t(label) }}</label>

    <div class="vz-select__container" @click="onClick">
      <slot name="prefix" />

      <template v-if="!$slots['badge'] && (vModel || []).length">
        <div
          v-if="!loading"
          :class="{ 'vz-select__container-multiple': multiple, 'vz-select__container-select': !multiple }"
          @click="inputRef?.focus()"
        >
          <slot v-for="(selected, index) in vModel" :key="index" :name="$slots['selection'] ? 'selection' : 'item'" :item="selected">
            <span>{{ getTitle(selected) }}</span>
            <span v-if="index < vModel.length - 1" class="me-1">,</span>
          </slot>
        </div>

        <div v-if="vModel.length > 1" class="vz-select__container-multiple-count" @click="inputRef?.focus()">({{ vModel.length }})</div>
      </template>

      <input
        ref="inputRef"
        v-model="searchValue"
        type="text"
        tabindex="0"
        :readonly="readonly"
        :placeholder="!vModel?.length ? $t(placeholder) : undefined"
        :disabled="disabled"
        :aria-label="t(`COMPONENT_LABELS.${multiple ? 'AUTOCOMPLETE_FIELD' : 'SELECT_FIELD'}`, { value: ariaLabel || label || placeholder })"
        @keydown="onQuerySearch"
        @focus="onFocus"
        @blur="onBlur"
        @input="onInput"
      />

      <vz-icon
        v-if="clearable && isClearable"
        role="button"
        name="svg:xmark"
        size="0.75rem"
        color="primary-900"
        :clickable="!disabled"
        :aria-label="t('COMPONENT_LABELS.BUTTON', { value: 'GENERAL.CLEAR' })"
        @click="$emit('update:model-value', undefined)"
      />

      <slot name="append" />
    </div>

    <template v-if="(isListShown || isForceOpen) && !loading">
      <div class="vz-select__list" :style="{ top: `${top}px` }">
        <div
          class="vz-select__list-container"
          role="list"
          :style="{ position: 'fixed', width: width + 'px', maxHeight: `calc(${maxHeight}px - 3rem)` }"
        >
          <div v-if="loading">{{ $t('GENERAL.LOADING') }}</div>

          <div v-else-if="!itemList?.length">{{ $t(noDataText) }}</div>

          <template v-else>
            <div
              v-for="(item, index) in itemList"
              :key="index"
              class="vz-select__list-item"
              :class="{
                [`vz-select__list-item-${index}`]: true,
                'vz-select__list-item--active': selectedIndex === index,
                'vz-select__list-item--selected': multiple && isItemSelected(item),
              }"
              role="listitem"
              @click="onSelectFromList(item)"
            >
              <slot name="item" :item="item" :label="getTitle(item)">
                {{ getTitle(item) }}
              </slot>
            </div>
          </template>
        </div>
      </div>
    </template>

    <div
      v-show="!$slots['badge'] || validateMessage || externalError"
      :class="['vz-select__error', { 'vz-select__error--hidden': hideDetails }]"
      role="alert"
    >
      <p v-if="validateMessage" :class="{ 'vz-select__error-internal': !isTouched }">{{ $t(validateMessage) }}</p>
      <p v-else-if="externalError">{{ $t(externalError) }}</p>
    </div>

    <div v-if="$slots['badge'] && multiple" class="vz-select__badge">
      <slot
        v-for="(item, index) in selectionValueModal(modelValue)"
        :key="index"
        name="badge"
        :item="item"
        :text="item"
        :value="getValue(item)"
        :on-clear="() => onClearSelectedItem(index)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { BaseOption } from '@/shared/models';
import type { ItemFunctionArg } from '@/shared/components/fields/vz-select/models';
import type { ErrorResponse } from '@/shared/services/api-service/models';
import { computed, nextTick, onMounted, type PropType, ref, useSlots, watch } from 'vue';
import { scrollToView, uniqueKey } from '@/shared/helpers';
import { useValidator } from '@/shared/components/fields/helpers';
import { useGetItemText, useGetItemValue, useItemValuesRevert } from '@/shared/components/fields/vz-select/helpers';
import { useTranslator } from '@/plugins/i18n/helpers';

const props = defineProps({
  name: { type: String as PropType<string | undefined>, default: undefined },
  multiple: { type: Boolean, default: false },
  modelValue: {
    type: [Object, Number, String, Array] as PropType<any | Array<any> | Record<any, any> | undefined | null>,
    required: true,
  },
  autoOpen: { type: Boolean, default: false },
  autoCloseOnSelect: { type: Boolean as PropType<boolean | undefined>, default: undefined },
  label: { type: String, default: '' },
  ariaLabel: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  debounce: { type: [String, Number], default: 0 },
  items: { type: Array as PropType<Array<any> | undefined | null>, default: undefined },
  itemIdKey: { type: String as PropType<string | undefined>, default: undefined },
  itemText: { type: [Function, String, Array] as PropType<((item: ItemFunctionArg) => any) | string | Array<string> | null>, default: 'title' },
  itemValue: { type: [Function, String] as PropType<((item: ItemFunctionArg) => any) | string>, default: 'value' },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  onlyFromList: { type: Boolean, default: false },
  clearable: { type: Boolean, default: true },
  hideDetails: { type: Boolean, default: false },
  errorMessage: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  noDataText: { type: String, default: 'DATA.NO_DATA_AVAILABLE' },
  textManipulationCallback: { type: Function as PropType<(item: any) => any>, default: (item: any) => item },
  fieldErrors: { type: Object as PropType<Record<string, string> | null>, default: null },
});
const emit = defineEmits(['update:model-value', 'search']);
const t = useTranslator();
const slots = useSlots();

const inputId = uniqueKey(props.label);
const isFocus = ref<boolean>(false);
const blurTimeout = ref<ReturnType<typeof setTimeout>>();
const debounceTimeout = ref<ReturnType<typeof setTimeout>>(0);
const inputRef = ref<HTMLInputElement | undefined>(undefined);
const searchValue = ref<string | null>(null);
const selectedIndex = ref<number>(0);
const isForceOpen = ref<boolean>(false);
const isListShown = ref<boolean>(false);
const isAutoCloseOnSelect = ref<boolean>(false);

const onClick = (): void => {
  if (!props.multiple && !props.readonly && !props.disabled) {
    isForceOpen.value = true;
  }
};

const autoCompleteShown = computed(
  (): boolean =>
    !!props.items && isFocus.value && !isSelected.value && (!!searchValue.value || (!searchValue.value && props.autoOpen) || !!slots['badge'])
);

const optionsList = computed(() =>
  (props.items || []).map((item: BaseOption | string) => (typeof item === 'string' ? { title: t(item), value: item.split('.').pop() } : item))
);
const { validateMessage, isTouched } = useValidator(
  computed(() => props.modelValue),
  computed(() => props.rules),
  props.name || props.label
);

const getTitle = useGetItemText(props.itemText, props.textManipulationCallback);
const getValue = useGetItemValue(props.itemValue);

const selectionValueModal = useItemValuesRevert(
  computed(() => optionsList.value || []),
  props.multiple,
  getValue,
  getTitle,
  props.itemIdKey
);

const vModel = computed({
  get: (): any => selectionValueModal(props.modelValue),
  set: (value) => emit('update:model-value', value),
});

const externalError = computed(() => {
  if (props.fieldErrors && props.name && props.fieldErrors[props.name]) {
    return props.fieldErrors[props.name];
  }

  if (!props.errorMessage) {
    return;
  }

  if (typeof props.errorMessage === 'string') {
    return props.errorMessage;
  }

  const { message, ...fields } = props.errorMessage.errorMessage!.pop() || {};

  return message ? t(message, { ...fields, ...(props.label ? { property: props.label } : {}) }) : undefined;
});
const isSelected = computed((): boolean => !!vModel.value?.length && !props.multiple);
const isClearable = computed(
  () => (props.multiple ? !!((props.modelValue as Array<any>) || []).length : !!props.modelValue) && !props.readonly && !props.disabled
);

const itemList = computed((): Array<any> => {
  const value = searchValue.value;
  const regExp = new RegExp(`(${value})`, 'gi');

  return value
    ? optionsList.value?.filter((item) => {
        const text = getTitle(item);

        return (typeof text === 'string' ? text : JSON.stringify(item)).match(regExp);
      })
    : optionsList.value;
});

const top = ref<number>(0);
const width = ref<number>(0);
const maxHeight = ref<number>(0);

const debounce = (value: string | null) => {
  clearTimeout(debounceTimeout.value);

  debounceTimeout.value = setTimeout(() => {
    emit('search', value);
    inputRef.value?.focus();
  }, +props.debounce);
};

const isItemSelected = (value: any): boolean => {
  return !!(Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]).find(
    (item) => JSON.stringify(getValue(value)) === JSON.stringify(getValue(item))
  );
};

const onFocus = (): void => {
  if (blurTimeout.value) {
    clearTimeout(blurTimeout.value);
  }

  setTimeout(() => {
    let element = inputRef.value?.parentElement;

    while (element && !element.scrollTop) {
      element = element.parentElement;
    }

    const visualViewport = window.visualViewport!;
    const container = inputRef.value?.parentElement;
    const { top: boundingTop = 0, height: boundingHeight = 0 } = container?.getBoundingClientRect() || {};
    top.value = boundingHeight + (props.hideDetails ? 2 : 32) - (element?.scrollTop || 0);
    maxHeight.value = Math.max((visualViewport.height || window.innerHeight) - (boundingTop + boundingHeight + 32), 96);
    width.value = container?.getBoundingClientRect().width || 200;
    isFocus.value = true;
  }, 250);
};

const onBlur = (): void => {
  blurTimeout.value = setTimeout(() => {
    searchValue.value = null;
    isFocus.value = false;
    isForceOpen.value = false;
  }, 250);
};

const onInput = (): void => debounce(searchValue.value);

const onQuerySearch = (ev: KeyboardEvent): void => {
  switch (ev.key) {
    case 'Backspace':
      if (!!searchValue.value?.length || slots['badge']) {
        return;
      } else if (Array.isArray(props.modelValue)) {
        emit('update:model-value', props.modelValue.slice(0, -1));
      } else {
        emit('update:model-value', null);
      }
      break;
    case 'ArrowDown':
      selectedIndex.value = Math.min(selectedIndex.value + 1, (itemList.value?.length || 0) - 1);
      scrollToView(`.vz-select__list-item-${selectedIndex.value}`);
      ev.preventDefault();
      break;
    case 'ArrowUp':
      selectedIndex.value = Math.max(selectedIndex.value - 1, 0);
      scrollToView(`.vz-select__list-item-${selectedIndex.value}`);
      ev.preventDefault();
      break;
    case 'Enter':
      if (autoCompleteShown.value) {
        onSelectFromList(itemList.value![selectedIndex.value]);
      }
      ev.preventDefault();
      break;
    case 'Escape':
      inputRef.value?.blur();
      ev.preventDefault();
      break;
    default:
      selectedIndex.value = 0;
      scrollToView(`.vz-select__list-item-${selectedIndex.value}`);
      break;
  }
};

const onSelectFromList = (item: Record<string, any>): void => {
  if (!isAutoCloseOnSelect.value && !slots['badge']) {
    inputRef.value?.focus();
  } else {
    inputRef.value?.blur();
    isForceOpen.value = false;
  }

  if (props.multiple) {
    searchValue.value = null;
    emit(
      'update:model-value',
      ((props.modelValue as Array<any>) || []).includes(getValue(item))
        ? (props.modelValue as Array<any>).filter((currentItem) => currentItem !== getValue(item))
        : [...((props.modelValue as Array<any>) || []), getValue(item)]
    );
  } else {
    searchValue.value = null;
    emit('update:model-value', getValue(item));
  }
};

const onClearSelectedItem = (index: number) => {
  if (!props.multiple) {
    return;
  }

  emit(
    'update:model-value',
    (props.modelValue as Array<any>).filter((_, itemIndex) => itemIndex !== index)
  );
};

onMounted(() => {
  isAutoCloseOnSelect.value = !props.multiple || !!props.autoCloseOnSelect;
});

watch(
  () => autoCompleteShown.value,
  (value) => nextTick(() => (isListShown.value = value)),
  { immediate: true }
);
</script>

<style lang="scss" src="./vz-select.scss" />
