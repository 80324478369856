import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import type { ColorsMap } from '@shared/services/css-service/types';
import { computed, type PropType } from 'vue';
import { StatusEnum } from '@/constants';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-status-badge',
  props: {
  status: { type: String as PropType<keyof typeof StatusEnum>, required: true },
  prefix: { type: String as PropType<string | undefined>, default: undefined },
  override: { type: Object as PropType<{ text: string; color?: ColorsMap | string } | undefined>, default: undefined },
},
  setup(__props) {

const props = __props;

const bind = computed(() => {
  switch (props.status) {
    case StatusEnum.PENDING:
      return {
        text: 'STATUS.PENDING',
        color: 'red-600',
      };
    case StatusEnum.REJECTED:
      return {
        text: 'STATUS.REJECTED',
        color: 'red-900',
      };
    case StatusEnum.APPROVED:
      return {
        text: 'STATUS.APPROVED',
        color: 'green-700',
      };
    case StatusEnum.EXPIRED:
      return {
        text: 'STATUS.EXPIRED',
        color: 'yellow-900',
      };
    default:
      return {
        text: 'GENERAL.DRAFT',
        color: 'mono-600',
      };
  }
});

return (_ctx: any,_cache: any) => {
  const _component_vz_badge = _resolveComponent("vz-badge")!

  return (_openBlock(), _createBlock(_component_vz_badge, _mergeProps({ class: "vz-status-badge" }, { ..._ctx.$attrs, ...(__props.override || bind.value) }, { prefix: __props.prefix }), null, 16, ["prefix"]))
}
}

})