import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "vz-calendar-day__control"
}

import { computed, ref } from 'vue';
import { useResizeObserver } from '@/shared/composables';
import { convertPixelToRem } from '@/shared/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-calendar-day',
  props: {
  day: { type: Number, required: true },
  extendable: { type: Boolean, default: false },
  isJustifyCenter: { type: Boolean, default: false },
},
  setup(__props) {

const props = __props;

const isShowMore = ref<boolean>(false);
const contentRef = ref<Element | undefined>(undefined);
const { height } = useResizeObserver(contentRef, true);

const minHeight = computed(() => 2.25);
const isExtendable = computed(() => props.extendable);

const dayStyle = computed(() => {
  if (!isExtendable.value || !height.value) {
    return;
  }

  return isShowMore.value ? { maxHeight: `${convertPixelToRem(`${height.value}px`)}rem` } : { maxHeight: `${minHeight.value}rem` };
});

const onCollapse = () => {
  isShowMore.value = !isShowMore.value;
};

return (_ctx: any,_cache: any) => {
  const _component_vz_button = _resolveComponent("vz-button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["vz-calendar-day", { 'vz-calendar-day--open': isShowMore.value }])
  }, [
    (__props.day)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createElementVNode("div", {
            class: _normalizeClass('vz-calendar-day__header' + (__props.isJustifyCenter ? ' justify-center' : ''))
          }, [
            _createElementVNode("span", null, _toDisplayString(__props.day), 1)
          ], 2),
          (_ctx.$slots['default'])
            ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                ref_key: "contentRef",
                ref: contentRef,
                class: "vz-calendar-day__content",
                style: _normalizeStyle(dayStyle.value)
              }, [
                _renderSlot(_ctx.$slots, "default")
              ], 4)), [
                [_directive_tooltip]
              ])
            : _createCommentVNode("", true),
          (isExtendable.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_vz_button, {
                  type: "flat",
                  "icon-size": "1rem",
                  text: `GENERAL.${isShowMore.value ? 'SHOW_LESS' : 'SHOW_MORE'}`,
                  "icon-name": isShowMore.value ? 'svg:arrow-up' : 'svg:arrow-down',
                  onClick: onCollapse
                }, null, 8, ["text", "icon-name"])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 2))
}
}

})