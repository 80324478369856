import type { BaseResponse, BaseOptionsResponse, BaseId, BaseRecords } from '@/shared/models';
import type { BaseMessage } from '@/views/messenger/types';
import {
  GetConversationMessagesReq,
  GetConversationRes,
  UpdateConversationStatusReq,
  CreateConversationRes,
  CreateConversationReq,
  UpdateConversationAgreementReq,
  SearchJobOffersReq,
  SearchJobOffersRes,
  GetJobOfferDetailsRes,
  ListSkillsReq,
  InvitesConversationReq,
  AddTransactionToConversationReq,
} from '@/views/job/types';
import ApiService from '@/shared/services/api-service/api.service';
import { inject, injectable } from 'tsyringe';
import { LIST_CLASSIFICATIONS, LIST_SKILLS } from '@/views/job/store/constants/job.constants';
import { GET_JOB_OFFER_DETAILS, SEARCH_JOB_OFFERS } from '@/views/job/store/constants/job-offer.constants';
import {
  ADD_TRANSACTION_TO_CONVERSATION,
  CREATE_CONVERSATION,
  GET_CONVERSATION,
  GET_CONVERSATION_MESSAGES,
  INVITES_CONVERSATION,
  LEAVE_CONVERSATION,
  SEND_CONVERSATION_MESSAGE,
  UPDATE_CONVERSATION_AGREEMENTS,
  UPDATE_CONVERSATION_STATUS,
} from '@/views/job/store/constants/conversation.constants';

@injectable()
export default class JobService {
  public constructor(@inject('ApiService') private apiService: ApiService) {}

  private baseUrl = '/job';

  public [LIST_CLASSIFICATIONS](): Promise<BaseOptionsResponse<string>> {
    return this.apiService.get<BaseOptionsResponse<string>>(`${this.baseUrl}/${LIST_CLASSIFICATIONS}`);
  }

  public [LIST_SKILLS](payload: ListSkillsReq): Promise<BaseOptionsResponse<string>> {
    const query = [`groups=${payload.groups.join(',')}`, payload.type ? `type=${payload.type}` : undefined].filter((value) => !!value).join('&');

    return this.apiService.get<BaseOptionsResponse<string>>(`${this.baseUrl}/${LIST_SKILLS}?${query}`);
  }

  public [GET_JOB_OFFER_DETAILS](positionId: BaseId, userId?: BaseId): Promise<GetJobOfferDetailsRes> {
    return this.apiService.post<GetJobOfferDetailsRes>(
      `${this.baseUrl}/${GET_JOB_OFFER_DETAILS}/${positionId}`,
      { userId },
      { allowOnceAtTime: true }
    );
  }

  public [SEARCH_JOB_OFFERS](payload: SearchJobOffersReq): Promise<SearchJobOffersRes> {
    return this.apiService.post<SearchJobOffersRes>(`${this.baseUrl}/${SEARCH_JOB_OFFERS}`, payload, { allowOnceAtTime: true });
  }

  public [INVITES_CONVERSATION](payload: InvitesConversationReq): Promise<BaseResponse> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${INVITES_CONVERSATION}`, payload);
  }

  public [CREATE_CONVERSATION](payload: CreateConversationReq): Promise<CreateConversationRes> {
    return this.apiService.post<CreateConversationRes>(`${this.baseUrl}/${CREATE_CONVERSATION}`, payload);
  }

  public [GET_CONVERSATION](conversationId: BaseId): Promise<GetConversationRes> {
    return this.apiService.get<GetConversationRes>(`${this.baseUrl}/${GET_CONVERSATION}/${conversationId}`);
  }

  public [LEAVE_CONVERSATION](conversationId: BaseId): Promise<GetConversationRes> {
    return this.apiService.get<GetConversationRes>(`${this.baseUrl}/${LEAVE_CONVERSATION}/${conversationId}`);
  }

  public [UPDATE_CONVERSATION_STATUS](payload: UpdateConversationStatusReq): Promise<BaseResponse> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${UPDATE_CONVERSATION_STATUS}`, payload);
  }

  public [SEND_CONVERSATION_MESSAGE](payload: FormData): Promise<BaseMessage> {
    return this.apiService.post<BaseMessage>(`${this.baseUrl}/${SEND_CONVERSATION_MESSAGE}`, payload);
  }

  public [UPDATE_CONVERSATION_AGREEMENTS](payload: UpdateConversationAgreementReq, conversationId: BaseId): Promise<BaseResponse> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${UPDATE_CONVERSATION_AGREEMENTS}/${conversationId}`, payload);
  }

  public [GET_CONVERSATION_MESSAGES](payload: GetConversationMessagesReq): Promise<BaseRecords<BaseMessage>> {
    return this.apiService.post<BaseRecords<BaseMessage>>(`${this.baseUrl}/${GET_CONVERSATION_MESSAGES}`, payload);
  }

  public [ADD_TRANSACTION_TO_CONVERSATION](payload: AddTransactionToConversationReq): Promise<BaseResponse> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${ADD_TRANSACTION_TO_CONVERSATION}`, payload);
  }
}
