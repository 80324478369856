/* eslint-disable */
type Layer = {
  img: HTMLImageElement;
  dx: number;
  dy: number;
  dw: number;
  dh: number;
  sx: number;
  sy: number;
  sw: number;
  sh: number;
  r: 90 | 180 | 270 | 0;
};

export class CanvasBoard extends HTMLElement {
  private readonly canvas: HTMLCanvasElement = document.createElement('canvas');
  private readonly ctx: CanvasRenderingContext2D = this.canvas.getContext('2d')!;
  private readonly virtualCanvas: HTMLCanvasElement = document.createElement('canvas');
  private readonly virtualCtx: CanvasRenderingContext2D = this.virtualCanvas.getContext('2d')!;
  private corner: number = 12;
  private margin: number = 10;
  private readonly: boolean = false;
  private objectFit: 'cover' | 'contain' = 'contain';
  private exportType: string = 'image/jpeg';
  private backgroundColor: string | undefined = undefined;
  private initClient = { x: 0, y: 0 };
  private debounceId: ReturnType<typeof setTimeout>;
  private initialTouchDistance: number | null = null;
  private initialScale: number = 1;
  private keyboardEnabled: boolean = false;
  private layers: Array<Layer> = [];
  private layerIndex: number = -1;
  private layerCatch = { center: false, left: false, right: false, top: false, bottom: false };
  private isResizingMode: boolean = false;
  private isDraggingMode: boolean = false;
  private isShiftLock: boolean = false;
  private isEventListenersInitialized: boolean = false;
  private megaPixel: number = 0;

  static get observedAttributes(): Array<string> {
    return ['src', 'height', 'width', 'disabled', 'background-color', 'megapixel', 'object-fit', 'keyboard-enabled', 'type'];
  }

  constructor() {
    super();
    this.handleStart = this.handleStart.bind(this);
    this.handleMove = this.handleMove.bind(this);
    this.handleEnd = this.handleEnd.bind(this);
    this.handleWheel = this.handleWheel.bind(this);
  }

  connectedCallback() {
    this.style.display = 'flex';
    this.style.justifyContent = 'center';
    this.canvas.style.border = '1px solid #ccc';
    this.appendChild(this.canvas);

    this.adjustCanvasSize();

    this.addEventListeners();
  }

  attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    switch (name) {
      case 'src':
        this.add(newValue || null);
        break;
      case 'height':
        this.canvas.height = parseInt(newValue || '600') + this.margin * 2;
        break;
      case 'width':
        this.canvas.width = parseInt(newValue || '600') + this.margin * 2;
        break;
      case 'background-color':
        this.backgroundColor = newValue || undefined;
        break;
      case 'object-fit':
        this.objectFit = newValue === 'cover' ? 'cover' : 'contain';
        this.reset();
        this.draw();
        break;
      case 'keyboard-enabled':
        this.keyboardEnabled = newValue === 'true';
        break;
      case 'type':
        this.exportType = newValue || 'image/jpeg';
        break;
      case 'megapixel':
        this.megaPixel = parseFloat(newValue || '0');
        break;
      case 'disabled':
        this.readonly = newValue === 'true';

        if (this.readonly) {
          this.removeEventListeners();
        } else if (this.layers.length) {
          this.addEventListeners();
        }
        break;
    }
  }

  private adjustCanvasSize() {
    // Define maximum dimensions (e.g., from parent container or specific values)
    const maxWidth = this.parentElement?.clientWidth || window.innerWidth;
    const maxHeight = this.parentElement?.clientHeight || window.innerHeight;

    const aspectRatio = this.canvas.width / this.canvas.height;

    // Calculate the best fit dimensions while maintaining aspect ratio
    let newCanvasWidth = maxWidth;
    let newCanvasHeight = newCanvasWidth / aspectRatio;

    if (newCanvasHeight > maxHeight) {
      newCanvasHeight = maxHeight;
      newCanvasWidth = newCanvasHeight * aspectRatio;
    }

    // Apply the new dimensions to the canvas
    this.canvas.width = newCanvasWidth;
    this.canvas.height = newCanvasHeight;

    // Adjust the canvas style to center it if the canvas is smaller than the parent
    this.canvas.style.maxWidth = '100%';
    this.canvas.style.maxHeight = '100%';
  }

  private insertLayer(initSrc: string): void {
    this.layers.push({ img: new Image(), dx: 0, dy: 0, dw: 0, dh: 0, sx: 0, sy: 0, sw: 0, sh: 0, r: 0 });
    const index = this.layers.length - 1;
    this.layers[index].img.src = initSrc;
    this.layers[index].img.onload = () => {
      this.layerIndex = index;
      this.resetLayer(index);
      this.draw();
      this.addEventListeners();
    };
  }

  public remove(index?: number) {
    if ((index === undefined && this.layerIndex === -1) || !this.layers[this.layerIndex]) {
      return;
    }

    this.layers.splice(index !== undefined ? index : this.layerIndex, 1);
    this.layerIndex = -1;
    this.draw();
  }

  public async add(input?: string | File | null): Promise<void> {
    if (input === null) {
      return;
    } else if (!input) {
      input = (await this.uploadFile({ accept: ['image/*'], max: 1 }))?.[0];
    }

    if (!input) {
      return;
    }

    if (input instanceof File) {
      const reader = new FileReader();

      reader.readAsDataURL(input);
      reader.onloadend = () => this.insertLayer(reader.result as string);

      return;
    }

    const domain = input.indexOf('://') > -1 ? input.split('/')[2] : window.location.host;

    if (!input.includes('base64') && domain !== window.location.host) {
      fetch(input).then((response) =>
        response.blob().then((blob) => {
          const reader = new FileReader();

          reader.readAsDataURL(blob);
          reader.onloadend = () => this.insertLayer(reader.result as string);
        })
      );
    } else {
      this.insertLayer(input);
    }
  }

  public reset(): void {
    for (let index = 0; index < this.layers.length; index++) {
      this.resetLayer(index);
    }

    this.draw();
  }

  public zoom(zoomFactor: number): void {
    this.scaleHandle(zoomFactor);
    this.draw();
  }

  public move({ x, y }: Partial<{ x: number; y: number }>): void {
    const layer = this.layers[this.layerIndex];

    if (layer) {
      this.moveHandle(layer.dx + (x || 0), layer.dy + (y || 0));
      this.draw();
    }
  }

  private handleChange(debounceTime?: number): void {
    this.debounce(
      () => {
        if (!this.layers.length) {
          this.dispatchEvent(new CustomEvent('change', { detail: { base64: '' } }));
          return;
        }

        const aspectRatio = this.canvas.width / this.canvas.height;
        const megapixels = this.megaPixel ? this.megaPixel : 2;

        const calculateDimensions = (aspectRatio: number, megapixels: number) => {
          const width = Math.sqrt(megapixels * 1000000 * (aspectRatio / (aspectRatio + 1)));
          const height = width / aspectRatio;
          return { width: Math.round(width), height: Math.round(height) };
        };

        const { width: virtualWidth, height: virtualHeight } = calculateDimensions(aspectRatio, megapixels);

        this.virtualCanvas.width = virtualWidth;
        this.virtualCanvas.height = virtualHeight;

        if (this.backgroundColor) {
          this.virtualCtx.fillStyle = this.backgroundColor;
          this.virtualCtx.fillRect(0, 0, this.virtualCanvas.width, this.virtualCanvas.height);
        }

        const scaleFactorWidth = virtualWidth / this.canvas.width;
        const scaleFactorHeight = virtualHeight / this.canvas.height;

        this.layers.forEach(({ img, dx, dy, dw, dh }) => {
          // Calculate the position and size of the image on the virtual canvas
          const virtualDx = dx * scaleFactorWidth;
          const virtualDy = dy * scaleFactorHeight;
          const virtualDw = dw * scaleFactorWidth;
          const virtualDh = dh * scaleFactorHeight;

          this.virtualCtx.drawImage(img, virtualDx, virtualDy, virtualDw, virtualDh);
        });

        this.virtualCanvas.toBlob((blob) => {
          this.dispatchEvent(new CustomEvent('change', { detail: { base64: this.virtualCanvas.toDataURL(this.exportType), blob } }));
        });
      },
      debounceTime !== undefined ? debounceTime : 100
    );
  }

  private resetLayer = (index: number): void => {
    const minScaleWidth = this.canvas.width / this.layers[index].img.width;
    const minScaleHeight = this.canvas.height / this.layers[index].img.height;
    const scale = (this.objectFit === 'cover' ? Math.max : Math.min)(minScaleWidth, minScaleHeight);

    this.layers[index] = {
      ...this.layers[index],
      dx: (this.canvas.width - this.layers[index].img.width * scale) / 2,
      dy: (this.canvas.height - this.layers[index].img.height * scale) / 2,
      dw: this.layers[index].img.width * scale,
      dh: this.layers[index].img.height * scale,
      r: 0,
    };
  };

  private draw(debounceTime?: number): void {
    const margin = this.margin;
    const boxSize = 10;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    if (this.backgroundColor) {
      this.ctx.fillStyle = this.backgroundColor;
      this.ctx.fillRect(margin, margin, this.canvas.width - margin * 2, this.canvas.height - margin * 2);
    }

    this.layers.forEach(({ img, dx, dy, dw, dh, r }, index) => {
      dx += margin;
      dy += margin;
      dw -= margin * 2;
      dh -= margin * 2;

      this.ctx.drawImage(img, dx, dy, dw, dh);

      if (index === this.layerIndex) {
        this.ctx.strokeStyle = `rgba(200,200,200, 0.5)`;
        this.ctx.strokeRect(dx - 2, dy - 2, dw + 4, dh + 4);

        this.ctx.fillStyle = `rgba(200,200,200,0.9)`;
        [
          { x: dx + (dw / 2 - boxSize / 2 + 2), y: dy - boxSize / 2 - 2 }, // Top center
          { x: dx - boxSize / 2 - 2, y: dy + dh / 2 - boxSize / 2 }, // Left center
          { x: dx + dw / 2 - boxSize / 2 + 2, y: dy + dh - boxSize / 2 + 2 }, // Bottom center
          { x: dx + dw - boxSize / 2 + 2, y: dy + dh / 2 - boxSize / 2 }, // Right center
          { x: dx - boxSize / 2 - 2, y: dy - boxSize / 2 - 2 }, // Top left
          { x: dx + dw - boxSize / 2 + 2, y: dy - boxSize / 2 - 2 }, // Top right
          { x: dx - boxSize / 2 - 2, y: dy + dh - boxSize / 2 + 2 }, // Bottom left
          { x: dx + dw - boxSize / 2 + 2, y: dy + dh - boxSize / 2 + 2 }, // Bottom right
        ].forEach(({ x, y }) => this.ctx.fillRect(x, y, boxSize, boxSize));
      }
    });

    this.ctx.fillStyle = 'rgba(0,0,0,0.5)';
    this.ctx.fillRect(0, 0, this.canvas.width, margin);
    this.ctx.fillRect(0, this.canvas.height - margin, this.canvas.width, margin);
    this.ctx.fillRect(0, margin, margin, this.canvas.height - margin * 2);
    this.ctx.fillRect(this.canvas.width - margin, margin, margin, this.canvas.height - margin * 2);
    this.handleChange(debounceTime);
  }

  private moveHandle(x?: number, y?: number): void {
    const layer = this.layers[this.layerIndex];
    const { minX, maxX, minY, maxY } = this.calculateBoundaries(layer);
    layer.dx = Math.min(Math.max(x || layer.dx, minX), maxX);
    layer.dy = Math.min(Math.max(y || layer.dy, minY), maxY);
  }

  private scaleHandle(zoomFactor: number, saveCenter: boolean = true) {
    const layer = this.layers[this.layerIndex];
    const oldWidth = layer.dw;
    const oldHeight = layer.dh;
    const newWidth = layer.dw * zoomFactor;
    const newHeight = layer.dh * zoomFactor;

    layer.dw = newWidth;
    layer.dh = newHeight;

    if (saveCenter) {
      this.moveHandle(layer.dx - (newWidth - oldWidth) / 2, layer.dy - (newHeight - oldHeight) / 2);
    }
  }

  private handleMouseCursor(): void {
    if (this.layerIndex === -1) {
      this.canvas.style.cursor = 'default';
    } else if (this.layerCatch.center) {
      this.canvas.style.cursor = 'grab';
    } else if ((this.layerCatch.left && this.layerCatch.top) || (this.layerCatch.right && this.layerCatch.bottom)) {
      this.canvas.style.cursor = 'nwse-resize';
    } else if ((this.layerCatch.right && this.layerCatch.top) || (this.layerCatch.left && this.layerCatch.bottom)) {
      this.canvas.style.cursor = 'nesw-resize';
    } else if (this.layerCatch.left || this.layerCatch.right) {
      this.canvas.style.cursor = 'ew-resize';
    } else if (this.layerCatch.top || this.layerCatch.bottom) {
      this.canvas.style.cursor = 'ns-resize';
    }
  }

  private catchLayer(event: MouseEvent | TouchEvent): boolean {
    const connerSize = this.corner / 2;
    const marginShift = this.margin * 2;
    const rect = this.canvas.getBoundingClientRect();
    const canvasX = (event instanceof MouseEvent ? event.clientX - rect.left : event.touches[0].clientX - rect.left) - this.corner;
    const canvasY = (event instanceof MouseEvent ? event.clientY - rect.top : event.touches[0].clientY - rect.top) - this.corner;

    this.layerIndex = this.layers.reverse().findIndex(({ dx, dy, dw, dh }) => {
      const minX = dx - connerSize * 2;
      const maxX = dx + dw - marginShift + connerSize * 2;
      const minY = dy - connerSize * 2;
      const maxY = dy + dh - marginShift + connerSize * 2;

      return canvasX > minX && canvasX < maxX && canvasY > minY && canvasY < maxY;
    });

    if (this.layerIndex !== -1) {
      const { dx: leftX, dy: topY, dw, dh } = this.layers[this.layerIndex];
      const rightX = leftX + dw - marginShift;
      const bottomY = topY + dh - marginShift;

      this.layerCatch = {
        center:
          canvasX > leftX + connerSize / 2 &&
          canvasX < rightX - connerSize / 2 &&
          canvasY > topY + connerSize / 2 &&
          canvasY < bottomY - connerSize / 2,
        left: canvasX > leftX - connerSize && canvasX < leftX + connerSize,
        right: canvasX > rightX - connerSize && canvasX < rightX + connerSize,
        top: canvasY > topY - connerSize && canvasY < topY + connerSize,
        bottom: canvasY > bottomY - connerSize && canvasY < bottomY + connerSize,
      };
    } else {
      this.layerCatch = { center: false, left: false, right: false, top: false, bottom: false };
    }

    this.handleMouseCursor();
    return this.layerIndex > -1;
  }

  private handleStart(event: MouseEvent | TouchEvent): void {
    event.preventDefault();
    this.catchLayer(event);
    this.draw();

    const layer = this.layers[this.layerIndex];

    if (!layer) {
      return;
    }

    if (event instanceof MouseEvent) {
      this.initClient.x = event.clientX;
      this.initClient.y = event.clientY;
    } else if (event.touches.length === 1) {
      this.initClient.x = event.touches[0].clientX;
      this.initClient.y = event.touches[0].clientY;
    }

    if (this.layerCatch.center) {
      this.isDraggingMode = true;
      this.isResizingMode = false;
      this.canvas.style.cursor = 'grabbing';
    } else {
      this.isDraggingMode = false;
      this.isResizingMode = true;
    }
  }

  private handleMove(event: MouseEvent | TouchEvent): void {
    event.preventDefault();

    const layer = this.layers[this.layerIndex];

    if (!layer) {
      return;
    }

    if (this.isDraggingMode) {
      this.drag(event);
    } else if (this.isResizingMode) {
      this.resize(event);
    } else {
      this.catchLayer(event);
    }
  }

  private handleEnd(): void {
    this.isDraggingMode = false;
    this.isResizingMode = false;
    this.canvas.style.cursor = 'default';
  }

  private resize(event: MouseEvent | TouchEvent): void {
    const layer = this.layers[this.layerIndex];
    const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
    const clientY = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;
    const diffX = clientX - this.initClient.x;
    const diffY = clientY - this.initClient.y;

    const ratio = layer.dw / layer.dh;

    const hRatio = () => {
      const height = layer.dh;
      layer.dh = layer.dw / ratio;
      const diffH = height - layer.dh;
      layer.dy = layer.dy + diffH / 2;
    };

    const wRatio = () => {
      const width = layer.dw;
      layer.dw = layer.dh * ratio;
      const diffW = width - layer.dw;
      layer.dx = layer.dx + diffW / 2;
    };

    if (this.layerCatch.top && this.layerCatch.left) {
      layer.dw = layer.dw - diffX;
      layer.dx = layer.dx + diffX;
      if (this.isShiftLock) {
        layer.dh = layer.dh - diffY;
        layer.dy = layer.dy + diffY;
      } else {
        hRatio();
      }
    } else if (this.layerCatch.top && this.layerCatch.right) {
      layer.dw = layer.dw + diffX;
      if (this.isShiftLock) {
        layer.dh = layer.dh - diffY;
        layer.dy = layer.dy + diffY;
      } else {
        hRatio();
      }
    } else if (this.layerCatch.top) {
      layer.dh = layer.dh - diffY;
      layer.dy = layer.dy + diffY;
      if (!this.isShiftLock) {
        wRatio();
      }
    } else if (this.layerCatch.bottom && this.layerCatch.left) {
      layer.dh = layer.dh + diffY;
      if (this.isShiftLock) {
        layer.dw = layer.dw - diffX;
        layer.dx = layer.dx + diffX;
      } else {
        wRatio();
      }
    } else if (this.layerCatch.bottom && this.layerCatch.right) {
      layer.dh = layer.dh + diffY;
      if (this.isShiftLock) {
        layer.dw = layer.dw + diffX;
      } else {
        wRatio();
      }
    } else if (this.layerCatch.bottom) {
      layer.dh = layer.dh + diffY;
      if (!this.isShiftLock) {
        wRatio();
      }
    } else if (this.layerCatch.left) {
      layer.dw = layer.dw - diffX;
      layer.dx = layer.dx + diffX;
      if (!this.isShiftLock) {
        hRatio();
      }
    } else if (this.layerCatch.right) {
      layer.dw = layer.dw + diffX;
      if (!this.isShiftLock) {
        hRatio();
      }
    }

    this.draw();
    this.initClient.x = clientX;
    this.initClient.y = clientY;
  }

  private drag(event: MouseEvent | TouchEvent): void {
    const layer = this.layers[this.layerIndex];

    if (this.isDraggingMode) {
      const x = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
      const y = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY;
      const dx = x - this.initClient.x;
      const dy = y - this.initClient.y;

      this.moveHandle(layer.dx + dx, layer.dy + dy);
      this.initClient.x = x;
      this.initClient.y = y;
      this.draw();
    }
  }

  private handleWheel(event: WheelEvent): void {
    event.preventDefault();

    this.catchLayer(event);
    const layer = this.layers[this.layerIndex];

    if (!layer) {
      return;
    }

    const scaleFactor = event.deltaY > 0 ? 0.98 : 1.02;

    this.zoom(scaleFactor);
    this.draw();
  }

  private calculateBoundaries(layer: Layer): { minX: number; maxX: number; minY: number; maxY: number } {
    return {
      minX: Math.min(0, this.canvas.width - layer.dw),
      maxX: Math.max(0, this.canvas.width - layer.dw),
      minY: Math.min(0, this.canvas.height - layer.dh),
      maxY: Math.max(0, this.canvas.height - layer.dh),
    };
  }

  private handleKeyup(event: KeyboardEvent): void {
    if (event.key === 'Shift') {
      this.isShiftLock = false;
    }
  }

  private handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Shift') {
      this.isShiftLock = true;
    }

    if (event.key === 'Backspace' || event.key === 'Delete') {
      this.remove();
    }

    if (event.key === 'Help') {
      this.add();
    }

    const isValidKey = ['Escape', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', '+', '-', '='].includes(event.key);

    if (this.layers.length === 1 && this.layerIndex === -1) {
      this.layerIndex = 0;
    }

    if (!this.keyboardEnabled || !isValidKey || this.layerIndex === -1) {
      return;
    }

    event.preventDefault();
    const moveStep = 2;
    const zoomStep = 0.02;

    switch (event.key) {
      case 'Escape':
        this.reset();
        break;
      case 'ArrowUp':
        this.move({ y: -moveStep });
        break;
      case 'ArrowDown':
        this.move({ y: moveStep });
        break;
      case 'ArrowLeft':
        this.move({ x: -moveStep });
        break;
      case 'ArrowRight':
        this.move({ x: moveStep });
        break;
      case '=':
      case '+':
        this.zoom(1 + zoomStep);
        break;
      case '-':
        this.zoom(1 - zoomStep);
        break;
    }
  }

  private handlePitchZoom(event: TouchEvent): void {
    const layer = this.layers[this.layerIndex];
    const currentDistance = this.calculateTouchDistance(event.touches);

    if (this.initialTouchDistance) {
      const scaleMultiplier = currentDistance / this.initialTouchDistance;
      layer.dw = layer.dw * (scaleMultiplier - 1);
      layer.dh = layer.dh * (scaleMultiplier - 1);

      const midpoint = {
        x: (event.touches[0].clientX + event.touches[1].clientX) / 2,
        y: (event.touches[0].clientY + event.touches[1].clientY) / 2,
      };

      const rect = this.canvas.getBoundingClientRect();
      const canvasMidpoint = {
        x: midpoint.x - rect.left,
        y: midpoint.y - rect.top,
      };

      const imageFocalPoint = {
        x: (canvasMidpoint.x - layer.dx) / this.initialScale,
        y: (canvasMidpoint.y - layer.dy) / this.initialScale,
      };

      const scaleX = layer.dw / layer.img.width;
      const scaleY = layer.dh / layer.img.height;
      this.moveHandle(canvasMidpoint.x - imageFocalPoint.x * scaleX, canvasMidpoint.y - imageFocalPoint.y * scaleY);

      this.draw();
    }
  }
  private calculateTouchDistance(touches: TouchList): number {
    const touch1 = touches[0];
    const touch2 = touches[1];

    return Math.sqrt(Math.pow(touch2.pageX - touch1.pageX, 2) + Math.pow(touch2.pageY - touch1.pageY, 2));
  }

  private async uploadFile(options?: { accept?: Array<string>; multiple?: boolean; max?: number }): Promise<FileList | null> {
    const { accept, multiple, max } = options || {};

    return new Promise((resolve, reject) => {
      const uploadInput: HTMLInputElement = document.createElement('input');
      uploadInput.max = max?.toString() || '';
      uploadInput.multiple = multiple || false;
      uploadInput.type = 'file';
      uploadInput.accept = (accept || ['*']).join(',');
      uploadInput.click();

      uploadInput.onchange = () => {
        uploadInput.remove();
        resolve(uploadInput?.files);
      };

      uploadInput.onerror = () => {
        uploadInput.remove();
        reject();
      };
    });
  }

  private addEventListeners(): void {
    if (this.readonly || this.isEventListenersInitialized) {
      return;
    }

    this.canvas.addEventListener('mousedown', this.handleStart.bind(this));
    this.canvas.addEventListener('mousemove', this.handleMove.bind(this));
    this.canvas.addEventListener('mouseup', this.handleEnd.bind(this));
    this.canvas.addEventListener('mouseleave', this.handleEnd.bind(this));
    this.canvas.addEventListener('wheel', this.handleWheel.bind(this));

    this.canvas.addEventListener('touchstart', this.handleStart.bind(this));
    this.canvas.addEventListener('touchmove', this.handleMove.bind(this));
    this.canvas.addEventListener('touchend', this.handleEnd.bind(this));

    window.addEventListener('keyup', this.handleKeyup.bind(this));
    window.addEventListener('keydown', this.handleKeydown.bind(this));
    window.addEventListener('resize', () => this.adjustCanvasSize());

    this.isEventListenersInitialized = true;
  }

  private removeEventListeners(): void {
    this.canvas.removeEventListener('mousedown', this.handleStart.bind(this));
    this.canvas.removeEventListener('mousemove', this.handleMove.bind(this));
    this.canvas.removeEventListener('mouseup', this.handleEnd.bind(this));
    this.canvas.removeEventListener('mouseleave', this.handleEnd.bind(this));
    this.canvas.removeEventListener('wheel', this.handleWheel.bind(this));

    this.canvas.removeEventListener('touchstart', this.handleStart.bind(this));
    this.canvas.removeEventListener('touchmove', this.handleMove.bind(this));
    this.canvas.removeEventListener('touchend', this.handleEnd.bind(this));

    window.removeEventListener('keyup', this.handleKeyup.bind(this));
    window.removeEventListener('keydown', this.handleKeydown.bind(this));
    window.removeEventListener('resize', this.adjustCanvasSize);

    this.isEventListenersInitialized = false;
  }

  private debounce(fn: () => void, delay = 500): void {
    clearTimeout(this.debounceId);

    return ((...args) => {
      this.debounceId = setTimeout(() => fn?.(...args), delay);
    })();
  }
}

customElements.define('canvas-board', CanvasBoard);
