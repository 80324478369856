<template>
  <div v-if="dynamicComponent" class="vz-stepper">
    <div class="d-flex flex-column gap-1 mb-2">
      <p v-if="dynamicComponent.title" class="text-title-3 text-ellipsis">{{ $t(dynamicComponent.title) }}</p>

      <p v-else-if="title" class="text-title-3 text-ellipsis">{{ $t(title) }}</p>

      <!-- <p v-if="dynamicComponent.subtitle" class="text-title-1 font-weight-400 text-ellipsis mt-1">{{
        $t(dynamicComponent.subtitle) }}</p> -->
    </div>

    <vz-error-alert v-if="!hideErrors" class="vz-stepper__errors" :errors="serverError" />
    <div v-if="type === ''">
      <div v-if="!$slots['splash'] && !hideProgressBar && activeSteps.length > 1" class="vz-stepper__progress">
        <div
          v-for="index in activeSteps.length"
          :key="index"
          :class="{ 'vz-stepper_active': stepNumber + 1 === index, 'vz-stepper_passed': stepNumber + 1 > index }"
          v-on="stepNumber > index ? { click: () => (stepNumber = index) } : {}"
        >
          {{ index }}
        </div>
      </div>
    </div>

    <div v-if="type === 'v2'">
      <div v-if="!$slots['splash'] && !hideProgressBar && activeSteps.length > 1" class="stepper-wrapper">
        <div
          v-for="(i, index) in activeSteps"
          :key="index"
          :class="['stepper-item', { active: stepNumber === index }, { completed: stepNumber > index }]"
          v-on="stepNumber + 1 > index ? { click: () => (stepNumber = index - 1) } : {}"
        >
          <div class="step-counter">{{ index + 1 }}</div>
          <div class="step-name">{{ i.subtitle }}</div>
        </div>
      </div>
    </div>
    <div v-if="!$slots['splash'] && !hideProgressBar && activeSteps.length > 1" class="vz-stepper__progress_bar">
      <div
        v-for="index in activeSteps.length"
        :key="index"
        :class="[
          'vz-stepper__progress_bar-item',
          { 'vz-stepper_progress_bar-item--active': stepNumber + 1 === index, 'vz-stepper_progress_bar-item--passed': stepNumber + 1 > index },
        ]"
        v-on="stepNumber + 1 > index ? { click: () => (stepNumber = index - 1) } : {}"
      >
        {{ index }}
      </div>
    </div>

    <div v-if="loading" class="d-flex align-center justify-center flex-grow-1">
      <vz-spinner size="96px" />
    </div>

    <div v-else-if="$slots['splash']" class="d-flex align-center justify-center flex-grow-1">
      <slot name="splash" />
    </div>

    <template v-else>
      <slot :name="dynamicComponent.name ? `${dynamicComponent.name}-header` : `h${stepNumber + 1}`" />

      <vz-form ref="vzFormRef" :class="`pa-2 vz-form-content__${dynamicComponent.name || 'content'}`" :actions="actions" :disabled="loading">
        <template v-if="dynamicComponent.component">
          <component
            :is="dynamicComponent.component"
            :field-message="fields"
            :model-value="modelValue"
            :errors="errors"
            v-bind="dynamicComponent.props"
            @update:model-value="emit('update:model-value', $event)"
          />
        </template>

        <slot :name="dynamicComponent.name || stepNumber + 1" :field-message="fields" />

        <template v-if="$slots['actions']" #extra-actions>
          <slot name="actions" :step="stepNumber" :disabled="disabled || dynamicComponent.disabled" />
        </template>
      </vz-form>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType, ref, watch } from 'vue';
import type { StepComponent } from '@/shared/components/stepper/models/step-component';
import type { FormActionButtons } from '@shared/components/fields/vz-form/models';
import { stepperComponentMapper } from '@/shared/components/stepper/helpers/stepper-components-mapper';
import ServerError from '@shared/services/api-service/server-error';
import VzForm from '@shared/components/fields/vz-form/vz-form.vue';
import { useServerErrorsMapper } from '@shared/composables';

const props = defineProps({
  index: { type: Number, default: 0 },
  title: { type: String as PropType<string | undefined>, default: undefined },
  autocomplete: { type: String as PropType<'off' | 'on'>, default: 'off' },
  modelValue: { type: [Object, Number, String, Array], default: undefined },
  steps: { type: Array as PropType<Array<StepComponent>>, required: true },
  submitText: { type: String, default: 'GENERAL.SUBMIT' },
  submitCallback: { type: Function as PropType<() => Promise<void> | void | undefined>, default: undefined },
  cancelText: { type: String, default: 'GENERAL.CANCEL' },
  cancelCallback: { type: Function as PropType<() => Promise<void> | void | undefined>, default: undefined },
  cancelDisabled: { type: Boolean, default: false },
  hideProgressBar: { type: Boolean, default: false },
  hideErrors: { type: Boolean, default: false },
  errors: { type: Object as PropType<ServerError | null>, default: null },
  loading: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  type: { type: String, default: '' },
});

const emit = defineEmits(['update:model-value', 'update:step', 'step:previous', 'step:next', 'submit', 'cancel']);

const vzFormRef = ref<InstanceType<typeof VzForm> | null>(null);
const serverError = useServerErrorsMapper(computed(() => props.errors || errors.value));

const activeSteps = computed(() => props.steps.filter(({ hide }) => !hide));

const actions = computed(
  (): FormActionButtons => [
    {
      text: !stepNumber.value ? props.cancelText : 'GENERAL.BACK',
      disabled: !stepNumber.value ? props.cancelDisabled : false,
      callback: onBack,
    },
    {
      text: isLastStep.value ? props.submitText : 'GENERAL.NEXT',
      validate: true,
      disabled: props.disabled,
      callback: onNext,
    },
  ]
);

const { formRef, dynamicComponent, nextStep, previousStep, stepNumber, isLastStep, loading, errors, fields } = stepperComponentMapper(activeSteps, {
  submit: async () => {
    await props.submitCallback?.();
    emit('submit');
  },
  cancel: async () => {
    await props.cancelCallback?.();
    emit('cancel');
  },
});

const onNext = async (): Promise<void> => {
  await nextStep();

  emit('step:next');
};

const onBack = (): void => {
  previousStep();
  emit('step:previous');
};

watch(
  () => props.index,
  (value) => (stepNumber.value = value),
  { immediate: true }
);

defineExpose({
  formRef,
  stepNumber,
  isLastStep,
  loading,
  errors,
  fields,
  onNext,
  onBack,
  validate: (isSilent?: boolean) => vzFormRef.value?.validate(isSilent),
  dynamicComponent,
});
</script>

<style lang="scss">
.vz-stepper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  max-width: 100%;
  height: 100%;
  padding-left: 50px;
  padding-right: 50px;

  [screen='minimal'] & {
    padding: 2.5rem 1rem 1rem 1rem;
  }

  &__errors {
    margin: 0 1px 1rem 1px;
  }

  [class^='vz-form-content__'] {
    display: flex;
    flex-direction: column;
  }

  form {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin: 0.5rem 0 1rem 0.5rem;
    overflow-x: hidden;

    > * {
      flex-grow: 1;
    }

    @include max-mobile-layout {
      padding: 0.25rem;
    }

    @include min-mobile-layout {
      padding: 0 0.5rem 1rem 1rem;
    }
  }

  &__content {
    display: initial;
  }

  &__progress_bar {
    user-select: none;
    --modal-progress-bar-width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    width: var(--modal-progress-bar-width);
    height: 2rem;
    margin-bottom: 0.5rem;
    // padding-left: 60px;
    // padding-right: 60px;
    padding: 0 1rem;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 1rem;
      width: calc(var(--modal-progress-bar-width) - 2rem);
      height: 100%;
      border-top: 1px solid var(--color-mono-400);
    }

    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      border: var(--border-regular);
      border-radius: 50%;
      font-weight: 500;
      z-index: 1;
      transition:
        height 0.3s,
        width 0.3s,
        background-color 0.3s,
        margin-top 0.3s;

      // &:not(.vz-stepper__active) {
      //   margin-top: 2px;
      //   height: 28px;
      //   width: 28px;
      //   color: var(--color-mono-900);

      //   &:not(.vz-stepper__passed) {
      //     background-color: var(--color-mono-200);
      //   }
      // }

      // &.vz-stepper__active {
      //   margin-top: 2px;
      //   height: 28px;
      //   width: 28px;
      &--active {
        height: 32px;
        width: 32px;
        color: var(--color-mono-200);
        background-color: #00124e;
      }

      // &.vz-stepper__passed {
      //   cursor: pointer;
      //   color: var(--color-mono-200);
      //   background-color: #00124e;
      &:not(&--active) {
        margin-top: 2px;
        height: 28px;
        width: 28px;
        color: var(--color-mono-400);
      }

      &--passed {
        background-color: var(--color-primary-300);
      }

      // &:before {
      //   content: '';
      //   position: absolute;
      //   top: 50%;
      //   width: calc(100% - 120px);
      //   height: 100%;
      //   left: 60px;
      //   border-top: 2px solid #ccc;
      // }

      // &.vz-stepper__active:before {
      //   border-color: #00124e;
      &:not(&--passed):not(&--active) {
        background-color: var(--color-mono-200);
      }
    }
  }

  &__actions {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-grow: 1;

    > * {
      min-width: 6rem;
      margin-inline-end: 0.5rem;
    }

    &:before {
      position: absolute;
      top: 8px;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
    }
  }
}

.stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .step {
    display: flex;
    align-items: center;

    .step-number {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: #ccc;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 18px;

      &.active {
        background-color: #00124e;
      }

      &.passed {
        background-color: #a0a0d9;
        cursor: pointer;
      }
    }

    .step-line {
      height: 2px;
      width: 50px;
      background-color: #ccc;
      &.active {
        background-color: #00124e;
      }

      &.passed {
        background-color: #00124e;
      }
    }

    &.active .step-number,
    &.active .step-line {
      background-color: #00124e;
    }

    &.passed .step-number,
    &.passed .step-line {
      background-color: #a0a0d9;
    }
  }
}

$stepper-color-default: #ccc;
$stepper-color-completed: #00124e;

.stepper-wrapper {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  color: #ffff;
}

.step-name {
  display: none;
  color: $stepper-color-completed;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    font-size: 12px;
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    border-bottom: 2px solid $stepper-color-default;
    width: 100%;
    top: 15px;
    z-index: 2;
  }

  &::before {
    left: -50%;
  }

  &::after {
    left: 50%;
  }

  .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #ffff;
    border: solid 2px $stepper-color-default;
    margin-bottom: 6px;
    color: $stepper-color-completed;
    cursor: pointer;
    font-weight: 500;
  }

  &.active {
    font-weight: bold;

    .step-counter {
      background-color: $stepper-color-completed;
      color: #ffff;
    }

    .step-name {
      display: block;
    }
  }

  &.completed {
    .step-counter {
      background-color: $stepper-color-completed;
      color: #ffff;
    }

    &::after {
      border-bottom-color: $stepper-color-completed;
      z-index: 3;
    }
  }

  &:first-child::before {
    content: none;
  }

  &:last-child::after {
    content: none;
  }
}
</style>
