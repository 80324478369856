<template>
  <div class="message-input">
    <div v-if="payload.replyTo" class="mt-4 pa-4 relative">
      <vz-close-button @click="onUpdatePayload({ replyTo: undefined })" />

      <message-card v-if="payload.replyTo" disabled :participants="participants" :message="payload.replyTo" />
    </div>

    <message-share-location
      v-if="payload?.coordinates"
      class="mt-4"
      clearable
      :coordinates="payload?.coordinates"
      @clear="onUpdatePayload({ coordinates: undefined })"
    />

    <vz-attachment
      v-if="payload.attachments?.length"
      accept-type="image/jpeg,image/png,application/pdf"
      :value="payload.attachments"
      @update:value="(attachments) => onUpdatePayload({ attachments })"
    />

    <div ref="audioRecordRef" class="message-input__container">
      <div class="d-flex gap-1 align-center">
        <vz-icon
          name="svg:microphone"
          size="1.5rem"
          :color="isRecording ? 'primary-900' : 'mono-500'"
          @mousedown="startRecording"
          @mouseup="stopRecording"
          @touchstart="startRecording"
          @touchend="stopRecording"
        />

        <message-share-sheet :payload="value" @update:payload="onUpdatePayload" />
      </div>

      <vz-input
        v-if="!isRecording && !payload.audio"
        v-model="payload.message"
        class="flex-grow-1"
        hide-details
        :disabled="disabled"
        :rules="{ maxLength: [+maxLength] }"
        @enter="onSendMessage"
        @focus="$emit('force')"
        @blur="$emit('blur')"
      >
        <template #append>
          <vz-spinner v-if="loading" size="1.5rem" />
        </template>
      </vz-input>

      <div v-else class="flex-grow-1 text-center">
        {{ runTimeDisplay }}
      </div>

      <div v-if="isRecording || payload.audio" class="d-flex align-center gap-4 pe-4">
        <vz-icon name="svg:check" size="1rem" @click="stopRecording" />

        <vz-icon name="svg:xmark" size="1rem" @click="cancelRecording" />
      </div>

      <vz-icon v-else clickable name="svg:send" size="1.5rem" :color="loading ? 'mono-500' : 'primary-900'" @click="onSendMessage" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SendMessagePayload } from '@shared/components/messenger/messenger.type';
import type { BaseChat } from '@/views/messenger/types';
import { computed, type PropType, ref, watch } from 'vue';
import { useSwipe } from '@shared/composables/use-swipe';
import { AudioRecorder } from '@shared/services/audio-recorder';
import dayjs from 'dayjs';
import { LENGTH } from '@shared/constants/length';
import MessageShareSheet from '@shared/components/messenger/components/message-share-sheet/message-share-sheet.vue';
import MessageShareLocation from '@shared/components/messenger/components/message-share-sheet/message-share-location.vue';
import MessageCard from '@shared/components/messenger/components/message-card.vue';
import VzAttachment from '@shared/components/vz-attachment.vue';

const props = defineProps({
  value: { type: Object as PropType<SendMessagePayload | undefined>, default: undefined },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  maxLength: { type: [String, Number], default: LENGTH.LONG_DESCRIPTION },
  participants: { type: Array as PropType<BaseChat['participants']>, required: true },
});

const emit = defineEmits(['send', 'force', 'blur', 'update:payload']);

const payload = ref<SendMessagePayload>(props.value || {});
const audioRecordRef = ref<HTMLElement | undefined>(undefined);
const isRecording = ref<boolean>(false);
const startAt = ref<number>(0);
const recordTime = ref<number>(0);
const countId = ref<ReturnType<typeof setInterval>>();

const runTimeDisplay = computed(() => {
  const duration = dayjs.duration(recordTime.value);
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
});

const recorder = new AudioRecorder({
  startCallback: () => startCount(),
  stopCallback: async () => stopCount(),
});

const startRecording = async () => {
  if (props.disabled) {
    return;
  }

  await recorder.start();
};

const stopRecording = async (): Promise<void> => {
  await recorder.stop(async () => {
    recordTime.value = recorder.recordTime;
    emit('send', { audio: recorder.getFile(), message: undefined });
  });
};

const onSendMessage = (): void => {
  if (props.disabled) {
    return;
  }

  emit('send', { ...payload.value, audio: undefined });
};

const onUpdatePayload = (update: Partial<SendMessagePayload>): void => {
  emit('update:payload', { ...payload.value, ...update });
};

const cancelRecording = async (): Promise<void> => await recorder.cancel();

const startCount = () => {
  isRecording.value = true;
  startAt.value = Date.now();
  recordTime.value = 0;
  clearInterval(countId.value);

  countId.value = setInterval(() => (recordTime.value = Date.now() - startAt.value), 100);
};

const stopCount = () => {
  isRecording.value = false;
  clearInterval(countId.value);
};

useSwipe(audioRecordRef, {
  minStepSize: 30,
  onNext: cancelRecording,
});

watch(
  () => props.value,
  (value) => (payload.value = value || {})
);

defineExpose({ isLoading: isRecording, recordTime, startRecording, stopRecording });
</script>

<style scoped lang="scss">
.message-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__container {
    user-select: none;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-grow: 1;
    margin-bottom: 0.5rem;
    width: 100%;
    max-width: 100vw;

    .vz-input {
      max-width: calc(100% - 5rem);
    }
  }
}
</style>
