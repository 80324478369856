import type { RouteRecordRaw } from 'vue-router';
import Modules from '@/router/constants/modules';
import Notifications from '@/views/notifications/notifications.view.vue';

const notificationsRoutes: Readonly<RouteRecordRaw> = {
  path: Modules.PATHS[Modules.NOTIFICATIONS],
  name: Modules.NOTIFICATIONS,
  component: Notifications,
};

export default notificationsRoutes;
