import { useI18n } from 'vue-i18n';

export const useTranslator = (): ((value: string, fields?: Record<string, any>) => string) => {
  const { t } = useI18n();

  return (value: string, fields: Record<string, string | number | undefined> = {}) => {
    try {
      const processedValue = typeof t === 'function' ? t(value) : value;

      return processedValue.replace(/(<.*?>)/g, (_, key) => {
        const strippedKey = ('' + key).slice(1, -1);

        return t(fields[strippedKey] || '');
      });
    } catch (e) {
      console.error(e);
      return '';
    }
  };
};
