import type { MutationTree } from 'vuex';
import type { JobState } from '@/views/job/store/job.types';
import type { GetConversationRes } from '@/views/job/types';
import { CONVERSATION, SET_CONVERSATION, UPDATE_CONVERSATION } from '@/views/job/store/constants/conversation.constants';
import { JOB_OFFERS } from '@/views/job/store/constants/job-offer.constants';

const mutations: MutationTree<JobState> = {
  [SET_CONVERSATION]: (state, payload?: GetConversationRes) => {
    state[CONVERSATION] = payload || null;
  },

  [UPDATE_CONVERSATION]: (state, payload: Partial<GetConversationRes> = {}) => {
    const id = payload._id || state[CONVERSATION]?._id;

    if (!id) {
      return;
    }

    if (id === state[CONVERSATION]?._id) {
      state[CONVERSATION] = { ...state[CONVERSATION], ...payload } as GetConversationRes;
    }

    state[JOB_OFFERS] =
      state[JOB_OFFERS]?.map((position) => (position._id === state[CONVERSATION]?.positionId ? { ...position, status: payload.status } : position)) ||
      null;
  },
};

export default mutations;
