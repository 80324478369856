<template>
  <div class="card-badge" :style="{ color: getColor, top: `calc(${top} + 1px)` }">
    <div v-if="label" class="text-uppercase">{{ $t(label) }}</div>

    <slot name="default" />
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from 'vue';
import type { ColorsMap } from '@shared/services/css-service/types';
import getThemeColor from '@shared/services/css-service/helpers/get-theme-color';
import { SizeUnit } from '@shared/types';

const props = defineProps({
  top: { type: String as PropType<SizeUnit>, default: '1.5rem' },
  label: { type: String as PropType<string | undefined>, default: undefined },
  color: { type: String as PropType<ColorsMap | string | undefined>, default: undefined },
});

const getColor = computed((): string | undefined => (props.color ? getThemeColor(props.color) : 'currentColor'));
</script>

<style lang="scss" scoped>
.card-badge {
  position: absolute;
  @include inline-end(-0.45rem);
  width: fit-content;
  min-width: 2rem;
  height: 1.5rem;
  padding: 0 0.5rem;
  box-shadow: var(--shadow-light);
  top: calc(1.5rem + 1px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-background-regular);

  @include before {
    @include rtl(border-radius, 0 0.25rem 0.25rem 0.25rem, 0.25rem 0 0.25rem 0.25rem);
    background-color: currentColor;
    opacity: 0.2;
  }

  @include after {
    top: calc(-0.45rem + 0.5px);
    @include inline-end(-1px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.4rem 0 0 0.5rem;
    border-color: transparent transparent transparent currentColor;
    @include rtl(transform, rotate(270deg), rotate(0deg));
    opacity: 0.5;
  }
}
</style>
