import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, withKeys as _withKeys, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, ref } from 'vue';
import { isRtl } from '@/plugins/i18n/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-popover',
  props: {
  dim: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  size: { type: [String, Number], default: 42 },
  offsetX: { type: [String, Number], default: 4 },
  offsetY: { type: [String, Number], default: 0 },
},
  setup(__props, { expose: __expose }) {

const props = __props;

const isActive = ref<boolean>(false);
const popoverRef = ref<HTMLDivElement>();
const popoverContent = ref<HTMLDivElement>();
const loadingIndex = ref<number | null>(null);

const popoverStyle = computed(() => {
  if (!popoverRef.value || !popoverContent.value) {
    return {};
  }

  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
  const { offsetLeft: mainLeft, offsetTop: mainTop, clientHeight: mainHeight, clientWidth: mainWidth } = popoverRef.value;
  const { clientWidth: contentWidth } = popoverContent.value;

  const isLtr = !isRtl();
  const positionX = isLtr ? mainLeft + +props.offsetX : mainLeft + (mainWidth - contentWidth) - +props.offsetX;

  const targetTop = mainTop + mainHeight + 4 + +props.offsetY;
  const targetLeft = contentWidth + positionX < windowWidth ? Math.max(positionX, 4) + 'px' : 'initial';
  const targetRight = contentWidth + positionX > windowWidth ? Math.min(windowWidth - positionX - mainWidth, windowWidth) + 'px' : 'initial';

  return {
    top: targetTop + 'px',
    left: targetLeft,
    right: targetRight,
    maxHeight: windowHeight - targetTop - 16 + 'px',
  };
});

const onClose = () => {
  isActive.value = false;
  window.removeEventListener('click', onClose);
};

const onOpen = (): void => {
  isActive.value = !isActive.value;
  loadingIndex.value = null;

  if (isActive.value) {
    window.addEventListener('click', onClose);
  }
};

__expose({ close: onClose, open: onOpen });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['vz-popover', { 'vz-popover--background': isActive.value && __props.dim }])
  }, [
    _createElementVNode("div", {
      ref_key: "popoverRef",
      ref: popoverRef,
      class: _normalizeClass(['vz-popover__activator', { 'vz-popover__activator--disabled': __props.disabled }]),
      role: "button",
      onKeydown: _withKeys(onOpen, ["enter"]),
      onClick: _withModifiers(onOpen, ["stop"])
    }, [
      _renderSlot(_ctx.$slots, "activator")
    ], 34),
    (isActive.value && !__props.disabled)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          ref_key: "popoverContent",
          ref: popoverContent,
          class: "vz-popover__content",
          style: _normalizeStyle(popoverStyle.value)
        }, [
          _renderSlot(_ctx.$slots, "top", { close: onClose }),
          _renderSlot(_ctx.$slots, "default", { close: onClose }),
          _renderSlot(_ctx.$slots, "bottom", { close: onClose })
        ], 4))
      : _createCommentVNode("", true)
  ], 2))
}
}

})