import type { RootState } from '@/store';
import type { JobManagerProjectState } from '@/views/job/views/manager/modules/project/store/job-manager-project.types';
import type { ActionTree } from 'vuex';
import type { BaseId } from '@shared/models';
import {
  CreateProjectReq,
  GetProjectDetailsRes,
  RemoveFromProjectReq,
  SearchProjectReq,
  SearchProjectRes,
  SendProjectAgreementReq,
  SummarizeProjectAgreementRes,
  UpdateProjectReq,
  SummarizeProjectAgreementReq,
  GetProjectFilesReq,
  GetProjectFilesRes,
} from '@/views/job/types/project.types';
import { isEqual } from 'lodash';
import { container } from 'tsyringe';
import { getAssignedUserId } from '@/store/auth/helpers';
import JobManagerProjectService from '@/views/job/views/manager/modules/project/service/job-manager-project.service';
import {
  CREATE_AGREEMENT_PDF,
  CREATE_PROJECT,
  DELETE_PROJECT,
  GET_PROJECT_DETAILS,
  PROJECT_AGREEMENT_MANUAL_APPROVAL,
  GET_PROJECT_FILES,
  PROJECT_DETAILS,
  PROJECT_FILTER,
  PROJECT_PAGINATION,
  REMOVE_FROM_PROJECT,
  SEARCH_PROJECTS,
  SEND_PROJECT_AGREEMENT,
  SET_PROJECT_DETAILS,
  SET_PROJECT_FILTER,
  SET_PROJECTS,
  SUMMARIZE_PROJECT_AGREEMENT,
  UPDATE_PROJECT,
  UPLOAD_PROJECT_FILES,
  GET_PROJECT_FILE,
} from '@/views/job/views/manager/modules/project/store/job-manager-project.constants';
import SocketClientService from '@shared/services/socket-service/socket-client.service';
import { SocketEnum } from '@shared/services/socket-service/socket.enum';
import { StatusEnum } from '@/constants';
import { objectToFormData } from '@shared/helpers';

const jobManagerProjectService = container.resolve(JobManagerProjectService);

const actions: ActionTree<JobManagerProjectState, RootState> = {
  [PROJECT_AGREEMENT_MANUAL_APPROVAL]: async ({ dispatch, state }, agreementKey: string): Promise<void> => {
    const projectId = state[PROJECT_DETAILS]!._id;
    const agreement = state[PROJECT_DETAILS]?.agreement.find((agreement) => agreement.key === agreementKey);

    await jobManagerProjectService[PROJECT_AGREEMENT_MANUAL_APPROVAL]({
      projectId,
      agreementKey,
      isApproved: agreement?.status !== StatusEnum.APPROVED,
    });
    await dispatch(GET_PROJECT_DETAILS);
  },

  [SEARCH_PROJECTS]: async ({ commit, state }, payload: SearchProjectReq): Promise<SearchProjectRes> => {
    const userId = getAssignedUserId();
    const { page = { index: 0 }, ...filters } = payload || {};

    if (!isEqual(filters, state[PROJECT_FILTER])) {
      page.index = 0;
    }

    const jobs = await jobManagerProjectService[SEARCH_PROJECTS]({ ...filters, page: { ...state[PROJECT_PAGINATION], ...page }, userId });
    commit(SET_PROJECT_FILTER, filters);
    commit(SET_PROJECTS, jobs);

    return jobs;
  },

  [CREATE_PROJECT]: async ({ dispatch }, payload: CreateProjectReq): Promise<string> => {
    const userId = getAssignedUserId();
    const { projectId } = await jobManagerProjectService[CREATE_PROJECT]({ ...payload, userId });
    await dispatch(SEARCH_PROJECTS);

    return projectId;
  },

  [UPDATE_PROJECT]: async ({ dispatch, state }, payload: UpdateProjectReq): Promise<void> => {
    const userId = getAssignedUserId();
    const projectId = (payload.projectId || state[PROJECT_DETAILS]?._id)!;

    await jobManagerProjectService[UPDATE_PROJECT]({ ...payload, projectId, userId });
    await dispatch(GET_PROJECT_DETAILS);
    await dispatch(SEARCH_PROJECTS);
  },

  [DELETE_PROJECT]: async ({ dispatch, commit, state }, projectId?: string): Promise<void> => {
    const id = projectId || state[PROJECT_DETAILS]?._id;

    if (!id) {
      return;
    }

    const userId = getAssignedUserId();
    commit(SET_PROJECT_DETAILS, null);
    await jobManagerProjectService[DELETE_PROJECT](id, userId);
    await dispatch(SEARCH_PROJECTS);
  },

  [GET_PROJECT_DETAILS]: async ({ commit, state, dispatch }, projectId?: string | null): Promise<GetProjectDetailsRes | null> => {
    const currentId = state[PROJECT_DETAILS]?._id;
    const id = projectId || state[PROJECT_DETAILS]?._id;

    const $socket = (projectDetails?: GetProjectDetailsRes, isEnabled?: boolean, userId?: BaseId) => {
      if (!projectDetails) {
        return;
      }

      if (isEnabled) {
        SocketClientService.join([{ roomId: projectDetails._id, roomKey: 'job-manager-project' }], userId);
        SocketClientService.on(SocketEnum.UPDATE, (id: string) => (id === projectDetails._id ? dispatch(GET_PROJECT_DETAILS) : null), true);
      } else {
        SocketClientService.leave([{ roomId: projectDetails._id, roomKey: 'job-manager-project' }]);
        SocketClientService.off(SocketEnum.UPDATE);
      }
    };

    if (currentId && (currentId !== id || projectId === null)) {
      $socket(state[PROJECT_DETAILS]!, false);
      commit(SET_PROJECT_DETAILS, null);
    }

    if (!id || projectId === null) {
      return null;
    }

    const userId = getAssignedUserId();
    const res = await jobManagerProjectService[GET_PROJECT_DETAILS](id, userId);
    $socket(res, true, userId);
    commit(SET_PROJECT_DETAILS, res || null);

    return res;
  },

  [REMOVE_FROM_PROJECT]: async ({ dispatch }, payload: RemoveFromProjectReq): Promise<void> => {
    const userId = getAssignedUserId();
    await jobManagerProjectService[REMOVE_FROM_PROJECT]({ ...payload, userId });
    await dispatch(GET_PROJECT_DETAILS);
  },

  [SUMMARIZE_PROJECT_AGREEMENT]: async ({ state }, payload: SummarizeProjectAgreementReq): Promise<SummarizeProjectAgreementRes | null> => {
    const userId = getAssignedUserId();
    const { projectId, agreementKey, ...rest } = payload;

    return jobManagerProjectService[SUMMARIZE_PROJECT_AGREEMENT]((projectId || state[PROJECT_DETAILS]?._id)!, agreementKey, { userId, ...rest });
  },

  [SEND_PROJECT_AGREEMENT]: async ({ state, dispatch }, payload: SendProjectAgreementReq): Promise<void> => {
    const userId = getAssignedUserId();
    const projectId = payload.projectId || state[PROJECT_DETAILS]!._id;

    await jobManagerProjectService[SEND_PROJECT_AGREEMENT]({ ...payload, projectId, userId });
    await dispatch(GET_PROJECT_DETAILS);
    await dispatch(SEARCH_PROJECTS);
  },

  [CREATE_AGREEMENT_PDF]: async ({ state }, payload: SendProjectAgreementReq): Promise<string> => {
    const userId = getAssignedUserId();
    const { projectId, agreementKey, ...rest } = payload;

    return await jobManagerProjectService[CREATE_AGREEMENT_PDF]((projectId || state[PROJECT_DETAILS]?._id)!, agreementKey, { userId, ...rest });
  },

  [UPLOAD_PROJECT_FILES]: async ({ state }, files: Array<File>): Promise<void> => {
    const projectId = state[PROJECT_DETAILS]!._id;

    await jobManagerProjectService[UPLOAD_PROJECT_FILES](projectId, objectToFormData({ files, filename: files.map(({ name }) => name) }));
  },

  [GET_PROJECT_FILES]: async ({ state }, payload: Omit<GetProjectFilesReq, 'projectId'>): Promise<GetProjectFilesRes> => {
    const projectId = state[PROJECT_DETAILS]!._id;

    return jobManagerProjectService[GET_PROJECT_FILES](projectId, payload);
  },

  [GET_PROJECT_FILE]: async ({ state }, payload: { projectId?: string; key: string; fileName: string }): Promise<void> => {
    const projectId = payload.projectId || state[PROJECT_DETAILS]!._id;

    jobManagerProjectService[GET_PROJECT_FILE](projectId, payload.key, payload.fileName);
  },
};

export default actions;
