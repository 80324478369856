export enum SocketEnum {
  SYSTEM = 'SYSTEM',
  SETUP = 'SETUP',
  UPDATE = 'UPDATE',
  JOIN_CHAT = 'JOIN',
  LEAVE_CHAT = 'LEAVE',
  ONLINE = 'ONLINE',
  TYPING = 'TYPING',
  PUSH = 'PUSH',
  SEEN = 'SEEN',
  CONNECTED = 'CONNECTED',
}
