import type { AxiosRequestConfig } from 'axios';
import { DOMAIN } from '@/shared/constants/domain';

const TIMEOUT = 30 * 1000; // 30 seconds

// AXIOS DEFAULT CONFIG
export const axiosConfig: AxiosRequestConfig = {
  withCredentials: true,
  baseURL: DOMAIN,
  timeout: TIMEOUT,
  headers: { 'Content-Type': 'application/json', VER: 1 },
};
