import type { RouteRecordRaw } from 'vue-router';
import Modules from '@/router/constants/modules';

const JobManagerPost = () => import(/* webpackChunkName: "job-manager" */ '@/views/job/views/manager/modules/post/post-manager.view.vue');

const postJobRoutes: Readonly<RouteRecordRaw> = {
  path: `${Modules.PATHS[Modules.MANAGER.JOB_MANAGER_POST]}/:jobId?/:positionId?/:conversationId?`,
  name: Modules.MANAGER.JOB_MANAGER_POST,
  component: JobManagerPost,
  meta: {
    parentName: Modules.MANAGER.JOB_MANAGER,
  },
};

export default postJobRoutes;
