import { type RootState } from '@/store';
import type { ActionTree } from 'vuex';
import type { NotificationState, GetNotificationReq, GetNotificationsRes } from '@/views/notifications/store/notification.types';
import { container } from 'tsyringe';
import NotificationService from '@/views/notifications/store/service/notifications.service';
import { GET_NOTIFICATIONS } from '@/views/notifications/store/notification.constants';

const notificationService = container.resolve(NotificationService);

const notificationActions: ActionTree<NotificationState, RootState> = {
  [GET_NOTIFICATIONS]: async (_, payload: GetNotificationReq): Promise<GetNotificationsRes> => {
    return await notificationService[GET_NOTIFICATIONS](payload);
  },
};

export default notificationActions;
