import type { ActionTree } from 'vuex';
import type { JobState } from '@/views/job/store/job.types';
import type { SearchJobOffersReq, SearchJobOffersRes } from '@/views/job/types';
import { RootState } from '@/store';
import { container } from 'tsyringe';
import JobService from '@/views/job/service/job.service';
import {
  GET_JOB_OFFER_DETAILS,
  JOB_OFFER_DETAILS,
  JOB_OFFERS_PAGINATION,
  SEARCH_JOB_OFFERS,
  SET_JOB_OFFER_DETAILS,
  SET_JOB_OFFERS,
} from '@/views/job/store/constants/job-offer.constants';
import { LIST_CLASSIFICATIONS } from '@/views/job/store/constants/job.constants';
import { SET_CONVERSATION } from '@/views/job/store/constants/conversation.constants';

const jobService = container.resolve(JobService);

const actions: ActionTree<JobState, RootState> = {
  [SEARCH_JOB_OFFERS]: async ({ commit, state, dispatch }, { page, ...filters }: SearchJobOffersReq): Promise<SearchJobOffersRes> => {
    const res = await jobService[SEARCH_JOB_OFFERS]({ ...filters, page: { ...state[JOB_OFFERS_PAGINATION], ...(page || {}) } });
    await dispatch(LIST_CLASSIFICATIONS);

    commit(SET_JOB_OFFERS, res || {});

    return res;
  },

  [GET_JOB_OFFER_DETAILS]: async ({ commit, state, dispatch }, positionId?: string): Promise<void> => {
    const id = positionId || state[JOB_OFFER_DETAILS]?._id;

    commit(SET_CONVERSATION);
    commit(SET_JOB_OFFER_DETAILS);

    if (!id) {
      return;
    }

    commit(SET_JOB_OFFER_DETAILS, await jobService[GET_JOB_OFFER_DETAILS](id));
  },
};

export default actions;
