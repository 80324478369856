import { type CalendarState } from '@/views/calendar/store/index';
import type { ActionTree } from 'vuex';
import type { RootState } from '@/store';
import type { GetCalendarEventsReq, GetCalendarEventsRes, GetUpcomingEventsReq, GetUpcomingEventsRes } from 'src/views/calendar/types';
import { container } from 'tsyringe';
import CalendarService from '@/views/calendar/service/calendar.service';
import { GET_CALENDAR_EVENTS, GET_UPCOMING_EVENTS } from '@/views/calendar/store/calendar.constants';
import { getAssignedUserId } from '@/store/auth/helpers';

const calendarService = container.resolve(CalendarService);

const calendarActions: ActionTree<CalendarState, RootState> = {
  [GET_UPCOMING_EVENTS]: async (_, payload?: GetUpcomingEventsReq): Promise<GetUpcomingEventsRes> => {
    const userId = getAssignedUserId();

    return await calendarService[GET_UPCOMING_EVENTS]({ ...payload, userId });
  },
  [GET_CALENDAR_EVENTS]: async (_, payload?: GetCalendarEventsReq): Promise<GetCalendarEventsRes> => {
    const userId = getAssignedUserId();
    const { year = new Date().getFullYear(), month = new Date().getMonth() + 1 } = payload || {};
    const getCalendarEventsPayload = { year: +year, month: +month };

    return await calendarService[GET_CALENDAR_EVENTS]({ ...getCalendarEventsPayload, userId });
  },
};

export default calendarActions;
