import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "message-card__header" }
const _hoisted_2 = { class: "message-card__header-sender" }
const _hoisted_3 = { key: 0 }

import type { ProfileBaseDetails } from 'src/views/employee/types';
import { computed, type PropType } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'message-typing',
  props: {
  user: { type: Object as PropType<ProfileBaseDetails>, required: true },
  online: { type: Array as PropType<Array<string>>, required: true },
  status: { type: String as PropType<string | undefined>, default: undefined },
},
  setup(__props) {

const props = __props;

const isOnline = computed(() => props.online?.includes(props.user._id));

return (_ctx: any,_cache: any) => {
  const _component_vz_avatar = _resolveComponent("vz-avatar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_vz_avatar, {
        hid: __props.user?._id,
        color: isOnline.value ? 'green-500' : undefined,
        size: "36"
      }, null, 8, ["hid", "color"]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("span", null, _toDisplayString(__props.user?.firstName), 1),
          _createElementVNode("span", null, _toDisplayString(__props.user?.lastName), 1),
          _createElementVNode("span", null, _toDisplayString(__props.user?.nickName), 1)
        ]),
        (__props.status)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t(__props.status)), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "message-card__content" }, [
      _createElementVNode("div")
    ], -1))
  ]))
}
}

})