/**
 * Generates a hash based on the provided value and key.
 * @param value The base value for generating the key.
 * @param key An optional additional key component.
 * @returns A string representing the generated hash.
 */
const generateKey = (value: string | number = Math.round(Math.random() * 100000000), key?: string | number): string => {
  const input = '' + value + (key || '');
  return input
    .split('')
    .reduce((sum, char, index) => sum + char.charCodeAt(0) ** ((index % 10) + 1), 0)
    .toString(16);
};

/**
 * Generates a unique key based on the current timestamp and optional additional key.
 * @param value The base value for generating the key, defaults to current timestamp.
 * @param key An optional additional key component.
 * @returns A unique key string.
 */

export const uniqueKey = (value: string | number = Math.round(Math.random() * 100000000), key?: string | number): string => generateKey(value, key);
