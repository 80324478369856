import FirebaseClientService from '@shared/services/firebase-client.service';
import AuthService from '@/store/auth/auth.service';
import { SAVE_TOKEN } from '@/store/auth/auth.constants';
import { container } from 'tsyringe';

const authService = container.resolve(AuthService);

export const firebaseMessaging = new FirebaseClientService({
  saveTokenCallback: async (token: string) => {
    try {
      await authService[SAVE_TOKEN](token);
    } catch (e) {
      console.error('firebaseMessaging:onInitCallback', 'failed', e);
    }
  },
});
