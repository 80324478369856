import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toHandlers as _toHandlers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["data-errors"]
const _hoisted_2 = ["aria-label", "value", "type", "data-display"]
const _hoisted_3 = { key: 1 }

import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import { computed, type PropType, ref } from 'vue';
import { useValidator } from '@/shared/components/fields/helpers';
import { useTranslator } from '@/plugins/i18n/helpers';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-datepicker',
  props: {
  name: { type: String as PropType<string | undefined>, default: undefined },
  modelValue: { type: Number as PropType<number | undefined>, default: undefined },
  type: { type: String as PropType<'date' | 'datetime-local' | 'time'>, default: 'date' },
  label: { type: String, default: '' },
  ariaLabel: { type: String, default: '' },
  nullable: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
  clearable: { type: Boolean, default: false },
  hideDetails: { type: Boolean, default: false },
  errorMessage: { type: String as PropType<string | null>, default: null },
  rules: { type: Object as PropType<ValidatorFieldRules>, default: () => ({}) },
  min: { type: Number as PropType<number | undefined>, default: undefined },
  max: { type: Number as PropType<number | undefined>, default: undefined },
  classDatepicker: { type: String, default: '' },
},
  emits: ['update:model-value'],
  setup(__props, { emit: __emit }) {

const t = useTranslator();

const props = __props;

const emit = __emit;

const timestampToDate = (timestamp?: number, isMonthIndex = true): [string, string, string] | null => {
  if (!timestamp) {
    return null;
  }

  const date = new Date(timestamp);

  return ['' + date.getFullYear(), ('0' + (date.getMonth() + (isMonthIndex ? 0 : 1))).slice(-2), ('0' + date.getDate()).slice(-2)];
};

const timestampToTime = (timestamp?: number): [string, string] | null => {
  if (!timestamp) {
    return null;
  }

  const date = new Date(timestamp);

  return [('0' + date.getHours()).slice(-2), ('0' + date.getMinutes()).slice(-2)];
};

const vBind = computed(() => ({
  ...(props.min ? { min: timestampToDate(props.min, false)?.join('-') } : {}),
  ...(props.max ? { max: timestampToDate(props.max, false)?.join('-') } : {}),
}));

const vModel = computed({
  get: (): string | undefined => {
    if (!props.modelValue) {
      return undefined;
    }

    const date = timestampToDate(props.modelValue)?.join('-');

    switch (props.type) {
      case 'date':
        return date;
      case 'datetime-local': {
        const time = timestampToTime(props.modelValue)?.join(':');

        return `${date} ${time}`;
      }
      case 'time': {
        return timestampToTime(props.modelValue)?.join(':');
      }
      default:
        return undefined;
    }
  },
  set: (value) => value,
});

const displayDate = computed((): string | null => {
  const date = timestampToDate(props.modelValue, false)?.reverse().join('/');

  if (!date) {
    return null;
  }

  switch (props.type) {
    case 'time':
      return timestampToTime(props.modelValue)?.join(':') || null;
    case 'date':
      return date;
    case 'datetime-local': {
      const time = timestampToTime(props.modelValue)?.join(':');

      return `${date} ${time}`;
    }
    default:
      return null;
  }
});

const isFocus = ref<boolean>(false);
const blurTimeout = ref<ReturnType<typeof setTimeout>>();
const inputRef = ref<Element | undefined>(undefined);
const dateInputRef = ref<HTMLInputElement | undefined>(undefined);

const onClick = (event: MouseEvent): void => {
  if (event.isTrusted) {
    dateInputRef.value?.focus();
    dateInputRef.value?.showPicker();
  }
};

const onChange = ({ target }: { target: HTMLInputElement }): void => {
  if (!target.value) {
    emit('update:model-value', props.nullable ? null : undefined);

    return;
  }

  switch (props.type) {
    case 'time': {
      const day = new Date(props.modelValue || 0).getDate();
      const month = new Date(props.modelValue || 0).getMonth();
      const year = new Date(props.modelValue || 0).getFullYear();
      const [hours = 0, minutes = 0] = target.value.split(':');

      emit('update:model-value', new Date(year, month, day, +hours, +minutes));
      break;
    }
    case 'date': {
      const date = target.value.slice(0, 10);
      const [yearValue, monthValue, dayValue] = date.split('-');

      emit('update:model-value', new Date(+yearValue, +monthValue - 1, +dayValue).valueOf());
      break;
    }
    case 'datetime-local': {
      const date = target.value.slice(0, 10);
      const time = target.value.slice(11);

      const [yearValue, monthValue, dayValue] = date.split('-');
      const [hoursValue, minutesValue] = time.split(':');

      emit('update:model-value', new Date(+yearValue, +monthValue - 1, +dayValue, +(hoursValue || 0), +(minutesValue || 0)).valueOf());
      break;
    }
  }
};

const onFocus = () => {
  if (blurTimeout.value) {
    clearTimeout(blurTimeout.value);
  }

  isFocus.value = true;
};

const onBlur = () => {
  blurTimeout.value = setTimeout(() => (isFocus.value = false), 500);
};

const { validateMessage, isTouched } = useValidator(
  computed(() => props.modelValue),
  computed(() => props.rules),
  props.name || props.label
);

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!

  return (_openBlock(), _createElementBlock("div", {
    ref_key: "inputRef",
    ref: inputRef,
    class: _normalizeClass(["vz-datepicker", { 'vz-datepicker--loading': __props.loading, 'vz-datepicker--disabled': __props.disabled }]),
    "data-errors": _unref(validateMessage)
  }, [
    _createElementVNode("label", {
      class: _normalizeClass(__props.classDatepicker)
    }, _toDisplayString(_ctx.$t(__props.label)), 3),
    _createElementVNode("div", _mergeProps({ class: "vz-datepicker__container" }, _toHandlers(!vModel.value ? { click: onClick } : {}, true)), [
      _renderSlot(_ctx.$slots, "prefix"),
      _createElementVNode("input", _mergeProps({
        ref_key: "dateInputRef",
        ref: dateInputRef,
        tabindex: "0",
        "aria-label": _unref(t)('COMPONENT_LABELS.PICKER', { value: __props.ariaLabel || __props.label || 'GENERAL.DATE' }),
        value: vModel.value,
        type: __props.type,
        "data-display": displayDate.value
      }, vBind.value, {
        onFocus: onFocus,
        onBlur: onBlur,
        onInput: onChange,
        onClick: onClick
      }), null, 16, _hoisted_2),
      (!!vModel.value && __props.clearable)
        ? (_openBlock(), _createBlock(_component_vz_icon, {
            key: 0,
            name: "svg:xmark",
            size: "0.75rem",
            role: "button",
            color: !!vModel.value ? 'primary-900' : 'mono-700',
            clickable: !!vModel.value,
            "aria-label": _unref(t)('COMPONENT_LABELS.BUTTON', { value: 'GENERAL.CLEAR' }),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:model-value', undefined)))
          }, null, 8, ["color", "clickable", "aria-label"]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "append")
    ], 16),
    _createElementVNode("div", {
      class: _normalizeClass(['vz-datepicker__error', { 'vz-datepicker__error--hidden': __props.hideDetails }])
    }, [
      (_unref(validateMessage))
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass({ 'vz-datepicker__error-internal': !_unref(isTouched) })
          }, _toDisplayString(_ctx.$t(_unref(validateMessage))), 3))
        : (__props.errorMessage)
          ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t(__props.errorMessage)), 1))
          : _createCommentVNode("", true)
    ], 2)
  ], 10, _hoisted_1))
}
}

})