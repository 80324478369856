<template>
  <div>
    <div class="message-card__header">
      <vz-avatar :hid="user?._id" :color="isOnline ? 'green-500' : undefined" size="36" />

      <div class="message-card__header-sender">
        <div>
          <span>{{ user?.firstName }}</span>
          <span>{{ user?.lastName }}</span>
          <span>{{ user?.nickName }}</span>
        </div>

        <p v-if="status">{{ $t(status) }}</p>
      </div>
    </div>

    <div class="message-card__content">
      <div />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProfileBaseDetails } from 'src/views/employee/types';
import { computed, type PropType } from 'vue';

const props = defineProps({
  user: { type: Object as PropType<ProfileBaseDetails>, required: true },
  online: { type: Array as PropType<Array<string>>, required: true },
  status: { type: String as PropType<string | undefined>, default: undefined },
});

const isOnline = computed(() => props.online?.includes(props.user._id));
</script>

<style scoped lang="scss">
.message-card {
  &__header {
    display: flex;

    &-sender {
      margin: 0 0.25rem;
      display: flex;
      flex-direction: column;

      > div:first-child {
        span {
          padding: 0 2px;
        }
      }

      > p:last-child {
        font-size: var(--font-size-14);
      }
    }
  }

  &__content {
    margin: 0 1rem;
    padding: 0.5rem 1rem;
    background-color: var(--color-primary-100);
    max-width: fit-content;
    @include rtl(border-radius, 0.5rem 0 0.5rem 0.5rem, 0 0.5rem 0.5rem 0.5rem);

    > div {
      position: relative;
      height: 0.75rem;
      width: 0.75rem;
      border-radius: 50%;
      box-shadow: 0 0.75em 0 -0.2rem var(--color-primary-900);
      animation: typing 0.8s ease-in-out alternate infinite;
      animation-delay: 0.32s;
      top: -1.25rem;
      margin: 0 1rem;

      &:after,
      &:before {
        content: '';
        position: absolute;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        box-shadow: inherit;
        animation: inherit;
      }

      &:before {
        left: -0.75rem;
        animation-delay: 0.48s;
      }

      &:after {
        right: -0.75rem;
        animation-delay: 0.16s;
      }

      @keyframes typing {
        0% {
          box-shadow: 0 2em 0 -0.2em currentcolor;
        }
        100% {
          box-shadow: 0 1em 0 -0.2em currentcolor;
        }
      }
    }
  }
}
</style>
