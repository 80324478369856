<template>
  <template v-if="!multiple">
    <vz-canvas-board-cropper
      :ref="(el: any) => (vzCanvasBoardCropperRefs[0] = el)"
      v-model="vModel"
      :rules="rules"
      :label="label"
      :name="name"
      :hide-details="hideDetails"
      :error-message="errorMessage"
      v-bind="$attrs"
    />
  </template>

  <div v-else :data-errors="validateMessage">
    <p v-if="label" class="mb-2">{{ $t(label) }}</p>

    <div class="row-flex-4">
      <vz-canvas-board-cropper
        v-for="(_, index) in vMultipleModel"
        :key="index"
        :ref="(el: any) => (vzCanvasBoardCropperRefs[index] = el)"
        v-model="vMultipleModel[index]"
        v-bind="$attrs"
        @update:model-value="onUpdateMultipleModel(index, $event)"
      />
    </div>

    <div :class="['vz-input__error', { 'vz-input__error--hidden': hideDetails && !validateMessage && !externalError }]" role="alert">
      <p v-if="validateMessage" :class="{ 'vz-input__error-internal': !isTouched }">{{ $t(validateMessage) }}</p>
      <p v-else-if="externalError">{{ $t(externalError) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import type VzCanvasBoardCropper from '@shared/components/fields/vz-canvas-board-cropper.vue';
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { ErrorResponse } from '@shared/services/api-service/models';
import { computed, type PropType, ref } from 'vue';
import { useValidator } from '@shared/components/fields/helpers';
import { useTranslator } from '@/plugins/i18n/helpers';

const t = useTranslator();

const props = defineProps({
  modelValue: { type: [String, Array] as PropType<string | Array<string> | undefined>, required: true },
  multiple: { type: [Boolean, Number], default: false },
  name: { type: String as PropType<string | undefined>, default: undefined },
  label: { type: String as PropType<string | undefined>, default: undefined },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  hideDetails: { type: Boolean, default: false },
  errorMessage: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
});

const emit = defineEmits(['update:model-value']);
const vzCanvasBoardCropperRefs = ref<Array<InstanceType<typeof VzCanvasBoardCropper> | null>>([]);

const vModel = computed({
  get: (): string => (Array.isArray(props.modelValue) ? props.modelValue[0] : props.modelValue || ''),
  set: (value: string) => {
    emit('update:model-value', value);
  },
});

const vMultipleModel = computed({
  get: (): Array<string> => {
    const currentValue = [...(Array.isArray(props.modelValue) ? props.modelValue : []).filter((value) => !!value)];

    return typeof props.multiple === 'boolean' || currentValue.length < props.multiple ? [...currentValue, ''] : currentValue;
  },
  set: (value: Array<string>) => {
    emit('update:model-value', value?.filter((value) => !!value));
  },
});

const { validateMessage, isTouched } = useValidator(
  computed(() => (props.multiple ? vMultipleModel.value : vModel.value)),
  computed(() => props.rules),
  props.name || props.label || 'COMPONENT_LABELS.IMAGE_CROPPER'
);

const externalError = computed(() => {
  if (!props.errorMessage) {
    return;
  }

  if (typeof props.errorMessage === 'string') {
    return props.errorMessage;
  }

  const { message, ...fields } = props.errorMessage.errorMessage!.pop() || {};

  return message ? t(message, { ...fields, ...(props.label ? { property: props.label } : {}) }) : undefined;
});

const onUpdateMultipleModel = (index: number, value?: string): void => {
  vMultipleModel.value = vMultipleModel.value.map((item, i) => (i === index ? value || '' : item));
};

const saveAll = (): void => {
  vzCanvasBoardCropperRefs.value.forEach((ref) => ref?.save());
};

defineExpose({ save: saveAll });
</script>
