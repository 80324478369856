<template>
  <vz-modal
    title="BACKOFFICE.SYSTEM_LOG.TITLE"
    hide-submit
    cancel-text="GENERAL.CLOSE"
    :is-open="!!log"
    :size="VzOverlayEnum.XLARGE"
    @close="() => (log = null)"
  >
    <template #header>
      <div class="px-4 pt-1 d-flex gap-4">
        <div class="text-title-1 ltr">{{ log?.title }}</div>
        <vz-badge :text="log?.type" :color="SystemLogColorEnum[log?.type as keyof typeof SystemLogColorEnum]" />
      </div>

      <employee-strip v-if="log?.user" horizontal show-company font-size="1rem" :item="log.user" avatar-size="42" />
    </template>

    <vz-json-viewer auto-tab :value="log?.data" />
  </vz-modal>
</template>

<script setup lang="ts">
import { VzOverlayEnum } from '@shared/components/overlay/vz-overlay.enum';
import { SystemLogColorEnum } from '@/views/backoffice/system-log/constants/system-log.enum';
import EmployeeStrip from '@/views/employee/components/employee-strip.vue';
import VzJsonViewer from '@shared/components/vz-json-viewer.vue';
import { useBackofficeLogStore } from '@/views/backoffice/system-log/store';
import { storeToRefs } from 'pinia';

const useBackofficeJobStore = useBackofficeLogStore();

const { log } = storeToRefs(useBackofficeJobStore);
</script>

<style scoped lang="scss"></style>
