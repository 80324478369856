// actions
export const LIST_GROUPS = 'list-groups';
export const GET_GROUP = 'get-group';
export const ADD_GROUP = 'add-group';
export const DELETE_GROUP = 'delete-group';
export const UPDATE_GROUP = 'update-group';
export const ADD_GROUP_MEMBER = 'add-group-member';
export const REMOVE_GROUP_MEMBER = 'remove-group-member';

// mutations
export const SET_ACTIVE_GROUP = 'set-active-group';

// state
export const ACTIVE_GROUP = 'active-group';
