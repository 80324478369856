import { computed, type ComputedRef, ref, type Ref, watch } from 'vue';
import FieldValidator from '@shared/services/validator/field-validator/field-validator.service';
import { useTranslator } from '@/plugins/i18n/helpers';
import { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';

export const useValidator = (
  valueModel: Ref,
  rules: Ref<ValidatorFieldRules | undefined>,
  label: string
): { validateMessage: ComputedRef<string | null>; isTouched: Ref<boolean> } => {
  const t = useTranslator();
  const isTouched = ref<boolean>(false);

  watch(
    () => [valueModel.value],
    () => (isTouched.value = true),
    { deep: true }
  );

  return {
    validateMessage: computed((): string | null => {
      if (!rules.value) {
        return null;
      }

      const validator1 = new FieldValidator([rules.value]);
      const result = validator1.validate(valueModel.value);

      return result ? t(result.message, { value: result.value, property: label }) : null;
    }),
    isTouched,
  };
};
