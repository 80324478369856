import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, toHandlers as _toHandlers, mergeProps as _mergeProps, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "split-view__panel--dynamic-buttons" }

import { computed, onUnmounted, type PropType, ref, watch } from 'vue';
import { isRtl } from '@/plugins/i18n/helpers';

const MIN_SENSITIVE = 1;


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-split-view',
  props: {
  invert: { type: Boolean, default: false },
  nonOverlay: { type: Boolean, default: false },
  autoScale: { type: Boolean, default: false },
  resizable: { type: Boolean, default: true },
  autoShown: { type: Boolean, default: false },
  minWidth: { type: String as PropType<`${number}px` | `${number}rem` | `${number}%`>, default: '30%' },
  maxWidth: { type: String as PropType<`${number}px` | `${number}rem` | `${number}%`>, default: '50%' },
  hideToggle: { type: Boolean, default: false },
  hidePin: { type: Boolean, default: false },
  closeOnEscape: { type: Boolean, default: false },
},
  emits: ['open', 'close'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;

const convertSizeToPercent = (size: `${number}px` | `${number}rem` | `${number}%`): number => {
  const sizeInt = parseFloat(size);
  const widthPercent = splitViewRef.value?.offsetWidth || 0;

  if (size.endsWith('px')) {
    return (sizeInt / widthPercent) * 100;
  } else if (size.endsWith('rem')) {
    return ((sizeInt * 16) / widthPercent) * 100;
  }

  return sizeInt;
};

const manualMin = computed((): number => convertSizeToPercent(props.minWidth));
const manualMax = computed((): number => convertSizeToPercent(props.maxWidth || props.minWidth));
const minViewWidth = computed(() => Math.max(convertSizeToPercent('96px'), manualMin.value));

const isResizing = ref<boolean>(false);
const isPinned = ref<boolean>(false);
const splitViewRef = ref<HTMLDivElement | null>(null);
const splitViewDynamicRef = ref<HTMLDivElement | null>(null);
const dynamicContentPercent = ref<number>(0);
const lastDynamicContentPercent = ref<number>(0);
const isCollapsed = ref<boolean>(true);
const isResizable = computed(
  () => props.resizable && (isCollapsed.value || convertSizeToPercent(props.minWidth) !== convertSizeToPercent(props.maxWidth))
);

const dynamicWidth = computed((): string => {
  const max = `min(calc(${props.maxWidth} * var(--split-view-ratio)),100%)`;
  const min = `min(calc(${minViewWidth.value}% * var(--split-view-ratio)),100%)`;

  return isCollapsed.value ? `48px` : `max(min(${dynamicContentPercent.value}%,${max}),${min})`;
});

const mainWidth = computed((): string => `calc(100% - ${dynamicWidth.value})`);

const startResize = (event: MouseEvent) => {
  isResizing.value = true;
  document.body.style.cursor = 'ew-resize';
  document.body.style.userSelect = 'none';

  let debounce: ReturnType<typeof setTimeout>;
  const startX = event.clientX;
  const parentWidth = splitViewRef.value?.offsetWidth ?? 0;
  const contentWidth = splitViewDynamicRef.value!.querySelector('.split-view__panel--dynamic-content')!.clientWidth;
  const startPercent = (contentWidth / parentWidth) * 100;

  const onResize = (moveEvent: MouseEvent) => {
    const currentX = moveEvent.clientX;
    const diffX = ((currentX - startX) / parentWidth) * 100;
    const newPercent = isRtl() ? startPercent + diffX : startPercent - diffX;

    const isOverMinSensitive = Math.abs(diffX) > MIN_SENSITIVE;
    const isClosing = !props.hideToggle && !isCollapsed.value && isOverMinSensitive && minViewWidth.value > newPercent * 1.2;
    const isOpening = isCollapsed.value && newPercent > MIN_SENSITIVE;

    if (isClosing || isOpening) {
      onResizeEnd();
      toggleCollapse();

      return;
    }

    dynamicContentPercent.value = newPercent;
    lastDynamicContentPercent.value = dynamicContentPercent.value;

    clearTimeout(debounce);
    debounce = setTimeout(onResizeEnd, 500);
  };

  const onResizeEnd = () => {
    window.removeEventListener('mousemove', onResize);
    window.removeEventListener('mouseup', onResizeEnd);

    document.body.style.cursor = '';
    document.body.style.userSelect = '';
    isResizing.value = false;
  };

  window.addEventListener('mousemove', onResize);
  window.addEventListener('mouseup', onResizeEnd);
};

const onKeydown = (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    close();
  }
};

const toggleCollapse = () => {
  if (props.hideToggle && !isCollapsed.value) {
    return;
  }

  if (isCollapsed.value) {
    const maxViewWidth = convertSizeToPercent(props.maxWidth);

    dynamicContentPercent.value = lastDynamicContentPercent.value
      ? Math.min(Math.max(lastDynamicContentPercent.value, minViewWidth.value), maxViewWidth)
      : (manualMin.value + manualMax.value) / 2;

    isPinned.value = false;

    if (props.closeOnEscape) {
      window.addEventListener('keydown', onKeydown);
    }
  } else {
    lastDynamicContentPercent.value = dynamicContentPercent.value;
    dynamicContentPercent.value = 0;
    window.removeEventListener('keydown', onKeydown);
  }

  isCollapsed.value = !isCollapsed.value;
  emit(isCollapsed.value ? 'close' : 'open');
};

const close = () => {
  if (!isCollapsed.value) {
    toggleCollapse();
  }
};

const open = () => {
  if (isCollapsed.value) {
    toggleCollapse();
  }
};

const onClick = () => {
  if (props.hidePin) {
    return;
  }

  const mainContent = splitViewRef.value?.querySelector('.split-view__panel--main') as HTMLDivElement;

  if (!mainContent) {
    return;
  }

  const mainComputedStyle = window.getComputedStyle(mainContent);

  if (mainComputedStyle.opacity === '1') {
    return;
  }

  if (!isCollapsed.value && !isPinned.value) {
    toggleCollapse();
  }
};

onUnmounted(() => {
  window.removeEventListener('keydown', onKeydown);
});

watch(
  () => splitViewRef.value,
  () => {
    if (props.autoShown) {
      open();
    }
  }
);

__expose({ open, close, isCollapsed, isPinned });

return (_ctx: any,_cache: any) => {
  const _component_vz_icon = _resolveComponent("vz-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "header"),
    _createElementVNode("div", {
      ref_key: "splitViewRef",
      ref: splitViewRef,
      class: _normalizeClass([
      'split-view',
      {
        'split-view--auto-scale': __props.autoScale,
        'split-view--resizable': isResizable.value,
        'split-view--collapsed': isCollapsed.value,
        'split-view--pinned': isPinned.value,
        'split-view--resizing': isResizing.value,
        'split-view--non-overlay': __props.nonOverlay,
        'split-view--invert': __props.invert,
      },
    ])
    }, [
      _createElementVNode("div", {
        class: "split-view__panel split-view__panel--main",
        style: _normalizeStyle({ width: !_ctx.$slots['dynamic'] ? '100%' : mainWidth.value }),
        onClick: onClick
      }, [
        (!__props.invert)
          ? _renderSlot(_ctx.$slots, "default", { key: 0 })
          : _renderSlot(_ctx.$slots, "dynamic", {
              key: 1,
              close: close,
              open: open
            })
      ], 4),
      (_ctx.$slots['dynamic'])
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            ref_key: "splitViewDynamicRef",
            ref: splitViewDynamicRef,
            class: "split-view__panel split-view__panel--dynamic",
            style: _normalizeStyle({ width: dynamicWidth.value })
          }, [
            _createElementVNode("div", _mergeProps({ class: "split-view__panel--dynamic-divider" }, _toHandlers({ ...(isResizable.value ? { mousedown: startResize } : {}), ...(!__props.hideToggle ? {} : { dblclick: toggleCollapse }) }, true)), [
              _createElementVNode("div", _hoisted_1, [
                (!__props.hideToggle)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "split-view__panel--dynamic-buttons-toggle",
                      onMousedown: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
                      onClick: toggleCollapse
                    }, [
                      _createVNode(_component_vz_icon, {
                        size: "1rem",
                        name: "svg:arrow-right",
                        color: "mono-700",
                        "aria-label": _ctx.$t('GENERAL.COLLAPSE')
                      }, null, 8, ["aria-label"])
                    ], 32))
                  : _createCommentVNode("", true),
                (!isCollapsed.value && !__props.hidePin)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "split-view__panel--dynamic-buttons-pinned",
                      onMousedown: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (isPinned.value = !isPinned.value))
                    }, [
                      _createVNode(_component_vz_icon, {
                        size: "1rem",
                        name: "svg:thumbtack",
                        color: "mono-700",
                        type: isPinned.value ? 'solid' : 'regular',
                        "aria-label": _ctx.$t('GENERAL.THUMBTACK')
                      }, null, 8, ["type", "aria-label"])
                    ], 32))
                  : _createCommentVNode("", true),
                _renderSlot(_ctx.$slots, "actions")
              ])
            ], 16),
            _createElementVNode("div", {
              class: _normalizeClass(['split-view__panel--dynamic-content', { 'overflow-hidden': isCollapsed.value }])
            }, [
              (!__props.invert)
                ? _renderSlot(_ctx.$slots, "dynamic", {
                    key: 0,
                    close: close,
                    open: open
                  })
                : _renderSlot(_ctx.$slots, "default", { key: 1 })
            ], 2)
          ], 4))
        : _createCommentVNode("", true)
    ], 2)
  ], 64))
}
}

})