import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ms-auto" }
const _hoisted_2 = { class: "text-title-1" }
const _hoisted_3 = { class: "text-title-1" }

import { ref, onMounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'lobby-date-now',
  setup(__props) {

const day = ref('');
const date = ref('');

onMounted(() => {
  const today = new Date();

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  // Ambil hari dan tanggal hari ini
  day.value = days[today.getDay()]; // Mendapatkan hari (misal: Tuesday)
  const dayOfMonth = String(today.getDate()).padStart(2, '0'); // Mendapatkan tanggal dengan dua digit
  const month = months[today.getMonth()]; // Mendapatkan nama bulan
  const year = today.getFullYear(); // Mendapatkan tahun

  // Formatkan tanggal (misal: 01 October, 2024)
  date.value = `${dayOfMonth} ${month}, ${year}`;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(day.value), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(date.value), 1)
  ]))
}
}

})