import type { MutationTree } from 'vuex';
import type { GeneralState } from '@/store/client/client.types';
import type { BaseAddress, BaseOptions } from '@/shared/models';
import type { ListCompaniesRes } from 'src/views/employee/types';
import type { StateCache } from '@/store/services/store.cache';
import type { ListCurrenciesRes, ListPhoneCodesRes } from '@/store/client/models';
import {
  ADDRESSES_CACHE,
  COMPANIES_CACHE,
  CURRENCIES,
  PHONE_CODES,
  SET_ADDRESSES_CACHE,
  SET_COMPANIES_CACHE,
  SET_CURRENCIES,
  SET_PHONE_CODES,
} from '@/store/client/client.constants';
import { initialState } from '@/store/client/index';

const clientMutations: MutationTree<GeneralState> = {
  [SET_COMPANIES_CACHE]: (state, payload: StateCache<ListCompaniesRes['companies']>) => {
    state[COMPANIES_CACHE] = payload;
  },

  [SET_ADDRESSES_CACHE]: (state, payload: StateCache<BaseOptions<BaseAddress>>) => {
    state[ADDRESSES_CACHE] = payload;
  },

  [SET_CURRENCIES]: (state, payload?: ListCurrenciesRes['list']) => {
    state[CURRENCIES] = payload || initialState()[CURRENCIES];
  },

  [SET_PHONE_CODES]: (state, payload: ListPhoneCodesRes['list']) => {
    state[PHONE_CODES] = payload || initialState()[PHONE_CODES];
  },
};

export default clientMutations;
