class JSONViewer extends HTMLElement {
  private json: any = null;
  private isDarkMode: boolean = false;
  private pre: HTMLPreElement = document.createElement('pre');

  constructor() {
    super();
  }

  static get observedAttributes() {
    return ['json-data', 'dark', 'white-space'];
  }

  connectedCallback(): void {
    this.pre.style.overflow = 'auto';
    this.pre.style.width = '100%';
    this.pre.style.height = '100%';
    this.pre.style.direction = 'ltr';
    this.pre.style.padding = '4px 8px';
    this.replaceWith(this.pre);

    this.update();
  }

  attributeChangedCallback(name: string, oldValue: string | null, newValue: string | null): void {
    if (oldValue !== newValue) {
      this.update();
    }
  }

  private update(): void {
    try {
      const jsonData = this.getAttribute('json-data');
      this.json = JSON.parse(jsonData!);
      this.isDarkMode = this.getAttribute('dark') === 'true' || this.getAttribute('dark') === '';

      this.render();
    } catch (e) {
      this.pre!.textContent = 'Invalid JSON';
    }
  }

  render(): void {
    if (this.pre) {
      this.pre.style.whiteSpace = this.getAttribute('white-space') || 'pre';
      this.pre.style.backgroundColor = this.isDarkMode ? '#333' : '#f5f5f5';
      this.pre.style.color = this.isDarkMode ? '#ddd' : '#000';
      this.pre.style.border = this.isDarkMode ? '1px solid #444' : '1px solid #ccc';
    }

    if (!this.json) {
      this.pre!.textContent = 'No JSON provided';
      return;
    }

    this.pre!.innerHTML = this.syntaxHighlight(this.json);
  }

  syntaxHighlight(json: any): string {
    const jsonStr = JSON.stringify(json, null, 2);
    const isDarkMode = this.isDarkMode;

    return jsonStr
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+-]?\d+)?)/g, (match: string) => {
        let style = `color: ${isDarkMode ? 'orange' : 'darkorange'};`;

        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            style = `color: ${isDarkMode ? 'sandybrown' : 'brown'};`;
          } else {
            style = `color: ${isDarkMode ? 'lightgreen' : 'green'};`;
          }
        } else if (/true|false/.test(match)) {
          style = `color: ${isDarkMode ? 'cornflowerblue' : 'blue'};`;
        } else if (/null/.test(match)) {
          style = 'color: magenta;';
        }
        return `<span style="${style}">${match}</span>`;
      });
  }
}

// Define the custom element
customElements.define('json-viewer', JSONViewer);
