import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "vz-star-rating__stars" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

import { computed, ref } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-star-rating',
  props: {
  editable: { type: Boolean, default: false },
  showLabel: { type: Boolean, default: false },
  value: { type: [String, Number], default: 0 },
  size: { type: [String, Number], default: 32 },
  step: { type: [String, Number], default: 0.5 },
  total: { type: [String, Number], default: 5 },
},
  emits: ['input'],
  setup(__props) {

const props = __props;



const tempRate = ref<number | null>(null);
const currentRate = computed(() => tempRate.value || +props.value);

const fullStar = computed(() => require('@/assets/images/full-star.svg'));
const emptyStar = computed(() => require('@/assets/images/empty-star.svg'));

const rateStyle = computed(() => {
  const width = +props.total * +props.size;

  return { clipPath: `inset(0 ${width * ((+props.total - currentRate.value) / +props.total)}px 0 0)` };
});

const onMouseMove = (ev: MouseEvent): void => {
  const x = Math.min(Math.max(1, ev.offsetX), +props.size * +props.total);
  const width = +props.total * +props.size;

  tempRate.value = Math.round((+props.total / +props.step) * (x / width)) * +props.step;
};

const fullWidthStyle = computed(() => ({
  minWidth: +props.size * +props.total + 'px',
  height: props.size + 'px',
  lineHeight: props.size + 'px',
}));

const starWidthStyle = computed(() => ({ width: props.size + 'px', height: props.size + 'px' }));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "vz-star-rating",
    style: _normalizeStyle(fullWidthStyle.value)
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.total, (index) => {
        return (_openBlock(), _createElementBlock("img", {
          key: index,
          alt: "",
          role: "presentation",
          src: emptyStar.value,
          style: _normalizeStyle(starWidthStyle.value)
        }, null, 12, _hoisted_2))
      }), 128))
    ]),
    _createElementVNode("div", {
      class: "vz-star-rating__stars",
      style: _normalizeStyle(rateStyle.value)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.total, (index) => {
        return (_openBlock(), _createElementBlock("img", {
          key: index,
          alt: "",
          role: "presentation",
          src: fullStar.value,
          style: _normalizeStyle(starWidthStyle.value)
        }, null, 12, _hoisted_3))
      }), 128))
    ], 4),
    (__props.editable)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "vz-star-rating__stars clickable",
          role: "button",
          style: _normalizeStyle(fullWidthStyle.value),
          onMousemove: _withModifiers(onMouseMove, ["stop"]),
          onMouseleave: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (tempRate.value = null), ["stop"])),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('input', tempRate.value)))
        }, null, 36))
      : _createCommentVNode("", true),
    (__props.showLabel)
      ? (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: "text-subtitle-1 ms-1",
          style: _normalizeStyle({ paddingInlineStart: fullWidthStyle.value.minWidth })
        }, _toDisplayString(currentRate.value.toFixed(1)), 5))
      : _createCommentVNode("", true)
  ], 4))
}
}

})