import type { VzPopupConfig } from '@shared/components/popup/popup.types';
import { emitter } from '@/main';
import { useAsync } from '@shared/composables';
import { CLOSE_POPUP, OPEN_POPUP } from '@shared/components/popup/popup.constants';

export const openPopup = async (config: VzPopupConfig): Promise<void> => {
  return new Promise((resolve, reject) => {
    emitter.emit(OPEN_POPUP, {
      title: undefined,
      ...config,
      actions: config.actions.map(({ callback, ...rest }) => ({
        ...rest,
        ...{
          request: useAsync((callback || (() => null)) as () => any, {
            successCallback: () => emitter.emit(CLOSE_POPUP),
            failedCallback: (error) => {
              reject(error);
              emitter.emit(CLOSE_POPUP);
            },
          }),
        },
      })),
    });

    const onCloseEvent = () => {
      resolve();
      emitter.off(CLOSE_POPUP, onCloseEvent);
    };

    emitter.on(CLOSE_POPUP, onCloseEvent);
  });
};
