import type { JobActions, JobMutations, JobState } from '@/views/job/store/job.types';
import { StoreNamespace } from '@/store/store-namespace';
import { createNamespacedHelpers } from 'vuex-composition-helpers';
import store from '@/store';
import jobStore from '@/views/job/store';

const useJobStore = () => {
  if (!store.hasModule(StoreNamespace.JOBS_MODULE)) {
    store.registerModule(StoreNamespace.JOBS_MODULE, jobStore);
  }

  return createNamespacedHelpers<JobState, never, JobActions, JobMutations>(StoreNamespace.JOBS_MODULE);
};

export default useJobStore;
