import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import useClientStore from '@/store/client/composables/use-client-store';
import { GET_ATTACHMENT } from '@/store/client/client.constants';
import { computed, onMounted, PropType, ref } from 'vue';
import { GetAttachmentRes } from '@/store/client/models';
import { useAsync } from '@shared/composables';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-audio',
  props: {
  hid: { type: String, required: true },
  provider: { type: Function as PropType<((id: string) => Promise<any>) | undefined>, default: undefined },
},
  emits: ['end'],
  setup(__props, { expose: __expose }) {

const props = __props;



const { [GET_ATTACHMENT]: getAttachment } = useClientStore().useActions([GET_ATTACHMENT]);
const getAttachmentRequest = useAsync<GetAttachmentRes>((props.provider || getAttachment) as ({ id }: { id: string }) => Promise<GetAttachmentRes>);

const audioPlayerRef = ref();
const audioSource = computed(() => getAttachmentRequest.results.value?.data);

onMounted(() => {
  getAttachmentRequest.call({ id: props.hid });
});

__expose({ play: () => audioPlayerRef.value?.play() });

return (_ctx: any,_cache: any) => {
  const _component_audio_player = _resolveComponent("audio-player")!

  return (_openBlock(), _createBlock(_component_audio_player, {
    ref_key: "audioPlayerRef",
    ref: audioPlayerRef,
    src: audioSource.value,
    loading: _unref(getAttachmentRequest).loading.value,
    onEnd: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('end')))
  }, null, 8, ["src", "loading"]))
}
}

})