import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "message-share-attachment" }
const _hoisted_2 = { class: "flex-grow-1 mx-4 d-flex" }
const _hoisted_3 = {
  key: 0,
  class: "d-flex gap-4 justify-center flex-ch-1 mt-4 mx-4"
}

import { ref } from 'vue';
import VzAttachment from '@shared/components/vz-attachment.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'message-share-attachment',
  props: {
  clearable: { type: Boolean, default: false },
  readonly: { type: Boolean, default: false },
},
  emits: ['cancel', 'clear', 'update:attachments'],
  setup(__props, { emit: __emit }) {



const emit = __emit;

const files = ref<Array<File>>([]);

const onConfirm = () => {
  emit('update:attachments', { attachments: files.value });
};

return (_ctx: any,_cache: any) => {
  const _component_vz_button = _resolveComponent("vz-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(VzAttachment, {
        value: files.value,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((files).value = $event)),
        "accept-type": "image/jpeg,image/png,application/pdf"
      }, null, 8, ["value"])
    ]),
    (!__props.readonly)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_vz_button, {
            text: "GENERAL.CANCEL",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('cancel')))
          }),
          _createVNode(_component_vz_button, {
            text: "GENERAL.CONFIRM",
            onClick: onConfirm
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})