import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "vz-rich-text" }
const _hoisted_2 = {
  key: 0,
  class: "text-ellipsis mb-4"
}
const _hoisted_3 = { class: "vz-rich-text__container" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { key: 1 }

import type { CustomValidator, ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import { computed, type PropType } from 'vue';
import { useValidator } from '@shared/components/fields/helpers';
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-rich-text',
  props: {
  readonly: { type: Boolean, default: false },
  name: { type: String as PropType<string | undefined>, default: undefined },
  label: { type: String as PropType<string | undefined>, default: undefined },
  modelValue: { type: String as PropType<string | undefined>, required: true },
  hideDetails: { type: Boolean, default: false },
  errorMessage: { type: String as PropType<string | null | undefined>, default: null },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  dynamicFields: { type: Array as PropType<Array<{ text: string; value: string }> | undefined>, default: undefined },
  direction: { type: String as PropType<'ltr' | 'rtl' | undefined>, default: undefined },
},
  emits: ['update:model-value'],
  setup(__props, { emit: __emit }) {

const props = __props;

const { t } = useI18n();
const emit = __emit;

const dynamicFields = computed(() => {
  if (!props.dynamicFields) {
    return '';
  }

  const options = props.dynamicFields.map(({ text, value }) => ({ text: t(text), value }));

  return JSON.stringify(options);
});

const vModel = computed({
  get: (): string | undefined => props.modelValue || '',
  set: (value) => emit('update:model-value', value),
});

const onUpdate = (ev?: CustomEvent<{ htmlBody: string; direction?: string }>) => {
  if (ev?.detail?.htmlBody !== undefined) {
    vModel.value = ev.detail.htmlBody;
  }
};

const requiredHtmlValidator: CustomValidator = (value: string) => {
  return [!!value?.replace(/<[^>]*>/g, '').length, 'VALIDATE.REQUIRED'];
};

const vRules = computed((): ValidatorFieldRules | undefined => {
  return props.rules
    ? { ...props.rules, ...(props.rules?.required?.[0] ? { custom: [requiredHtmlValidator, ...(props.rules.custom || [])] } : {}) }
    : undefined;
});

const { validateMessage, isTouched } = useValidator(
  computed(() => props.modelValue),
  vRules,
  props.name || props.label || ''
);

return (_ctx: any,_cache: any) => {
  const _component_rich_text = _resolveComponent("rich-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.$t(__props.label)), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (!__props.readonly)
        ? (_openBlock(), _createBlock(_component_rich_text, {
            key: 0,
            class: "flex-grow-1",
            value: vModel.value,
            "dynamic-fields": dynamicFields.value,
            undo: _ctx.$t('COMPONENT.RICH_TEXT.UNDO'),
            redo: _ctx.$t('COMPONENT.RICH_TEXT.REDO'),
            bold: _ctx.$t('COMPONENT.RICH_TEXT.BOLD'),
            italic: _ctx.$t('COMPONENT.RICH_TEXT.ITALIC'),
            underline: _ctx.$t('COMPONENT.RICH_TEXT.UNDERLINE'),
            "font-size": _ctx.$t('COMPONENT.RICH_TEXT.FONT_SIZE'),
            "format-block": _ctx.$t('COMPONENT.RICH_TEXT.STYLES'),
            "justify-left": _ctx.$t('COMPONENT.RICH_TEXT.ALIGN_LEFT'),
            "justify-center": _ctx.$t('COMPONENT.RICH_TEXT.ALIGN_CENTER'),
            "justify-right": _ctx.$t('COMPONENT.RICH_TEXT.ALIGN_RIGHT'),
            "insert-ordered-list": _ctx.$t('COMPONENT.RICH_TEXT.ORDERED_LIST'),
            "insert-unordered-list": _ctx.$t('COMPONENT.RICH_TEXT.UNORDERED_LIST'),
            indent: _ctx.$t('COMPONENT.RICH_TEXT.INDENT'),
            outdent: _ctx.$t('COMPONENT.RICH_TEXT.OUTDENT'),
            "remove-format": _ctx.$t('COMPONENT.RICH_TEXT.REMOVE_FORMAT'),
            "doc-direction": _ctx.$t('COMPONENT.RICH_TEXT.DOCUMENT_DIRECTION'),
            "full-screen": _ctx.$t('COMPONENT.RICH_TEXT.FULL_SCREEN'),
            "insert-dynamic-field": _ctx.$t('COMPONENT.RICH_TEXT.INSERT_DYNAMIC_FIELD'),
            direction: __props.direction,
            onChange: onUpdate
          }, null, 8, ["value", "dynamic-fields", "undo", "redo", "bold", "italic", "underline", "font-size", "format-block", "justify-left", "justify-center", "justify-right", "insert-ordered-list", "insert-unordered-list", "indent", "outdent", "remove-format", "doc-direction", "full-screen", "insert-dynamic-field", "direction"]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "border-regular border-radius-regular flex-grow-1",
            innerHTML: vModel.value
          }, null, 8, _hoisted_4)),
      _createElementVNode("div", {
        class: _normalizeClass(['vz-rich-text__error', { 'vz-rich-text__error--hidden': __props.hideDetails }]),
        role: "alert"
      }, [
        (_unref(validateMessage))
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: _normalizeClass({ 'vz-rich-text__error-internal': !_unref(isTouched) })
            }, _toDisplayString(_ctx.$t(_unref(validateMessage))), 3))
          : (__props.errorMessage && !_unref(isTouched))
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t(__props.errorMessage)), 1))
            : _createCommentVNode("", true)
      ], 2)
    ])
  ]))
}
}

})