import type { MutationTree } from 'vuex';
import type { GetProjectDetailsRes, SearchProjectReq, SearchProjectRes } from '@/views/job/types/project.types';
import type { JobManagerProjectState } from '@/views/job/views/manager/modules/project/store/job-manager-project.types';
import {
  CLEAR_PROJECTS,
  PROJECT_DETAILS,
  PROJECT_FILTER,
  PROJECT_PAGINATION,
  PROJECTS,
  SET_PROJECT_DETAILS,
  SET_PROJECT_FILTER,
  SET_PROJECTS,
} from '@/views/job/views/manager/modules/project/store/job-manager-project.constants';
import { initialState } from '@/views/job/views/manager/modules/project/store/index';

const mutations: MutationTree<JobManagerProjectState> = {
  [CLEAR_PROJECTS]: (state) => {
    state[PROJECTS] = initialState()[PROJECTS];
    state[PROJECT_FILTER] = initialState()[PROJECT_FILTER];
    state[PROJECT_PAGINATION] = initialState()[PROJECT_PAGINATION];
  },

  [SET_PROJECTS]: (state, payload?: SearchProjectRes) => {
    state[PROJECTS] = payload?.data || initialState()[PROJECTS];
    state[PROJECT_PAGINATION] = payload?.page || initialState()[PROJECT_PAGINATION];
  },

  [SET_PROJECT_FILTER]: (state, payload?: Omit<SearchProjectReq, 'page'>) => {
    state[PROJECT_FILTER] = payload || initialState()[PROJECT_FILTER];
  },

  [SET_PROJECT_DETAILS]: (state, payload?: GetProjectDetailsRes) => {
    state[PROJECT_DETAILS] = payload || null;
  },
};

export default mutations;
