// actions
export const LIST_SKILLS = 'list-skills';
export const LIST_CLASSIFICATIONS = 'list-classifications';

// state
export const SKILLS = 'skills';
export const CLASSIFICATIONS = 'classifications';

// mutations
export const SET_SKILLS_CACHE = 'set-skills-cache';
export const SET_JOB_CLASSIFICATION = 'set-job-classification';
