// actions
export const SEARCH_JOB_OFFERS = 'search-job-offers';
export const GET_JOB_OFFER_DETAILS = 'get-job-offer-details';

// state
export const JOB_OFFERS = 'job-offers';
export const JOB_OFFERS_FILTER = 'job-offers-filter';
export const JOB_OFFERS_PAGINATION = 'job-offers-pagination';
export const JOB_OFFER_DETAILS = 'job-offer-details';

// mutations
export const SET_JOB_OFFERS = 'set-job-offers';
export const SET_JOB_OFFERS_FILTER = 'set-job-offers-filter';
export const SET_JOB_OFFER_DETAILS = 'set-position-details';
export const CLEAR_JOB_OFFERS = 'clear-job-offers';
