import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "message-share-location" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 2,
  class: "d-flex gap-4 justify-center flex-ch-1 mt-4 mx-4"
}

import type { Coordinates } from '@shared/types';
import { onMounted, type PropType, ref } from 'vue';
import { useAsync } from '@shared/composables';
import { navigatorGeoLocation } from '@shared/helpers';
import type { BaseAddress } from '@shared/models';
import LocationSelect from '@/components/fields/location-select.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'message-share-location',
  props: {
  coordinates: { type: Object as PropType<Coordinates | undefined>, default: undefined },
  clearable: { type: Boolean, default: false },
  readonly: { type: Boolean, default: true },
},
  emits: ['cancel', 'clear', 'update:coordinates'],
  setup(__props) {

const props = __props;



const localCoordinate = ref<Coordinates | undefined>(props.coordinates);
const coordinateRequest = useAsync<Coordinates | undefined>(navigatorGeoLocation, { resultsRef: localCoordinate });
const address = ref<BaseAddress | null>(null);

onMounted(() => {
  if (!props.readonly) {
    coordinateRequest.call();
  }
});

return (_ctx: any,_cache: any) => {
  const _component_vz_spinner = _resolveComponent("vz-spinner")!
  const _component_vz_close_button = _resolveComponent("vz-close-button")!
  const _component_vz_button = _resolveComponent("vz-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!__props.readonly && !_unref(coordinateRequest).loading.value)
      ? (_openBlock(), _createBlock(LocationSelect, {
          key: 0,
          modelValue: address.value,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => ((address).value = $event)),
            _cache[1] || (_cache[1] = () => (localCoordinate.value = address.value?.coordinates))
          ],
          class: "mx-4",
          clearable: "",
          label: "GENERAL.ADDRESS",
          rules: { required: [true] }
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (_unref(coordinateRequest).loading.value)
      ? (_openBlock(), _createBlock(_component_vz_spinner, {
          key: 1,
          class: "mt-4",
          size: "96px"
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (localCoordinate.value)
            ? (_openBlock(), _createElementBlock("iframe", {
                key: 0,
                class: "mx-4",
                src: `https://maps.google.com/maps?q=${localCoordinate.value[0]},${localCoordinate.value[1]}&hl=es&z=14&output=embed`
              }, null, 8, _hoisted_2))
            : _createCommentVNode("", true),
          (__props.clearable)
            ? (_openBlock(), _createBlock(_component_vz_close_button, {
                key: 1,
                class: "message-share-location__close-button",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('clear')))
              }))
            : _createCommentVNode("", true),
          (!__props.readonly)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_vz_button, {
                  text: "GENERAL.CANCEL",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('cancel')))
                }),
                _createVNode(_component_vz_button, {
                  text: "GENERAL.CONFIRM",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:coordinates', { coordinates: localCoordinate.value })))
                })
              ]))
            : _createCommentVNode("", true)
        ], 64))
  ]))
}
}

})