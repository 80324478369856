import type { ActionTree } from 'vuex';
import type { EmployeeState } from '@/views/employee/store/employee.types';
import type {
  GetReviewsReq,
  UpdateProfileReq,
  AddReviewReq,
  SearchEmployeesReq,
  SearchEmployeesRes,
  GetReviewRes,
  ProfileFullDetails,
} from 'src/views/employee/types';
import type { BaseId, BaseImageResponse, BaseRecords } from '@/shared/models';
import type { RootState } from '@/store';
import {
  ADD_REVIEW,
  CLEAR_EMPLOYEE_STATE,
  EMPLOYEE_DETAILS,
  EMPLOYEE_DETAILS_CACHE,
  EMPLOYEE_REVIEWS,
  GET_AVATAR,
  GET_COVER,
  GET_EMPLOYEE,
  GET_EMPLOYEE_DETAILS,
  GET_REVIEWS,
  SEARCH_EMPLOYEES,
  SET_EMPLOYEE_DETAILS,
  SET_EMPLOYEE_DETAILS_CACHE,
  SET_EMPLOYEE_REVIEWS,
  SET_EMPLOYEES,
  UPDATE_EMPLOYEE_DETAILS,
  UPDATE_EMPLOYEE_PROFILE,
} from '@/views/employee/store/employee.constants';
import { container } from 'tsyringe';
import EmployeeService from '@/views/employee/service/employee.service';
import { useRoot } from '@/store/helpers/use-root';
import { SET_LOADING } from '@/store/constants';
import StoreCache from '@/store/services/store.cache';
import { StoreNamespace } from '@/store/store-namespace';
import store from '@/store';
import { GET_AUTH_SESSION } from '@/store/auth/auth.constants';

const root = useRoot();
const employeeService = container.resolve(EmployeeService);

const employeeActions: ActionTree<EmployeeState, RootState> = {
  [GET_AVATAR]: async (_, payload: [string, boolean?]): Promise<BaseImageResponse> => {
    const [hid, noCache] = payload;

    return employeeService[GET_AVATAR](hid, noCache);
  },

  [GET_COVER]: async (_, payload: [string, boolean?]): Promise<BaseImageResponse> => {
    const [hid, noCache] = payload;

    return employeeService[GET_COVER](hid, noCache);
  },

  [SEARCH_EMPLOYEES]: async ({ commit }, payload?: SearchEmployeesReq): Promise<SearchEmployeesRes> => {
    const res = await employeeService[SEARCH_EMPLOYEES](payload);
    commit(SET_EMPLOYEES, res);

    return res;
  },

  [GET_EMPLOYEE]: async (_, userId: BaseId): Promise<ProfileFullDetails> => {
    return await employeeService[GET_EMPLOYEE_DETAILS](userId, 'BASE');
  },

  [GET_EMPLOYEE_DETAILS]: async ({ commit, state }, options?: { userId?: string; ignoreCache?: boolean }): Promise<void> => {
    const id = options?.userId || state[EMPLOYEE_DETAILS]!._id;

    if (!id) {
      return;
    }

    commit(SET_EMPLOYEE_DETAILS);
    commit(CLEAR_EMPLOYEE_STATE);

    try {
      const cacheService = new StoreCache(EMPLOYEE_DETAILS_CACHE, SET_EMPLOYEE_DETAILS_CACHE, StoreNamespace.EMPLOYEES_MODULE);
      root[SET_LOADING](id);

      const res = await (async (): Promise<ProfileFullDetails> => {
        if (options?.ignoreCache) {
          return await employeeService[GET_EMPLOYEE_DETAILS](id);
        }

        return cacheService.get<string, ProfileFullDetails>(id) || (await employeeService[GET_EMPLOYEE_DETAILS](id));
      })();

      commit(SET_EMPLOYEE_DETAILS, res);
    } finally {
      root[SET_LOADING](id);
    }
  },

  [GET_REVIEWS]: async ({ commit, state }, payload: GetReviewsReq): Promise<BaseRecords<GetReviewRes>> => {
    const res = await employeeService[GET_REVIEWS](payload || { userId: state[EMPLOYEE_DETAILS]!._id });
    commit(SET_EMPLOYEE_REVIEWS, res);

    return res;
  },

  [UPDATE_EMPLOYEE_PROFILE]: async ({ state, commit, dispatch }, payload: UpdateProfileReq): Promise<void> => {
    if (!state[EMPLOYEE_DETAILS]?._id) {
      return;
    }

    await employeeService[UPDATE_EMPLOYEE_PROFILE](payload);
    await dispatch(GET_EMPLOYEE_DETAILS, { ignoreCache: true });
    await store.dispatch(`${StoreNamespace.AUTH_MODULE}/${GET_AUTH_SESSION}`, ['user', 'impersonate']);

    if (payload.avatar !== undefined || payload.cover !== undefined) {
      await dispatch(GET_AVATAR, [state[EMPLOYEE_DETAILS]?._id, true]);
      await dispatch(GET_COVER, [state[EMPLOYEE_DETAILS]?._id, true]);
    }
  },

  [ADD_REVIEW]: async ({ state, commit, dispatch }, payload: AddReviewReq): Promise<void> => {
    const { userId = state[EMPLOYEE_DETAILS]?._id, ...rest } = payload;

    if (!userId) {
      return;
    }

    await employeeService[ADD_REVIEW]({ userId, ...rest });

    const { rating } = await employeeService[GET_EMPLOYEE_DETAILS](userId);
    commit(UPDATE_EMPLOYEE_DETAILS, { rating });
    await dispatch(GET_REVIEWS, { userId, page: state[EMPLOYEE_REVIEWS].page });
  },
};

export default employeeActions;
