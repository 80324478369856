<template>
  <div class="canvas-board-cropper" :data-errors="validateMessage">
    <p v-if="label" class="mb-2">{{ $t(label) }}</p>

    <div v-if="vModel" class="canvas-board-cropper__preview">
      <img :src="vModel" :height="height" :width="width" :alt="$t(label || 'COMPONENT_LABELS.IMAGE_CROPPER')" />

      <div>
        <vz-icon name="svg:trash" color="#fff" size="2rem" @click="onClear" />
      </div>
    </div>

    <div v-else class="canvas-board-cropper__cropper mb-2">
      <file-drop class="fill-width" @update="onFileDrop">
        <canvas-board
          ref="canvasBoardRef"
          :src="src"
          :width="width"
          :height="height"
          :background-color="backgroundColor || ''"
          :megapixel="megapixel"
          :type="type"
          @change="onChange"
        />
      </file-drop>

      <div v-if="!tempSrc" class="canvas-board-cropper__cropper-add-button">
        <vz-button icon-name="svg:plus" type="solid" @click="onUploadFile" />
      </div>
    </div>

    <div :class="['vz-input__error', { 'vz-input__error--hidden': hideDetails && !validateMessage && !externalError }]" role="alert">
      <p v-if="validateMessage" :class="{ 'vz-input__error-internal': !isTouched }">{{ $t(validateMessage) }}</p>
      <p v-else-if="externalError">{{ $t(externalError) }}</p>
    </div>

    <div v-if="!autoSave && !vModel && tempSrc" class="canvas-board-cropper__control">
      <vz-button text="GENERAL.SAVE" @click="onSave" />

      <vz-button text="GENERAL.CANCEL" @click="onClear" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType, ref, watch } from 'vue';
import FileService from '@shared/services/file.service';
import VzIcon from '@shared/components/icon/vz-icon.vue';
import { useValidator } from '@shared/components/fields/helpers';
import type { ValidatorFieldRules } from '@shared/services/validator/field-validator/field-validator.type';
import type { ErrorResponse } from '@shared/services/api-service/models';
import { useTranslator } from '@/plugins/i18n/helpers';

const t = useTranslator();

const props = defineProps({
  hideDetails: { type: Boolean, default: false },
  autoSave: { type: Boolean, default: false },
  modelValue: { type: String as PropType<string | null | undefined>, required: true },
  file: { type: File as PropType<File | undefined>, default: undefined },
  label: { type: String as PropType<string | undefined>, default: undefined },
  height: { type: [Number, String], default: 300 },
  width: { type: [Number, String], default: 400 },
  megapixel: { type: [String, Number], default: 2 },
  backgroundColor: { type: String as PropType<string | null>, default: '#fff' },
  type: { type: String as PropType<`image/${'jpeg' | 'png'}`>, default: 'image/jpeg' },
  rules: { type: Object as PropType<ValidatorFieldRules | undefined>, default: undefined },
  name: { type: String as PropType<string | undefined>, default: undefined },
  errorMessage: { type: [Object, String] as PropType<ErrorResponse | string | null | undefined>, default: null },
});

const emit = defineEmits(['update:model-value', 'update:file']);
const src = ref<string>('');
const tempSrc = ref<string>('');
const canvasBoardRef = ref();

const vModel = computed({
  get: (): string => props.modelValue || '',
  set: (value: string | null) => {
    emit('update:model-value', value);
    emit('update:file', value ? FileService.base64ToFile(value) : null);
  },
});

const { validateMessage, isTouched } = useValidator(
  computed(() => props.modelValue),
  computed(() => props.rules),
  props.name || props.label || 'COMPONENT_LABELS.IMAGE_CROPPER'
);

const externalError = computed(() => {
  if (!props.errorMessage) {
    return;
  }

  if (typeof props.errorMessage === 'string') {
    return props.errorMessage;
  }

  const { message, ...fields } = props.errorMessage.errorMessage!.pop() || {};

  return message ? t(message, { ...fields, ...(props.label ? { property: props.label } : {}) }) : undefined;
});

const onSave = (): void => {
  if (!tempSrc.value) {
    return;
  }

  vModel.value = tempSrc.value;
  tempSrc.value = '';
};

const onChange = ({ detail }: CustomEvent): void => {
  tempSrc.value = detail.base64;
};

const loadFile = async (file?: File): Promise<void> => {
  if (file) {
    src.value = await FileService.fileToBase64(file);
  }
};

const onUploadFile = async (): Promise<void> => {
  const files = await FileService.uploadFile({ accept: ['image/*'], multiple: false });

  await loadFile(files?.[0]);
};

const onFileDrop = async ({ detail }: CustomEvent): Promise<void> => {
  await loadFile(detail.files?.[0]);
};

const onClear = () => {
  src.value = '';
  tempSrc.value = '';
  canvasBoardRef.value?.remove();
  emit('update:model-value', null);
};

watch(
  () => tempSrc.value,
  (value) => {
    if (props.autoSave && value) {
      onSave();
    }
  }
);

defineExpose({ save: onSave });
</script>

<style scoped lang="scss">
.canvas-board-cropper {
  &__preview {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    > img {
      width: 100%;
      object-fit: contain;
      border: var(--border-regular);
    }

    &:hover {
      > :last-child {
        opacity: 1;
      }
    }

    > :last-child {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.7);
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  }

  &__cropper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &-add-button {
      position: absolute;
      width: 100%;
      height: calc(100% + 1rem);
      top: -0.5rem;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      border: 1px dashed var(--color-primary-900);
      border-radius: var(--border-radius-regular);
    }
  }

  &__control {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    margin: 0.5rem 1rem;

    &-group {
      display: flex;
      gap: 0.5rem;
      margin: 0 1rem;
    }
  }
}
</style>
