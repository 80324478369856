import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "vz-search-panel" }
const _hoisted_2 = {
  key: 0,
  class: "vz-search-panel__controls"
}

import { computed, ref } from 'vue';
import { useResizeObserver } from '@/shared/composables';
import { useFormValidator } from '@/shared/components/fields/helpers';

const MAX_HEIGHT = 88;


export default /*@__PURE__*/_defineComponent({
  __name: 'vz-search-panel',
  props: { hideControls: { type: Boolean, default: false } },
  emits: ['search', 'clear'],
  setup(__props, { emit: __emit }) {



const formRef = ref<Element | undefined>(undefined);
const emit = __emit;

const containerRef = ref<Element | undefined>(undefined);
const { maxHeight: containerHeight } = useResizeObserver(containerRef);

const isShowMore = ref<boolean>(false);

const isExtendable = computed((): boolean => containerHeight.value > MAX_HEIGHT * 1.5);
const containerStyle = computed(() => {
  if (!isExtendable.value) {
    return;
  }

  return isExtendable.value && isShowMore.value ? { maxHeight: `${containerHeight.value}px` } : { maxHeight: `${MAX_HEIGHT}px` };
});

const onSearch = async (): Promise<void> => {
  const isValid = useFormValidator(formRef);

  if (!isValid()) {
    return;
  }

  emit('search');
};

return (_ctx: any,_cache: any) => {
  const _component_vz_button = _resolveComponent("vz-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      ref_key: "formRef",
      ref: formRef,
      role: "form",
      autocomplete: "off",
      onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
    }, [
      _createElementVNode("div", {
        ref_key: "containerRef",
        ref: containerRef,
        class: "row-flex-3 overflow-hidden",
        style: _normalizeStyle(containerStyle.value)
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 4)
    ], 544),
    (!__props.hideControls)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_vz_button, {
            tabindex: "2",
            text: "GENERAL.CLEAR",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('clear')))
          }),
          _createVNode(_component_vz_button, {
            tabindex: "1",
            text: "GENERAL.SEARCH",
            onClick: onSearch
          }),
          (isExtendable.value)
            ? (_openBlock(), _createBlock(_component_vz_button, {
                key: 0,
                class: "vz-search-panel__fields--extend",
                type: "flat",
                "icon-size": "1rem",
                text: `GENERAL.${isShowMore.value ? 'SHOW_LESS' : 'SHOW_MORE'}`,
                "icon-name": isShowMore.value ? 'svg:arrow-up' : 'svg:arrow-down',
                onClick: _cache[2] || (_cache[2] = ($event: any) => (isShowMore.value = !isShowMore.value))
              }, null, 8, ["text", "icon-name"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})