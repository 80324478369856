import router from '@/router';
import Modules from '@/router/constants/modules';
import { routeTo } from '@shared/composables/route-to';

export const useRouteBack =
  (params?: Array<string>) =>
  async (step: number = -1): Promise<void> => {
    const filteredParams = params?.filter((value) => !!value && !!router.currentRoute.value.params[value]);

    if (filteredParams?.length) {
      const newParams = { ...router.currentRoute.value.params };
      const paramName = filteredParams[filteredParams.length - 1];
      delete newParams[paramName];

      await routeTo({ params: newParams });
    } else if (router.currentRoute.value.query?.from) {
      const { query } = router.currentRoute.value;

      await routeTo({ name: router.currentRoute.value.query.from as string, query });
    } else if (window.history.length > 1 && router.currentRoute.value.redirectedFrom) {
      router.go(step);
    } else {
      await routeTo({ name: Modules.HOME });
    }
  };
