import type { GroupsState } from '@/views/job/views/manager/modules/group/store/groups.types';
import type { RootState } from '@/store';
import type { Module } from 'vuex';
import settingsActions from './settings.actions';
import groupsMutations from '@/views/job/views/manager/modules/group/store/groups.mutations';
import { ACTIVE_GROUP } from '@/views/job/views/manager/modules/group/store/groups.constants';

export const initialState = (): GroupsState => ({
  [ACTIVE_GROUP]: null,
});

const settingsStore: Module<GroupsState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: settingsActions,
  mutations: groupsMutations,
};

export default settingsStore;
