import type { Module } from 'vuex';
import type { RootState } from '@/store';
import type { NotificationState } from '@/views/notifications/store/notification.types';
import notificationActions from './notification.actions';

export const initialState = (): NotificationState => ({});

const notificationStore: Module<NotificationState, RootState> = {
  namespaced: true,
  state: initialState(),
  actions: notificationActions,
};

export default notificationStore;
