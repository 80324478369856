export const objectToFormData = (obj: any, formData?: FormData, parentKey?: string | null): FormData => {
  formData = formData || new FormData();

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      const fieldName = parentKey ? `${parentKey}[${key}]` : key;

      if (value === undefined) {
        continue;
      }

      if (value instanceof File) {
        formData.append(fieldName, value);
      } else if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const arrayItem = value[i];

          objectToFormData({ [i]: arrayItem }, formData, fieldName);
        }
      } else if (typeof value === 'object') {
        objectToFormData(value, formData, fieldName);
      } else if (typeof value === 'string') {
        formData.append(fieldName, value);
      } else {
        formData.append(fieldName, JSON.stringify(value));
      }
    }
  }

  return formData;
};
