<template>
  <svg>
    <use :href="href" />
  </svg>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import type { SplashName } from '@shared/components/svg-href/svg-splash.type';

const props = defineProps({
  name: { type: String as PropType<SplashName | string>, required: true },
});

const href = computed(() => require(`@/assets/images/${props.name}.svg`) + `#${props.name}`);
</script>
