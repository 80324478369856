import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "notification" }

import NotificationsTabs from '@/views/notifications/components/notifications-tabs.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'notifications.view',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(NotificationsTabs)
  ]))
}
}

})