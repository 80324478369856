import type { AxiosRequestInterceptor } from '@/shared/services/api-service/models';
import { AxiosError, type InternalAxiosRequestConfig } from 'axios';
import store from '@/store';
import { GET_SESSION } from '@/store/auth/auth.constants';
import { StoreNamespace } from '@/store/store-namespace';
import { COORDINATES, LANGUAGE } from '@/store/constants';
import LocalStorageService from '@shared/services/local-storage.service';

export const addUserDetailsInterceptor: AxiosRequestInterceptor = {
  onFulfilled: (request: InternalAxiosRequestConfig) => {
    const { skills, specialization, currency, location, timezone } = store.getters[`${StoreNamespace.AUTH_MODULE}/${GET_SESSION}`] || {};

    const coordinates = store.getters[COORDINATES];
    const language = store.getters[LANGUAGE] || LocalStorageService.load(LANGUAGE);

    (request.headers || {}).timezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (language) {
      (request.headers || {}).lang = language;
    }

    if (skills) {
      (request.headers || {}).skills = skills;
    }

    if (specialization) {
      (request.headers || {}).specialization = specialization;
    }

    if (currency) {
      (request.headers || {}).currency = currency;
    }

    if (location?.coordinates || coordinates) {
      (request.headers || {}).coordinates = location?.coordinates || coordinates;
    }

    return request;
  },
  onRejected: (error: AxiosError) => Promise.reject(error),
};
