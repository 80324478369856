import type { RouteRecordRaw } from 'vue-router';
import Modules from '@/router/constants/modules';

const ProjectManagerPost = () =>
  import(/* webpackChunkName: "project-manager" */ '@/views/job/views/manager/modules/project/project-manager.view.vue');

const projectManagerRoutes: Readonly<RouteRecordRaw> = {
  path: `${Modules.PATHS[Modules.MANAGER.JOB_MANAGER_PROJECT]}/:projectId?`,
  name: Modules.MANAGER.JOB_MANAGER_PROJECT,
  component: ProjectManagerPost,
  meta: {
    parentName: Modules.MANAGER.JOB_MANAGER,
  },
};

export default projectManagerRoutes;
