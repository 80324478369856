import type { GetNotificationReq, GetNotificationsRes } from '@/views/notifications/store/notification.types';
import { inject, injectable } from 'tsyringe';
import { GET_NOTIFICATIONS } from '@/views/notifications/store/notification.constants';
import ApiService from '@shared/services/api-service/api.service';

@injectable()
export default class NotificationService {
  public constructor(@inject('ApiService') private apiService: ApiService) {}

  private baseUrl = '/notification';

  public async [GET_NOTIFICATIONS](params: GetNotificationReq): Promise<GetNotificationsRes> {
    return this.apiService.post<GetNotificationsRes>(`${this.baseUrl}/${GET_NOTIFICATIONS}`, params);
  }
}
