import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "vz-form__controls"
}
const _hoisted_2 = { class: "flex-grow-1" }

import { computed, type PropType, ref } from 'vue';
import type { FormActionButton } from '@shared/components/fields/vz-form/models';
import { useFormValidator } from '@shared/components/fields/helpers';
import VzErrorAlert from '@shared/components/vz-error-alert.vue';
import type { ErrorResponse } from '@shared/services/api-service/models';

export default /*@__PURE__*/_defineComponent({
  __name: 'vz-form',
  props: {
  hideActions: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  actions: { type: Array as PropType<Array<FormActionButton>>, default: () => [] },
  class: { type: [Object, Array, String] as PropType<string | Record<string, any> | Array<string | Record<string, any>>>, default: () => [] },
},
  emits: ['submit'],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;
const fieldClass = computed(() => (Array.isArray(props.class) ? props.class : [props.class]));

const formRef = ref<Element | undefined>(undefined);
const isLoading = ref<Record<string, boolean>>({});
const errors = ref<ErrorResponse | null>(null);

const validate = (isSilent?: boolean): boolean => {
  const isValid = useFormValidator(formRef, isSilent);

  return isValid();
};

const onAction = async (action: FormActionButton): Promise<void> => {
  if (action.validate && !validate()) {
    return;
  }

  isLoading.value[action.text] = true;

  try {
    await action.callback?.();

    emit('submit');
  } catch (e: any) {
    errors.value = e;
  } finally {
    isLoading.value[action.text] = false;
  }
};

__expose({ validate });

return (_ctx: any,_cache: any) => {
  const _component_vz_button = _resolveComponent("vz-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (errors.value)
      ? (_openBlock(), _createBlock(VzErrorAlert, {
          key: 0,
          errors: errors.value
        }, null, 8, ["errors"]))
      : _createCommentVNode("", true),
    _createElementVNode("form", _mergeProps({
      ref_key: "formRef",
      ref: formRef,
      class: "vz-form",
      role: "form",
      autocomplete: "off"
    }, _ctx.$attrs, {
      onSubmit: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
    }), [
      _createElementVNode("div", {
        class: _normalizeClass(fieldClass.value)
      }, [
        _renderSlot(_ctx.$slots, "default", { validate: validate })
      ], 2)
    ], 16),
    (_ctx.$slots['actions'] || __props.actions?.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "extra-actions")
          ]),
          (!__props.hideActions)
            ? _renderSlot(_ctx.$slots, "actions", { key: 0 }, () => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.actions, ({ callback, text, ...rest }, index) => {
                  return (_openBlock(), _createBlock(_component_vz_button, _mergeProps({
                    key: index,
                    tabindex: 0,
                    disabled: __props.loading || __props.disabled,
                    loading: isLoading.value[text],
                    ref_for: true
                  }, { text, ...rest }, {
                    type: "default",
                    class: "w-240",
                    "background-color": text.toLowerCase().includes('next') || text.toLowerCase().includes('submit') ? 'var(--color-primary-900)' : '',
                    color: text.toLowerCase().includes('next') || text.toLowerCase().includes('submit') ? 'var(--color-background-regular)' : '',
                    onClick: ($event: any) => (onAction({ callback, text, ...rest }))
                  }), null, 16, ["disabled", "loading", "background-color", "color", "onClick"]))
                }), 128))
              ])
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})