import { RouteRecordRaw } from 'vue-router';
import Modules from '@/router/constants/modules';
import SystemLog from './system-log.view.vue';

const backofficeLogRoutes: Readonly<RouteRecordRaw> = {
  path: Modules.PATHS[Modules.BACKOFFICE_LOG],
  name: Modules.BACKOFFICE_LOG,
  component: SystemLog,
  meta: {
    parentName: Modules.BACKOFFICE_ADMIN,
  },
  children: [],
};

export default backofficeLogRoutes;
