import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import VzPageWrapper from '@shared/components/vz-page-wrapper.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'backoffice.view',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(VzPageWrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_router_view, null, {
        default: _withCtx(({ Component, route }) => [
          (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: route.path
          }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})