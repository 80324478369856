<template>
  <slot v-if="$slots['activator']" name="activator" :toggle="toggle" :is-shown="isShown" v-bind="activatorBind" />

  <teleport v-if="isModalOpen" to="body">
    <div :class="['vz-overlay', { 'vz-overlay--hidden': !isShown, 'vz-overlay--background': transperant }]" v-bind="$attrs" :style="style">
      <div
        v-drag-resize="{ isResizable: !nonResizable, isDraggable: !nonDraggable, title: $t(title) }"
        :class="contentClass"
        :style="initContentStyle"
      >
        <slot v-if="loading || openCallbackRequest.loading.value" name="loader">
          <div class="d-flex align-center justify-center flex-grow-1 min-height-320">
            <vz-spinner size="96px" />
          </div>
        </slot>

        <slot v-else-if="isShown" v-bind="activatorBind" :errors="errorFields" :server-errors="serverErrors" :close="() => setShownOff()" />

        <div class="vz-overlay-modal__close-button">
          <slot name="close-button">
            <vz-close-button v-if="!hideCloseButton" size="18" @click="() => activator(false)" />
          </slot>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import type { ErrorResponse } from '@/shared/services/api-service/models';
import { computed, onBeforeMount, onUnmounted, type PropType, ref, type StyleValue, watch } from 'vue';
import { emitter } from '@/main';
import { VzOverlayEnum } from '@shared/components/overlay/vz-overlay.enum';
import { useAsync, useServerErrorsMapper } from '@shared/composables';
import { useAsyncMiddleware } from '@shared/composables/use-async-middleware';
import ServerError from '@shared/services/api-service/server-error';
import { openWarningPopup } from '@shared/components/popup/helpers/open-warning-popup';
import { useOverlayState } from '@shared/components/overlay/use-overlay-state';
import { isMobile, uniqueKey } from '@shared/helpers';
import { SizeUnit } from '@shared/types';

const props = defineProps({
  title: { type: String, default: '' },
  nonResizable: { type: Boolean, default: false },
  nonDraggable: { type: Boolean, default: false },
  transperant: { type: Boolean, default: false },
  isOpen: { type: Boolean as PropType<boolean | null>, default: null },
  openEvent: { type: String as PropType<string | undefined>, default: undefined },
  closeEvent: { type: String as PropType<string | undefined>, default: undefined },
  openCallback: { type: Function as PropType<(payload?: any) => Promise<unknown> | unknown>, default: undefined },
  closeCallback: { type: Function as PropType<(payload?: any) => Promise<unknown> | unknown>, default: undefined },
  warningCallback: { type: [Boolean, Function] as PropType<boolean | ((payload?: any) => Promise<unknown>)>, default: false },
  loading: { type: Boolean, default: false },
  disableEscape: { type: Boolean, default: false },
  fitContent: { type: Boolean, default: false },
  hideCloseButton: { type: Boolean, default: false },
  size: { type: String as PropType<keyof typeof VzOverlayEnum | undefined>, default: undefined },
  errors: { type: Object as PropType<ErrorResponse | null>, default: () => null },
  style: { type: Object as PropType<StyleValue | undefined>, default: undefined },
  class: { type: [Object, Array, String] as PropType<string | Record<string, any> | Array<string | Record<string, any>>>, default: () => [] },
  height: { type: String as PropType<SizeUnit | undefined>, default: undefined },
  width: { type: String as PropType<SizeUnit | undefined>, default: undefined },
});

const initContentStyle = (() => {
  const style = !isMobile
    ? {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }
    : {};

  const height = props.fitContent ? 'fit-content' : props.height;
  const width = props.width;

  switch (props.size) {
    case VzOverlayEnum.SMALL:
      return {
        width: width || '512px',
        height: height || 'fit-content',
        ...style,
      };
    case VzOverlayEnum.MEDIUM:
      return {
        width: width || '900px',
        height: height || '90vh',
        ...style,
      };
    case VzOverlayEnum.LARGE:
      return {
        width: width || '1024px',
        height: height || '90vh',
        ...style,
      };
    case VzOverlayEnum.XLARGE:
      return {
        width: width || '1280px',
        height: height || '95vh',
        ...style,
      };
    default:
      return {
        width: width || '100vw',
        height: height || '100vh',
      };
  }
})();

const emit = defineEmits(['update:is-open', 'open', 'close']);
const key = uniqueKey();
const state = useOverlayState(key);

const contentClass = computed(() => [
  `vz-overlay-modal`,
  {
    'vz-overlay-modal--hidden': !isShown.value,
    [`vz-overlay-modal--${props.size?.toLowerCase()}`]: !!props.size,
    'vz-overlay-modal--fit-height': props.fitContent,
  },
  ...(Array.isArray(props.class) ? props.class : [props.class]),
]);

const closeErrors = ref<ServerError | null>(null);
const serverErrors = computed(() => props.errors || closeErrors.value);
const errorFields = useServerErrorsMapper(serverErrors);

const isShown = ref<boolean>(false);
const isModalOpen = ref<boolean>(false);

const openCallbackRequest = useAsync(props.openCallback as (payload?: any) => Promise<unknown>);

onBeforeMount(() => {
  if (props.openEvent) {
    emitter.on(props.openEvent, setShownOn);
  }
});

onUnmounted(() => {
  if (props.openEvent) {
    emitter.off(props.openEvent, setShownOn);
  }

  if (props.closeEvent) {
    emitter.off(props.closeEvent, setShownOff);
  }
});

const onEscape = (ev: KeyboardEvent) => {
  if (props.disableEscape || ev.key !== 'Escape' || state.active.value !== key) {
    return;
  }

  const richTextActive = document.querySelector('.rich-text--full-screen');

  if (richTextActive) {
    return;
  }

  ev.preventDefault();
  ev.stopPropagation();
  setShownOff();
};

const open = (payload?: any) => {
  state.set();
  if (props.openEvent) {
    emitter.off(props.openEvent, setShownOn);
  }

  if (props.closeEvent) {
    emitter.on(props.closeEvent, setShownOff);
  }

  if (props.isOpen !== null) {
    emit('update:is-open', true);
  }

  isModalOpen.value = true;
  emit('open', payload);
  setTimeout(() => (isShown.value = true), 500);

  window.addEventListener('keydown', onEscape);
};
const close = (payload?: any) => {
  props.closeCallback?.(payload);

  if (props.openEvent) {
    emitter.on(props.openEvent, setShownOn);
  }

  if (props.closeEvent) {
    emitter.off(props.closeEvent, setShownOff);
  }

  if (props.isOpen !== null) {
    emit('update:is-open', false);
  }

  closeErrors.value = null;
  isShown.value = false;
  emit('close', payload);
  window.removeEventListener('keydown', onEscape);

  setTimeout(() => (isModalOpen.value = false), 500);
  state.unset();
};

const setShownOn = async (payload?: any): Promise<void> => {
  openCallbackRequest.call(payload);

  open(payload);
};

const setShownOff = async (forceClose: boolean = false): Promise<void> => {
  if (!isShown.value) {
    return;
  } else if (props.warningCallback && !forceClose) {
    const isFunction = typeof props.warningCallback === 'function';

    closeErrors.value = await useAsyncMiddleware(
      isFunction ? props.warningCallback?.() : openWarningPopup({ iconName: 'svg:warning', iconColor: 'red-600', yesColor: 'red-900' }),
      close,
      isFunction
    );
  } else {
    close();
  }
};

const activator = (state: boolean = isShown.value): Promise<void> => (state ? setShownOn : setShownOff)();
const activatorBind = { open: () => activator(true), close: () => activator(false) };

const toggle = () => (!isShown.value ? setShownOn : setShownOff)();

watch(
  () => props.isOpen,
  (isOpen) => (isOpen ? setShownOn : setShownOff)(),
  { immediate: true }
);

defineExpose({ open: setShownOn, close: (isForce?: boolean) => setShownOff(isForce), toggle, errors: serverErrors });
</script>

<style lang="scss">
.vz-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  height: 100%;
  display: flex;

  //@include min-tablet-layout {
  //  transition: transform 0.5s;
  //}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    transition: opacity 2s;
    opacity: 1;
  }

  &--hidden {
    @include max-tablet-layout {
      transform: translateY(100%);
    }

    @include min-tablet-layout {
      opacity: 0;

      .vz-overlay-modal {
        transform: scale(0.7);
      }
    }
  }

  &--background:before {
    opacity: 0;
  }

  @include max-tablet-layout {
    height: 100%;
  }

  &-modal {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: start;
    //transition: 0.5s;
    max-height: 100%;
    box-shadow: var(--shadow-menu);
    margin: auto;

    @include min-tablet-layout {
      position: relative;
      //margin: auto;
      width: fit-content;
      max-width: 100%;
      border-radius: var(--border-radius-regular);
      background-color: var(--color-background-regular);
      border: var(--border-regular);
    }

    @include max-tablet-layout {
      position: absolute;
      bottom: 0;
      margin: 0;
      max-width: 100vw !important;
      min-width: 100vw !important;
      max-height: 100vh !important;
      min-height: 100vh !important;
      background-color: var(--color-background-light);
      transform: translateY(0);
      padding-bottom: 2rem;
    }

    &--fit-height {
      @include min-tablet-layout {
        min-height: initial;
        height: fit-content;
      }
    }

    &__close-button {
      height: 24px;
      width: 24px;
      @include inline-end(0.5rem);
      position: absolute;
      top: 0.25rem;
    }
  }

  .vz-form {
    padding: 0;
  }
}
</style>
