// actions
export const INVITES_CONVERSATION = 'invites-conversation';
export const CREATE_CONVERSATION = 'create-conversation';
export const GET_CONVERSATION = 'get-conversation';
export const LEAVE_CONVERSATION = 'leave-conversation';
export const UPDATE_CONVERSATION_STATUS = 'update-conversation-status';
export const UPDATE_CONVERSATION_AGREEMENTS = 'update-conversation-agreements';
export const GET_CONVERSATION_MESSAGES = 'get-conversation-messages';
export const ADD_TRANSACTION_TO_CONVERSATION = 'add-transaction-to-conversation';

// state
export const CONVERSATION = 'conversation';

// mutations
export const SET_CONVERSATION = 'set-conversation';
export const UPDATE_CONVERSATION = 'update-conversation';
export const SEND_CONVERSATION_MESSAGE = 'send-conversation-message';
export const UPDATE_CONVERSATION_LAST_SEEN = 'update-conversation-last-seen';
