import type { BaseResponse, BaseId } from '@/shared/models';
import {
  GetProjectDetailsRes,
  RemoveFromProjectReq,
  SummarizeProjectAgreementRes,
  SendProjectAgreementReq,
  SummarizeProjectAgreementReq,
  ProjectAgreementManualApprovalReq,
  GetProjectFilesReq,
  GetProjectFilesRes,
} from '@/views/job/types';
import type { CreateProjectReq, SearchProjectReq, SearchProjectRes, UpdateProjectReq } from '@/views/job/types/project.types';
import ApiService from '@/shared/services/api-service/api.service';
import { inject, injectable } from 'tsyringe';
import Modules from '@/router/constants/modules';
import {
  CREATE_AGREEMENT_PDF,
  CREATE_PROJECT,
  DELETE_PROJECT,
  GET_PROJECT_DETAILS,
  PROJECT_AGREEMENT_MANUAL_APPROVAL,
  GET_PROJECT_FILES,
  REMOVE_FROM_PROJECT,
  SEARCH_PROJECTS,
  SEND_PROJECT_AGREEMENT,
  SUMMARIZE_PROJECT_AGREEMENT,
  UPDATE_PROJECT,
  UPLOAD_PROJECT_FILES,
  GET_PROJECT_FILE,
} from '@/views/job/views/manager/modules/project/store/job-manager-project.constants';
import FileService from '@shared/services/file.service';
import { DOMAIN } from '@shared/constants';

const UPLOAD_TIMEOUT = 60 * 60 * 1000; // 3 minutes

@injectable()
export default class JobManagerProjectService {
  public constructor(@inject('ApiService') private apiService: ApiService) {}

  private baseUrl = `/${Modules.MANAGER.JOB_MANAGER_PROJECT}`;

  public [PROJECT_AGREEMENT_MANUAL_APPROVAL](payload: ProjectAgreementManualApprovalReq): Promise<BaseResponse> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${PROJECT_AGREEMENT_MANUAL_APPROVAL}`, payload);
  }

  public [CREATE_PROJECT](payload: CreateProjectReq): Promise<BaseResponse & { projectId: string }> {
    return this.apiService.post<BaseResponse & { projectId: string }>(`${this.baseUrl}/${CREATE_PROJECT}`, payload);
  }

  public [GET_PROJECT_DETAILS](projectId: BaseId, userId?: BaseId): Promise<GetProjectDetailsRes> {
    return this.apiService.post<GetProjectDetailsRes>(`${this.baseUrl}/${GET_PROJECT_DETAILS}/${projectId}`, { userId }, { allowOnceAtTime: true });
  }

  public [UPDATE_PROJECT](payload: UpdateProjectReq): Promise<BaseResponse> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${UPDATE_PROJECT}`, payload);
  }

  public [DELETE_PROJECT](projectId: BaseId, userId?: BaseId): Promise<BaseResponse> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${DELETE_PROJECT}/${projectId}`, { userId });
  }

  public [SEARCH_PROJECTS](payload: SearchProjectReq): Promise<SearchProjectRes> {
    return this.apiService.post<SearchProjectRes>(`${this.baseUrl}/${SEARCH_PROJECTS}`, payload, { allowOnceAtTime: true });
  }

  public [REMOVE_FROM_PROJECT](payload: RemoveFromProjectReq): Promise<BaseResponse> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${REMOVE_FROM_PROJECT}`, payload);
  }

  public [SUMMARIZE_PROJECT_AGREEMENT](
    projectId: BaseId,
    agreementKey: string,
    data: Omit<SummarizeProjectAgreementReq, 'projectId' | 'agreementKey'>
  ): Promise<SummarizeProjectAgreementRes> {
    return this.apiService.post<SummarizeProjectAgreementRes>(`${this.baseUrl}/${SUMMARIZE_PROJECT_AGREEMENT}/${projectId}/${agreementKey}`, data);
  }

  public [SEND_PROJECT_AGREEMENT](payload: SendProjectAgreementReq): Promise<BaseResponse> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${SEND_PROJECT_AGREEMENT}`, payload);
  }

  public [CREATE_AGREEMENT_PDF](
    projectId: BaseId,
    agreementKey: string,
    data: Omit<SummarizeProjectAgreementReq, 'projectId' | 'agreementKey'>
  ): Promise<string> {
    return this.apiService.post<string>(`${this.baseUrl}/${CREATE_AGREEMENT_PDF}/${projectId}/${agreementKey}`, data);
  }

  public [UPLOAD_PROJECT_FILES](projectId: BaseId, formData: FormData): Promise<BaseResponse> {
    return this.apiService.post<BaseResponse>(`${this.baseUrl}/${UPLOAD_PROJECT_FILES}/${projectId}`, formData, { timeout: UPLOAD_TIMEOUT });
  }

  public [GET_PROJECT_FILES](projectId: BaseId, data: Omit<GetProjectFilesReq, 'projectId'>): Promise<GetProjectFilesRes> {
    return this.apiService.post<GetProjectFilesRes>(`${this.baseUrl}/${GET_PROJECT_FILES}/${projectId}`, data);
  }

  public [GET_PROJECT_FILE](projectId: BaseId, key: string, fileName: string): void {
    FileService.downloadLink(DOMAIN + `${this.baseUrl}/${GET_PROJECT_FILE}/${projectId}/${key}`, fileName);
  }
}
