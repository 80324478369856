import { createStore } from 'vuex';
import { actions } from '@/store/actions';
import { getters } from '@/store/getters';
import { mutations } from '@/store/mutations';
import { StoreNamespace } from '@/store/store-namespace';
import authStore from '@/store/auth';
import generalStore from '@/store/client';
import { COORDINATES, LANGUAGE, LOADING } from '@/store/constants';
import { AvailableLanguages } from '@/plugins/i18n/available-languages.enum';
import jobStore from '@/views/job/store';
import employeeStore from '@/views/employee/store';
import notificationStore from 'src/views/notifications/store';
import LocalStorageService from '@shared/services/local-storage.service';
import { setLanguage } from '@/plugins/i18n/helpers';
import settingsStore from '@/views/settings/store';
import jobManagerProjectStore from '@/views/job/views/manager/modules/project/store';

export interface RootState {
  [LOADING]: Array<string>;
  [LANGUAGE]: keyof typeof AvailableLanguages;
  [COORDINATES]: [number, number] | null;
}

export const rootState: RootState = (() => {
  const language = (LocalStorageService.load<string>(LANGUAGE) || window.navigator.languages![0] || window.navigator.language).slice(0, 2);
  setLanguage(language);

  return {
    [LOADING]: [],
    [LANGUAGE]: language as keyof typeof AvailableLanguages,
    [COORDINATES]: null,
  };
})();

const store = createStore({
  state: rootState,
  getters,
  mutations,
  actions: actions,
  modules: {
    [StoreNamespace.AUTH_MODULE]: authStore,
    [StoreNamespace.NOTIFICATION_MODULE]: notificationStore,
    [StoreNamespace.GENERAL_MODULE]: generalStore,
    [StoreNamespace.EMPLOYEES_MODULE]: employeeStore,
    [StoreNamespace.JOBS_MODULE]: jobStore,
    [StoreNamespace.SETTINGS_MODULE]: settingsStore,
    [StoreNamespace.JOB_MANAGER_PROJECT_MODULE]: jobManagerProjectStore,
  },
});

export default store;
